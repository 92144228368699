interface LowItemProps {
  className?: string;
  vendor: string;
}

export const WorstPerformingItem: React.FC<LowItemProps> = ({ className, vendor }) => {
  const vendorData = {
    Thelios: {
      imgSrc: '/media/glasses/thelios/304692.png',
      itemName: 'CD Diamond SI DM40072I DIOR Sunglasses',
      sales: 'Recent 30-Day Sales: 2',
    },
    Luxottica: {
      imgSrc: '/media/glasses/luxottica/313371.jpg',
      itemName: 'DOLCE & GABBANA - 0DG4415 502/13 56',
      sales: 'Recent 30-Day Sales: 0',
    },
  };

  const currentVendorData = vendorData[vendor];


  const cardStyle: React.CSSProperties = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '14em',
    maxHeight: '20em',
    overflow: 'hidden',
  };

  const flexItemStyle: React.CSSProperties = {
    flexShrink: 1,
    flexGrow: 0,
    flexBasis: 'auto',
  };

  const topSectionStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.5rem 0 1rem 0',
    ...flexItemStyle,
  };

  const titleStyle: React.CSSProperties = {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    flex: 1,
    marginBottom: '0.5rem',
  };

  const imageContainerStyle: React.CSSProperties = {
    maxHeight: '40%',
    maxWidth: '40%',
    overflow: 'hidden',
  };

  const topImageStyle: React.CSSProperties = {
    maxWidth: '100%',
    objectFit: 'cover',
    height: 'auto',
    borderRadius: '0.5rem',
  };

  const bottomContentStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 0 1rem 0',
    textAlign: 'center',
    ...flexItemStyle,
  };

  return (
    <div className={`card ${className}`} style={cardStyle}>
      <div style={{maxHeight: '100%', maxWidth: '100%'}}>
        <div style={topSectionStyle}>
          <div style={titleStyle}>
            Low Selling
          </div>
          <div style={imageContainerStyle}>
            <img
              src={currentVendorData.imgSrc}
              alt='Low Performing Item'
              style={topImageStyle}
            />
          </div>
        </div>

        <div style={bottomContentStyle}>
          <div>
            <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
              {currentVendorData.itemName}
            </div>
            <div>{currentVendorData.sales}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
