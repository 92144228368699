import React, { FC } from 'react';
import { useAccount } from '../../../../../../widgets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone,faLocationDot } from '@fortawesome/free-solid-svg-icons';

type AccountTableItems = {
  AccountID: number;
  Name: string;
  ContactInfo: string;
  Address: string;
  Region: string;
  BusinessName: string;
  ImageUrl: string;
};

const dummyAccountData: AccountTableItems[] = [
  {
    AccountID: 1,
    Name: 'Vero Aguilar',
    ContactInfo: '209-847-9592',
    Address: '1074 José E Arraras Ste 2 Terrace, 00680, Mayaguez, Puerto Rico',
    Region: 'Puerto Rico',
    BusinessName: 'SunnyShades LLC',
    ImageUrl: '/media/profile/randomguy.jpg'
  },
  {
    AccountID: 2,
    Name: 'Jamie Torres',
    ContactInfo: '787-555-0147',
    Address: '1502 Ponce de León, Santurce, 00909, San Juan, Puerto Rico',
    Region: 'Puerto Rico',
    BusinessName: 'IslandEyewear Inc.',
    ImageUrl: ''
  },
  {
    AccountID: 3,
    Name: 'Luisa Martínez',
    ContactInfo: '787-555-0234',
    Address: '18 Calle San José, San Juan, 00901, Puerto Rico',
    Region: 'Puerto Rico',
    BusinessName: 'CoastalViews Optics',
    ImageUrl: ''
  },
  {
    AccountID: 4,
    Name: 'Roberto Vazquez',
    ContactInfo: '787-555-0333',
    Address: 'Calle Loiza 1765, San Juan, 00911, Puerto Rico',
    Region: 'Puerto Rico',
    BusinessName: 'Visionary Lenses PR',
    ImageUrl: ''
  },
  {
    AccountID: 5,
    Name: 'Yael Cohen',
    ContactInfo: '03-555-0147',
    Address: 'Ha-Yarkon St 88, Tel Aviv',
    Region: 'Tel Aviv',
    BusinessName: 'OpticCity Ltd.',
    ImageUrl: ''
  },
  {
    AccountID: 6,
    Name: 'Eitan Levy',
    ContactInfo: '03-555-0923',
    Address: 'Ben Yehuda St 45, Tel Aviv',
    Region: 'Tel Aviv',
    BusinessName: 'Visionary Frames Ltd.',
    ImageUrl: ''
  },
  {
    AccountID: 7,
    Name: 'Noa Mizrahi',
    ContactInfo: '02-655-0182',
    Address: 'King George St 23, Jerusalem',
    Region: 'Jerusalem',
    BusinessName: 'Jerusalem Optics Co.',
    ImageUrl: ''
  },
  {
    AccountID: 8,
    Name: 'Amir Rosen',
    ContactInfo: '08-643-9872',
    Address: 'Herzl St 67, Beersheba',
    Region: 'South District',
    BusinessName: 'Desert View Eyewear Ltd.',
    ImageUrl: ''
  },
  {
    AccountID: 9,
    Name: 'Tamar Schwartz',
    ContactInfo: '04-853-2345',
    Address: 'Hahistadrut Blvd 12, Haifa',
    Region: 'Haifa',
    BusinessName: 'Haifa Sightline Optics Ltd.',
    ImageUrl: ''
  },
  {
    AccountID: 10,
    Name: 'Omer Katz',
    ContactInfo: '09-852-4567',
    Address: 'Weizmann St 88, Netanya',
    Region: 'Central District',
    BusinessName: 'ClearVision Optics Ltd.',
    ImageUrl: ''
  }
]  

const AccountInfoCard: FC = () => {
  const { selectedAccountID } = useAccount();

  const selectedAccountDetails = dummyAccountData.find((account) => account.AccountID === parseInt(selectedAccountID));

  return (
    <div className="card" 
      style={{ background: 'linear-gradient(45deg,#F1467A,#FB949E)', 
                fontSize: '1.2rem', 
                minHeight: '18.5rem', }}
    >
      <div className="d-flex flex-column mt-4 mx-4">
        <div className="d-flex flex-row mb-4">
          {selectedAccountDetails?.ImageUrl !== ''
            ? <div className="d-flex justify-content-center align-content-center" style={{ height: '100px', width: '100px' }}>
              <img src={selectedAccountDetails?.ImageUrl} alt="profile" style={{ height: '100px', width: '100px', borderRadius: '20%' }} />
            </div>
            : <div className="d-flex justify-content-center align-content-center" style={{ border: '1px dashed white', height: '100px', width: '100px' }}>
              <FontAwesomeIcon icon={faUser} style={{ color: 'white', fontSize: '6rem', paddingTop: '11%' }} />
            </div>
          }

          <div className="d-flex flex-column ms-10">
            <div className="fw-bolder fs-1">{selectedAccountDetails?.Name}
              <div className="d-flex flex-row justify-content-between fw-bold fs-2 my-2">
                <div>{selectedAccountDetails?.BusinessName}</div>
              </div>
            </div>

            <div className="d-flex">
              <div className="fw-bold">Account ID:</div>
              <div className="ms-2">{selectedAccountDetails?.AccountID}</div>
            </div>
          </div>
        </div>

        <div className="my-4" style={{ borderBottom: "1px solid rgba(247, 247, 247, 0.5)", margin: "0 2% 0 2%"}}></div>

        <div className="d-flex flex-row align-items-center fw-bold my-2" >
          <FontAwesomeIcon icon={faPhone} style={{ fontSize: '1.2rem' }} />
          <div className="ms-4 fs-6">{selectedAccountDetails?.ContactInfo}</div>
        </div>

        <div className="d-flex flex-row align-items-center fw-bold" style={{ margin: '11.5px 0' }}>
          <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: '1.2rem' }} />
          <div className='ms-4 fs-6'>{selectedAccountDetails?.Address}</div>
        </div>
      </div>
    </div>
  )
}

export { AccountInfoCard }