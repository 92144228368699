import axios from 'axios'
import standardHeaders from '../../../constants/standardHeaders'
import {KTIcon} from '../../helpers'
import React, {useState, useEffect} from 'react'
import * as XLSX from 'xlsx'
import 'toastr/build/toastr.min.css'
import toastr from 'toastr'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const excelFile = process.env.PUBLIC_URL + '/sample-catalog-upload.xlsx'

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-center',
  preventDuplicates: true,
  showDuration: 300,
  hideDuration: 1000,
  timeOut: 5000,
  extendedTimeOut: 1000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
}

interface UploadCatalogProps {
  className?: string
  setUploadedFiles: React.Dispatch<React.SetStateAction<any[]>>
  uploadedFiles: any[]
}

const UploadCatalog: React.FC<UploadCatalogProps> = ({
  className,
  setUploadedFiles,
  uploadedFiles,
}) => {
  const [file, setFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<string>('')
  const [headers, setHeaders] = useState<any>({
    unrecognized: [],
    mappings: {},
  })
  const [selectedMappings, setSelectedMappings] = useState<any>({})
  const [key, setKey] = useState(Date.now())
  useEffect(() => {
    console.log(headers)
  }, [headers])
  const [originalHeaders, setOriginalHeaders] = useState<string[]>([])
  const [showMatchedHeaders, setShowMatchedHeaders] = useState<boolean>(false)
  type UploadedFile = {
    name: string
    size: number
    status: string
    timestamp: string
  }
  useEffect(() => {
    console.log('Current state of uploadedFiles:', uploadedFiles)
  }, [uploadedFiles])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0])

      // Parse the Excel file to extract headers
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target?.result
        const workbook = XLSX.read(data, {type: 'binary'})
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1})
        if (jsonData.length > 0) {
          setOriginalHeaders(jsonData[0] as string[])
        }
      }
      reader.readAsBinaryString(event.target.files[0])
    }
  }

  const handleMappingChange = (header: string, event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value
    console.log('Changing mapping for header:', header, 'to value:', newValue)

    const updatedMappings = {
      ...selectedMappings,
      [header]: newValue === 'N/A' ? undefined : newValue,
    }
    setSelectedMappings(updatedMappings)
    console.log('Updated mappings:', updatedMappings)
  }

  const handleFileReupload = async () => {
    if (!file) {
      setUploadStatus('Please select a file to reupload.')
      return
    }

    // Parse the Excel file
    const reader = new FileReader()
    reader.onload = async (e) => {
      const data = e.target?.result
      const workbook = XLSX.read(data, {type: 'binary'})
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]

      // Get the original headers from the first row
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1})
      if (jsonData.length === 0) {
        return
      }

      const originalHeaders = jsonData[0] as string[]

      // Adjust the headers based on selectedMappings
      const adjustedHeaders = originalHeaders.map((header) => selectedMappings[header] || header)
      const forceUpdate = () => {
        setKey(Date.now())
      }
      // Identify non-standard headers
      const nonStandardHeaders = adjustedHeaders.filter(
        (header) => !standardHeaders.includes(header)
      )

      if (nonStandardHeaders.length > 0) {
        const proceedWithUpload = window.confirm(
          `The following headers do not match the standard: ${nonStandardHeaders.join(
            ', '
          )}. Are you sure you want to proceed with the upload?`
        )

        if (!proceedWithUpload) {
          return // Stop the upload process
        }
      }

      // Replace the first row (headers) in jsonData
      jsonData[0] = adjustedHeaders

      // Convert jsonData back to a worksheet
      const newWorksheet = XLSX.utils.json_to_sheet(jsonData)

      // Replace the old worksheet with the new one in the workbook
      workbook.Sheets[firstSheetName] = newWorksheet

      // Convert the workbook back to a binary form
      const binaryString = XLSX.write(workbook, {bookType: 'xlsx', type: 'binary'})
      const binaryArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)))
      const adjustedFile = new Blob([binaryArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      console.log('Adjusted file details:', adjustedFile, adjustedFile.type)

      const originalFileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.')
      const newFileName = `${originalFileNameWithoutExtension}-adjusted-headers.xlsx`

      const formData = new FormData()
      formData.append('file', adjustedFile, newFileName)

      // Send this adjusted file to the backend
      try {
        const response = await axios.post('http://localhost:4000/reupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        setShowMatchedHeaders(false)
        setUploadStatus(response.data.message)
        toastr.success('Uploading with adjusted headers...')
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            name: newFileName,
            size: file.size,
            status: 'Review Needed',
            timestamp: new Date().toISOString(),
          },
        ])
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          console.error('Detailed error from server:', error.response.data)
          setUploadStatus(error.response.data.error || 'An error occurred while reuploading.')
        } else {
          setUploadStatus('An unknown error occurred while reuploading.')
        }
        setShowMatchedHeaders(false)
      }
    }
    reader.readAsBinaryString(file)
  }

  const handleSubmit = async () => {
    if (!file) {
      setUploadStatus('Please select a file to upload.')
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await axios.post('http://localhost:4000/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (response.status === 200) {
        toastr.success('File uploaded successfully!')
        console.log('File uploaded successfully:', response.data)
        // Handle successful upload
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            name: file.name,
            size: file.size,
            status: 'Uploaded',
            timestamp: new Date().toISOString(),
          },
        ])
        console.log('File uploaded successfully.')
      } else if (response.status === 400 && response.data.error === 'Header discrepancies found.') {
        toastr.warning('Some headers didnt match the standard. Please map the headers.')

        console.log('Setting headers state with:', response.data.unrecognizedHeaders)
        setShowMatchedHeaders(true)
        // Compute the matched headers
        const matchedHeaders = originalHeaders.filter(
          (header) => !response.data.unrecognizedHeaders.includes(header)
        )
        setOriginalHeaders(matchedHeaders)

        setHeaders({
          unrecognized: response.data.unrecognizedHeaders,
        })

        setUploadStatus('Header discrepancies found. Please map the headers.')
      } else {
        setUploadStatus(response.data.error || 'An error occurred while uploading.')
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const errorData = error.response.data as {error?: string; unrecognizedHeaders?: string[]}
        setShowMatchedHeaders(true)
        toastr.warning('Some headers didnt match the standard. Please map the headers.')

        console.log('Error response data:', errorData)

        if (errorData.error === 'Header discrepancies found.') {
          // Compute the matched headers
          const matchedHeaders = originalHeaders.filter(
            (header) => !errorData.unrecognizedHeaders?.includes(header)
          )
          setOriginalHeaders(matchedHeaders)

          setHeaders({
            unrecognized: errorData.unrecognizedHeaders || [],
            mappings: {}, // you can replace this with the actual mappings from the response if it's provided by the backend
          })

          setUploadStatus('Header discrepancies found. Please map the headers.')
        } else {
          setUploadStatus(errorData.error || 'An error occurred while uploading.')
        }
      } else {
        setUploadStatus('An unknown error occurred while uploading.')
      }
    }
  }

  // Compute recognized headers
  const recognizedHeaders = originalHeaders
    ? originalHeaders.filter((header) => !headers.unrecognized.includes(header))
    : []

  const getDropdownValue = (header: string) => {
    return selectedMappings[header] || 'N/A'
  }

  return (
    <div key={key} className={`card ${className} pt-6`}>
      <div className='card-header'>
        <h4>Upload Excel File</h4>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='mb-3'>
              <input type='file' className='form-control' onChange={handleFileChange} />
            </div>
            <button
              className='btn btn-primary'
              onClick={handleSubmit}
              disabled={uploadStatus === 'Header discrepancies found. Please map the headers.'}
            >
              Upload
            </button>

            {/* Header matching section begins here */}
            {uploadStatus === 'Header discrepancies found. Please map the headers.' && (
              <div className='mt-4'>
                <h6>
                  <i className='bi bi-exclamation-triangle-fill' style={{color: 'red'}}></i>{' '}
                  Unrecognized Headers:
                </h6>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>File Header</th>
                      <th className='pt-5'>⟶</th>
                      <th>Standard Header</th>
                    </tr>
                  </thead>
                  <tbody>
                    {headers.unrecognized.map((header, index) => (
                      <tr key={index}>
                        <td>{header}</td>
                        <td className='pt-5'>⟶</td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <select
                              className='form-select'
                              value={selectedMappings[header] || 'N/A'}
                              onChange={(event) => handleMappingChange(header, event)}
                              style={{flex: '1'}}
                            >
                              <option value='N/A'>N/A</option>
                              {standardHeaders.map((headerObj) => (
                                <option
                                  key={headerObj.header}
                                  value={headerObj.header}
                                  title={headerObj.description}
                                >
                                  {headerObj.header}
                                </option>
                              ))}
                            </select>
                            {selectedMappings[header] ? (
                              <i
                                className='bi bi-check-circle-fill'
                                style={{color: 'green', marginLeft: '10px'}}
                              ></i>
                            ) : (
                              <i
                                className='bi bi-exclamation-triangle-fill'
                                style={{color: 'red', marginLeft: '10px'}}
                              ></i>
                            )}
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id={`tooltip-${header}`}>
                                  {
                                    standardHeaders.find(
                                      (h) => h.header === (selectedMappings[header] || 'N/A')
                                    )?.description
                                  }
                                </Tooltip>
                              }
                            >
                              <i className='bi bi-question-circle' style={{marginLeft: '10px'}}></i>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  data-tooltip='Upload the file after adjusting the headers to match the required format.'
                  className='btn btn-primary'
                  onClick={handleFileReupload}
                >
                  Upload with New Headers
                </button>
              </div>
            )}
          </div>
          <div className='col-md-4'>
            <a
              className='btn btn-secondary'
              href={excelFile}
              download='sample-catalog-upload.xlsx'
              onClick={() => toastr.success('File downloaded successfully')}
            >
              <KTIcon iconName='download' /> Download Sample Excel
            </a>
          </div>
        </div>
      </div>

      {/* Additional header mappings section */}
      {headers.mappings && Object.keys(headers.mappings).length > 0 && (
        <div className='card mt-4'>
          <div className='card-header'>
            <h6>Header Mappings:</h6>
          </div>
          <div className='card-body'>
            <table className='table'>
              <thead>
                <tr>
                  <th>File Header</th>
                  <th className='pt-5'>⟶</th>
                  <th>Standard Header</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(headers.mappings).map(([fileHeader, standardHeader], index) => (
                  <tr key={index}>
                    <td>{fileHeader}</td>
                    <td className='pt-5'>⟶</td>
                    <td>{standardHeader as string}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {showMatchedHeaders && (
        <div className='card mt-4'>
          <div className='card-header mt-2'>
            <h6 className='mt-5'>
              <i className='bi bi-check-circle-fill' style={{color: 'green'}}></i> Matched Headers:
            </h6>
          </div>
          <div className='card-body'>
            <table className='table'>
              <thead>
                <tr>
                  <th>File Header</th>
                  <th className='pt-5'>⟶</th>
                  <th>Standard Header</th>
                </tr>
              </thead>
              <tbody>
                {recognizedHeaders.map((header, index) => (
                  <tr key={index}>
                    <td>{header}</td>
                    <td className='pt-5'>⟶</td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <select
                          className='form-select'
                          value={selectedMappings[header] || header}
                          onChange={(event) => handleMappingChange(header, event)}
                          style={{flex: '1'}}
                        >
                          {standardHeaders.map((headerObj) => (
                            <option
                              key={headerObj.header}
                              value={headerObj.header}
                              data-bs-toggle='tooltip'
                              title={headerObj.description}
                            >
                              {headerObj.header}
                            </option>
                          ))}
                        </select>
                        <i
                          className='bi bi-check-circle-fill'
                          style={{color: 'green', marginLeft: '10px'}}
                        ></i>
                        <OverlayTrigger
  placement='top'
  overlay={
    <Tooltip id={`tooltip-${header}`}>
      {standardHeaders.find((h) => h.header === header)?.description}
    </Tooltip>
  }
>
  <i className='bi bi-question-circle' style={{marginLeft: '10px'}}></i>
</OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}
export {UploadCatalog}
