import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../_designereyes/helpers';
import { PageTitle } from '../../../_designereyes/layout/core';
import {
  ListsWidget7,
} from '../../../_designereyes/partials/widgets';
import { UploadCatalog } from '../../../_designereyes/partials/de-tiles/UploadCatalog';

const CatalogPage: React.FC = () => {
  // Define the state for uploadedFiles
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  console.log("Current Uploaded Files in CatalogWrapper:", uploadedFiles);

  return (
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-7'>
        {/* Pass down the setUploadedFiles method to UploadCatalog */}
        <UploadCatalog className='card-xl-stretch mb-xl-8' setUploadedFiles={setUploadedFiles} uploadedFiles={uploadedFiles} />
      </div>
      <div className='col-xl-5'>
        {/* Pass down the uploadedFiles state to ListsWidget7 */}
        <ListsWidget7 className='card-xl-stretch mb-5 mb-xl-8' uploadedFiles={uploadedFiles} />
      </div>
    </div>
  );
};

const CatalogWrapper: React.FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CATALOG' })}</PageTitle>
      <CatalogPage />
    </>
  );
};

export { CatalogWrapper };
