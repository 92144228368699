interface BestItemProps {
  className?: string;
  vendor: string;
}

export const BestPerformingItem1: React.FC<BestItemProps> = ({ className, vendor}) => {
  const vendorData = {
    Thelios: {
      imgSrc: '/media/glasses/thelios/bestSellingGlasses.png', 
      itemName: 'LW40108I LOEWE Sunglasses', 
      sales: 'Recent 30-Day Sales: 200', 
    },
    Luxottica: {
      imgSrc: '/media/glasses/luxottica/bestSellingGlasses.png', 
      itemName: 'RAY BAN - JUSTIN 0RB4165 622/55 54', 
      sales: 'Recent 30-Day Sales: 4500', 
    },
  };

  
  const currentVendorData = vendorData[vendor];
  

  const cardStyle: React.CSSProperties = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '14em',
    maxHeight: '20em',
    overflow: 'hidden',
  };

  const topSectionStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'center', 
    padding: '0.5rem 0 1rem 0',
  };  

  const titleStyle: React.CSSProperties = {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    flex: 1, 
    marginBottom: '0.5rem',
  };

  const imageContainerStyle: React.CSSProperties = {
    maxHeight: '40%', 
    maxWidth: '40%', 
    overflow: 'hidden',
  };

  const topImageStyle: React.CSSProperties = {
    maxWidth: '100%',
    objectFit: 'cover',
    height: 'auto',
    borderRadius: '0.5rem',
  };

  const bottomContentStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center', 
    padding: '1rem 0 1rem 0', 
    textAlign: 'center', 
  };  

  return (
    <div className={`card ${className}`} style={cardStyle}>
      {/* Top Section Container */}
      <div style={topSectionStyle}>
        {/* Title */}
        <div style={titleStyle}>
          Best Selling
        </div>
        {/* Image Container */}
        <div style={imageContainerStyle}>
          <img
            src={currentVendorData.imgSrc}
            alt='Best Performing Item'
            style={topImageStyle}
          />
        </div>
      </div>

      {/* Bottom Content Container */}
      <div style={bottomContentStyle}>
        {/* Item Description */}
        <div>
          <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
            {currentVendorData.itemName}
          </div>
          <div>{currentVendorData.sales}</div>
        </div>
      </div>
    </div>
  );
};