import React, { FC, useEffect, useState, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { API_URL } from '../../../../../../../app/modules/auth/core/_requests';
import { useWeek } from './WeekContext';

const columns = [
  { field: 'Image', header: 'Image', },
  { field: 'UPC', header: 'UPC', },
  { field: 'BRAND', header: 'Brand' },
  { field: 'MODEL', header: 'Model', },
  { field: 'GRID', header: 'Grid', },
  // { field: 'MATERIAL', header: 'Material',},
  // { field: 'SIZE', header: 'Size',},
  // { field: 'SHAPE', header: 'Shape',},
  // { field: 'COLOR', header: 'Color',},
  // { field: 'DESCRIPTION', header: 'Description',},
  { field: 'TYPE', header: 'Type', },
  { field: 'LAUNCH', header: 'Launch' },
  { field: 'MSRP', header: 'MSRP' },
  { field: 'WHOLESALE', header: 'Wholesale' },
  { field: 'OFFER PRICE', header: 'Offer Price' },
  { field: 'OFFER QTY', header: 'Offer Qty' },
  { field: 'ORDER QTY', header: 'Order Qty' },
  { field: '_vendor_name', header: 'Vendor Name' },
];

interface Product {
  Image: string;
  UPC: string;
  BRAND: string;
  MODEL: string;
  GRID: string;
  // MATERIAL: string;
  // SIZE: string;
  // SHAPE: string;
  // COLOR: string;
  // DESCRIPTION: string;
  TYPE: string;
  LAUNCH: string;
  MSRP: number;
  WHOLESALE: number;
  'OFFER PRICE': number;
  'OFFER QTY': number;
  'ORDER QTY': number;
  _vendor_name: string;
}

interface EditedRow {
  UPC: string;
  'ORDER QTY': number;
}

export const CustomerPOTable = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [editedRows, setEditedRows] = useState({});

  useEffect(() => {
    axios.get(`${API_URL}/offers/fetch-customer-offers`)
      .then(res => setProducts(res.data))
      .catch(error => console.error('Error fetching data:', error));

    setLoading(false);
  }, []);

  const onEditorValueChange = (props, value) => {
    const updatedProducts = products.map(product => {
      if (product.UPC === props.rowData.UPC) {
        return { ...product, 'ORDER QTY': Number(value) };
      }
      return product;
    });
    setProducts(updatedProducts); // Update the products array with the new value

    const updatedEditedRows = {
      ...editedRows,
      [props.rowData.UPC]: { ...props.rowData, 'ORDER QTY': Number(value) }
    };
    setEditedRows(updatedEditedRows); // Keep tracking the edited rows separately if needed
  };

  const saveChanges = async () => {
    try {
      // Explicitly assert the type of the array elements
      const updates = (Object.values(editedRows) as EditedRow[]).map((row: EditedRow) => ({
        UPC: row.UPC,
        'ORDER QTY': row['ORDER QTY'],
      }));

      const response = await axios.post(`${API_URL}/offers/update-order-qty`, {
        updates, // Send the simplified updates array as the request payload
      });

      console.log('Save successful:', response.data);
      // Optionally, refresh your products list from the server here to reflect the changes
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };


  const orderQtyEditor = (props) => {
    return (
      <input
        type="number"
        value={props.rowData[props.field]}
        onChange={(e) => {
          // Ensure conversion from string to integer
          const value = parseInt(e.target.value, 10);
          if (!isNaN(value)) {
            onEditorValueChange(props, value);
          }
        }}
        style={{ width: '100%' }}
      />
    );
  };

  const imageBodyTemplate = (rowData) => {
    if (!rowData.IMAGE) return <span>No Image</span>;

    const src = `data:image/jpeg;base64,${rowData.IMAGE}`;
    return <img src={src} alt="Product" style={{ width: '50px', height: 'auto' }} />;
  };


  return (
    <div>
      <DataTable
        value={products} showGridlines stripedRows scrollable scrollHeight="800px" loading={loading}
        editMode='cell' resizableColumns columnResizeMode='expand' reorderableColumns
        paginator rows={12} rowsPerPageOptions={[10, 25, 1000]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
      >
        {columns.map((col, index) => {
          if (col.field === 'Image') {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                body={imageBodyTemplate}
              />
            );
          } else if (col.field === 'ORDER QTY') {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable
                filter
                filterPlaceholder={`Search by ${col.field}`}
                editor={orderQtyEditor}
              />
            );
          } else {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable
                filter
                filterPlaceholder={`Search by ${col.field}`}
              />
            );
          }
        })}
      </DataTable>
      <button onClick={saveChanges}>Save Changes</button>
    </div>
  );
}