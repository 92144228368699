import React, { FC, useEffect, useState } from 'react';
import { MasterPOTable, WeekCalendar } from '../../../../_designereyes/partials/widgets/'
import { PageTitle } from '../../../../_designereyes/layout/core'
import { WeekProvider } from '../../../../_designereyes/partials/widgets/_new/cards/vendors/purchase-order-offer/WeekContext'

const MasterPOPage = () => {
  return (
    <WeekProvider>
      <div className="mb-6">
        <WeekCalendar viewMode="POManager" table="masterOffers"/>
      </div>
      <div>
        <MasterPOTable />
      </div>
    </WeekProvider>
  )
}


export const MasterPOWrapper: FC = () => {
  return (
    <>
      <PageTitle>Master Purchase Orders Week 10</PageTitle>
      <MasterPOPage />
    </>
  )
};
