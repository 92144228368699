import React, { createContext, useState, useContext, ReactNode } from 'react';
import moment from 'moment';

interface WeekContextType {
  currentWeek: number;
  setCurrentWeek: (weekNumber: number) => void;
}

const defaultContextValue: WeekContextType = {
  currentWeek: moment().week(),
  setCurrentWeek: () => {},
};

const WeekContext = createContext<WeekContextType>(defaultContextValue);

export const useWeek = () => useContext(WeekContext);

export const WeekProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [currentWeek, setCurrentWeek] = useState<number>(moment().week());

  return (
    <WeekContext.Provider value={{ currentWeek, setCurrentWeek }}>
      {children}
    </WeekContext.Provider>
  );
};
