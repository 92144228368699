import React, { FC, useEffect, useState, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { API_URL } from '../../../../../../../app/modules/auth/core/_requests';
import { useWeek } from './WeekContext';

const columns = [
  { field: 'customerID', header: 'Customer ID', },
  { field: 'vendorName', header: 'Vendor Name', },
  { field: 'upc', header: 'UPC' },
  { field: 'price', header: 'Price', },
  { field: 'qty', header: 'QTY', },
];

interface Product {
  customerID: string;
  vendorName: string;
  upc: string;
  price: number;
  qty: number;
}

interface EditedRow {
  upc: string;
  'qty': number;
}

export const CustomerOrdersTable = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentWeek } = useWeek();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    axios.get(`${API_URL}/offers/fetch-orders?weekNumber=${currentWeek}&currentYear=${currentYear}`)
      .then(res => setProducts(res.data))
      .catch(error => console.error('Error fetching data:', error));

    setLoading(false);
  }, [currentWeek]);

  return (
    <div>
      <DataTable
        value={products} showGridlines stripedRows scrollable scrollHeight="800px" loading={loading}
        editMode='cell' resizableColumns columnResizeMode='expand' reorderableColumns
        paginator rows={12} rowsPerPageOptions={[10, 25, 1000]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
      >
        {columns.map((col, index) => {
          if (col.field === 'qty') {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable
                filter
                filterPlaceholder={`Search by ${col.field}`}
              />
            );
          } else {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable
                filter
                filterPlaceholder={`Search by ${col.field}`}
              />
            );
          }
        })}
      </DataTable>
    </div>
  );
}