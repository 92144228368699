/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, FC } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_designereyes/helpers'
import { PageTitle } from '../../../_designereyes/layout/core'
import { useAuth } from '../../../app/modules/auth'
import {
  AdminUserPull,
  AdminAnalyticsWidget1,
  //
  TotalCustomers1,
  TotalCustomersBar,
  TotalUnitsSold1,
  TotalUnitsSoldBar,
  TotalOrders1,
  TotalOrdersBar,
  TodaySales1,
  TodaySalesBar,
  BestSellingBrands,
  BestSellingBrandsOverTime,
  UnitsSoldLine1,
  //
  TotalUnitsSold,
  TotalOrders,
  TodaySales,
  //
  TotalSalesLine,
  BrandSalesBar,
  BrandSalesLine,

  CostaDashboardTable,
  CostaPNLTable,
  
} from '../../../_designereyes/partials/widgets/'
import StoreManagerDashboard from './StoreManagerDashboard'
import { DEDashboardWrapper } from './DesignerEyesDashboard'
import axios from 'axios'
import 'daterangepicker/daterangepicker.css'
import $ from 'jquery'
import moment, { Moment } from 'moment'
import 'daterangepicker'
import { API_URL } from '../../../app/modules/auth/core/_requests'

import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  BarController,
  CategoryScale,
  LinearScale,
  Tooltip,
} from 'chart.js'

Chart.register(
  LineController,
  LineElement,
  PointElement,
  BarController,
  CategoryScale,
  LinearScale,
  Tooltip
)

type DashboardPageProps = {
  dateRange: {
    start: Moment
    end: Moment
  }
  rangeDisplay: string
}

const DashboardPage: FC<DashboardPageProps> = ({ dateRange, rangeDisplay }) => {
  const { currentUser } = useAuth()
  const [showCharts, setShowCharts] = useState(false)

  const toggleCharts = () => {
    setShowCharts(!showCharts)
  }

  if (currentUser?.decoded?.role === 'Store Manager') {
    return <StoreManagerDashboard />
  }

  if (currentUser?.decoded?.vendorName === 'Designer Eyes') {
    return <DEDashboardWrapper />
  }

  return (
    <>
      {/* begin Luxottica Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Luxottica' && (
        <>
          {/* Toggle Button */}
          <button
            className='d-none d-xl-block'
            onClick={toggleCharts}
            style={{
              padding: '4px 12px',
              fontSize: '1rem',
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: '#0C6099',
              border: '1px solid #2B2B40',
              borderRadius: '5px',
              cursor: 'pointer',
              outline: 'none',
              boxShadow: '0 1px 4px rgba(255, 255, 255, 0.3)',
              transition: 'background-color 0.3s',
              marginBottom: '10px',
            }}
            onMouseOver={({ currentTarget }) => {
              currentTarget.style.backgroundColor = '#36A2EB'
            }}
            onMouseOut={({ currentTarget }) => {
              currentTarget.style.backgroundColor = '#0C6099'
            }}
          >
            {showCharts ? 'Hide Details' : 'Expand Details'}
          </button>

          <div className='row g-5 g-xl-10'>
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <TodaySales1
                  todaySales={276}
                  vendor={currentUser?.decoded?.vendorName}
                  isExpanded={showCharts}
                />
              </div>
            </div>
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <TotalUnitsSold1
                  percentageChange={20}
                  vendor={currentUser?.decoded?.vendorName}
                  isExpanded={showCharts}
                  dateRange={dateRange}
                  rangeDisplay={rangeDisplay}
                />
              </div>
            </div>
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <TotalOrders1
                  percentageChange={15}
                  vendor={currentUser?.decoded?.vendorName}
                  isExpanded={showCharts}
                  dateRange={dateRange}
                  rangeDisplay={rangeDisplay}
                />
              </div>
            </div>
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <TotalCustomers1
                  percentageChange={19}
                  vendor={currentUser?.decoded?.vendorName}
                  isExpanded={showCharts}
                  dateRange={rangeDisplay}
                />
              </div>
            </div>
          </div>

          {showCharts && (
            <div className='row g-5 g-xl-10'>
              <div className='col-lg-6 col-xl-3 col-lg-3'>
                <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                  <TodaySalesBar
                    data={{ Amazon: 284, Walmart: 94, Websites: 58 }}
                    isExpanded={showCharts}
                  />
                </div>
              </div>
              <div className='col-lg-6 col-xl-3 col-lg-3'>
                <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                  <TotalUnitsSoldBar
                    data={{ Amazon: 22897, Walmart: 5879, Websites: 3105 }}
                    isExpanded={showCharts}
                  />
                </div>
              </div>
              <div className='col-lg-6 col-xl-3 col-lg-3'>
                <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                  <TotalOrdersBar
                    data={{ Amazon: 22230, Walmart: 5721, Websites: 3017 }}
                    isExpanded={showCharts}
                  />
                </div>
              </div>
              <div className='col-lg-6 col-xl-3 col-lg-3'>
                <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                  <TotalCustomersBar
                    data={{ Amazon: 19923, Walmart: 5250, Websites: 3142 }}
                    isExpanded={showCharts}
                  />
                </div>
              </div>
            </div>
          )}

          <div className='row g-5 g-xl-10 mt-5 mt-xl-5 mb-5 mb-xl-10'>
            <div className='col-md-6 col-lg-6'>
              <UnitsSoldLine1
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
            <div className='col-md-6 col-lg-6'>
              <BestSellingBrands
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-5'>
            <div className='col-md-12 col-lg-12'>
              <BestSellingBrandsOverTime
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>
        </>
      )}
      {/* end Luxottica Vendor::Row */}

      {/* begin Thelios Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Thelios' && (
        <>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-lg-6 col-xl-4 col-lg-4'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <TodaySales vendor={currentUser?.decoded?.vendorName} />
              </div>
            </div>
            <div className='col-lg-6 col-xl-4 col-lg-4'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <TotalUnitsSold
                  percentageChange={20}
                  vendor={currentUser?.decoded?.vendorName}
                  dateRange={dateRange}
                  rangeDisplay={rangeDisplay}
                />
              </div>
            </div>
            <div className='col-lg-6 col-xl-4 col-lg-4'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <TotalOrders
                  percentageChange={15}
                  vendor={currentUser?.decoded?.vendorName}
                  dateRange={dateRange}
                  rangeDisplay={rangeDisplay}
                />
              </div>
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-12 col-lg-12'>
              <BrandSalesLine
                className='h-md-100 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
                dateRange={dateRange}
                rangeDisplay={rangeDisplay}
              />
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-6 col-lg-6'>
              <BrandSalesBar
                className='h-md-100 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
                dateRange={dateRange}
                rangeDisplay={rangeDisplay}
              />
            </div>
            <div className='col-md-6 col-lg-6'>
              <TotalSalesLine
                className='h-md-100 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
                dateRange={dateRange}
                rangeDisplay={rangeDisplay}
              />
            </div>
          </div>
        </>
      )}
      {/* end Thelios Vendor::Row */}

      {/* begin Costa Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Costa' && (
        <>
          <div className='row g-6 g-xl-10 mt-4'>
            <CostaDashboardTable
              minDate={dateRange.start.format('YYYY-MM-DD')}
              maxDate={dateRange.end.format('YYYY-MM-DD')}
            />
          </div>
        </>
      )}
      {/* end Costa Vendor::Row */}


      {/* begin Admin::Row */}
      {/* {currentUser?.decoded?.role === 'admin' && (
        <div className='row gy-5 g-xl-8'>
          <div className='col-xl-6'>
            <AdminUserPull className='card-xl-stretch mb-xl-8' />
          </div>
          <div className='col-xl-6'>
            <AdminAnalyticsWidget1 className='card-xl-stretch mb-xl-8' />
          </div>
        </div>
      )} */}
      {/* end Admin::Row */}
    </>
  )
}

export const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const [dateRange, setDateRange] = useState({ start: moment().subtract(29, 'days'), end: moment() })
  const [rangeDisplay, setRangeDisplay] = useState<string>('Last 30 Days') // Initialize as a string
  const { currentUser } = useAuth()

  useEffect(() => {
    $(function () {
      $('input[name="daterange"]').daterangepicker(
        {
          opens: 'left',
          startDate: dateRange.start,
          endDate: dateRange.end,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'Last 365 Days': [moment().subtract(364, 'days'), moment()],
            'Current Month': [moment().startOf('month'), moment().endOf('month')],
            'Current Year': [moment().startOf('year'), moment().endOf('year')],
            'Previous Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Previous Year': [
              moment().subtract(1, 'year').startOf('year'),
              moment().subtract(1, 'year').endOf('year'),
            ],
            'All Time': [moment('2015-01-01'), moment()],
          },
        },
        function (start, end, label) {
          console.log('Date range selected:', start, end)
          setDateRange({ start, end })

          if (label === 'Custom Range') {
            setRangeDisplay(`${start.format('MM/DD/YYYY')} - ${end.format('MM/DD/YYYY')}`)
          } else {
            setRangeDisplay(label)
          }
        }
      )
    })
  }, [])

  const handleDownloadCSV = () => {
    const startDate = dateRange.start.format('YYYY-MM-DD')
    const endDate = dateRange.end.format('YYYY-MM-DD')

    const fileStart = dateRange.start.format('YYYYMMDD')
    const fileEnd = dateRange.end.format('YYYYMMDD')
    const filename = `retail_sales_report_${fileStart}_to_${fileEnd}.csv`

    axios
      .get(`${API_URL}/data/download-sales-data?startDate=${startDate}&endDate=${endDate}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => console.error('Error downloading file:', error))
  }

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>

      {(currentUser?.decoded?.vendorName === 'Thelios' || currentUser?.decoded?.vendorName === 'Costa') && (
        <>
          <input
            type='text'
            name='daterange'
            style={{
              position: 'sticky',
              zIndex: '101',
              marginBottom: '2rem',
              top: '2.5%',
              minWidth: '15rem',
            }}
          />
          {currentUser?.decoded?.vendorName === 'Thelios' && (
            <button
              onClick={handleDownloadCSV}
              className={'btn btn-primary btn-hover'}
              style={{ marginLeft: '1%', fontSize: '1rem', padding: '4px 6px' }}
            >
              Download CSV Report
            </button>
          )}
        </>
      )}

      <DashboardPage dateRange={dateRange} rangeDisplay={rangeDisplay} />
    </>
  )
}
