import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlasses, faSortUp, faSortDown, faArrowUp, faArrowDown, faMoneyBillTransfer, faS } from '@fortawesome/free-solid-svg-icons';
import React, { CSSProperties, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';

interface consumerMetricsTableProps {
  vendor: string;
  isExpanded?: boolean;
  selectedCountry: string;
}

const worldwideData = [
  { Country: "United States", UnitsSold: 4734, GrossRevenue: 270821, MarketShare: 89.54 },
  { Country: "Others", UnitsSold: 70, GrossRevenue: 4365, MarketShare: 1.44 },
  { Country: "Mexico", UnitsSold: 152, GrossRevenue: 9228, MarketShare: 3.05 },
  { Country: "Canada", UnitsSold: 73, GrossRevenue: 6019, MarketShare: 1.99 },
  { Country: "Colombia", UnitsSold: 120, GrossRevenue: 6778, MarketShare: 2.24 },
  { Country: "Peru", UnitsSold: 20, GrossRevenue: 1277, MarketShare: 0.42 },
  { Country: "Philippines", UnitsSold: 17, GrossRevenue: 897, MarketShare: 0.30 },
  { Country: "Israel", UnitsSold: 2, GrossRevenue: 121, MarketShare: 0.04 },
  { Country: "Chile", UnitsSold: 8, GrossRevenue: 708, MarketShare: 0.23 },
  { Country: "Denmark", UnitsSold: 1, GrossRevenue: 218, MarketShare: 0.07 },
  { Country: "South Africa", UnitsSold: 1, GrossRevenue: 50, MarketShare: 0.02 },
  { Country: "Hong Kong", UnitsSold: 4, GrossRevenue: 647, MarketShare: 0.21 },
  { Country: "Japan", UnitsSold: 1, GrossRevenue: 43, MarketShare: 0.01 },
  { Country: "Bermuda", UnitsSold: 1, GrossRevenue: 47, MarketShare: 0.02 },
  { Country: "United Kingdom", UnitsSold: 2, GrossRevenue: 247, MarketShare: 0.08 },
  { Country: "Hungary", UnitsSold: 1, GrossRevenue: 103, MarketShare: 0.03 },
  { Country: "Taiwan", UnitsSold: 2, GrossRevenue: 202, MarketShare: 0.07 },
  { Country: "Puerto Rico", UnitsSold: 1, GrossRevenue: 54, MarketShare: 0.02 },
  { Country: "Poland", UnitsSold: 1, GrossRevenue: 128, MarketShare: 0.04 },
  { Country: "Qatar", UnitsSold: 1, GrossRevenue: 238, MarketShare: 0.08 },
  { Country: "Nigeria", UnitsSold: 1, GrossRevenue: 79, MarketShare: 0.03 },
  { Country: "France", UnitsSold: 1, GrossRevenue: 73, MarketShare: 0.02 },
  { Country: "Germany", UnitsSold: 1, GrossRevenue: 67, MarketShare: 0.02 },
  { Country: "Costa Rica", UnitsSold: 1, GrossRevenue: 35, MarketShare: 0.01 },
];

const unitedStatesData = [
  { Country: "California", UnitsSold: 659, GrossRevenue: 38241, MarketShare: 14.12 },
  { Country: "Florida", UnitsSold: 634, GrossRevenue: 32541, MarketShare: 12.02 },
  { Country: "Texas", UnitsSold: 509, GrossRevenue: 27748, MarketShare: 10.25 },
  { Country: "New York", UnitsSold: 315, GrossRevenue: 20427, MarketShare: 7.54 },
  { Country: "New Jersey", UnitsSold: 213, GrossRevenue: 12672, MarketShare: 4.68 },
  { Country: "Illinois", UnitsSold: 158, GrossRevenue: 8974, MarketShare: 3.31 },
  { Country: "Pennsylvania", UnitsSold: 138, GrossRevenue: 7666, MarketShare: 2.83 },
  { Country: "Arizona", UnitsSold: 132, GrossRevenue: 7585, MarketShare: 2.80 },
  { Country: "Ohio", UnitsSold: 129, GrossRevenue: 7596, MarketShare: 2.80 },
  { Country: "Georgia", UnitsSold: 126, GrossRevenue: 7927, MarketShare: 2.93 },
  { Country: "Michigan", UnitsSold: 120, GrossRevenue: 6904, MarketShare: 2.55 },
  { Country: "North Carolina", UnitsSold: 119, GrossRevenue: 6885, MarketShare: 2.54 },
  { Country: "Virginia", UnitsSold: 106, GrossRevenue: 5532, MarketShare: 2.04 },
  { Country: "Massachusetts", UnitsSold: 87, GrossRevenue: 5277, MarketShare: 1.95 },
  { Country: "Minnesota", UnitsSold: 85, GrossRevenue: 3062, MarketShare: 1.13 },
  { Country: "Colorado", UnitsSold: 79, GrossRevenue: 4781, MarketShare: 1.77 },
  { Country: "Tennessee", UnitsSold: 76, GrossRevenue: 4428, MarketShare: 1.64 },
  { Country: "Maryland", UnitsSold: 73, GrossRevenue: 4185, MarketShare: 1.55 },
  { Country: "Washington", UnitsSold: 68, GrossRevenue: 3712, MarketShare: 1.37 },
  { Country: "Missouri", UnitsSold: 67, GrossRevenue: 3807, MarketShare: 1.41 },
  { Country: "Indiana", UnitsSold: 64, GrossRevenue: 3643, MarketShare: 1.35 },
  { Country: "South Carolina", UnitsSold: 63, GrossRevenue: 3656, MarketShare: 1.35 },
  { Country: "Connecticut", UnitsSold: 48, GrossRevenue: 3056, MarketShare: 1.13 },
  { Country: "Louisiana", UnitsSold: 46, GrossRevenue: 2446, MarketShare: 0.90 },
  { Country: "Alabama", UnitsSold: 43, GrossRevenue: 2459, MarketShare: 0.91 },
  { Country: "Wisconsin", UnitsSold: 42, GrossRevenue: 2716, MarketShare: 1.00 },
  { Country: "Nevada", UnitsSold: 39, GrossRevenue: 2612, MarketShare: 0.96 },
  { Country: "Oklahoma", UnitsSold: 38, GrossRevenue: 2576, MarketShare: 0.95 },
  { Country: "Utah", UnitsSold: 36, GrossRevenue: 2909, MarketShare: 1.07 },
  { Country: "Kentucky", UnitsSold: 35, GrossRevenue: 1813, MarketShare: 0.67 },
  { Country: "Oregon", UnitsSold: 33, GrossRevenue: 1609, MarketShare: 0.59 },
  { Country: "New Mexico", UnitsSold: 27, GrossRevenue: 1982, MarketShare: 0.73 },
  { Country: "Delaware", UnitsSold: 26, GrossRevenue: 1615, MarketShare: 0.60 },
  { Country: "Mississippi", UnitsSold: 23, GrossRevenue: 1531, MarketShare: 0.57 },
  { Country: "Hawaii", UnitsSold: 22, GrossRevenue: 1181, MarketShare: 0.44 },
  { Country: "Idaho", UnitsSold: 21, GrossRevenue: 895, MarketShare: 0.33 },
  { Country: "Iowa", UnitsSold: 19, GrossRevenue: 872, MarketShare: 0.32 },
  { Country: "Arkansas", UnitsSold: 19, GrossRevenue: 846, MarketShare: 0.31 },
  { Country: "Rhode Island", UnitsSold: 18, GrossRevenue: 1580, MarketShare: 0.58 },
  { Country: "Kansas", UnitsSold: 17, GrossRevenue: 894, MarketShare: 0.33 },
  { Country: "West Virginia", UnitsSold: 17, GrossRevenue: 1230, MarketShare: 0.45 },
  { Country: "New Hampshire", UnitsSold: 15, GrossRevenue: 843, MarketShare: 0.31 },
  { Country: "Nebraska", UnitsSold: 14, GrossRevenue: 864, MarketShare: 0.32 },
  { Country: "Puerto Rico", UnitsSold: 12, GrossRevenue: 795, MarketShare: 0.29 },
  { Country: "Maine", UnitsSold: 9, GrossRevenue: 745, MarketShare: 0.28 },
  { Country: "Wyoming", UnitsSold: 6, GrossRevenue: 266, MarketShare: 0.10 },
  { Country: "Alaska", UnitsSold: 5, GrossRevenue: 374, MarketShare: 0.14 },
  { Country: "North Dakota", UnitsSold: 4, GrossRevenue: 234, MarketShare: 0.09 },
  { Country: "Montana", UnitsSold: 4, GrossRevenue: 141, MarketShare: 0.05 },
  { Country: "District of Columbia", UnitsSold: 3, GrossRevenue: 165, MarketShare: 0.06 },
  { Country: "South Dakota", UnitsSold: 2, GrossRevenue: 82, MarketShare: 0.03 },
  { Country: "Vermont", UnitsSold: 1, GrossRevenue: 26, MarketShare: 0.01 },
  // Note: 'CONFIDENTIAL' and other non-Countrys have been omitted
];

const europeData = [
  { Country: "Denmark", UnitsSold: 1, GrossRevenue: 218, MarketShare: 0.0007 },
  { Country: "Great Britain", UnitsSold: 2, GrossRevenue: 247, MarketShare: 0.0008 },
  { Country: "Hungary", UnitsSold: 1, GrossRevenue: 103, MarketShare: 0.0003 },
  { Country: "Poland", UnitsSold: 1, GrossRevenue: 128, MarketShare: 0.0004 },
  { Country: "France", UnitsSold: 1, GrossRevenue: 73, MarketShare: 0.0002 },
  { Country: "Germany", UnitsSold: 1, GrossRevenue: 67, MarketShare: 0.0002 },
];

const headerStyles: CSSProperties = {
  textAlign: 'left',
  padding: '12px 15px',
  borderBottom: '2px solid #e1e1e1', // Solid bottom border
  color: 'white', // Black text color
  fontWeight: 'bold', // Bold font weight
  fontSize: '1.25rem',
  cursor: 'pointer', // Change cursor to indicate clickable headers
};

// Define the table styles
const tableStyles: CSSProperties = {
  width: '100%',
  borderCollapse: 'collapse',
};

// Function to generate row style based on row index
const getRowStyle = (index: number): CSSProperties => {
  return {
    backgroundColor: index % 2 === 0 ? '#1A233F' : '#1F2844', // alternating colors for rows
    color: 'white', // text color
    fontSize: '1.1rem',
  };
};

// New style for aligning icons
const iconStyle: CSSProperties = {
  marginLeft: '5px', // Space between header text and icon
  // any other styling you might need for proper alignment
};

export const ConsumerMetricsTable: React.FC<consumerMetricsTableProps> = ({ vendor, isExpanded = false, selectedCountry }) => {
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const handleSort = (field: string) => {
    if (sortField === field) {
      // Toggle sort direction or remove sorting
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else if (sortDirection === 'desc') {
        setSortField(null); // Remove sorting
        setSortDirection('asc'); // Reset to default for next sort
      }
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Function to get sorted dataset
  const getSortedDataset = () => {
    const dataset = getDataset();
    if (!sortField) return dataset;

    return [...dataset].sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const renderSortIcon = (field: string) => {
    if (sortField !== field) return null;
    return (
      <FontAwesomeIcon 
        icon={sortDirection === 'asc' ? faSortDown : faSortUp} 
        style={iconStyle} 
      />
    );
  };
  
  // Function to select the correct dataset based on selectedCountry
  const getDataset = () => {
    switch (selectedCountry) {
      case 'Worldwide':
        return worldwideData;
      case 'USA':
        return unitedStatesData;
      case 'Europe':
        return europeData;
      default:
        return [];
    }
  };
  
  // Rest of your component
  return (
    <div className="card card-flush" style={{ maxHeight: '44.9rem', minHeight: '44.9rem' }}>
      <div style={{overflow: 'auto', padding:'0.5rem'}}>
      <table style={tableStyles}>
        <thead style={headerStyles}>
          <tr>
            <th onClick={() => handleSort('Country')}>Country/State {renderSortIcon('Country')}</th>
            <th onClick={() => handleSort('UnitsSold')}>Units Sold {renderSortIcon('UnitsSold')}</th>
            <th onClick={() => handleSort('GrossRevenue')}>Gross Revenue {renderSortIcon('GrossRevenue')}</th>
            <th onClick={() => handleSort('MarketShare')}>Market Share {renderSortIcon('MarketShare')}</th>
          </tr>
        </thead>
        <tbody>
          {getSortedDataset().map((data, index) => (
            <tr key={index} style={getRowStyle(index)}>
              <td>{data.Country}</td>
              <td>{data.UnitsSold}</td>
              <td>${data.GrossRevenue.toLocaleString()}</td>
              <td>{data.MarketShare}%</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className="card-footer">
        {/* Footer content here */}
      </div>
    </div>
  );
};