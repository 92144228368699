/* eslint-disable jsx-a11y/anchor-is-valid */
import { Chart, ArcElement, Tooltip, Legend, CategoryScale, LinearScale } from 'chart.js';
import React, { FC, useEffect, useRef } from 'react';

Chart.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
}

const BrandPieChart: FC<Props> = ({ className }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart<'pie', number[], string> | null>(null);

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d');
    if (ctx && !chartInstance.current) {
      chartInstance.current = new Chart(ctx, {
        type: 'pie',
        data: {
            labels: ['Dior', 'Loewe', 'Givenchy', 'Celine', 'Fendi'],
            datasets: [{
              label: 'Sales per brand',
              data: [36, 22, 18, 12, 12],
              backgroundColor: [
                'rgba(255, 99, 132, 0.9)',
                'rgba(54, 162, 235, 0.9)',
                'rgba(255, 206, 86, 0.9)',
                'rgba(75, 192, 192, 0.9)',
                'rgba(153, 102, 255, 0.9)'
              ],
              borderColor: [
                'rgba(255, 255, 255, 1)'
              ],
              borderWidth: 1
            }]
          },
          options: {
            responsive: false,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                position: 'top',
              },
              tooltip: {
                mode: 'index',
                intersect: false
              }
            }
          }
        });
      }
  
      return () => {
        chartInstance.current?.destroy();
        chartInstance.current = null;
      };
    }, []);
  
  return (
    <div className={`card card-flush ${className}`} style={{ height: '400px' }}> {/* Set a fixed height for the container */}
      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <canvas ref={chartRef} /> {/* Remove inline styles for width and height */}
      </div>
    </div>
  );
}
  
export {BrandPieChart};