import React, { FC, useEffect, useState, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { API_URL } from '../../../../../../../app/modules/auth/core/_requests';
import { useWeek } from './WeekContext';

// const columns: Column<DataRow>[] = [
//   { key: 'UPC', name: 'UPC', width: 150, sortable: true },
//   { key: 'BRAND', name: 'Brand', width: 75, sortable: true  },
//   { key: 'MODEL', name: 'Model', width: 100 },
//   { key: 'GRID', name: 'Grid', width: 100 },
//   // { key: 'MATERIAL', name: 'Material', width: 60},
//   // { key: 'SIZE', name: 'Size', width: 60 },
//   // { key: 'SHAPE', name: 'Shape', width: 60 },
//   // { key: 'COLOR', name: 'Color', width: 60 },
//   // { key: 'DESCRIPTION', name: 'Description', width: 60},
//   { key: 'TYPE', name: 'Type', width: 100 },
//   { key: 'LAUNCH', name: 'Launch', width: 75 },
//   // { key: 'CN03 QTY', name: 'CN03 QTY', width: 60 },
//   // { key: 'IT03 QTY', name: 'IT03 QTY', width: 60 },
//   // { key: 'US03 QTY', name: 'US03 QTY', width: 60 },
//   { key: 'OTHER QTY', name: 'Other QTY' },
//   { key: 'TOTAL QTY', name: 'Total QTY' },
//   { key: 'DISCOUNT', name: 'Discount' },
//   { key: 'MSRP', name: 'MSRP' },
//   { key: 'WHOLESALE', name: 'Wholesale' },
//   { key: 'DE COST', name: 'DE Cost' },
//   { key: 'CUSTOMER WHOLESALE', name: 'Customer Wholesale' },
//   { key: 'CUSTOMER PRICE', name: 'Customer Price' },
//   { key: '_vendor_name', name: 'Vendor Name' },
// ];

const columns = [
    { field: 'UPC', header: 'UPC', sortable: true },
    { field: 'BRAND', header: 'Brand', sortable: true },
    { field: 'MODEL', header: 'Model' },
    { field: 'GRID', header: 'Grid' },
    { field: 'TYPE', header: 'Type' },
    { field: 'OTHER QTY', header: 'Other QTY' },
    { field: 'TOTAL QTY', header: 'Total QTY' },
    { field: 'DISCOUNT', header: 'Discount' },
    { field: 'MSRP', header: 'MSRP' },
    { field: 'WHOLESALE', header: 'Wholesale' },
    { field: 'DE COST', header: 'DE Cost' },
    { field: 'CUSTOMER WHOLESALE', header: 'Customer Wholesale' },
    { field: 'CUSTOMER PRICE', header: 'Customer Price' },
    { field: '_vendor_name', header: 'Vendor Name' },
];

export const MasterPOTable = () => {
    const [products, setProducts] = useState([]);
    const { currentWeek } = useWeek();

    useEffect(() => {
        axios.get(`${API_URL}/offers/fetch-master-offers`)
            .then(res => setProducts(res.data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <div>
            <DataTable 
                value={products} 
                scrollable scrollHeight="800px" style={{ minWidth: '50rem' }} paginator rows={12} rowsPerPageOptions={[10, 25, 1000]} stripedRows
            >
                {columns.map((col, index) => (
                    <Column key={index} field={col.field} header={col.header} sortable />
                ))}
            </DataTable>
        </div>
    );
}