import React, { FC, useEffect, useRef, useState } from 'react';
import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';
import salesData from './dailysales.json'; // Adjust the file name as needed
import annotationPlugin from 'chartjs-plugin-annotation';
import weeklySalesData from './weeklysales.json'
import data2024 from './data2024.json'; // Adjust the file name as needed


// Inside your component, before creating the chart instance
Chart.register(annotationPlugin);
Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale);

type TimeSeriesLineProps = {
  className: string;
  vendor: string;
};

const getYearlyExtremes = (year) => {
  const yearlyData = weeklySalesData.filter(d => d.year === year);
  const salesValues = yearlyData.map(d => d.sales);
  return {
    lowest: Math.min(...salesValues),
    highest: Math.max(...salesValues)
  };
};

const getYearlyExtremes2024 = (year) => {
  const yearlyData = data2024.filter(d => d.year === year);
  const salesValues = yearlyData.map(d => d.sales);
  return {
    lowest: Math.min(...salesValues),
    highest: Math.max(...salesValues)
  };
};

const extremes2022 = getYearlyExtremes(2022);
const extremes2023 = getYearlyExtremes(2023);
const extremes2024 = getYearlyExtremes2024(2024);

const highLowAnnotations2022 = {
  lowest2022: {
    type: 'label',
    xValue: '2022 Week-26', // This should be a label on the x-axis that corresponds to the lowest value
    yValue: extremes2022.lowest - 1000,
    content: `2022 Low: ${extremes2022.lowest} Week-23`,
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'black',
    borderRadius: 4,
    borderColor: 'red',
    borderWidth: 2,
    padding: 10,
    font: {
      weight: 'bold',
    }
  },
  highest2022: {
    type: 'label',
    xValue: '2022 Week-26', // This should be a label on the x-axis that corresponds to the highest value
    yValue: extremes2022.highest + 1000,
    content: `2022 High: ${extremes2022.highest} Week-34`,
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'black',
    borderRadius: 4,
    borderColor: 'green',
    borderWidth: 2,
    padding: 10,
    font: {
      weight: 'bold',
    }
  },
}

const yLineAnnotations2022 = {
  lineAt2646: {
    type: 'line',
    yMin: 2646,
    yMax: 2646,
    borderColor: 'rgba(255, 255, 255, 0.4)',
    borderWidth: 1,
    borderDash: [6, 6], // Creates a dashed line
  },
  lineAt7662: {
    type: 'line',
    yMin: 7662,
    yMax: 7662,
    borderColor: 'rgba(255, 255, 255, 0.4)',
    borderWidth: 1,
    borderDash: [6, 6], // Creates a dashed line
  }
};

const highLowAnnotations2023 = {
  lowest2023: {
    type: 'label',
    xValue: '2023 Week-26', // This should be a label on the x-axis that corresponds to the lowest value
    yValue: extremes2023.lowest - 1000,
    content: `2023 Low: ${extremes2023.lowest} Week-27`,
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'black',
    borderRadius: 4,
    borderColor: 'red',
    borderWidth: 2,
    padding: 10,
    font: {
      weight: 'bold',
    }
  },
  highest2023: {
    type: 'label',
    xValue: '2023 Week-26', // This should be a label on the x-axis that corresponds to the highest value
    yValue: extremes2023.highest + 1000,
    content: `2023 High: ${extremes2023.highest} Week-47`,
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'black',
    borderRadius: 4,
    borderColor: 'green',
    borderWidth: 2,
    padding: 10,
    font: {
      weight: 'bold',
    }
  },
}

const yLineAnnotations2023 = {
  lineAt4587: {
    type: 'line',
    yMin: 4587,
    yMax: 4587,
    borderColor: 'rgba(255, 255, 255, 0.4)',
    borderWidth: 1,
    borderDash: [6, 6], // Creates a dashed line
  },
  lineAt11893: {
    type: 'line',
    yMin: 11893,
    yMax: 11893,
    borderColor: 'rgba(255, 255, 255, 0.4)',
    borderWidth: 1,
    borderDash: [6, 6], // Creates a dashed line
  }
};

const highLowAnnotations2024 = {
  lowest2024: {
    type: 'label',
    xValue: '2024 Week-26', // This should be a label on the x-axis that corresponds to the lowest value
    yValue: extremes2024.lowest - 900,
    content: `2024 Low: ${extremes2024.lowest} Week-2`,
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'black',
    borderRadius: 4,
    borderColor: 'red',
    borderWidth: 2,
    padding: 10,
    font: {
      weight: 'bold',
    }
  },
  highest2024: {
    type: 'label',
    xValue: '2024 Week-26', // This should be a label on the x-axis that corresponds to the highest value
    yValue: extremes2024.highest + 800,
    content: `2024 High: ${extremes2024.highest} Week-31`,
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'black',
    borderRadius: 4,
    borderColor: 'green',
    borderWidth: 2,
    padding: 10,
    font: {
      weight: 'bold',
    }
  },
}

const yLineAnnotations2024 = {
  lineAt16136: {
    type: 'line',
    yMin: 16136,
    yMax: 16136,
    borderColor: 'rgba(255, 255, 255, 0.8)',
    borderWidth: 2,
    borderDash: [6, 6], // Creates a dashed line
  },
  lineAt6923: {
    type: 'line',
    yMin: 6923,
    yMax: 6923,
    borderColor: 'rgba(255, 255, 255, 0.8)',
    borderWidth: 2,
    borderDash: [6, 6], // Creates a dashed line
  }
};

const xLineAnnotations = {
  lineAt2022Week52: {
    type: 'line',
    xMin: '2022 Week-52',
    xMax: '2022 Week-52',
    borderColor: 'rgba(255, 255, 255, 0.4)',
    borderWidth: 1,
    borderDash: [6, 6], // Creates a dashed line
  },
  lineAt2023Week52: {
    type: 'line',
    xMin: '2023 Week-52',
    xMax: '2023 Week-52',
    borderColor: 'rgba(255, 255, 255, 0.4)',
    borderWidth: 1,
    borderDash: [6, 6], // Creates a dashed line
  },
};

const TimeSeriesLine: FC<TimeSeriesLineProps> = ({ className, vendor }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const delayed = useRef(false);
  const [isChartSplit, setIsChartSplit] = useState(false);
  const [isForecastLoaded, setIsForecastLoaded] = useState(false);
  const [showHighsLows, setShowHighsLows] = useState(true);

  const toggleHighsLows = () => {
    setShowHighsLows(!showHighsLows);

    if (chartInstance.current && chartInstance.current.options && chartInstance.current.options.plugins) {
      const annotations = showHighsLows
        ? { ...xLineAnnotations, ...yLineAnnotations2022, ...yLineAnnotations2023, ...yLineAnnotations2024, ...highLowAnnotations2022, ...highLowAnnotations2023, ...highLowAnnotations2024, }
        : {};

      // Check if annotation plugin is initialized
      if (!chartInstance.current.options.plugins.annotation) {
        chartInstance.current.options.plugins.annotation = { annotations: {} };
      }

      chartInstance.current.options.plugins.annotation.annotations = annotations;
      chartInstance.current.update();
    }
  };

  const handleAddForecastData = () => {
    if (!chartInstance.current || !chartInstance.current.data || isForecastLoaded) return;

    let dataIndex = 0; // Start from the first data point of 2024
    const intervalId = setInterval(() => {
      if (dataIndex < data2024.length && chartInstance.current) {
        const newLabel = `2024 Week-${data2024[dataIndex].week}`;
        const newSalesData = data2024[dataIndex].sales;

        chartInstance.current.data.datasets[0].data.push(newSalesData);
        (chartInstance.current.data.labels as string[]).push(newLabel);
        chartInstance.current.update();
        dataIndex++;
      } else {
        clearInterval(intervalId);
        setIsForecastLoaded(true);
        setTimeout(() => {
          handleSplitChart(); // Split the chart after forecast data is loaded
          setTimeout(() => {
            toggleHighsLows();
          }, 1000);
        }, 1000);
      }
    }, 75);
  };


  const handleForecast = () => {
    if (!chartInstance.current || !chartInstance.current.data) return;

    // Add a new dataset for 2024 with a different color
    chartInstance.current.data.datasets.push({
      label: 'Forecast Sales (2024)',
      data: [], // Initially no data
      borderColor: 'rgba(54, 162, 235, 1)', // Set a distinct color for the 2024 data
      fill: false,
      // Other styling as needed
    });

    chartInstance.current.update();
    handleAddForecastData(); // Call the function to start adding the forecast data
  };

  const handleSplitChart = () => {
    setIsChartSplit(!isChartSplit);

    if (!chartInstance.current || !chartInstance.current.data) return;

    if (isChartSplit) {
      // Combine the datasets
      const combinedData = [...weeklySalesData.map(d => d.sales), ...data2024.map(d => d.sales)];
      chartInstance.current.data.datasets = [{
        label: 'Weekly Sales',
        data: combinedData,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.7)',
      }];
    } else {
      // Split the datasets
      const originalData = weeklySalesData.map(d => d.sales);
      const forecastData = new Array(originalData.length).fill(null).concat(data2024.map(d => d.sales));
      chartInstance.current.data.datasets = [
        {
          label: 'Weekly Sales (2022 & 2023)',
          data: originalData,
          borderColor: 'rgba(0, 199, 214, 1)',
          backgroundColor: 'rgba(0, 199, 214, 1)',
          fill: false,
        },
        {
          label: 'Forecast Sales (2024)',
          data: forecastData,
          borderColor: 'rgba(140, 232, 130, 1)',
          backgroundColor: 'rgba(140, 232, 130, 1)',
          fill: false,
        }
      ];
    }
    chartInstance.current.update();
  };

  const handleReset = () => {
    if (!chartInstance.current || !chartInstance.current.data) return;

    // Reset the chart data to original state
    chartInstance.current.data.labels = weeklySalesData.map(d => `${d.year} Week-${d.week}`);
    chartInstance.current.data.datasets = [
      {
        label: 'Weekly Sales (2022 & 2023)',
        data: weeklySalesData.map(d => d.sales),
        borderColor: 'rgba(0, 199, 214, 1)',
        backgroundColor: 'rgba(0, 199, 214, 1)',
        fill: false,
      }
    ];

    // Reset the annotations
    if (chartInstance.current.options.plugins && chartInstance.current.options.plugins.annotation) {
      chartInstance.current.options.plugins.annotation.annotations = {};
    }

    // Reset state flags
    setIsChartSplit(false);
    setIsForecastLoaded(false);
    setShowHighsLows(true); // Assuming you want to reset this as well

    // Update the chart to apply the changes
    chartInstance.current.update();
  };


  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy(); // Destroy any existing chart instance
    }
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: weeklySalesData.map(d => `${d.year} Week-${d.week}`),
            datasets: [
              {
                label: 'Weekly Sales (2022 & 2023)',
                data: weeklySalesData.map(d => d.sales),
                borderColor: 'rgba(0, 199, 214, 1)',
                backgroundColor: 'rgba(0, 199, 214, 1)',
                fill: false,
              }]
          },
          options: {
            responsive: true,
            animation: {
              onComplete: () => {
                delayed.current = true;
              },
              delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed.current) {
                  delay = context.dataIndex * 1 + context.datasetIndex * 1;
                }
                return delay;
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white',
                },
                grid: {
                  color: 'transparent',
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  color: 'white',
                },
                grid: {
                  color: 'rgba(255, 255, 255, 0.05)',
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                mode: 'index',
                intersect: false,
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                titleFont: {
                  size: 16,
                },
                bodyFont: {
                  size: 14,
                },
                padding: {
                  top: 10,
                  right: 15,
                  bottom: 10,
                  left: 10
                },
                callbacks: {
                  label: function (tooltipItem) {
                    // Check if the data point exists
                    if (weeklySalesData[tooltipItem.dataIndex]) {
                      const item = weeklySalesData[tooltipItem.dataIndex];
                      return `Year: ${item.year}, Week: ${item.week} (Start: ${item.startweek}, End: ${item.endweek}) - Sales: ${tooltipItem.formattedValue}`;
                    }
                    return ''; // Return an empty string if the data point does not exist
                  }
                },
              },
              annotation: {
                annotations: showHighsLows ? {

                } : {}
              }
            },
          }
        });
      }
    }
  }, [vendor]); // Add any other dependencies if necessary

  return (
    <div className={`card card-flush ${className}`} style={cardStyle}>
      <div className='card-header'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex justify-content-between' style={{ display: 'flex', flexDirection: 'row' }}>

            {/* Left Side: Titles */}
            <div style={{ flex: 3 }}>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2 mb-3 mt-5' style={{ whiteSpace: 'nowrap' }}> Total Sales over Time </span>
              <div style={{ color: 'white' }}> Time Series Model using Seasonal Autoregressive Integrated Moving Average (SARIMA)</div>
              <div style={{ color: 'red' }}> EXPERIMENTAL </div>
            </div>


          </div>
            {/* Right Side: Buttons */}
        </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding:'20px' }}>
              <button className='' onClick={handleForecast}           
              style={{
                  padding: '4px 12px',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: '#0C6099',
                  border: '1px solid #2B2B40',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  outline: 'none',
                  boxShadow: '0 1px 4px rgba(255, 255, 255, 0.3)',
                  transition: 'background-color 0.3s',
                  marginBottom: '10px'
              }}
              onMouseOver={({ currentTarget }) => {
                currentTarget.style.backgroundColor = '#0056b3';
              }}
              onMouseOut={({ currentTarget }) => {
                currentTarget.style.backgroundColor = '#0C6099';
              }}>Forecast Sales</button>
              <button className='' onClick={handleReset}               
                style={{
                  padding: '4px 12px',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: '#0C6099',
                  border: '1px solid #2B2B40',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  outline: 'none',
                  boxShadow: '0 1px 4px rgba(255, 255, 255, 0.3)',
                  transition: 'background-color 0.3s',
                  marginLeft: '2rem',
                  marginBottom: '10px'
                }}
                onMouseOver={({ currentTarget }) => {
                  currentTarget.style.backgroundColor = '#0056b3';
                }}
                onMouseOut={({ currentTarget }) => {
                  currentTarget.style.backgroundColor = '#0C6099';
                }}>Reset Chart</button>
            </div>
      </div>

      <div className='card-body'>
        <div style={{backgroundColor: 'rgb(26,35,63)', padding: ' 10px 20px 0px 20px', borderRadius:'10px',  }}>
          <canvas ref={chartRef}
            style=
            {{
              width: 'auto',
              maxHeight: '600px',
              backgroundColor: 'rgb(26,35,63)',
              marginBottom: '20px',
            }}
          />
        </div>
      </div>
    </div>
  );
};

const cardStyle: React.CSSProperties = {
  maxHeight: '100%',
  width: '100%',
  overflow: 'auto',
  position: 'relative',
  color: '#fff',
  borderRadius: '8px',
  // ... other styles
};

export { TimeSeriesLine };
