import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import axios, { AxiosError } from 'axios';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { API_URL } from '../../../../../../../../app/modules/auth/core/_requests';
import { useAuth } from '../../../../../../../../app/modules/auth'
import { useWeek } from '../WeekContext'; // Adjust the import path accordingly
import { Toast } from 'primereact/toast';
import { debounce } from 'lodash';
import { Dialog } from 'primereact/dialog';

interface ColumnDefinition {
  field: string;
  header: string;
  editor?: 'numeric' | ((options: any) => JSX.Element); // Adjust the editor type as needed
  body?: (rowData: any) => JSX.Element;
}

const columns: ColumnDefinition[] = [
  // { field: 'image', header: 'Image', },
  { field: 'vendorName', header: 'Vendor Name', },
  { field: 'upc', header: 'UPC', },
  { field: 'brand', header: 'Brand' },
  { field: 'model', header: 'Model', },
  { field: 'grid', header: 'Grid', },
  // { field: 'material', header: 'Material',},
  // { field: 'size', header: 'Size',},
  // { field: 'shape', header: 'Shape',},
  // { field: 'color', header: 'Color',},
  // { field: 'description', header: 'Description',},
  { field: 'type', header: 'Type', },
  { field: 'launch', header: 'Launch' },
  { field: 'msrp', header: 'MSRP' },
  { field: 'wholesale', header: 'Wholesale' },
  { field: 'qty', header: 'Offer QTY' },
];

interface Product {
  image: string;
  vendorName: string;
  upc: string;
  brand: string;
  model: string;
  grid: string;
  type: string;
  launch: string;
  msrp: number;
  wholesale: number;
  qty: number;
}

export const CustomerOfferTable = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [offersUnavailable, setOffersUnavailable] = useState(false);
  const { currentUser } = useAuth();
  const customerID = currentUser?.decoded?.customerID;
  const { currentWeek } = useWeek();
  const toast = useRef<Toast>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    const fetchOffers = async () => {
      setLoading(true);
      setOffersUnavailable(false);
      try {
        const response = await axios.get(`${API_URL}/customer-offers/fetch-customer-offers?customerID=${customerID}&weekNumber=${currentWeek.toString().padStart(2, '0')}&currentYear=${new Date().getFullYear()}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        if ((error as AxiosError).response?.data === "Access to offers is not allowed for this customer or week number.") {
          setOffersUnavailable(true);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, [currentWeek]);

  const onEditorValueChange = debounce((props, value) => {
    const updatedProduct = { ...products[props.rowIndex], [props.field]: value };

    axios.patch(`${API_URL}/customer-offers/update-customer-offers`, {
      updates: [{
        customerID: customerID,
        weekNumber: `${new Date().getFullYear()}/${currentWeek}`,
        vendorName: updatedProduct.vendorName,
        upc: updatedProduct.upc,
        orderQty: value, // Updated qty
        price: updatedProduct.wholesale
      }]
    })
      .then(response => {
        toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Update successful', life: 3000 });
        const updatedProducts = [...products];
        updatedProducts[props.rowIndex] = updatedProduct;
        setProducts(updatedProducts);
      })
      .catch(error => {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Update not allowed', life: 3000 });
        console.error('Error saving edits:', error);
      });
  }, 300);

  const numericEditor = (options) => (
    <InputNumber value={options.rowData[options.field]} onValueChange={(e) => onEditorValueChange(options, e.value)}
      mode="decimal" minFractionDigits={0} maxFractionDigits={2} />
  );

  const handleSubmitClick = () => {
    setShowConfirmationDialog(true); // Show the confirmation dialog
  };

  const handleConfirmSubmit = async () => {
    try {
      const response = await axios.patch(`${API_URL}/customer-offers/submit-offer`, {
        customerID: customerID,
        weekNumber: currentWeek
      });

      toast.current?.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
    } catch (error) {
      let errorMessage = 'Failed to submit the offer';
      if (axios.isAxiosError(error) && error.response) {
        errorMessage = error.response.data;
      }
      toast.current?.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 3000 });
    } finally {
      setShowConfirmationDialog(false); // Close the confirmation dialog
    }
  };

  return (
    <div className="card p-4 mt-4">
      {loading ? (
        <div className="loading-content d-flex justify-content-center">
          <h2>Loading Week {currentWeek}'s Data...</h2>
        </div>
      ) : (
        <>
          <Toast ref={toast} />
          {offersUnavailable ? (
            <div className="d-flex justify-content-center">
              <h2 className="alert alert-warning text-center w-50">
                Week {currentWeek}'s Offer is no longer available
              </h2>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center mb-2">
                <h1>Week {currentWeek}'s Offer</h1>
              </div>
              <DataTable
                value={products}
                scrollable scrollHeight="800px" style={{ minWidth: '50rem' }} stripedRows
                paginator rows={12} rowsPerPageOptions={[10, 25, 1000]}
                editMode='cell' resizableColumns columnResizeMode='expand'
              >
                {columns.map((col, index) => (
                  (col.field === 'qty')
                    ? <Column field="qty" header="Offer Qty" editor={numericEditor} sortable />
                    : <Column key={index} field={col.field} header={col.header} sortable />
                ))}
              </DataTable>
            </>
          )}
          <div className="mt-4">
            <Dialog header="Confirm Submission" visible={showConfirmationDialog} onHide={() => setShowConfirmationDialog(false)}>
              <p>Are you sure you want to submit the offer?</p>
              <Button label="Yes" icon="pi pi-check" onClick={handleConfirmSubmit} />
              <Button label="No" icon="pi pi-times" onClick={() => setShowConfirmationDialog(false)} />
            </Dialog>
            <Button label="Submit Offer" onClick={handleSubmitClick}></Button>
          </div>
        </>
      )}
    </div>
  );
};