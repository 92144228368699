interface bestItemProps {
  className?: string;
  title: string;
}
export const StoreImage: React.FC<bestItemProps> = ({className, title}) => {
  const getImageSrc = (title: string) => {
    const imageMap: { [key: string]: string } = {
      'All Stores': '/media/store-images/allstorescollage.jpg',
      'R001 - POV Sawgrass': '/media/store-images/R001 Point of View Sawgrass.jpg',
      'R002 - Just One Look': '/media/store-images/R002 JOL Dolphin Mall.jpg',
      'R003 - Eyes on Lincoln': '/media/store-images/R003 EOL.jpg',
      'R004 - PV2 Dolphin': '/media/store-images/R004 POV2.jpg',
      'R005 - DE Mall of San Juan': '/media/store-images/R005 DEPR.jpg',
      'R006 - DE Brickell': '/media/store-images/R006 BCC.jpg',
      'R008 - DE Florida Mall': '/media/store-images/R008 DE FL MALL.jpg',
      'R009 - Aventura': '/media/store-images/R009 - Aventura.jpg',
      'R010 - PV3 iDrive': '/media/store-images/R010 POV Orlando iDrive.jpg',
      'R011 - PV4 Vineland': '/media/store-images/R011 POV Orlando Vineland.jpg',
      'R014 - DE Plaza Las Americas': '/media/store-images/R014 DE PLA.jpg',
    };

    return imageMap[title] || '/media/store-images/aventuramallde.png';
  };  

  const getAddress = (title: string) => {
    const addressMap: { [key: string]: string } = {
      'All Stores': '',
      'R001 - POV Sawgrass': 'Sunrise, Florida',
      'R002 - Just One Look': 'Miami, Florida',
      'R003 - Eyes on Lincoln': 'Miami Beach, Florida',
      'R004 - PV2 Dolphin': 'Miami, Florida',
      'R005 - DE Mall of San Juan': 'San Juan, Puerto Rico',
      'R006 - DE Brickell': 'Miami, Florida',
      'R008 - DE Florida Mall': 'Orlando, Florida',
      'R009 - Aventura': 'Aventura, Florida',
      'R010 - PV3 iDrive': 'Orlando, Florida',
      'R011 - PV4 Vineland': 'Orlando, Florida',
      'R014 - DE Plaza Las Americas': 'San Juan, Puerto Rico',
    };

    return addressMap[title] || '';
  }
  
  const cardStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    maxHeight: '38em',
  };

  const textContainerStyle: React.CSSProperties = {
    flex: 1,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',  
    alignItems: 'center',
  };  
  
  const imageContainerStyle: React.CSSProperties = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  
  const imageStyle: React.CSSProperties = {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '0.5rem',
  };
  
  const titleStyle: React.CSSProperties = {
    fontSize: '2.25rem',
    fontWeight: '700',
    color: 'white', 
    marginBottom: '0.5rem', 
  };
    
  const addressStyle: React.CSSProperties = {
    color: 'white', 
    fontSize: '1rem',
    lineHeight: '1.5',
  };

  return (
    <div className={`card ${className}`} style={{...cardStyle}}>
      {/* Text Container for Title and Address */}
      <div style={textContainerStyle}>
        <h3 style={titleStyle}>{title}</h3>
        <p style={addressStyle}>{getAddress(title)}</p>
      </div>

    {/* Image Container */}
    <div style={imageContainerStyle}>
        <img src={getImageSrc(title)} alt="Store Image" style={imageStyle} />
      </div>
    </div>
  );
};
