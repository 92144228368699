/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'

type Props = {
  className: string,
  uploadedFiles: Array<{ name: string, size: string, status: string, timestamp: string }>
}


const ListsWidget7: React.FC<Props> = ({ className, uploadedFiles }) => {
  console.log("Received Uploaded Files in ListsWidget7:", uploadedFiles);

  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold text-dark'>Latest Catalog Uploads</span>
          <span className='text-muted mt-1 fw-semibold fs-7'></span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3'>
        {uploadedFiles.map((file, index) => (
          <>
          <div className='d-flex align-items-sm-center mb-7' key={index} style={{ width: "100%", borderTop: "1px solid #eee",  padding: "15px 15px" }}>
            {/* begin::Symbol */}
            <div className='symbol symbol-60px symbol-2by3 me-4'>
              <div className="symbol-label">
                <i className="fas fa-folder fa-3x"></i>
              </div>
            </div>
            {/* end::Symbol */}
            {/* begin::Title */}
            <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                  {file.name}
                </a><br />
                <span className='badge badge-light-grey fs-8 fw-bold my-2'>
                  {new Date(file.timestamp).toLocaleString()}
                </span>
                <span className='text-muted fw-semibold d-block pt-1'>Size: {file.size}KB</span>
              </div>
              <span className={`badge badge-light-grey fs-8 fw-bold my-2 ${file.status === "Uploaded" ? "badge-light-success" : file.status === "Review Needed" ? "badge-light-warning" : "badge-light-grey"}`}>{file.status}</span>
            </div>
            {/* end::Title */}
          </div>
            <hr style={{ width: "80%", borderTop: "1px solid #eee", margin: "10px auto" }} /></>
        ))}

        <div className='d-flex align-items-sm-center mb-7' style={{ width: "100%", borderTop: "1px solid #eee",  padding: "15px 15px" }}>
            {/* begin::Symbol */}
            <div className='symbol symbol-60px symbol-2by3 me-4'>
              <div className="symbol-label">
                <i className="fas fa-folder fa-3x"></i>
              </div>
            </div>
            {/* end::Symbol */}
            {/* begin::Title */}
            <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                  Sample File - Your uploads will go here
                </a><br />
                <span className='badge badge-light-grey fs-8 fw-bold my-2'>
                  10/10/2021 12:00:00
                </span>
                <span className='text-muted fw-semibold d-block pt-1'>Size: 136 MB</span>
              </div>
              <span className="badge badge-light-grey fs-8 fw-bold my-2 badge-light-success">Uploaded</span>
            </div>
            {/* end::Title */}
          </div>
        <hr style={{ width: "80%", borderTop: "1px solid #eee", margin: "10px auto" }} />
      </div>
      {/* end::Body */}
    </div>
  )
}


export { ListsWidget7 }
