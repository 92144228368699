import React, { FC, CSSProperties, useEffect, useState } from 'react';
import { useAccount } from '../../../../../../widgets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faGlobe } from '@fortawesome/free-solid-svg-icons';

type AccountStoreTableItems = {
  StoreID: number;
  AccountID: number;
  Location: string;
  PhotoURL: string;
  WebsiteURL: string;
  StoreType: string;
  StoreName: string;
};

const dummyAccountStoresData: AccountStoreTableItems[] = [
  {
    StoreID: 1,
    AccountID: 1,
    Location: '1074 José E Arraras Ste 2 Terrace, 00680, Mayaguez, Puerto Rico',
    PhotoURL: '/media/distributor-store-images/sunnyshades.jpg',
    WebsiteURL: 'sunnyshades.pr',
    StoreType: 'Retail',
    StoreName: 'SunnyShades',
  },
  {
    StoreID: 2,
    AccountID: 1,
    Location: '1074 José E Arraras Ste 2 Terrace, 00680, Mayaguez, Puerto Rico',
    PhotoURL: '/media/distributor-store-images/sunnyshadesonline.jpg',
    WebsiteURL: 'sunnyshades.pr',
    StoreType: 'Online',
    StoreName: 'SunnyShades',
  },
  {
    StoreID: 3,
    AccountID: 2,
    Location: '1502 Ponce de León, Santurce, 00909, San Juan, Puerto Rico',
    PhotoURL: '/media/distributor-store-images/islandeyewear.jpg',
    WebsiteURL: 'islandeyewear.pr',
    StoreType: 'Retail',
    StoreName: 'IslandEyewear',
  },
  {
    StoreID: 4,
    AccountID: 2,
    Location: '1502 Ponce de León, Santurce, 00909, San Juan, Puerto Rico',
    PhotoURL: '/media/distributor-store-images/islandeyewearonline.jpg',
    WebsiteURL: 'islandeyewear.pr',
    StoreType: 'Online',
    StoreName: 'IslandEyewear',
  },
  {
    StoreID: 5,
    AccountID: 3,
    Location: '18 Calle San José, San Juan, 00901, Puerto Rico',
    PhotoURL: '/media/distributor-store-images/coastalviews.jpg',
    WebsiteURL: 'coastalviews.pr',
    StoreType: 'Retail',
    StoreName: 'CoastalViews',
  },
  {
    StoreID: 6,
    AccountID: 3,
    Location: '20 San Pablo, San Juan, 00806, Puerto Rico',
    PhotoURL: '/media/distributor-store-images/sunglasslooks.jpg',
    WebsiteURL: 'sunglasslooks.pr',
    StoreType: 'Retail',
    StoreName: 'Sunglasses Looks',
  },
  {
    StoreID: 7,
    AccountID: 3,
    Location: '18 Calle San José, San Juan, 00901, Puerto Rico',
    PhotoURL: '/media/distributor-store-images/coastalviewsonline.jpg',
    WebsiteURL: 'coastalviews.pr',
    StoreType: 'Online',
    StoreName: 'CoastalViews',
  },
  {
    StoreID: 8,
    AccountID: 4,
    Location: 'Calle Loiza 1765, San Juan, 00911, Puerto Rico',
    PhotoURL: '/media/distributor-store-images/visionarylenses.jpg',
    WebsiteURL: 'visionarylenses.pr',
    StoreType: 'Retail',
    StoreName: 'Visionary Lenses',
  },
  {
    StoreID: 9,
    AccountID: 4,
    Location: 'Calle Loiza 1765, San Juan, 00911, Puerto Rico',
    PhotoURL: '/media/distributor-store-images/visionarylensesonline.jpg',
    WebsiteURL: 'visionarylenses.pr',
    StoreType: 'Online',
    StoreName: 'Visionary Lenses',
  },
  {
    StoreID: 10,
    AccountID: 5,
    Location: 'Ha-Yarkon St 88, Tel Aviv',
    PhotoURL: '/media/distributor-store-images/opticcity.jpg',
    WebsiteURL: 'opticcity.co.il',
    StoreType: 'Retail',
    StoreName: 'OpticCity',
  },
  {
    StoreID: 11,
    AccountID: 5,
    Location: 'Ha-Yarkon St 88, Tel Aviv',
    PhotoURL: '/media/distributor-store-images/opticcityonline.jpg',
    WebsiteURL: 'opticcity.co.il',
    StoreType: 'Online',
    StoreName: 'OpticCity',
  },
  {
    StoreID: 12,
    AccountID: 6,
    Location: 'Ben Yehuda St 45, Tel Aviv',
    PhotoURL: '/media/distributor-store-images/visionaryframes.jpg',
    WebsiteURL: 'visionaryframes.co.il',
    StoreType: 'Retail',
    StoreName: 'Visionary Frames',
  },
  {
    StoreID: 13,
    AccountID: 6,
    Location: 'Ben Yehuda St 45, Tel Aviv',
    PhotoURL: '/media/distributor-store-images/visionaryframesonline.jpg',
    WebsiteURL: 'visionaryframes.co.il',
    StoreType: 'Online',
    StoreName: 'Visionary Frames',
  },
  {
    StoreID: 14,
    AccountID: 7,
    Location: 'King George St 23, Jerusalem',
    PhotoURL: '/media/distributor-store-images/jerusalemoptics.jpg',
    WebsiteURL: 'jerusalemoptics.co.il',
    StoreType: 'Retail',
    StoreName: 'Jerusalem Optics',
  },
  {
    StoreID: 15,
    AccountID: 7,
    Location: 'King George St 23, Jerusalem',
    PhotoURL: '/media/distributor-store-images/jerusalemopticsonline.jpg',
    WebsiteURL: 'jerusalemoptics.co.il',
    StoreType: 'Online',
    StoreName: 'Jerusalem Optics',
  },
  { 
    StoreID: 16,
    AccountID: 8,
    Location: 'Herzl St 67, Beersheba',
    PhotoURL: '/media/distributor-store-images/desertview.jpg',
    WebsiteURL: 'desertview.co.il',
    StoreType: 'Retail',
    StoreName: 'Desert View',
  },
  {
    StoreID: 17,
    AccountID: 9,
    Location: 'Hahistadrut Blvd 12, Haifa',
    PhotoURL: '/media/distributor-store-images/haifasightline.jpg',
    WebsiteURL: 'haifasightline.co.il',
    StoreType: 'Retail',
    StoreName: 'Haifa Sightline',
  },
  {
    StoreID: 18,
    AccountID: 10,
    Location: 'Weizmann St 88, Netanya',
    PhotoURL: '/media/distributor-store-images/clearvision.jpg',
    WebsiteURL: 'clearvision.co.il',
    StoreType: 'Retail',
    StoreName: 'ClearVision',
  }
]

const StoreDetails: FC<AccountStoreTableItems> = (account) => {
  return (
    <div className='d-flex flex-column p-4' style={{}}>
      <div className="d-flex flex-row mb-10">
        <div className="">
          <img src={account.PhotoURL} alt="" style={{ maxWidth: '20rem', maxHeight: '18rem', borderRadius: '10px', overflow: 'hidden' }} />
        </div>
        <div className="d-flex flex-column ms-4">
          <div className="d-flex fw-bold fs-1">
            <div>
              {account.StoreName}
            </div>
            <div className="d-flex fs-5 align-items-center text-white-50 ms-4">{account.StoreType}</div>
          </div>
          <div className="fw-bold">Store ID: {account.StoreID}</div>
          <div className="d-flex flex-row fw-bold my-8">
            <FontAwesomeIcon icon={faGlobe} style={{ fontSize: '1.3rem' }} />
            <div className="ms-4">{account.WebsiteURL}</div>
          </div>
          <div className="d-flex flex-row">
            <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: '1.3rem' }} />
            <div className="fw-bold ms-4">{account.Location}</div>
          </div>
        </div>
      </div>
      <div style={{ height: '1px', width: 'auto', margin: '0 5% 0 5%', background: 'rgba(255,255,255,0.3)' }}></div>
    </div>
  )
}

const StoreDetailsTable = () => {
  const { selectedAccountID } = useAccount();

  return (
    <div className='card d-flex flex-column p-4' style={{ overflow: 'auto' }}>
      <div className="d-flex fs-1 fw-bolder px-4">Store Details</div>
      <div>
        {dummyAccountStoresData.map((store) => {
          if (store.AccountID === parseInt(selectedAccountID)) {
            return (
              <StoreDetails
                StoreID={store.StoreID}
                AccountID={store.AccountID}
                Location={store.Location}
                PhotoURL={store.PhotoURL}
                WebsiteURL={store.WebsiteURL}
                StoreType={store.StoreType}
                StoreName={store.StoreName}
              />
            )
          }
        })}
      </div>
    </div>
  )
}

export { StoreDetailsTable }