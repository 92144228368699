import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';

const OpenPOCard = () => {
  return (
    <div className="card" style={{background: 'linear-gradient(45deg,#6155D4,#5B97F2)'}}>
      <div className="h-auto rounded-top p-4">
        <header className="" style={{fontSize: '2rem'}}>
          <FontAwesomeIcon icon={faBoxOpen} />        
        </header>
        <div className="pt-6 pb-3 d-flex flex-column">
          <div className="fw-bolder" style={{fontSize: '3rem', lineHeight: '1'}}>
            {(6147).toLocaleString()}
          </div>
          <div className="pt-2 fw-bold" style={{fontSize: '1.15rem'}}>
            Total Quantity
          </div>
        </div>
      </div>
      <div style={{ height: '1px', width: 'auto', margin:'0 50% 0 5%', background: 'rgba(255,255,255,0.5)' }}></div>
      <footer className="h-auto rounded-bottom pt-4 pb-8 px-4">
        <div className="d-flex flex-row align-items-center">
          <div className="fs-1 fw-bold">12</div>
          <div className="text-body-secondary fw-bold ms-2">Open Purchase Orders</div>
        </div>
      </footer>
    </div>
  )
}

export { OpenPOCard }