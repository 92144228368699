import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { Moment } from 'moment';
import axios from 'axios';
import { API_URL } from '../../../../../../../app/modules/auth/core/_requests'

interface totalOrdersProps {
  percentageChange: number;
  vendor: string;
  isExpanded?: boolean;
  dateRange: {
    start: Moment;
    end: Moment;
  };
  rangeDisplay?: string;
}

function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
}


export const TotalOrders: React.FC<totalOrdersProps> = ({ percentageChange, vendor, isExpanded = false, dateRange, rangeDisplay }) => {
  const [totalUnitsFromApi, setTotalUnitsFromApi] = useState(0);
  

  const formattedStartDate = dateRange.start.format('YYYY-MM-DD');
  const formattedEndDate = dateRange.end.format('YYYY-MM-DD');
  

  const getTotalOrders = (vendor: string): number => {
    if (vendor === 'Thelios') {
      return totalUnitsFromApi;
    }

    const vendorSales = {
      'Luxottica': 30968, 
    }

    return vendorSales[vendor] || 0;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/data/query-total-orders`, {
          
          params: { startDate: formattedStartDate, endDate: formattedEndDate }
        });
        
        setTotalUnitsFromApi(response.data.totalOrders);
      } catch (error) {
        console.error('Error fetching data:', error);
        
      }
    };

    fetchData();
  }, [formattedStartDate, formattedEndDate]);

  const totalOrders = getTotalOrders(vendor);
  const formattedNumber = formatNumber(totalOrders);
  const [isHovered, setIsHovered] = useState(false);

  const cardStyle: React.CSSProperties = {
    height: '100%',  
    width: '100%',   
    overflow: 'hidden', 
    position: 'relative',
    
    color: '#fff',

    borderRadius: isExpanded ? '8px 8px 0 0' : '8px', 
    border: isExpanded ? '0px' : '', 

    background: 'linear-gradient(45deg,#27A47C,#6FE594)',
    
  };

  const cardBodyStyle: React.CSSProperties = {
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'flex-end', 
    margin: '0 0 0 0',
    height: '100%', 
    alignItems: 'center', 
  };

  const bigNumberStyle: React.CSSProperties = {
    paddingTop: '1rem',
    fontSize: '4rem',
    lineHeight: '1',
    fontWeight: '500', 
    color: '#fff', 
  };

  const footerInnerContainerStyle: React.CSSProperties = {
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
  };

  const footerTitleStyle: React.CSSProperties = {
    color: '#fff', 
    fontSize: '1.15rem', 
    fontWeight: '700',
  };

  const footerStyle: React.CSSProperties = {
    textAlign: 'center', 
    color: 'white', 
    paddingTop: '1rem', 
    paddingRight: '1rem', 
  };

  const iconStyle: React.CSSProperties = {
    position: 'absolute',
    top: '1rem', 
    left: '1rem', 
    fontSize: '2rem', 
    color: 'white', 
  };

  const arrowStyle: React.CSSProperties = {
    color: '#00FF00', 
    marginLeft: '0.5rem',
    fontSize: '1.25rem',
  };

  const percentageStyle: React.CSSProperties = {
    fontSize: '1.25rem', 
    marginLeft: '0.5rem', 
    color: '#00FF00', 
  };

  const innerContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center',
    width: '100%', 
  };

  const growthContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
  };

  const getChartData = (vendor) => {
    const dataForVendors = {
      'Thelios': [16, 28, 21, 37, 124, 109, 73, 58, 17, 38, 64, 62, 24, 237, 256, 30, 35, 57, 213, 251, 128, 63, 70, 63, 202, 124, 89, 206, 65, 28],
      'Luxottica': [211, 238, 166, 302, 454, 490, 376, 337, 210, 184, 585, 260, 176, 384, 505, 203, 202, 170, 944, 1073, 954, 920, 304, 188, 1537, 1141, 1025, 912, 211, 135],
    };

    return {
      labels: [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
        '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
        '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
        '31'
      ],
      datasets: [
        {
          label: 'Total Customers',
          data: dataForVendors[vendor] || [], 
          fill: false,
          backgroundColor: 'white',
          borderColor: 'white',
          pointRadius: 0,
          tension: 0.4,
        },
      ],
    }
  };

  
  const chartData = getChartData(vendor);

  const chartOptions = {
    scales: {
      x: {
        display: false, 
      },
      y: {
        display: false, 
        beginAtZero: false,
      }
    },
    plugins: {
      legend: {
        display: false, 
      }
    },
    maintainAspectRatio: false
  };


  return (
    <div className="card card-flush"
      style={cardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FontAwesomeIcon icon={faBox} style={iconStyle} />
      <div style={{ position: 'absolute', top: '1.5rem', right: '1.5rem', width: '12rem', height: '6rem' }}>
        {/* <Line data={chartData} options={chartOptions} /> */}
      </div>
      <div className="card-body" style={cardBodyStyle}>
        <div style={innerContainerStyle}>
          <div style={growthContainerStyle}>
            <span className="text-dark" style={bigNumberStyle}>
              {isHovered ? totalOrders.toLocaleString() : formattedNumber}
            </span>
            {/* {percentageChange > 0 && (
              <>
                <FontAwesomeIcon icon={faArrowUp} style={arrowStyle} />
                <span style={percentageStyle}>{`${percentageChange}%`}</span>
              </>
            )} */}
          </div>
        </div>
      </div>
      <div className="card-footer" style={footerStyle}>
        <div style={footerInnerContainerStyle}>
          <div style={footerTitleStyle}>Total Orders</div>
          {rangeDisplay}
        </div>
      </div>
    </div>
  );
};
