/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  className: string
}

const AdminAnalyticsWidget1: React.FC<Props> = ({className}) => {
  const {currentUser, logout} = useAuth()
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Analytics<span className='badge badge-warning fw-bolder fs-8 px-2 py-1 ms-2'>{currentUser?.decoded?.role} only</span>
</h3>
        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
      <iframe title="Test" width="100%" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=d859ed26-c1bf-4d49-8d52-c83cbac8da46&autoAuth=true&ctid=8ea736fe-1f18-4787-a692-4a16c6f0b7f1" ></iframe>     </div>
      {/* end::Body */}
    </div>
  )
}

export {AdminAnalyticsWidget1}
