import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import axios from 'axios';
import moment from 'moment-timezone';
import { useWeek } from './WeekContext';
import { useAuth } from '../../../../../../../app/modules/auth';
import { API_URL } from '../../../../../../../app/modules/auth/core/_requests';

interface Week {
  weekNumber: number;
  orderStatus: string;
  expiryDate: string;
  submittedDate: string;
  lastUpdatedDate: string;
  select: JSX.Element;
}

const Week = ({ weekNumber, onClick, currentWeek }) => {
  const [isHovered, setIsHovered] = useState(false);
  const weekNumberFormatted = weekNumber.toString().padStart(2, '0');
  const currentWeekFormatted = currentWeek ? currentWeek.toString() : '';

  return (
    <div
      className="d-flex border border-white justify-content-center align-items-center text-center fs-4 fw-bold p-2 m-2"
      onClick={() => onClick(weekNumber)}
      style={{
        height: '25px',
        width: '25px',
        cursor: "pointer",
        background: isHovered || weekNumberFormatted === currentWeekFormatted ? 'red' : 'white',
        color: isHovered || weekNumberFormatted === currentWeekFormatted ? 'white' : 'black',
        borderRadius: '5px',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {weekNumber}
    </div>
  );
};

export const WeekCalendar = ({ viewMode, table }: { viewMode: string, table: string }) => {
  const [weeks, setWeeks] = useState<Week[]>([]);
  const [statusCounts, setStatusCounts] = useState<{ [key: string]: number }>({});
  const [activeStatus, setActiveStatus] = useState<string>('new');
  const { currentWeek, setCurrentWeek } = useWeek();
  const { currentUser } = useAuth();
  const customerID = currentUser?.decoded?.customerID;

  const fetchWeeks = () => {
    const apiUrl = viewMode === 'POManager'
      ? `${API_URL}/offers/fetch-available-weeks?table=${table}`
      : `${API_URL}/customer-offers/fetch-available-weeks?customerID=${customerID}`;

    axios.get(apiUrl).then(response => {
      const filteredData = response.data.filter(item => item.weekNumber.startsWith('2024/'))
        .map(week => ({
          ...week,
          weekNumber: parseInt(week.weekNumber.split('/')[1], 10),
          select: <Button label="Select" onClick={() => setCurrentWeek(parseInt(week.weekNumber.split('/')[1], 10))} />,
          expiryDate: week.expiryDate ? moment.tz(week.expiryDate, 'America/New_York').format('YYYY-MM-DD HH:mm:ss') : '',
          submittedDate: week.submittedDate ? moment.tz(week.submittedDate, 'America/New_York').format('YYYY-MM-DD HH:mm:ss') : '',
          lastUpdatedDate: week.lastUpdatedDate ? moment.tz(week.lastUpdatedDate, 'America/New_York').format('YYYY-MM-DD HH:mm:ss') : '',
        }));

      const counts = { new: 0, open: 0, submitted: 0, ordered: 0, cancelled: 0, archived: 0 };
      filteredData.forEach(week => {
        const status = week.orderStatus.toLowerCase();
        if (counts[status] !== undefined) {
          counts[status]++;
        }
      });
      setWeeks(filteredData);
      setStatusCounts(counts);
    }).catch(error => {
      console.error('Error fetching weeks:', error);
    });
  };

  useEffect(() => {
    fetchWeeks();
  }, [viewMode, table, customerID]);

  const handleWeekClick = (weekNumber) => {
    setCurrentWeek(weekNumber.toString().padStart(2, '0'));
  };

  if (viewMode === 'POManager') {
    return (
      <div className="card d-flex flex-wrap flex-row justify-content-center align-items-center">
                  <span className='d-flex flex-row justify-content-center align-items-center fs-2hx fw-bold text-dark me-2 lh-1 ls-n2 mx-4 mt-4'>Week Calendar {moment().format('YYYY')}</span>

        {Array.from({ length: 52 }, (_, i) => i + 1).map(weekNumber => (
          <Week key={weekNumber} weekNumber={weekNumber} onClick={handleWeekClick} currentWeek={currentWeek} />
        ))}
      </div>
    );
  }
  else {
    return (
      <div className="card">
        <div className="d-flex flex-column align-items-center w-100" style={{ padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
          <span className='d-flex flex-row justify-content-center align-items-center fs-2hx fw-bold text-dark me-2 lh-1 ls-n2 mx-4 mt-4'>Week Calendar {moment().format('YYYY')}</span>
          <div>
            <Button
              label=""
              icon="pi pi-refresh"
              className="p-button-sm p-button-rounded p-button-text ml-2"
              onClick={fetchWeeks}
            />
          </div>
          <div className="d-flex flex-wrap justify-content-center w-100 mb-2">
            {Object.keys(statusCounts).map(status => (
              <button key={status} onClick={() => setActiveStatus(status)}
                style={{
                  margin: '20px 20px 10px 20px',
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '5px',
                  backgroundColor: activeStatus === status ? '#e74c3c' : '#2c3e50',
                  color: 'white',
                  fontSize: '0.5em',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                  outline: 'none',
                  position: 'relative',
                }}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
                {statusCounts[status] > 0 && (
                  <span style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    color: 'white',
                    fontSize: '0.75em',
                    padding: '2px 6px',
                    transform: 'translate(50%, -50%)',
                    boxSizing: 'border-box'
                  }}>
                    {statusCounts[status]}
                  </span>)}
              </button>
            ))}
          </div>
          <DataTable value={weeks.filter(week => week.orderStatus.toLowerCase() === activeStatus)}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 20]}
            responsiveLayout="scroll"
            sortField="weekNumber"
            sortOrder={1}
            removableSort
            filterDisplay="menu"
            stripedRows>
            <Column field="select" header="Select" bodyStyle={{ textAlign: 'center' }}></Column>
            <Column field="weekNumber" header="Week Number" bodyStyle={{ textAlign: 'center' }} sortable filter filterPlaceholder="Search by number"></Column>
            <Column field="expiryDate" header="Expiry Date" bodyStyle={{ textAlign: 'center' }} sortable filter filterPlaceholder="Search by date"></Column>
            <Column field="submittedDate" header="Submitted Date" bodyStyle={{ textAlign: 'center' }} sortable filter filterPlaceholder="Search by date"></Column>
            <Column field="lastUpdatedDate" header="Last Updated Date" bodyStyle={{ textAlign: 'center' }} sortable filter filterPlaceholder="Search by date"></Column>
          </DataTable>
        </div>
      </div>
    )
  };
};
