import React, { FC, useEffect, useRef, useState } from 'react'
import { Chart, ChartDataset, ChartTypeRegistry, } from 'chart.js'
import moment from 'moment'

type Props = {
  className: string
  vendor: string
  dateRange: {
    start: moment.Moment
    end: moment.Moment
  }
  rangeDisplay?: string
  salesData: SalesByDoorData[] | null
}

type SalesByDoorData = {
  locationCode: string
  brandName: string
  quantity: number
}

const cardStyle: React.CSSProperties = {
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '8px',
  border: '0px solid white',
}

const brandColorMapping: Record<string, string> = {
  CELINE: 'rgba(255, 99, 132, 1)',
  DIOR: 'rgba(54, 162, 235, 1)',
  'DIOR HOMME': 'rgba(75, 192, 192, 1)',
  FENDI: 'rgba(255, 206, 86, 1)',
  FRED: 'rgba(153, 102, 255, 1)',
  GIVENCHY: 'rgba(255, 159, 64, 1)',
  LOEWE: 'rgba(155, 225, 132, 1)',

  'RAY BAN': 'rgba(255, 99, 132, 1)',
  PRADA: 'rgba(54, 162, 235, 1)',
  VERSACE: 'rgba(75, 192, 192, 1)',
  OAKLEY: 'rgba(255, 206, 86, 1)',
  'DOLCE & GABBANA': 'rgba(153, 102, 255, 1)',
  BURBERRY: 'rgba(255, 159, 64, 1)',
  'MICHAEL KORS': 'rgba(155, 225, 132, 1)',
  COACH: 'rgba(255, 99, 132, 1)',
  TIFFANY: 'rgba(54, 162, 235, 1)',
  'COSTA DEL MAR': 'rgba(75, 192, 192, 1)',
  'TORY BURCH': 'rgba(255, 206, 86, 1)',
  CHANEL: 'rgba(153, 102, 255, 1)',
}

const storeNameMapping: Record<string, string> = {
  'ALL Stores': 'All Stores',
  'POV STORE': 'R001 - POV Sawgrass',
  'JOL STORE': 'R002 - Just One Look',
  'EOL STORE': 'R003 - Eyes on Lincoln',
  'POV2 STORE': 'R004 - PV2 Dolphin',
  'DE1 STORE': 'R005 - DE Mall of San Juan',
  'BCC': 'R006 - DE Brickell',
  'DE2 STORE': 'R008 - DE Florida Mall',
  'R009': 'R009 - Aventura',
  'POV3 STORE': 'R010 - PV3 iDrive',
  'R011': 'R011 - PV4 Vineland',
  'R014': 'R014 - DE Plaza Las Americas',
};

const generateSwitchStyles = () => {
  const style = document.createElement('style');
  style.type = 'text/css';

  const rules = Object.entries(brandColorMapping).map(([brand, color]) => {
    const className = `switch-${brand.toLowerCase().replace(/\s+/g, '-')}`;
    return `
      .${className} .form-check-input:checked + .form-check-label {
        border-color: ${color} !important;
      }
      .${className} .form-check-input:checked {
        background-color: ${color} !important;
        border-color: ${color} !important;
      }
    `;
  }).join("\n");

  style.textContent = rules;

  document.head.appendChild(style);
};

export const SalesByDoor: FC<Props> = ({ className, rangeDisplay, salesData }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null)
  const chartInstance = useRef<Chart | null>(null)
  const delayed = useRef(false)
  const [selectAllLabel, setSelectAllLabel] = useState(true)

  type ActiveBrandsState = {
    'TOTAL': boolean;
    [brand: string]: boolean;
  };

  useEffect(() => {
    if (salesData) {
      const brandsInData = salesData.reduce((acc, { brandName }) => {
        acc[brandName] = true;
        return acc;
      }, {} as ActiveBrandsState);


      brandsInData['TOTAL'] = activeBrands['TOTAL'];

      setActiveBrands(brandsInData);
    }
  }, [salesData]);

  const [activeBrands, setActiveBrands] = useState(() => {
    const initialActiveBrands = { 'TOTAL': false };
    Object.keys(brandColorMapping).forEach((brand) => {
      initialActiveBrands[brand] = true;
    });
    return initialActiveBrands;
  });


  useEffect(() => {
    generateSwitchStyles();
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy()
      }
      const effectiveSalesData = Array.isArray(salesData) ? salesData : []

      const locationCodes = Array.from(new Set(effectiveSalesData.map((item) => item.locationCode)))

      const brandNames = Array.from(new Set(effectiveSalesData.map((item) => item.brandName)))
      const brandColors = brandNames.map(
        (brand) => brandColorMapping[brand.toUpperCase()] || 'rgba(0, 0, 0, 1)'
      )


      const totalSalesPerLocation = locationCodes.map((code) => {
        return effectiveSalesData
          .filter((s) => s.locationCode === code)
          .reduce((sum, current) => sum + current.quantity, 0)
      })


      const barDatasets = brandNames.map((brand, index) => ({
        label: brand,
        data: locationCodes.map((code) => {
          const sale = effectiveSalesData.find(
            (s) => s.locationCode === code && s.brandName === brand
          )
          return sale ? sale.quantity : 0
        }),
        backgroundColor: brandColors[index % brandColors.length],
        hidden: !activeBrands[brand],
      }))


      const lineDataset = {
        label: 'TOTAL',
        data: totalSalesPerLocation,
        type: 'line',
        borderColor: 'rgb(0, 123, 255)',
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        yAxisID: 'y',
        backgroundColor: 'rgba(0, 123, 255, 1)',
        hidden: !activeBrands['TOTAL'],
      }

      chartInstance.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: locationCodes,
          datasets: [...barDatasets, lineDataset as ChartDataset<keyof ChartTypeRegistry>],
        },
        options: {
          indexAxis: 'x',
          animation: {
            onComplete: () => {
              delayed.current = true
            },
            delay: (context) => {
              let delay = 0
              if (context.type === 'data' && context.mode === 'default' && !delayed.current) {
                delay = context.dataIndex * 100 + context.datasetIndex * 150
              }
              return delay
            },
          },
          scales: {
            x: {
              stacked: false,
              beginAtZero: true,
              grid: {
                color: 'rgba(255, 255, 255, 0.1)',
              },
              ticks: {
                color: 'white',
              },
            },
            y: {
              stacked: false,
              beginAtZero: true,
              grid: {
                color: 'rgba(255, 255, 255, 0.1)',
              },
              ticks: {
                color: 'white',
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                color: 'white',
              },
              display: false,
            },
            tooltip: {
              mode: 'index',
              axis: 'x',
              intersect: false,
              backgroundColor: '0, 0, 0, 0.9',
              titleFont: {
                size: 16,
              },
              bodyFont: {
                size: 14,
              },
              padding: {
                top: 10,
                right: 15,
                bottom: 10,
                left: 10,
              },
            },
          },
        },
      })
    }
  }, [salesData, activeBrands])

  const toggleBrand = (brand) => {
    setActiveBrands((prevActiveBrands) => {
      const newActiveBrands = { ...prevActiveBrands, [brand]: !prevActiveBrands[brand] };

      if (chartInstance.current) {
        chartInstance.current.data.datasets.forEach((dataset) => {
          if (dataset.label === brand) {
            dataset.hidden = !newActiveBrands[brand];
          }
        });
        chartInstance.current.update();
      }

      return newActiveBrands;
    });
  };

  const toggleAllBrands = () => {
    setActiveBrands((prevActiveBrands) => {
      // Check if all brands except 'TOTAL' are active
      const allActive = Object.entries(prevActiveBrands)
        .filter(([brand, _]) => brand !== 'TOTAL')
        .every(([_, isActive]) => isActive);
  
      const newActiveState = !allActive;
      
      // Create a new object with all brands toggled except 'TOTAL', which retains its state
      const newActiveBrands: ActiveBrandsState = Object.keys(prevActiveBrands).reduce((acc, brand) => {
        acc[brand] = brand === 'TOTAL' ? prevActiveBrands[brand] : newActiveState;
        return acc;
      }, {} as ActiveBrandsState);
  
      if (chartInstance.current) {
        chartInstance.current.data.datasets.forEach((dataset) => {
          // Update visibility for all datasets except 'TOTAL'
          if (dataset.label && dataset.label !== 'TOTAL') {
            const brandKey = dataset.label.toUpperCase();
            if (brandColorMapping.hasOwnProperty(brandKey)) {
              dataset.hidden = newActiveState; // Toggle based on the new state
            }
          }
        });
        chartInstance.current.update();
      }
  
      // The selectAll state should reflect the new state of all brands except 'TOTAL'
      setSelectAllLabel(newActiveState);
      return newActiveBrands;
    });
  };
  
  
  
  return (
    <div className={`card card-flush ${className}`} style={{ ...cardStyle, minHeight: '300px' }}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span
              className='fw-bold text-dark me-2 lh-1 ls-n2'
              style={{ whiteSpace: 'nowrap', fontSize: '2rem' }}
            >
              Units Sold per Brand per Door
            </span>
          </div>
          <span className='pt-1 fw-semibold fs-6' style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            {rangeDisplay}
          </span>
        </div>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '2rem' }}>
        <div className={`form-check form-switch`} style={{ marginRight: '10px' }}>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`switch-select-all`}
            checked={selectAllLabel}
            onChange={toggleAllBrands}
          />
          <label
            className="form-check-label"
            htmlFor={`switch-select-all`}
            style={{ color: 'white' }}
          >
            Select All
          </label>
        </div>

        {Object.keys(activeBrands).map((brand) => {
          const className = `switch-${brand.toLowerCase().replace(/\s+/g, '-')}`;
          return (
            <div className={`form-check form-switch ${className}`} key={brand} style={{ marginRight: '10px' }}>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id={`switch-${className}`}
                checked={typeof activeBrands[brand] === 'undefined' ? true : activeBrands[brand]}
                onChange={() => toggleBrand(brand)}
              />
              <label
                className="form-check-label"
                htmlFor={`flexSwitchCheck${brand}`}
                style={{ color: 'white' }}
              >
                {brand}
              </label>
            </div>
          );
        })}
      </div>

      <div style={{ width: '100%', overflowX: 'auto' }}>
        <div className='card-body pt-2 pb-4 d-flex align-items-center chart-container' style={{ whiteSpace: 'nowrap', }}>
          <canvas ref={chartRef} className='brand-performance-chart' style={{ maxHeight: '500px' }} />
        </div>
      </div>
    </div>
  )
}
