import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { API_URL } from '../../../../../../../../app/modules/auth/core/_requests';

export const CostaInventoryTable = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sku: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/costa-inventory/inventory-table`);
        setInventoryData(response.data.inventoryData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching inventory data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div className='table-header'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder='Global Search'
        />
      </span>
    </div>
  );

  const formatCurrency = (value) => {
    return value != null ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '';
  };

  return (
    <div className='datatable-crud-demo'>
      <div className='card'>
        <DataTable
          value={inventoryData}
          paginator
          rows={10}
          dataKey='sku'
          filters={filters}
          filterDisplay='menu'
          loading={loading}
          globalFilterFields={['productName', 'sku']}
          header={header}
          responsiveLayout='scroll'
          resizableColumns
          columnResizeMode='expand'
          reorderableColumns
          stripedRows
          rowsPerPageOptions={[5, 10, 20, 50]}
        >
          {/* <Column field='image' header='Image' body={(rowData) => (rowData.image ? <img src={rowData.image} alt={rowData.productName} width="50" /> : null)} sortable filter></Column> */}
          <Column field='productName' header='Product Name' sortable filter></Column>
          <Column field='upc' header='UPC' sortable filter></Column>
          <Column field='sku' header='SKU' sortable filter></Column>
          <Column field='asin' header='ASIN' sortable filter></Column>
          <Column field='qtyAvailable' header='Available' sortable filter></Column>
          {/* <Column field='yourMinimumPrice' header='Min Price' body={(rowData) => formatCurrency(rowData.yourMinimumPrice)} sortable filter></Column>
          <Column field='yourMaximumPrice' header='Max Price' body={(rowData) => formatCurrency(rowData.yourMaximumPrice)} sortable filter></Column>
          <Column field='unfulfillable' header='Unfulfillable' sortable filter></Column>
          <Column field='statusChangedDate' header='Status Changed Date' sortable filter></Column>
          <Column field='status' header='Status' sortable filter></Column>
          <Column field='salesRank' header='Sales Rank' sortable filter></Column>
          <Column field='reserved' header='Reserved' sortable filter></Column>
          <Column field='pricingStatus' header='Pricing Status' sortable filter></Column>
          <Column field='priceAndShippingCost' header='Price & Shipping Cost' body={(rowData) => formatCurrency(rowData.priceAndShippingCost)} sortable filter></Column>
          <Column field='inbound' header='Inbound' sortable filter></Column>
          <Column field='fulfilledBy' header='Fulfilled By' sortable filter></Column>
          <Column field='fnsku' header='FNSKU' sortable filter></Column>
          <Column field='featuredOfferEligible' header='Featured Offer Eligible' sortable filter></Column>
          <Column field='fbaFee' header='FBA Fee' body={(rowData) => formatCurrency(rowData.fbaFee)} sortable filter></Column>
          <Column field='estimatedFeePerUnitSold' header='Estimated Fee Per Unit Sold' body={(rowData) => formatCurrency(rowData.estimatedFeePerUnitSold)} sortable filter></Column>
          <Column field='dateCreated' header='Date Created' sortable filter></Column>
          <Column field='condition' header='Condition' sortable filter></Column>
          <Column field='valueOfTotal7d' header='Value of Total 7d' body={(rowData) => formatCurrency(rowData.valueOfTotal7d)} sortable filter></Column>
          <Column field='valueOfTotal30d' header='Value of Total 30d' body={(rowData) => formatCurrency(rowData.valueOfTotal30d)} sortable filter></Column>
          <Column field='diffValueOfTotal7d' header='Diff Value of Total 7d' body={(rowData) => formatCurrency(rowData.diffValueOfTotal7d)} sortable filter></Column>
          <Column field='diffValueOfTotal30d' header='Diff Value of Total 30d' body={(rowData) => formatCurrency(rowData.diffValueOfTotal30d)} sortable filter></Column> */}
        </DataTable>
      </div>
    </div>
  );
};
