/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../../../_designereyes/layout/core'
import {BrandSearch} from '../../../../_designereyes/partials/widgets'

const BrandSearchPage: FC = () => {
  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-12 col-lg-12'>
          <BrandSearch className='h-md-50 mb-5 mb-xl-10 h-lg-100' />
        </div>
      </div>
    </>
  )
}

const BrandSearchWrapper: FC = () => {
  return (
    <>
      <PageTitle>BI Brand Research Tool</PageTitle>
      <BrandSearchPage />
    </>
  )
}

export {BrandSearchWrapper}
