import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { API_URL } from '../../../../../../../../app/modules/auth/core/_requests';

interface ChartData {
  date: string;
  quantity_sold: number;
  revenue: number;
  profit: number;
}

const dateOptions = [
  "today", "yesterday", "dayBeforeYesterday", "last7Days", "thisWeek", "lastWeek", "thisMonth", "lastMonth", "last30Days", "thisQuarter", "lastQuarter", "thisYear", "lastYear", "last12Months"
];

const dateLabels: { [key: string]: string } = {
  today: "Today",
  yesterday: "Yesterday",
  dayBeforeYesterday: "Day Before Yesterday",
  last7Days: "Last 7 Days",
  thisWeek: "This Week",
  lastWeek: "Last Week",
  thisMonth: "This Month",
  lastMonth: "Last Month",
  last30Days: "Last 30 Days",
  thisQuarter: "This Quarter",
  lastQuarter: "Last Quarter",
  thisYear: "This Year",
  lastYear: "Last Year",
  last12Months: "Last 12 Months"
};

export const CostaLineChart: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState("thisMonth");
  const [chartData, setChartData] = useState<ChartData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/asin-dashboard/chart-data?date=${selectedDate}&interval=day`);
        const result = await response.json();
        setChartData(result.data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedDate]);

  const data = {
    labels: chartData.map(item => item.date),
    datasets: [
      {
        label: 'Units Sold',
        data: chartData.map(item => item.quantity_sold),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: false,
      },
      {
        label: 'Revenue',
        data: chartData.map(item => item.revenue),
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        fill: false,
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          color: 'white'
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: 'white'
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.05)'
        }
      },
      y: {
        ticks: {
          color: 'white'
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.05)'
        }
      }
    }
  };

  return (
    <div className="card p-4">
      <h2 style={{ color: 'white' }}>Sales Data</h2>
      <div>
        <select value={selectedDate} onChange={e => setSelectedDate(e.target.value)} style={{ color: 'black' }}>
          {dateOptions.map(option => (
            <option key={option} value={option}>{dateLabels[option]}</option>
          ))}
        </select>
      </div>
      <Line data={data} options={options} />
    </div>
  );
};
