import React, { useState, useEffect } from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { API_URL } from '../../../../../../../../app/modules/auth/core/_requests';
import moment from 'moment';

interface Product {
  asin: string;
  product: {
    image: string;
    title: string;
  };
  quantity_sold: number;
  revenue: number;
}

interface CostaUnitsSoldProps {
  date: string;
  title: string;
  className: string;
}

export const CostaUnitsSold: React.FC<CostaUnitsSoldProps> = ({ date, title, className }) => {
  const [data, setData] = useState<Product[]>([]);
  const [summary, setSummary] = useState<{ quantity_sold: number; revenue: number }>({ quantity_sold: 0, revenue: 0 });

  const getMinDate = (dateRange: string): string => {
    switch (dateRange) {
      case 'today':
        return moment().startOf('day').format('YYYY-MM-DD');
      case 'yesterday':
        return moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
      case 'last7Days':
        return moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD');
      default:
        return moment().startOf('day').format('YYYY-MM-DD');
    }
  };

  const getMaxDate = (dateRange: string): string => {
    switch (dateRange) {
      case 'today':
      case 'yesterday':
        return moment().subtract(dateRange === 'yesterday' ? 1 : 0, 'days').endOf('day').format('YYYY-MM-DD');
      case 'last7Days':
        return moment().endOf('day').format('YYYY-MM-DD');
      default:
        return moment().endOf('day').format('YYYY-MM-DD');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const minDate = getMinDate(date);
        const maxDate = getMaxDate(date);

        const response = await fetch(`${API_URL}/asin-dashboard/search-asins`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ minDate, maxDate }),
        });
        const result = await response.json();
        const totalSummary = result.find((item: Product) => item.asin === '__total__');
        const filteredData = result.filter((item: Product) => item.asin !== '__total__');
        const sortedData = filteredData.sort((a: Product, b: Product) => b.quantity_sold - a.quantity_sold);
        setSummary({
          quantity_sold: totalSummary ? totalSummary.quantity_sold : 0,
          revenue: totalSummary ? totalSummary.revenue : 0,
        });
        setData(sortedData.slice(0, 5)); // Get top 5 sellers
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [date]);

  const imageBodyTemplate = (rowData: Product) => {
    return rowData.product && rowData.product.image ? (
      <img
        src={rowData.product.image}
        alt={rowData.product.title}
        width="50"
        title={rowData.product.title}
      />
    ) : null;
  };

  const formatCurrency = (value: number) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  return (
    <div className={className}>
      <div className="card text-center">
        <div className="card-header justify-content-center align-items-center fs-4" style={{ color: 'white' }}>
          {title}
        </div>
        <div className="card-body" style={{ minHeight: '350px', maxHeight: '350px', overflowY: 'auto' }}>
          <h5 className="card-title" style={{ color: 'white' }}>{summary.quantity_sold} Units Sold</h5>
          <h5 className="card-title" style={{ color: 'white' }}>Revenue: {formatCurrency(summary.revenue)}</h5>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Units</th>
                <th>Image</th>
                <th>Revenue</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.quantity_sold}</td>
                  <td>{imageBodyTemplate(item)}</td>
                  <td>{formatCurrency(item.revenue)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="card-footer text-muted">
          <a href="/dashboard" className="btn btn-primary">See More</a>
        </div>
      </div>
    </div>
  );
};
