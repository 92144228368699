import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_designereyes/layout/core';
import { UploadImage } from '../../../_designereyes/partials/de-tiles/UploadImage';

const ImageUploadPage: React.FC = () => {

  return (<>
  <div>
    
  </div>
    <div className='card card-flush'>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-1hx fw-bold text-dark me-2 lh-1 ls-n2'>Upload Your Image</span>
           
          </div>
        </div>
      </div>
      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
      <UploadImage />
      </div>
    </div>
    <div className='card card-flush mt-7'>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-1hx fw-bold text-dark me-2 lh-1 ls-n2'>Uploaded Image Details</span>
          </div>
        </div>
      </div>
      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
       TODO table with images and details
      </div>
    </div>
    </>
  );
};



const ImageUploadWrapper: React.FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>Image Uploader</PageTitle>
      <ImageUploadPage />
    </>
  );
};

export { ImageUploadWrapper };
