/* eslint-disable jsx-a11y/anchor-is-valid */
import { Chart, BarController, CategoryScale, LinearScale } from 'chart.js';
import React, { FC, useEffect, useRef, useState } from 'react';

Chart.register(BarController, CategoryScale, LinearScale);

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  vendor: string
}

const cardStyle: React.CSSProperties = {
  height: '100%',  // Fixed height
  width: '100%',   // Fixed width
  overflow: 'hidden', // Hides any overflowing content
  position: 'relative',
  borderRadius: '8px', // Optional: if you want rounded corners
  border: '0px solid white',

  // Gradient style definition
  // background: 'linear-gradient(315deg, #01081F 0%, #021530 40%, #032242 90%, #021530 100%, #01081F 130%)',
};

export const BestSellingBrandsOverTimeEcom: FC<Props> = ({ className, vendor, chartSize, chartLine, chartRotate }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const [timeRange, setTimeRange] = useState('Year');
  const [selectedYear, setSelectedYear] = useState('2024'); // for 'Year' view
  const [selectedMonth, setSelectedMonth] = useState('January'); // for 'Month' view
  const [selectedYearForMonth, setSelectedYearForMonth] = useState('2024'); // year selection in 'Month' view
  const [datasetVisibility, setDatasetVisibility] = useState({});
  const delayed = useRef(false); // Using useRef to persist the value without causing re-renders

  const generateRandomData = (length, min, max) => 
  Array.from({ length }, () => Math.floor(Math.random() * (max - min + 1)) + min);

  // Brands to include in the chart
  const theliosBrands = ['Dior', 'Loewe', 'Celine', 'Fendi'];
  const luxotticaBrands = ['Ray Ban', 'Prada', 'Versace', 'Oakley', 'Dolce & Gabbana', 'Burberry'];

  // Choose brands based on vendor
  const brands = vendor === 'Thelios' ? theliosBrands : luxotticaBrands;

  // Generate data for each brand
  const generateYearlyBrandData = (brands, vendor) => {
    let brandData = {};

    if (vendor === 'Thelios') {
      brands.forEach(brand => {
        if (brand === 'Dior') {  
          brandData[brand] = {
            '2024': generateRandomData(12, 20, 33),
            '2023': generateRandomData(12, 20, 35),
            '2022': generateRandomData(12, 20, 34),
            // ... similarly for other years
          };
        }
        else if (brand === 'Loewe') {
          brandData[brand] = {
            '2024': generateRandomData(12, 15, 30),
            '2023': generateRandomData(12, 15, 33),
            '2022': generateRandomData(12, 15, 31),
            // ... similarly for other years
          };
        }
        else {
          brandData[brand] = {
            '2024': generateRandomData(12, 10, 19),
            '2023': generateRandomData(12, 10, 20),
            '2022': generateRandomData(12, 10, 21),
            // ... similarly for other years
          };
        }
      });
    }
    if (vendor === 'Luxottica') {
      brands.forEach(brand => {
        if (brand === 'Ray Ban') {  
          brandData[brand] = {
            '2024': generateRandomData(12, 3000, 5000),
            '2023': generateRandomData(12, 3000, 4000),
            '2022': generateRandomData(12, 3000, 4000),
            // ... similarly for other years
          };
        }
        else if (brand === 'Prada') {
          brandData[brand] = {
            '2024': generateRandomData(12, 1000, 3000),
            '2023': generateRandomData(12, 1000, 2000),
            '2022': generateRandomData(12, 1000, 2000),
            // ... similarly for other years
          };
        }
        else if (brand === 'Versace') {
          brandData[brand] = {
            '2024': generateRandomData(12, 1000, 1700),
            '2023': generateRandomData(12, 1000, 1500),
            '2022': generateRandomData(12, 1000, 1500),
            // ... similarly for other years
          };
        }
        else {
          brandData[brand] = {
            '2024': generateRandomData(12, 400, 1000),
            '2023': generateRandomData(12, 400, 1000),
            '2022': generateRandomData(12, 400, 1000),
            // ... similarly for other years
          };
        }
      });
    }
    return brandData;
  };

  const generateMonthlyBrandData = (brands, vendor) => {
    let brandData = {};

    if (vendor === 'Thelios') {
      brands.forEach(brand => {
        if (brand === 'Dior') {
          brandData[brand] = {
            '2024': {
              'January': generateRandomData(31, 20, 30),
              'February': generateRandomData(28, 20, 30),
              'March': generateRandomData(31, 20, 30),
              'April': generateRandomData(30, 20, 30),
              'May': generateRandomData(31, 20, 30),
              'June': generateRandomData(30, 20, 30),
              'July': generateRandomData(31, 20, 30),
              'August': generateRandomData(31, 20, 30),
              'September': generateRandomData(30, 20, 30),
              'October': generateRandomData(31, 20, 30),
              'November': generateRandomData(30, 20, 30),
              'December': generateRandomData(31, 20, 30),
              // ... other months
            },
            '2023': {
              'January': generateRandomData(31, 20, 30),
              'February': generateRandomData(28, 20, 30),
              'March': generateRandomData(31, 20, 30),
              'April': generateRandomData(30, 20, 30),
              'May': generateRandomData(31, 20, 30),
              'June': generateRandomData(30, 20, 30),
              'July': generateRandomData(31, 20, 30),
              'August': generateRandomData(31, 20, 30),
              'September': generateRandomData(30, 20, 30),
              'October': generateRandomData(31, 20, 30),
              'November': generateRandomData(30, 20, 30),
              'December': generateRandomData(31, 20, 30),
            },
            '2022': {
              'January': generateRandomData(31, 20, 30),
              'February': generateRandomData(28, 20, 30),
              'March': generateRandomData(31, 20, 30),
              'April': generateRandomData(30, 20, 30),
              'May': generateRandomData(31, 20, 30),
              'June': generateRandomData(30, 20, 30),
              'July': generateRandomData(31, 20, 30),
              'August': generateRandomData(31, 20, 30),
              'September': generateRandomData(30, 20, 30),
              'October': generateRandomData(31, 20, 30),
              'November': generateRandomData(30, 20, 30),
              'December': generateRandomData(31, 20, 30),
            },
            // ... similarly for other years
          };
        }
        else {      
          brandData[brand] = {
            '2024': {
              'January': generateRandomData(31, 10, 20),
              'February': generateRandomData(28, 10, 20),
              'March': generateRandomData(31, 10, 20),
              'April': generateRandomData(30, 10, 20),
              'May': generateRandomData(31, 10, 20),
              'June': generateRandomData(30, 10, 20),
              'July': generateRandomData(31, 10, 20),
              'August': generateRandomData(31, 10, 20),
              'September': generateRandomData(30, 10, 20),
              'October': generateRandomData(31, 10, 20),
              'November': generateRandomData(30, 10, 20),
              'December': generateRandomData(31, 10, 20),
              // ... other months
            },
            '2023': {
              'January': generateRandomData(31, 10, 20),
              'February': generateRandomData(28, 10, 20),
              'March': generateRandomData(31, 10, 20),
              'April': generateRandomData(30, 10, 20),
              'May': generateRandomData(31, 10, 20),
              'June': generateRandomData(30, 10, 20),
              'July': generateRandomData(31, 10, 20),
              'August': generateRandomData(31, 10, 20),
              'September': generateRandomData(30, 10, 20),
              'October': generateRandomData(31, 10, 20),
              'November': generateRandomData(30, 10, 20),
              'December': generateRandomData(31, 10, 20),
            },
            '2022': {
              'January': generateRandomData(31, 10, 20),
              'February': generateRandomData(28, 10, 20),
              'March': generateRandomData(31, 10, 20),
              'April': generateRandomData(30, 10, 20),
              'May': generateRandomData(31, 10, 20),
              'June': generateRandomData(30, 10, 20),
              'July': generateRandomData(31, 10, 20),
              'August': generateRandomData(31, 10, 20),
              'September': generateRandomData(30, 10, 20),
              'October': generateRandomData(31, 10, 20),
              'November': generateRandomData(30, 10, 20),
              'December': generateRandomData(31, 10, 20),
            },
          };
        }
      });
    }
    else if (vendor === 'Luxottica') {
      brands.forEach(brand => {
        if (brand === 'Ray Ban') {
          brandData[brand] = {
            '2024': {
              'January': generateRandomData(31, 100, 150),
              'February': generateRandomData(28, 100, 150),
              'March': generateRandomData(31, 100, 150),
              'April': generateRandomData(30, 100, 150),
              'May': generateRandomData(31, 100, 150),
              'June': generateRandomData(30, 100, 150),
              'July': generateRandomData(31, 100, 150),
              'August': generateRandomData(31, 100, 150),
              'September': generateRandomData(30, 100, 150),
              'October': generateRandomData(31, 100, 150),
              'November': generateRandomData(30, 100, 150),
              'December': generateRandomData(31, 100, 150),
              // ... other months
            },
            '2023': {
              'January': generateRandomData(31, 100, 150),
              'February': generateRandomData(28, 100, 150),
              'March': generateRandomData(31, 100, 150),
              'April': generateRandomData(30, 100, 150),
              'May': generateRandomData(31, 100, 150),
              'June': generateRandomData(30, 100, 150),
              'July': generateRandomData(31, 100, 150),
              'August': generateRandomData(31, 100, 150),
              'September': generateRandomData(30, 100, 150),
              'October': generateRandomData(31, 100, 150),
              'November': generateRandomData(30, 100, 150),
              'December': generateRandomData(31, 100, 150),
            },
            '2022': {
              'January': generateRandomData(31, 100, 150),
              'February': generateRandomData(28, 100, 150),
              'March': generateRandomData(31, 100, 150),
              'April': generateRandomData(30, 100, 150),
              'May': generateRandomData(31, 100, 150),
              'June': generateRandomData(30, 100, 150),
              'July': generateRandomData(31, 100, 150),
              'August': generateRandomData(31, 100, 150),
              'September': generateRandomData(30, 100, 150),
              'October': generateRandomData(31, 100, 150),
              'November': generateRandomData(30, 100, 150),
              'December': generateRandomData(31, 100, 150),
            },
            // ... similarly for other years
          };
        }
        else if (brand === 'Prada') {     
          brandData[brand] = {
            '2024': {
              'January': generateRandomData(31, 50, 100),
              'February': generateRandomData(28, 50, 100),
              'March': generateRandomData(31, 50, 100),
              'April': generateRandomData(30, 50, 100),
              'May': generateRandomData(31, 50, 100),
              'June': generateRandomData(30, 50, 100),
              'July': generateRandomData(31, 50, 100),
              'August': generateRandomData(31, 50, 100),
              'September': generateRandomData(30, 50, 100),
              'October': generateRandomData(31, 50, 100),
              'November': generateRandomData(30, 50, 100),
              'December': generateRandomData(31, 50, 100),
              // ... other months
            },
            '2023': {
              'January': generateRandomData(31, 50, 100),
              'February': generateRandomData(28, 50, 100),
              'March': generateRandomData(31, 50, 100),
              'April': generateRandomData(30, 50, 100),
              'May': generateRandomData(31, 50, 100),
              'June': generateRandomData(30, 50, 100),
              'July': generateRandomData(31, 50, 100),
              'August': generateRandomData(31, 50, 100),
              'September': generateRandomData(30, 50, 100),
              'October': generateRandomData(31, 50, 100),
              'November': generateRandomData(30, 50, 100),
              'December': generateRandomData(31, 50, 100),
            },
            '2022': {
              'January': generateRandomData(31, 50, 100),
              'February': generateRandomData(28, 50, 100),
              'March': generateRandomData(31, 50, 100),
              'April': generateRandomData(30, 50, 100),
              'May': generateRandomData(31, 50, 100),
              'June': generateRandomData(30, 50, 100),
              'July': generateRandomData(31, 50, 100),
              'August': generateRandomData(31, 50, 100),
              'September': generateRandomData(30, 50, 100),
              'October': generateRandomData(31, 50, 100),
              'November': generateRandomData(30, 50, 100),
              'December': generateRandomData(31, 50, 100),
            },
          };
        }
        else if (brand === 'Versace') {   
          brandData[brand] = {
            '2024': {
              'January': generateRandomData(31, 50, 80),
              'February': generateRandomData(28, 50, 80),
              'March': generateRandomData(31, 50, 80),
              'April': generateRandomData(30, 50, 80),
              'May': generateRandomData(31, 50, 80),
              'June': generateRandomData(30, 50, 80),
              'July': generateRandomData(31, 50, 80),
              'August': generateRandomData(31, 50, 80),
              'September': generateRandomData(30, 50, 80),
              'October': generateRandomData(31, 50, 80),
              'November': generateRandomData(30, 50, 80),
              'December': generateRandomData(31, 50, 80),
              // ... other months
            },
            '2023': {
              'January': generateRandomData(31, 50, 80),
              'February': generateRandomData(28, 50, 80),
              'March': generateRandomData(31, 50, 80),
              'April': generateRandomData(30, 50, 80),
              'May': generateRandomData(31, 50, 80),
              'June': generateRandomData(30, 50, 80),
              'July': generateRandomData(31, 50, 80),
              'August': generateRandomData(31, 50, 80),
              'September': generateRandomData(30, 50, 80),
              'October': generateRandomData(31, 50, 80),
              'November': generateRandomData(30, 50, 80),
              'December': generateRandomData(31, 50, 80),
            },
            '2022': {
              'January': generateRandomData(31, 50, 80),
              'February': generateRandomData(28, 50, 80),
              'March': generateRandomData(31, 50, 80),
              'April': generateRandomData(30, 50, 80),
              'May': generateRandomData(31, 50, 80),
              'June': generateRandomData(30, 50, 80),
              'July': generateRandomData(31, 50, 80),
              'August': generateRandomData(31, 50, 80),
              'September': generateRandomData(30, 50, 80),
              'October': generateRandomData(31, 50, 80),
              'November': generateRandomData(30, 50, 80),
              'December': generateRandomData(31, 50, 80),
            },
          };
        }
        else {      
          brandData[brand] = {
            '2024': {
              'January': generateRandomData(31, 30, 100),
              'February': generateRandomData(28, 20, 100),
              'March': generateRandomData(31, 30, 100),
              'April': generateRandomData(30, 40, 100),
              'May': generateRandomData(31, 30, 100),
              'June': generateRandomData(30, 30, 100),
              'July': generateRandomData(31, 10, 100),
              'August': generateRandomData(31, 30, 100),
              'September': generateRandomData(30, 30, 100),
              'October': generateRandomData(31, 20, 100),
              'November': generateRandomData(30, 10, 100),
              'December': generateRandomData(31, 20, 100),
              // ... other months
            },
            '2023': {
              'January': generateRandomData(31, 30, 60),
              'February': generateRandomData(28, 30, 60),
              'March': generateRandomData(31, 10, 40),
              'April': generateRandomData(30, 20, 20),
              'May': generateRandomData(31, 30, 60),
              'June': generateRandomData(30, 20, 60),
              'July': generateRandomData(31, 30, 60),
              'August': generateRandomData(31, 20, 60),
              'September': generateRandomData(30, 10, 60),
              'October': generateRandomData(31, 10, 60),
              'November': generateRandomData(30, 20, 60),
              'December': generateRandomData(31, 10, 60),
            },
            '2022': {
              'January': generateRandomData(31, 30, 30),
              'February': generateRandomData(28, 10, 30),
              'March': generateRandomData(31, 20, 20),
              'April': generateRandomData(30, 0, 20),
              'May': generateRandomData(31, 10, 20),
              'June': generateRandomData(30, 0, 30),
              'July': generateRandomData(31, 20, 20),
              'August': generateRandomData(31, 30, 30),
              'September': generateRandomData(30, 30, 40),
              'October': generateRandomData(31, 10, 200),
              'November': generateRandomData(30, 10, 20),
              'December': generateRandomData(31, 10, 10),
            },
          };
        }
      });
    }
    return brandData;
  };

  const yearlyData = generateYearlyBrandData(brands, vendor);
  const monthlyData = generateMonthlyBrandData(brands, vendor); 


  const choosebackgroundColor = (brand, vendor) => {
    if (vendor === 'Thelios') {
      if (brand === 'Dior') {
        return 'rgba(255, 99, 132, 1)';
      }
      else if (brand === 'Loewe') {
        return 'rgba(54, 162, 235, 1)';
      }
      else if (brand === 'Celine') {
        return 'rgba(75, 192, 192, 1)';
      }
      else {
        return 'rgba(255, 204, 0, 1)';
      }
    }
    else if (vendor === 'Luxottica') {
      if (brand === 'Ray Ban') {
        return 'rgba(255, 99, 132, 1)';
      }
      else if (brand === 'Prada') {
        return 'rgba(54, 162, 235, 1)';
      }
      else if (brand === 'Versace') {
        return 'rgba(75, 192, 192, 1)';
      }
      else if (brand === 'Oakley') {
        return 'rgba(255, 204, 0, 1)';
      }
      else if (brand === 'Dolce & Gabbana') {
        return 'rgba(153, 102, 255, 1)';
      }
      else {
        return 'rgba(255, 159, 64, 1)';
      }
    }
  }

  const createDatasets = (brands, yearlyData, monthlyData, selectedYear, selectedMonth, timeRange, vendor) => {
    const reversedBrands = [...brands].reverse();
    
    return brands.map(brand => ({
      label: brand,
      data: timeRange === 'Year' ? yearlyData[brand][selectedYear] : monthlyData[brand][selectedYear][selectedMonth],
      hidden: datasetVisibility[brand] === false,
      // fill: true,
      borderColor: choosebackgroundColor(brand, vendor),
      backgroundColor: choosebackgroundColor(brand, vendor),
      // ... other dataset properties
    }));
  };
  
  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        // Destroy the existing chart instance if it exists
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
  
        let chartData;
        if (timeRange === 'Year') {
          chartData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            datasets: createDatasets(brands, yearlyData, monthlyData, selectedYear, '', timeRange, vendor)
          };
        } else {
          chartData = {
            labels: Array.from({ length: 31 }, (_, i) => (i + 1).toString()), // Generate labels for days of the month
            datasets: createDatasets(brands, yearlyData, monthlyData, selectedYearForMonth, selectedMonth, timeRange, vendor)
          };
        }
  
        // Initialize a new Chart instance
        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: chartData,
          options: {
            animation: {
              onComplete: () => {
                delayed.current = true; // Update the useRef value
              },
              delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed.current) {
                  delay = context.dataIndex * 200 + context.datasetIndex * 150;
                }
                return delay;
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white'
                },
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)'
                },
              },
              y: {
                ticks: {
                  color: 'white'
                },
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)'
                },
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                onClick: (e, legendItem, legend) => {
                  const index = legendItem.datasetIndex;
                  const ci = legend.chart;
          
                  if (typeof index !== 'number') {
                    // Handle the case where index is not a number
                    return;
                  }
          
                  if (ci.isDatasetVisible(index)) {
                    ci.hide(index);
                    setDatasetVisibility({ ...datasetVisibility, [legendItem.text]: false });
                  } else {
                    ci.show(index);
                    setDatasetVisibility({ ...datasetVisibility, [legendItem.text]: true });
                  }
                },
                labels: {
                  color: 'white',
                }
              },
              tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  title: (tooltipItems) => {
                    if (tooltipItems[0]) {
                      return tooltipItems[0].label || '';
                    }
                    return '';
                  },
                  // Display label and value in tooltip
                  label: (tooltipItem) => {
                    if (tooltipItem.datasetIndex !== undefined) {
                      const dataset = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
                      const label = dataset.label || '';
                      const value = tooltipItem.formattedValue || '';
                      return `${label}: ${value}`;
                    }
                    return '';
                },
                }
              }
            }
          },
        });
      }
    }
  }, [selectedYear, selectedMonth, selectedYearForMonth, timeRange]);

  return (
    <div className={`card card-flush ${className}`} style={cardStyle}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>Brands' Sales Over Time</span>
            <select className='form-select form-select-sm' value={timeRange} onChange={(e) => setTimeRange(e.target.value)} style={{color: 'white', marginLeft:'4rem', width: 'auto'}}>
              <option value="Year">Year</option>
              <option value="Month">Month</option>
            </select>
            {timeRange === 'Year' ? (
              <select className='form-select form-select-sm' value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} style={{color: 'white', width: 'auto'}}>
                {['2024', '2023', '2022'].map(year => <option key={year} value={year}>{year}</option>)}
              </select>
            ) : (
              <>
                <select className='form-select form-select-sm' value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} style={{color: 'white', width: 'auto'}}>
                  {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                    .map(month => <option key={month} value={month}>{month}</option>)}
                </select>
                <select className='form-select form-select-sm' value={selectedYearForMonth} onChange={(e) => setSelectedYearForMonth(e.target.value)} style={{color: 'white', width: 'auto'}}>
                  {['2024', '2023', '2022'].map(year => <option key={year} value={year}>{year}</option>)}
                </select>
              </>
            )}
          </div>
          <span className='pt-1 fw-semibold fs-6'>Total Sales</span>
        </div>
      </div>
  
      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <canvas ref={chartRef} style={{ width: '100%', maxHeight: '300px' }} />
      </div>
    </div>
  )  
}
