import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import moment from 'moment-timezone';
import { API_URL } from '../../../../../../../app/modules/auth/core/_requests'
import { useQuery } from 'react-query';

interface TodaySalesProps {
  vendor: string;
  isExpanded?: boolean;
}

function formatNumber(num) {
  let roundedNum = Math.round(num);

  if (roundedNum >= 1000000) {
    return (roundedNum / 1000000).toFixed(1) + 'M';
  } else if (roundedNum >= 1000) {
    return (roundedNum / 1000).toFixed(1) + 'K';
  } else {
    return roundedNum.toString();
  }
}

const cardBodyStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  margin: '0 0 0 0',
  height: '100%',
};

const bigNumberStyle: React.CSSProperties = {
  paddingTop: '1rem',
  fontSize: '4rem',
  lineHeight: '1',
  fontWeight: '500',
  color: '#fff',
};

const footerInnerContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const footerTitleStyle: React.CSSProperties = {
  color: '#fff',
  fontSize: '1.15rem',
  fontWeight: '700',
};

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: 'black',
  paddingTop: '1rem',
  paddingRight: '1rem',
};

const iconStyle: React.CSSProperties = {
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  fontSize: '2rem',
  color: 'white',
};

const innerContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

const growthContainerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const fetchTodaySales = async ({ queryKey }) => {
  const [_key, { todayDate, vendor }] = queryKey;
  const response = await axios.get(`${API_URL}/data/query-today-sales`, { 
    params: { date: todayDate, vendor: vendor } 
  });
  let salesData = response.data.salesData;

  if (salesData.length === 0 || salesData[salesData.length - 1].date !== todayDate) {
    salesData.push({ date: todayDate, quantity: 0 });
  }

  return salesData;
};

export const TodaySales: React.FC<TodaySalesProps> = ({ vendor, isExpanded = false }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const cardStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    background: 'linear-gradient(45deg,#F1467A,#FB949E)',
    color: '#fff',

    borderRadius: isExpanded ? '8px 8px 0 0' : '8px',
    border: isExpanded ? '0px' : '',
  }

  const todayDate = moment.tz("America/New_York").format('YYYY-MM-DD');

  const {
    data: salesData,
    isLoading,
  } = useQuery(['todaySales', { todayDate, vendor }], fetchTodaySales);

  const placeholders = {
    todaySales: 0,
    sevenDayLabels: Array(7).fill(''),
    sevenDayData: Array(7).fill(0),
  };

  const todaySales = !isLoading && salesData ? salesData[salesData.length - 1].quantity : placeholders.todaySales;
  const sevenDayLabels = !isLoading && salesData ? salesData.map(data => moment(data.date, 'YYYY-MM-DD').format('MM/DD')) : placeholders.sevenDayLabels;
  const sevenDayData = !isLoading && salesData ? salesData.map(data => data.quantity) : placeholders.sevenDayData;


  const formattedNumber = formatNumber(todaySales);

  const barChartData = {
    labels: sevenDayLabels,
    datasets: [
      {
        label: 'Sales',
        data: sevenDayData,
        backgroundColor: sevenDayLabels.map((label, index) => index === sevenDayLabels.length - 1 ? '#ffcc00' : 'white'),
      },
    ],
  };

  const barChartOptions = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index' as 'index',
        axis: 'x' as 'x',
        intersect: false,
      }
    },
  };

  const smallChartStyle: React.CSSProperties = {
    position: 'absolute',
    top: '1.5rem',
    right: '1.5rem',
    width: '12rem',
    height: '8rem',
  };

  return (
    <div className="card card-flush"
      style={cardStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <FontAwesomeIcon icon={faMoneyBillTransfer} style={iconStyle} />
      <div style={smallChartStyle}>
        <Bar data={barChartData} options={barChartOptions} />
      </div>
      <div className="card-body" style={cardBodyStyle}>
        <div style={innerContainerStyle}>
          <div style={growthContainerStyle}>
            <span className="text-dark" style={bigNumberStyle}>
              {isHovered || isLoading ? Math.round(todaySales) : formattedNumber}
            </span>
          </div>
        </div>
      </div>
      <div className="card-footer" style={footerStyle}>
        <div style={footerInnerContainerStyle}>
          <div style={footerTitleStyle}>Today's Sales</div>
        </div>
      </div>
    </div>
  );
};
