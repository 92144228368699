import React, {useEffect, useState} from 'react'
import {BrandFocus} from '../../../../../../widgets'
import {BestPerformingItem} from '../../../../../../widgets'
import {LowPerformingItem} from '../../../../../../widgets'
import {CostaInventoryTable} from './CostaInventoryTable'
import {useAuth} from '../../../../../../../../app/modules/auth'
import axios from 'axios'
import {API_URL} from '../../../../../../../../app/modules/auth/core/_requests'

export const CostaInventoryInsights = () => {
  const {currentUser} = useAuth()
  const vendor = currentUser?.decoded?.vendorName || '' // Provide a default value if vendorName is undefined

  const [bestItem, setBestItem] = useState(null)
  const [lowItem, setLowItem] = useState(null)

  useEffect(() => {
    const fetchBestAndLowItems = async () => {
      try {
        const bestResponse = await axios.get(`${API_URL}/costa-inventory/best-selling`)
        const lowResponse = await axios.get(`${API_URL}/costa-inventory/low-selling`)
        setBestItem(bestResponse.data.bestSellingStyle)
        setLowItem(lowResponse.data.lowestSellingStyle)
      } catch (error) {
        console.error('Error fetching inventory data:', error)
      }
    }

    if (vendor) {
      fetchBestAndLowItems()
    }
  }, [vendor])

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-6 col-lg-6'>
          <div className='card'></div>
        </div>
        <div className='col-md-3 col-lg-3'>
          {bestItem ? (
            <BestPerformingItem className=' h-lg-100' vendor={vendor} bestItem={bestItem} />
          ) : (
            <div>No best selling item data available</div>
          )}
        </div>
        <div className='col-md-3 col-lg-3'>
          {lowItem ? (
            <LowPerformingItem className=' h-lg-100' vendor={vendor} lowItem={lowItem} />
          ) : (
            <div>No low selling item data available</div>
          )}
        </div>

        <div className='col-md-12 col-lg-12'>
          <CostaInventoryTable />
        </div>
      </div>
    </>
  )
}
