import React from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_designereyes/layout/core';



interface ChangelogEntry {
    date: string;
    version: string;
    title: string;
    description: string[];
}
const changelogData: ChangelogEntry[] = require('./changelog-archives/changelog.json');


const ChangelogPage: React.FC = () => {
    return (
        <div className='row gy-5 g-xl-8'>
            <div className='card card-xl-stretch mb-xl-8'>
                {/* begin::Header */}
                <div className='card-header align-items-center border-0 mt-4'>
                    <h2 className='card-title align-items-start flex-column'>
                        <span className='fw-bold text-dark'>Designer Eyes Vendor Portal Changelog</span>
                    </h2>
                </div>
                {/* begin::Body */}
                <div className='card-body pt-3'>
                    {changelogData.reverse().map((entry, index) => (
                        <div key={index} className="changelog-entry">
                            <h5 className="text-dark">{entry.title} - <span className="badge badge-info">v{entry.version}</span></h5>
                            <small className="text-muted pb-5">{entry.date}</small>
                            <ul className="pt-5">
                                {entry.description.map((descPoint, descIndex) => (
                                    <li className="p-1"key={descIndex}>{descPoint}</li>
                                ))}
                            </ul>
                            <hr style={{ width: "90%", borderTop: "1px solid #eee", margin: "15px 15px" }} />
                        </div>
                    ))}

                </div>
                {/* end::Body */}
            </div>
        </div>
    );
};

const ChangelogWrapper: React.FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CHANGELOG' })}</PageTitle>
            <ChangelogPage />
        </>
    );
};

export { ChangelogWrapper };
