/* eslint-disable jsx-a11y/anchor-is-valid */
import { Chart, BarController, CategoryScale, LinearScale } from 'chart.js';
import React, { FC, useEffect, useRef } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface Props {
  className: string;
  data: number[];
  title: string;
  vendor: string;
}

export const StoreInventoryBar1: FC<Props> = ({ className, data, title, vendor}) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const delayed = useRef(false);

  const brandColorMapping: Record<string, string> = {
    'CELINE': 'rgba(255, 99, 132, 1)',
    'DIOR': 'rgba(54, 162, 235, 1)',
    'DIOR HOMME': 'rgba(75, 192, 192, 1)',
    'FENDI': 'rgba(255, 206, 86, 1)',
    'FRED': 'rgba(153, 102, 255, 1)',
    'GIVENCHY': 'rgba(255, 159, 64, 1)',
    'LOEWE': 'rgba(155, 225, 132, 1)',

    'RAY BAN': 'rgba(255, 99, 132, 1)',
    'PRADA': 'rgba(54, 162, 235, 1)',
    'VERSACE': 'rgba(75, 192, 192, 1)',
    'OAKLEY': 'rgba(255, 206, 86, 1)',
    'DOLCE & GABBANA': 'rgba(153, 102, 255, 1)',
    'BURBERRY': 'rgba(255, 159, 64, 1)',
    'MICHAEL KORS': 'rgba(155, 225, 132, 1)',
    'COACH': 'rgba(255, 99, 132, 1)',
    'TIFFANY': 'rgba(54, 162, 235, 1)',
    'COSTA DEL MAR': 'rgba(75, 192, 192, 1)',
    'TORY BURCH': 'rgba(255, 206, 86, 1)',
    'CHANEL': 'rgba(153, 102, 255, 1)',
  };

  const brandLabels = {
    Thelios: ['Dior', 'Loewe', 'Celine', 'Fendi'],
    Luxottica: ['Ray Ban', 'Prada', 'Versace', 'Oakley', 'Dolce & Gabbana', 'Burberry', 'Michael Kors', 'Coach', 'Tiffany', 'Costa Del Mar', 'Tory Burch', 'Chanel'],
  };

  const labels = brandLabels[vendor] || [];
  const backgroundColors = labels.map(brand => brandColorMapping[brand.toUpperCase()] || 'rgba(0, 0, 0, 1)');

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      chartInstance.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            label: 'Inventory per brand',
            data: data,
            backgroundColor: backgroundColors,
            borderColor: backgroundColors,
            borderWidth: 0.2,
            barPercentage: 0.5, 
            categoryPercentage: 1
          }]
        },
        options: {
          indexAxis: 'y',
          animation: {
            onComplete: () => {
              delayed.current = true;
            },
            delay: (context) => {
              let delay = 0;
              if (context.type === 'data' && context.mode === 'default' && !delayed.current) {
                delay = context.dataIndex * 50 + context.datasetIndex * 150;
              }
              return delay;
            },
          },
          scales: {
            x: {
              ticks: {
                color: 'white'
              },
              grid: {
                color: 'rgba(255, 255, 255, 0.15)'
              },
            },
            y: {
              ticks: {
                color: 'white'
              },
              grid: {
                color: 'rgba(255, 255, 255, 0.15)'
              },
              beginAtZero: true
            },
          },
          plugins: {
            datalabels: {
              color: 'white',
              anchor: 'end',
              align: 'right',
              formatter: (value, context) => {
                return value;
              },
            },
            legend: {
              labels: {
                color: 'white'
              }
            },
            tooltip: {
              mode: 'index',
              axis: 'y',
              intersect: false,
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              titleFont: {
                size: 12,
              },
              bodyFont: {
                size: 12,
              },
              padding: {
                top: 10,
                right: 15,
                bottom: 10,
                left: 15
              }
            }
          },
        },
        plugins: [ChartDataLabels]
      });
    }
  }, [data]);

  return (
    <div className={`card card-flush ${className}`}  style={{minHeight: '42rem', maxHeight: '42rem'}}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>Inventory by Brand</span>
          </div>
          <span className='text-gray-800 pt-1 fw-semibold fs-20'>as of 12/31/2023</span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <canvas ref={chartRef} style={{ width: '100%', maxHeight: '400px', minHeight: '100px' }} />
      </div>
    </div>
  )
}

