/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {useAuth} from '../../../../app/modules/auth'
import { API_URL } from '../../../../app/modules/auth/core/_requests'


type Props = {
  className: string
}

interface User {
  id: number
  email: string
  role: string
  vendorName: string | null
  customerID: string | null
  name: string
}

  const AdminUserPull: React.FC<Props> = ({className}) => {
  const {auth, currentUser, logout} = useAuth()
  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    if (auth?.api_token) {
      axios
        .get(`${API_URL}/users/all_users`, {
          headers: {
            Authorization: `Bearer ${auth.api_token}`,
          },
        })
        .then((response) => {
          setUsers(response.data)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    }
  }, [auth])

  console.log(users)
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>
          Dashboard Accounts
          <span className='badge badge-warning fw-bolder fs-8 px-2 py-1 ms-2'>
            {currentUser?.decoded?.role} only
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            <thead>
              <tr className='border-0'>
                <th className='p-0 w-50px'>ID</th>
                <th className='p-0 min-w-150px'>Vendor Name</th>
                <th className='p-0 min-w-110px'>Role</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>{user.id}</td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {user.vendorName || 'N/A'}
                    <span className='text-muted fw-semibold d-block'>{user.email}</span>
                  </td>
                  <td className=''>
                    <span
                      className={`badge fw-bolder fs-8 px-2 py-1 ms-2 ${
                        user.role === 'vendor'
                          ? 'badge-light-success'
                          : user.role === 'admin'
                          ? 'badge-warning'
                          : ''
                      }`}
                    >
                      {user.role}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AdminUserPull}
