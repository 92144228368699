import React, { FC, CSSProperties, useEffect, useState } from 'react';

type Props = {
  className: string;
  vendor: string;
  selectedStore: string;
  inventoryTableItems: InventoryTableItems[];
};

type InventoryTableItems = {
  URL: string;
  UPC: string;
  "Brand Name": string;
  Description: string;
  Store_Inventory: number;
  SalesQuantity: number;
  status: string;
};

const brandLabels = {
  Thelios: ['CELINE', 'DIOR MAN', 'DIOR WOMAN', 'FENDI', 'LOEWE'],
  Luxottica: ['Ray Ban', 'Prada', 'Versace', 'Oakley', 'Dolce & Gabbana', 'Burberry', 'Michael Kors', 'Coach', 'Tiffany', 'Costa Del Mar', 'Tory Burch', 'Chanel'],
};

const headerStyles: CSSProperties = {
  backgroundColor: '',
  textAlign: 'left',
  borderBottom: '2px solid #ddd',
  fontSize: '16px'
};

const headerStyles2: CSSProperties = {
  backgroundColor: '',
  textAlign: 'center',
  borderBottom: '2px solid #ddd',
  fontSize: '16px'
};

const headerStyles3: CSSProperties = {
  backgroundColor: '',
  textAlign: 'center',
  borderBottom: '2px solid #ddd',
  fontSize: '16px'
};

const imageCellStyles: CSSProperties = {
  textAlign: 'center',
};

const cellStyles: CSSProperties = {
  textAlign: 'left',
  fontSize: '1.15rem',
  fontWeight: '400',
};

const cellStyles2: CSSProperties = {
  textAlign: 'center',
  fontSize: '1.25rem',
  fontWeight: '500'
};

const cellStylesSales: CSSProperties = {
  textAlign: 'center',
  paddingLeft: '2.5em',
  fontSize: '1.5rem',
};

const imageStyles: CSSProperties = {
  width: '60%',
  height: 'auto',
  borderRadius: '1000em',
};

const rowStyles: CSSProperties = {
  backgroundColor: '',
};

const oddRowStyles: CSSProperties = {
  backgroundColor: 'rgba(255, 255, 255, 0.025)',
};

const selectWrapperStyle: React.CSSProperties = {
  position: 'relative',
  display: 'inline-block',
};

const InventoryTable: FC<{ filteredData: InventoryTableItems[], handleSort: (column: string) => void, sortColumn: string, sortOrder: string }> = ({ filteredData, handleSort, sortColumn, sortOrder }) => {
  const itemsPerPage = 10;


  const remainder = filteredData.length % itemsPerPage;
  const emptyRowsCount = remainder > 0 ? itemsPerPage - remainder : 0;

  const lastItemIndex = filteredData.length - 1;
  const startStyleDark = lastItemIndex % 2 === 0;

  const getImageUrl = (url) => {
    if (url == null) return '';
    if (!url || !(url.startsWith('http://') || url.startsWith('https://'))) {
      return `https://${url}`;
    }
    return url;
  };
  

  return (
    <table className='table table-striped table-dark table-hover table-bordered'>
      <thead>
        <tr>
          {/* Update the onClick handlers to use your sorting function, and conditionally render the sort indicators */}
          <th style={{ ...headerStyles, width: '50px'}}>Images</th>
          <th style={{ ...headerStyles, width: '100px', cursor: 'pointer' }} onClick={() => handleSort('UPC')}>
            UPC{sortColumn === 'UPC' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th style={{ ...headerStyles, width: '75px', cursor: 'pointer' }} onClick={() => handleSort('Brand Name')}>
            Brand{sortColumn === 'Brand Name' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th style={{ ...headerStyles, width: '200px', cursor: 'pointer' }} onClick={() => handleSort('Description')}>
            Description{sortColumn === 'Description' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th style={{ ...headerStyles2, width: '50px', cursor: 'pointer' }} onClick={() => handleSort('Store_Inventory')}>
            QTY{sortColumn === 'Store_Inventory' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th style={{ ...headerStyles2, width: '50px', cursor: 'pointer' }} onClick={() => handleSort('SalesQuantity')}>
            60-Day Sales{sortColumn === 'SalesQuantity' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th style={{ ...headerStyles3, width: '50px', cursor: 'pointer' }}>
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item, index) => {
          return (
            <tr key={index} style={index % 2 === 0 ? rowStyles : oddRowStyles}>
              <td style={{ ...imageCellStyles, width: '50px', maxHeight: '50px'}}>
                <img src={getImageUrl(item.URL)} alt='' style={imageStyles} />
              </td>
              <td style={{ ...cellStyles, width: '100px' }}>{item.UPC}</td>
              <td style={{ ...cellStyles, width: '75px' }}>{item['Brand Name']}</td>
              <td style={{ ...cellStyles, width: '200px' }}>{item.Description}</td>
              <td style={{ ...cellStyles2, width: '50px' }}>{item.Store_Inventory}</td>
              <td style={{ ...cellStyles2, width: '50px' }}>
                {item.SalesQuantity}
              </td>
              <td style={{ ...cellStyles2}}>
                {item.status}
              </td>
            </tr>
          );
        })}

        {/* Add empty rows if needed */}
        {emptyRowsCount > 0 && Array.from({ length: emptyRowsCount }, (_, index) => {
          const isDark = startStyleDark ? (index % 2 === 1) : (index % 2 === 0);
          return (
            <tr key={`empty-row-${index}`} style={isDark ? rowStyles : oddRowStyles}>
              <td style={cellStyles}>&nbsp;</td>
              <td style={cellStyles}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
              <td style={cellStylesSales}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const StoreInventoryTable: FC<Props> = ({ className, vendor, selectedStore, inventoryTableItems }) => {
  const [inventoryData, setInventoryData] = useState<InventoryTableItems[]>([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setInventoryData(inventoryTableItems);
        console.log('Chart Data:', inventoryTableItems);
      } catch (error) {
        console.error('Error fetching inventory data:', error);
      }
    };

    if (selectedStore) {
      fetchData();
    }
  }, [inventoryTableItems, selectedStore]);

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortColumn(column);
    console.log(`Sorting by ${column}, Direction: ${isAsc ? 'desc' : 'asc'}`);
  };

  const applySort = (data) => {
    if (!sortColumn) return data;
    console.log(`Applying sort on ${sortColumn} in ${sortDirection} order`);
    return [...data].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const vendorBrands = brandLabels[vendor] || [];

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(1);
  };

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = (startIndex + itemsPerPage);

  const filteredData = inventoryData.filter((item) => {
    return (selectedBrand === '' || item['Brand Name'] === selectedBrand) &&
      (selectedStatus === '' || item.status === selectedStatus);
  });

  const sortedData = applySort(filteredData);
  const paginatedData = sortedData.slice(startIndex, endIndex);
  const maxPage = Math.ceil(inventoryData.length / itemsPerPage);

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / itemsPerPage) * itemsPerPage;
    return new Array(itemsPerPage).fill(null).map((_, idx) => start + idx + 1).filter(page => page <= maxPage);
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div style={{ overflowX: 'auto' }} className={`card p-7 card-flush ${className}`}>
      <div className='d-flex mt-5 justify-content-between'>
        <div className='d-flex align-items-left' style={{ flexDirection: 'column' }}>
          <span className='fw-bold text-dark me-2 lh-1 ls-n2' style={{ fontSize: '2.5rem' }}>Inventory</span>
        </div>
        <div className='d-flex fw-bold text-dark me-2 lh-1 ls-n2' style={{ fontSize: '2.5rem' }}>
          {selectedStore}
        </div>
        <div className='d-flex'>
          <label htmlFor="brand-select" style={{ marginLeft: '10rem', fontSize: '1.5rem' }}>Brand:</label>
          <div style={selectWrapperStyle}>
            <select className='form-select form-select-sm' id="brand-select" onChange={handleBrandChange} value={selectedBrand} style={{ background: 'white', color: 'black', marginLeft: '1rem', width: 'auto' }}>
              <option value="">All Brands</option>

              {vendorBrands.map((brand) => (
                <option key={brand} value={brand}>{brand}</option>
              ))}
            </select>
          </div>

          <label htmlFor="status-select" style={{ marginLeft: '4rem', fontSize: '1.5rem' }}>Status:</label>
          <div style={selectWrapperStyle}>
            <select className='form-select form-select-sm' id="status-select" onChange={handleStatusChange} value={selectedStatus} style={{ background: 'white', color: 'black', marginLeft: '1rem', width: 'auto' }}>
              <option value="">All Statuses</option>
              <option value="In Stock">In Stock</option>
              <option value="Low Stock">Low Stock</option>
              <option value="Out of Stock">Out of Stock</option>
            </select>
          </div>
        </div>
      </div>
      <div className='card-body p-5 d-flex flex-wrap align-items-center'>

        <InventoryTable
          filteredData={paginatedData}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortOrder={sortDirection}
        />

        <div className="pagination">
          <button
            onClick={() => changePage(currentPage - 1)}
            disabled={currentPage === 1}
            className="page-item"
          >
            &lt;
          </button>

          {getPaginationGroup().map(number => (
            <button
              key={number}
              onClick={() => changePage(number)}
              disabled={number === currentPage}
              className={`page-item ${number === currentPage ? "active" : ""}`}
            >
              {number}
            </button>
          ))}

          <button
            onClick={() => changePage(currentPage + 1)} disabled={currentPage === maxPage} className="page-item">
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export { StoreInventoryTable };
