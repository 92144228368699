import { useAuth } from '../../../../../app/modules/auth'
import { CustomerOfferTable, WeekCalendar } from '../../../../../_designereyes/partials/widgets/'
import { WeekProvider } from '../../../../../_designereyes/partials/widgets/_new/cards/vendors/purchase-order-offer/WeekContext'

const CustomerViewPage = () => {
  return (
    <WeekProvider>
      <div>
        <WeekCalendar viewMode="customer" table=""/>
        <CustomerOfferTable />
      </div>
    </WeekProvider>
  )
}

export const CustomerViewWrapper = () => {
  const { currentUser } = useAuth()

  return (
    <div className="d-flex flex-column">
      <div className="mb-10 fs-1">
        <CustomerViewPage />
      </div>
    </div>
  )
}