import React from 'react';

type SalesByDoorData = {
  locationCode: string;
  brandName: string;
  quantity: number;
};

type StoreTableProps = {
  storeName: string;
  storeData: SalesByDoorData[];
  completeBrandList: string[]; 
};


const tableStyles: React.CSSProperties = {
  color: 'white',
  borderCollapse: 'collapse',
  width: '100%', 
};

const cardTitleStyle: React.CSSProperties = {
  color: 'white', 
  padding: '16px', 
  textAlign: 'center', 
  borderRadius: '4px 4px 0 0', 
};

const headerStyles: React.CSSProperties = {
  padding: '10px 0', 
  textAlign: 'center', 
  borderBottom: '2px solid #ddd', 
  fontSize: '1.25rem',
};

const rowStyles: React.CSSProperties = {
  padding: '10px 0', 
  textAlign: 'center', 
};

const oddRowStyles: React.CSSProperties = {
  backgroundColor: 'rgba(255, 255, 255, 0.025)', 
  padding: '10px 0', 
  textAlign: 'center', 
}

const locationToNameMap = {
    'ALL Stores': 'All Stores',
    'POV STORE': 'R001 - POV Sawgrass',
    'JOL STORE': 'R002 - Just One Look',
    'EOL STORE': 'R003 - Eyes on Lincoln',
    'POV2 STORE': 'R004 - PV2 Dolphin',
    'DE1 STORE': 'R005 - DE Mall of San Juan',
    'BCC': 'R006 - DE Brickell',
    'DE2 STORE': 'R008 - DE Florida Mall',
    'R009': 'R009 - Aventura',
    'POV3 STORE': 'R010 - PV3 iDrive',
    'R011': 'R011 - PV4 Vineland',
    'R014': 'R014 - DE Plaza Las Americas',
    };

export const SalesByDoorTable: React.FC<StoreTableProps> = ({ storeData, storeName, completeBrandList }) => {
  
  const tableData = completeBrandList.map(brandName => {
    const brandData = storeData.find(data => data.brandName === brandName);
    return {
      brandName,
      quantity: brandData ? brandData.quantity : 0, 
    };
  });
    
  const fullStoreName = locationToNameMap[storeName] || storeName; 

  return (
    <div className="card card-flush" style={{ color: 'white', minHeight: '25rem' }}> {/* Card background */}
      <div className='card-header' style={{...cardTitleStyle, justifyContent: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h3 style={{ margin: 0, fontSize: '1.35rem', fontWeight: 'revert' }}>{fullStoreName}</h3> {/* Remove margin to center the title */}
        </div>
      </div>
      
      <div className='card-body'>
        <table className="table" style={tableStyles}>
          <thead>
            <tr style={headerStyles}>
              <th>Brand Name</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={index} style={index % 2 === 1 ? oddRowStyles : rowStyles}>
                <td>{item.brandName}</td>
                <td>{item.quantity.toLocaleString()}</td>
              </tr>
            ))}
            <tr style={{...rowStyles, borderTop: '1px solid white' }}>
              <td><b>Total</b></td>
              <td><b>{storeData.reduce((acc, curr) => acc + curr.quantity, 0).toLocaleString()}</b></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};