import React, { FC, useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { API_URL } from '../../../../../../../app/modules/auth/core/_requests'

type Props = {
  className: string;
  vendor: string;
  dateRange: {
    start: Moment;
    end: Moment;
  };
  rangeDisplay?: string;
};

type SalesData = {
  brand: string;
  date: string;
  quantity: number;
};

const cardStyle: React.CSSProperties = {
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '8px',
  border: '0px solid white',
};

const BrandSalesLine: FC<Props> = ({ className, vendor, dateRange, rangeDisplay }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const [salesData, setSalesData] = useState<SalesData[]>([]);
  const [aggregation, setAggregation] = useState('daily');
  const [activeInDataBrands, setActiveInDataBrands] = useState({});

  const buttonGroupStyle = {
    boxShadow: '0 2px 1px 2px rgba(0,0,0,0.3)',
    borderRadius: '5rem',
    background: '#1A233F',
  };

  const smallButtonStyle: React.CSSProperties = {
    padding: '0.25rem 0.5rem',
    fontSize: '1rem',
    lineHeight: '1.5',
  };

  const brandColorMapping: Record<string, string> = {
    'CELINE': 'rgba(255, 99, 132, 1)',
    'DIOR': 'rgba(54, 162, 235, 1)',
    'DIOR HOMME': 'rgba(75, 192, 192, 1)',
    'FENDI': 'rgba(255, 206, 86, 1)',
    'FRED': 'rgba(153, 102, 255, 1)',
    'GIVENCHY': 'rgba(255, 159, 64, 1)',
    'LOEWE': 'rgba(155, 225, 132, 1)',
  };

  const generateSwitchStyles = () => {
    const style = document.createElement('style');
    style.type = 'text/css';

    const rules = Object.entries(brandColorMapping).map(([brand, color]) => {
      const className = `switch-${brand.toLowerCase().replace(/\s+/g, '-')}`;
      return `
        .${className} .form-check-input:checked + .form-check-label {
          border-color: ${color} !important;
        }
        .${className} .form-check-input:checked {
          background-color: ${color} !important;
          border-color: ${color} !important;
        }
      `;
    }).join("\n");

    style.textContent = rules;

    document.head.appendChild(style);
  };

  useEffect(() => {
    generateSwitchStyles();
  }, []);

  type BrandAggregateData = {
    [key: string]: number;
  };

  type AggregatedSalesData = {
    [brand: string]: BrandAggregateData;
  };

  // Adjust the aggregateData function's return type
  const aggregateData = (data: SalesData[], frequency: string): AggregatedSalesData => {
    const aggregatedData: AggregatedSalesData = {};
    data.forEach(item => {
      const momentDate = moment(item.date, 'YYYY-MM-DD');
      let key;
      switch (frequency) {
        case 'weekly':
          key = momentDate.startOf('week').format('YYYY-MM-DD');
          break;
        case 'monthly':
          key = momentDate.startOf('month').format('YYYY-MM-DD');
          break;
        case 'yearly':
          key = momentDate.startOf('year').format('YYYY-MM-DD');
          break;
        default:
          key = item.date;
      }
      if (!aggregatedData[item.brand]) {
        aggregatedData[item.brand] = {};
      }
      if (!aggregatedData[item.brand][key]) {
        aggregatedData[item.brand][key] = 0;
      }
      aggregatedData[item.brand][key] += item.quantity;
    });
    return aggregatedData;
  };

  const fetchSalesData = async () => {
    try {
      const startDate = moment(dateRange.start).format('YYYY-MM-DD');
      const endDate = moment(dateRange.end).format('YYYY-MM-DD');
      const response = await axios.get(`${API_URL}/data/query-brand-range-sales?startDate=${startDate}&endDate=${endDate}`);
      setSalesData(response.data.salesData);
    } catch (error) {
      console.error('Error fetching sales data:', error);
    }
  };

  useEffect(() => {
    const startMoment = moment(dateRange.start);
    const endMoment = moment(dateRange.end);
    const rangeInDays = endMoment.diff(startMoment, 'days');
    console.log(`Range in days: ${rangeInDays}`);

    let newAggregation = 'daily';

    if (rangeInDays > 1300) {
      newAggregation = 'monthly';
    } else if (rangeInDays > 31) {
      newAggregation = 'weekly';
    }

    if (newAggregation !== aggregation) {
      console.log(`Updating aggregation to: ${newAggregation}`);
      setAggregation(newAggregation);
    }

    fetchSalesData();
  }, [dateRange, vendor]);

  useEffect(() => {
    const brandsInData = salesData.reduce((acc, { brand }) => {
      acc[brand] = true;
      return acc;
    }, {});

    setActiveInDataBrands(brandsInData);
  }, [salesData]);

  const [activeBrands, setActiveBrands] = useState(() => {
    const initialBrands = {};
    Object.keys(brandColorMapping).forEach((brand) => {
      initialBrands[brand] = true;
    });
    return initialBrands;
  });

  useEffect(() => {
    if (chartRef.current && salesData.length) {
      const aggregatedSalesData = aggregateData(salesData, aggregation);

      const allDates = Object.values(aggregatedSalesData).flatMap(brandData => Object.keys(brandData));
      const uniqueDates = Array.from(new Set(allDates)).sort();

      const chartLabels = uniqueDates.map(date => {
        if (aggregation === 'weekly') {
          return `Week of ${date}`;
        } else if (aggregation === 'monthly') {
          return `${moment(date).format('YYYY-MM')}`;
        } else if (aggregation === 'yearly') {
          return `${moment(date).format('YYYY')}`;
        }

        return date;
      });

      const datasets = Object.entries(aggregatedSalesData).map(([brand, brandData]) => {
        const brandColor = brandColorMapping[brand] || 'rgba(0, 0, 0, 1)';
        const dataPoints = uniqueDates.map(date => brandData[date] || 0);
        const isActive = typeof activeBrands[brand] === 'undefined' ? true : activeBrands[brand]; // Defaults to true if not set

        return {
          label: brand,
          data: dataPoints,
          borderColor: brandColor,
          backgroundColor: brandColor,
          pointBackgroundColor: brandColor,
          pointBorderColor: brandColor,
          fill: false,
          hidden: !isActive,
        };
      });

      if (chartInstance.current) {
        chartInstance.current.data.labels = chartLabels;
        chartInstance.current.data.datasets = datasets;
        chartInstance.current.update();
      } else {
        chartInstance.current = new Chart(chartRef.current, {
          type: 'line',
          data: {
            labels: chartLabels,
            datasets: datasets,
          },
          options: {
            scales: {
              x: {
                type: 'category',
                title: {
                  display: true,
                  text: 'Date',
                  color: 'white',
                },
                ticks: {
                  color: 'white',
                },
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)',
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'Quantity',
                  color: 'white',
                },
                ticks: {
                  color: 'white',
                },
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)',
                },
                beginAtZero: true,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  color: 'white',
                },
                display: false,
              },
              tooltip: {
                mode: 'index',
                intersect: false,
              },
            },
          },
        });
      }
    }
  }, [salesData, aggregation, activeBrands]);


  const toggleBrand = (brand) => {
    setActiveBrands(prev => ({
      ...prev,
      [brand]: !prev[brand],
    }));
  };


  return (
    <div className={`card card-flush ${className}`} style={cardStyle}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>Brands' Sales Over Time</span>
          </div>
          <span className='pt-1 fw-semibold fs-6' style={{ color: 'rgba(255, 255, 255, 0.7)' }}>{rangeDisplay}</span>
        </div>
        <div>
          <div className="btn-group mb-5" role="group" aria-label="Aggregation selector" style={buttonGroupStyle}>
            {['Daily', 'Weekly', 'Monthly', 'Yearly'].map((agg) => (
              <button
                key={agg}
                type="button"
                className={`btn ${aggregation === agg.toLowerCase() ? 'btn-primary' : 'btn-outline-primary'}`}
                style={smallButtonStyle}
                onClick={() => setAggregation(agg.toLowerCase())}
              >
                {agg}
              </button>
            ))}
          </div>
        </div>
      </div>


      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <div className={`form-check form-switch ${className}`} style={{ marginRight: '10px' }}>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`flexSwitchCheckAll`}
            style={{ marginRight: '10px' }}
            checked={Object.values(activeBrands).every(Boolean)}
            onChange={() => setActiveBrands(prev => {
              const allActive = Object.values(prev).every(Boolean);
              return Object.keys(prev).reduce((acc, key) => {
                acc[key] = !allActive;
                return acc;
              }, {});
            })}
          />
          <label
            className='form-check-label'
            htmlFor="flexSwitchCheckAll"
            style={{ color: 'white' }}
          >
            SELECT ALL
          </label>
        </div>

        {Object.keys(brandColorMapping)
          .filter(brand => activeInDataBrands[brand])
          .map((brand) => {
            const className = `switch-${brand.toLowerCase().replace(/\s+/g, '-')}`;
            return (
              <div className={`form-check form-switch ${className}`} key={brand} style={{ marginRight: '10px' }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id={`flexSwitchCheck${brand}`}
                  checked={typeof activeBrands[brand] === 'undefined' ? true : activeBrands[brand]}
                  onChange={() => toggleBrand(brand)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`flexSwitchCheck${brand}`}
                  style={{ color: 'white' }}
                >
                  {brand}
                </label>
              </div>
            );
          })}
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <canvas ref={chartRef} style={{ width: '100%', maxHeight: '30rem' }} />
      </div>
    </div>
  );
};

export { BrandSalesLine };
