import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {ProgressBar} from 'react-bootstrap'

interface UploadedFile {
  name: string
  progress: number
}

const UploadImage: React.FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const formData = new FormData()
      formData.append('file', file)

      fetch('YOUR_BACKEND_ENDPOINT', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {})
        .catch((error) => {})
    })
  }, [])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} className='dropzone'>
      <input {...getInputProps()} />
      <p>Drag 'n' drop some files here, or click to select files</p>
      <div>
        {uploadedFiles.map((file) => (
          <div key={file.name}>
            <span>{file.name}</span>
            <ProgressBar now={file.progress} />
          </div>
        ))}
      </div>
    </div>
  )
}

export {UploadImage}
