/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_designereyes/helpers'
import { PageTitle } from '../../../_designereyes/layout/core'
import { AdminUserPull, AdminAnalyticsWidget1, BestSellingBrandsOverTime, SalesByDoor, SalesByDoorTable } from '../../../_designereyes/partials/widgets'
import { useAuth } from '../../../app/modules/auth'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';
import moment from 'moment';
import 'daterangepicker';
import { Moment } from 'moment';
import { API_URL } from '../../../app/modules/auth/core/_requests'
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

// Define a type for your RetailPerformancePage props
type RetailPerformancePageProps = {
  dateRange: {
    start: Moment;
    end: Moment;
  };
  rangeDisplay: string;
};

type SalesByDoorData = {
  locationCode: string;
  brandName: string;
  quantity: number;
};

const ResponsiveGridLayout = WidthProvider(Responsive);

// Generate layout based on the store names
const generateLayout = (storeNames) => {
  return storeNames.map((storeName, index) => ({
    i: storeName,
    x: index % 4, // Will place the item in one of the 4 columns
    y: Math.floor(index / 4), // Will place the item in the next row every 4 items
    w: 1, // Each item takes up 1 column width
    h: 8, // Adjust the height as needed
  }));
};

const LAYOUT_LS_KEY = 'myGridLayout';

const RetailPerformancePage: FC<RetailPerformancePageProps> = ({ dateRange, rangeDisplay }) => {
  const { currentUser } = useAuth()
  const intl = useIntl();
  const [salesData, setSalesData] = useState<SalesByDoorData[]>([]);

  
  useEffect(() => {
    const fetchData = async () => {
      const startDate = dateRange.start.format('YYYY-MM-DD');
      const endDate = dateRange.end.format('YYYY-MM-DD');
      try {
        const response = await axios.get(`${API_URL}/data/query-sales-by-door?startDate=${startDate}&endDate=${endDate}`);
        // Extract the salesData array from the nested object
        setSalesData(response.data.salesData || []);
      } catch (error) {
        console.error('Error fetching sales data', error);
        setSalesData([]);
      }
    };

    fetchData();
  }, [dateRange]);

  const storeNames = Array.from(new Set(salesData.map(item => item.locationCode)));
  const layout = generateLayout(storeNames);
  // Derive completeBrandList from salesData
  const completeBrandList = Array.from(new Set(salesData.map(item => item.brandName)));

  //7 brands = 404
  //5 brands = 328
  const baseHeight = 140; 
  const heightPerBrand = 40.5; 

  const getDynamicHeight = (brandCount: number) => {
    const newHeight = (baseHeight + (brandCount * heightPerBrand)) / 10;
    return newHeight;
  };

  // Calculate the row height dynamically based on the complete brand list
  const dynamicRowHeight = getDynamicHeight(completeBrandList.length);
  
  return (
    <>
      {/* begin Luxottica Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Luxottica' && (<>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-5'>
          <div className='col-md-12 col-lg-12'>
            <BestSellingBrandsOverTime className='h-md-50 mb-5 mb-xl-10 h-lg-100' vendor={currentUser?.decoded?.vendorName} />
          </div>
        </div>
      </>
      )}
      {/* end Luxottica Vendor::Row */}


      {/* begin Thelios Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Thelios' && (<>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='col-md-12 col-lg-12'>
            <SalesByDoor
              className='h-md-100 mb-5 mb-xl-10 h-lg-100'
              vendor={currentUser?.decoded?.vendorName}
              dateRange={dateRange}
              rangeDisplay={rangeDisplay}
              salesData={salesData}
            />
          </div>
        </div>
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: layout }}
          breakpoints={{ s: 600, lg: 850 }}
          cols={{ s: 2, lg: 4 }}
          rowHeight={dynamicRowHeight}
          margin={[10, 14]}
          width={1200}
          isDraggable={true}
          isResizable={false}
        >
          {storeNames.map(storeName => (
            <div key={storeName} data-grid={layout.find(l => l.i === storeName)}>
              <SalesByDoorTable
                storeName={storeName}
                storeData={salesData.filter(item => item.locationCode === storeName)}
                completeBrandList={completeBrandList}
              />
            </div>
          ))}
        </ResponsiveGridLayout>
      </>
      )}
      {/* end Thelios Vendor::Row */}


     
    </>
  )
}

export const RetailPerformanceWrapper: FC = () => {
  const intl = useIntl();
  const [dateRange, setDateRange] = useState({ start: moment().subtract(29, 'days'), end: moment() });
  const [rangeDisplay, setRangeDisplay] = useState<string>('Last 30 Days'); 
  const { currentUser } = useAuth()

  useEffect(() => {
    $(function () {
      $('input[name="daterange"]').daterangepicker({
        opens: 'left',
        startDate: dateRange.start,
        endDate: dateRange.end,
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'Last 365 Days': [moment().subtract(364, 'days'), moment()],
          'Current Year': [moment().startOf('year'), moment().endOf('year')],
          'Current Month': [moment().startOf('month'), moment().endOf('month')],
          'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
          'Previous Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
          'All Time': [moment('2015-01-01'), moment()],
        }
      }, function (start, end, label) {
        console.log("Date range selected:", start, end);
        setDateRange({ start, end });

        // Correctly update 'rangeDisplay' in the daterangepicker callback
        if (label === 'Custom Range') {
          setRangeDisplay(`${start.format('MM/DD/YYYY')} - ${end.format('MM/DD/YYYY')}`);
        } else {
          setRangeDisplay(label);
        }
      });
    });
  }, []);

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>

      {currentUser?.decoded?.vendorName === 'Thelios' && (
        <input type="text" name="daterange" style={{ position: 'sticky', zIndex: '101', marginBottom: '2rem', top: '2.5%', minWidth: '15rem' }} />
      )}

      <RetailPerformancePage
        dateRange={dateRange}
        rangeDisplay={rangeDisplay}
      />
    </>
  )
}