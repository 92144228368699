import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../_designereyes/helpers';
import { PageTitle } from '../../../_designereyes/layout/core';
import {Documents} from '../../modules/profile/components/Documents'
import {useAuth} from '../../../app/modules/auth'


const DocumentsPage: React.FC = () => {
  
  // Define the state for uploadedFiles
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  console.log("Current Uploaded Files in DocumentsWrapper:", uploadedFiles);

  return (
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-12'>
        {/* Pass down the setUploadedFiles method to UploadCatalog */}
        <Documents />
      </div>
    </div>
  );
};

const DocumentsWrapper: React.FC = () => {
  const {currentUser} = useAuth()
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DOCUMENTS' })}</PageTitle>
      {(currentUser?.decoded?.role === 'Admin' || 'David') && (
      <DocumentsPage />)}
    </>
  );
};

export { DocumentsWrapper };
