import React, { createContext, useContext, useState } from 'react';

interface DistributorAccountContextType {
  selectedAccountID: string;
  handleSelectAccount: (accountID: string) => void;
}

const defaultValue: DistributorAccountContextType = {
  selectedAccountID: '',
  handleSelectAccount: () => {}, // Provide a noop function as the initial value
};

const DistributorAccountContext = createContext<DistributorAccountContextType>(defaultValue);

export const useAccount = () => useContext(DistributorAccountContext);

export const DistributorAccountProvider = ({ children }) => {
  const [selectedAccountID, setSelectedAccountID] = useState('');

  const handleSelectAccount = (accountID) => {
    setSelectedAccountID(accountID);
  };

  return (
    <DistributorAccountContext.Provider value={{ selectedAccountID, handleSelectAccount }}>
      {children}
    </DistributorAccountContext.Provider>
  );
};
