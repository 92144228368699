import React, {FC, useState, useEffect, useMemo} from 'react'
import axios from 'axios'
import {API_URL} from '../../../../../../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../../../../../../app/modules/auth'

type Props = {
  className: string
}

type InventoryItem = {
  imageUrl: string
  asin: string
  title: string
  brandName: string
  categoryId: any
  rank: string
  isVariation: string
  amazonIsr: number
  numberOfSellers: string
  outOfStockNow: string
  monthlyUnitsSold: string
  buyBoxPrice: string
  monthlyRevenueEstimate: string
  listedSince: string
  reviewCount: string
  reviewRating: string
  numberFbaSellers: string
  buyBoxEquity: string
  productPageScore: string
  MSRP: number
  WholesalePrice: number
  dollarVarianceMSRPvsBBP: number
  percentVarianceMSRPvsBBP: number
  dollarMargin: number
  percentMargin: number
}

const paginationButtons: React.CSSProperties = {
  padding: '4px 12px',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
  backgroundColor: '#0C6099',
  border: '1px solid #2B2B40',
  borderRadius: '5px',
  cursor: 'pointer',
  outline: 'none',
  boxShadow: '0 1px 4px rgba(255, 255, 255, 0.3)',
  transition: 'background-color 0.3s',
  marginBottom: '10px',
  marginRight: '10px',
}

const CostaSearch: FC<Props> = ({className}) => {
  const {currentUser} = useAuth()

  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([])
  const [searchedBrand, setSearchedBrand] = useState('')
  const [loading, setLoading] = useState(true)
  const formatCurrency = (amount: number, currency: string = 'USD'): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amount)
  }
  const [totalRevenue, setTotalRevenue] = useState(0)

  const fetchBrandData = async () => {
    try {
      const response = await axios.post(`${API_URL}/amazon-data/searchbrandproducts`, {
        brand: 'costa del mar',
      })
      const enrichedData = response.data.map((item) => {
        const msrp = item.MSRP || 0
        const buyBoxPrice = parseFloat(item.buyBoxPrice || '0')
        const wholesalePrice = item.WholesalePrice || 0

        // Calculate the variances and margins only if MSRP or Wholesale Price is not 0/null
        const dollarVarianceMSRPvsBBP = msrp > 0 ? msrp - buyBoxPrice : null
        const percentVarianceMSRPvsBBP = msrp > 0 ? ((msrp - buyBoxPrice) / msrp) * 100 : null
        const dollarMargin = wholesalePrice > 0 ? buyBoxPrice - wholesalePrice : null
        const percentMargin =
          wholesalePrice > 0 && buyBoxPrice > 0
            ? ((buyBoxPrice - wholesalePrice) / buyBoxPrice) * 100
            : null

        return {
          ...item,
          dollarVarianceMSRPvsBBP:
            dollarVarianceMSRPvsBBP !== null
              ? parseFloat(dollarVarianceMSRPvsBBP.toFixed(2))
              : null,
          percentVarianceMSRPvsBBP:
            percentVarianceMSRPvsBBP !== null
              ? parseFloat(percentVarianceMSRPvsBBP.toFixed(2))
              : null,
          dollarMargin: dollarMargin !== null ? parseFloat(dollarMargin.toFixed(2)) : null,
          percentMargin: percentMargin !== null ? parseFloat(percentMargin.toFixed(2)) : null,
        }
      })
      setInventoryItems(enrichedData)
      setSearchedBrand(response.data[0]?.brandName || 'Costa')
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchBrandData()
  }, [])

  const AmzProductTable: FC<{items: InventoryItem[]}> = ({items}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 99
    const [paginatedItems, setPaginatedItems] = useState<InventoryItem[]>([])

    const filteredItems = useMemo(
      () => items.filter((item) => item.categoryId === 9 || item.categoryId === 5),
      [items]
    )

    const [sortColumn, setSortColumn] = useState<string | null>(null)
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

    const handleSort = (column: string) => {
      const isAsc = sortColumn === column && sortOrder === 'asc'
      setSortOrder(isAsc ? 'desc' : 'asc')
      setSortColumn(column)
    }

    const sortedAndFilteredItems = useMemo(() => {
      if (!sortColumn) return items

      return [...items].sort((a, b) => {
        // Extract the values for the current sort column
        const valueA = a[sortColumn]
        const valueB = b[sortColumn]

        // Check if the value is null, undefined, or an empty string
        const isValueAEmpty = valueA === null || valueA === '' || valueA === undefined
        const isValueBEmpty = valueB === null || valueB === '' || valueB === undefined

        // Move null, undefined, or empty string values to the end, regardless of sort order
        if (isValueAEmpty && isValueBEmpty) return 0
        if (isValueAEmpty) return 1
        if (isValueBEmpty) return -1

        // Handle numeric and string comparisons separately
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          // Numeric comparison
          return sortOrder === 'asc' ? valueA - valueB : valueB - valueA
        } else {
          // String comparison (assumes that if not a number, then it's a string)
          const comparison = valueA.toString().localeCompare(valueB.toString())
          return sortOrder === 'asc' ? comparison : -comparison
        }
      })
    }, [items, sortColumn, sortOrder])

    useEffect(() => {
      const total = items
        .filter((item) => item.categoryId === 9 || item.categoryId === 5)
        .reduce((total, item) => total + parseFloat(item.monthlyRevenueEstimate), 0)

      // Set the state variable
      setTotalRevenue(total)
      const startIndex = (currentPage - 1) * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      setPaginatedItems(filteredItems.slice(startIndex, endIndex))
    }, [currentPage, filteredItems, items])

    const paginatedSortedFilteredItems = useMemo(() => {
      const startIndex = (currentPage - 1) * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      return sortedAndFilteredItems.slice(startIndex, endIndex)
    }, [sortedAndFilteredItems, currentPage, itemsPerPage])

    return (
      <div>
        <div style={{overflowX: 'auto'}}>
          <table
            className='table table-striped table-dark table-hover table-bordered'
            style={{minWidth: '2000px'}}
          >
            <thead>
              <tr style={{borderBottom: '2px solid #ddd'}}>
                <th style={{width: '70px', cursor: 'pointer', userSelect: 'none'}}>Image</th>
                <th
                  style={{width: '120px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('asin')}
                >
                  ASIN{sortColumn === 'asin' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '270px', cursor: 'pointer', userSelect: 'none', lineHeight: '1'}}
                  onClick={() => handleSort('title')}
                >
                  Title{sortColumn === 'title' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '70px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('rank')}
                >
                  Rank{sortColumn === 'rank' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '30px', cursor: 'pointer', userSelect: 'none', lineHeight: '1.2'}}
                  onClick={() => handleSort('amazonIsr')}
                >
                  Amazon in-stock-rate
                  {sortColumn === 'amazonIsr' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '30px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('numberOfSellers')}
                >
                  Number of Sellers
                  {sortColumn === 'numberOfSellers' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '30px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('numberFbaSellers')}
                >
                  Number of FBA Sellers
                  {sortColumn === 'numberFbaSellers' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '80px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('monthlyUnitsSold')}
                >
                  Monthly Units Sold
                  {sortColumn === 'monthlyUnitsSold' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                {/* prices end */}
                <th
                  style={{width: '80px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('buyBoxPrice')}
                >
                  Buy Box Price
                  {sortColumn === 'buyBoxPrice' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '80px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('MSRP')}
                >
                  MSRP{sortColumn === 'MSRP' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '80px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('dollarVarianceMSRPvsBBP')}
                >
                  <span style={{fontSize: '10px', color: '#0c6099'}}>(MSRP-BBP)</span>
                  <br />
                  Price Difference
                  {sortColumn === 'dollarVarianceMSRPvsBBP'
                    ? sortOrder === 'asc'
                      ? '↑'
                      : '↓'
                    : ''}
                </th>
                <th
                  style={{width: '80px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('percentVarianceMSRPvsBBP')}
                >
                  <span style={{fontSize: '10px', color: '#0c6099'}}>(MSRP-BBP)</span>
                  <br />
                  Price Difference %
                  {sortColumn === 'percentVarianceMSRPvsBBP'
                    ? sortOrder === 'asc'
                      ? '↑'
                      : '↓'
                    : ''}
                </th>
                {currentUser?.decoded?.role === 'admin' && (
                  <>
                    <th
                      style={{width: '80px', cursor: 'pointer', userSelect: 'none'}}
                      onClick={() => handleSort('WholesalePrice')}
                    >
                      Wholesale Price
                      {sortColumn === 'WholesalePrice' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    <th
                      style={{width: '80px', cursor: 'pointer', userSelect: 'none'}}
                      onClick={() => handleSort('dollarMargin')}
                    >
                      <span style={{fontSize: '10px', color: '#0c6099'}}>(BBP-Cost)</span>
                      <br />
                      Profit Margin $
                      {sortColumn === 'dollarMargin' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                    </th>
                    <th
                      style={{width: '80px', cursor: 'pointer', userSelect: 'none'}}
                      onClick={() => handleSort('percentMargin')}
                    >
                      <span style={{fontSize: '10px', color: '#0c6099'}}>(BBP-Cost)</span>
                      <br />
                      Profit Margin %
                      {sortColumn === 'percentMargin' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                    </th>
                  </>
                )}
                {/* prices */}
                <th
                  style={{width: '100px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('monthlyRevenueEstimate')}
                >
                  Monthly Revenue Estimate
                  {sortColumn === 'monthlyRevenueEstimate' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '100px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('listedSince')}
                >
                  Listed Since
                  {sortColumn === 'listedSince' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '80px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('reviewCount')}
                >
                  Review Count
                  {sortColumn === 'reviewCount' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '60px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('reviewRating')}
                >
                  Review Rating
                  {sortColumn === 'reviewRating' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '30px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('buyBoxEquity')}
                >
                  Buy Box Equity
                  {sortColumn === 'buyBoxEquity' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th
                  style={{width: '30px', cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => handleSort('productPageScore')}
                >
                  Product Page Score
                  {sortColumn === 'productPageScore' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedSortedFilteredItems.map((item, index) => {
                // Adjust calculations to maintain null values
                const dollarVarianceMSRPvsBBP =
                  item.MSRP && item.buyBoxPrice && item.MSRP > 0
                    ? item.MSRP - parseFloat(item.buyBoxPrice)
                    : null
                const percentVarianceMSRPvsBBP =
                  item.MSRP && dollarVarianceMSRPvsBBP !== null && item.MSRP > 0
                    ? (dollarVarianceMSRPvsBBP / item.MSRP) * 100
                    : null
                const dollarMargin =
                  item.WholesalePrice && item.buyBoxPrice && item.WholesalePrice > 0
                    ? parseFloat(item.buyBoxPrice) - item.WholesalePrice
                    : null
                const percentMargin =
                  dollarMargin !== null && item.buyBoxPrice && parseFloat(item.buyBoxPrice) > 0
                    ? (dollarMargin / parseFloat(item.buyBoxPrice)) * 100
                    : null

                // Now return your JSX for each item
                return (
                  <tr style={{height: '45px'}} key={index}>
                    <td style={{width: '70px'}}>
                      <img
                        src={`https://images-na.ssl-images-amazon.com/images/I/${item.imageUrl}`}
                        alt={item.title}
                        style={{
                          maxHeight: '45px',
                          display: 'flex',
                          margin: '0px auto',
                          objectFit: 'contain',
                        }}
                      />
                    </td>
                    <td style={{width: '120px'}}>{item.asin}</td>
                    <td style={{width: '270px', lineHeight: '1.2'}}>{item.title}</td>
                    <td style={{width: '70px'}}>{item.rank}</td>
                    <td style={{width: '30px'}}>{(item.amazonIsr * 100).toFixed(0)}%</td>
                    <td style={{width: '30px'}}>{item.numberOfSellers}</td>
                    <td style={{width: '30px'}}>{item.numberFbaSellers}</td>
                    <td style={{width: '80px'}}>{item.monthlyUnitsSold}</td>

                    {/* prices */}
                    <td style={{width: '80px'}}>{formatCurrency(parseFloat(item.buyBoxPrice))}</td>
                    <td>{item.MSRP && item.MSRP > 0 ? formatCurrency(item.MSRP, 'USD') : ''}</td>

                    <td>
                      {dollarVarianceMSRPvsBBP !== null
                        ? formatCurrency(dollarVarianceMSRPvsBBP)
                        : ''}
                    </td>
                    <td>
                      {percentVarianceMSRPvsBBP !== null
                        ? `${percentVarianceMSRPvsBBP.toFixed(2)}%`
                        : ''}
                    </td>
                    {currentUser?.decoded?.role === 'admin' && (
                      <>
                        <td>
                          {item.WholesalePrice && item.WholesalePrice > 0
                            ? formatCurrency(item.WholesalePrice, 'USD')
                            : ''}
                        </td>
                        <td>{dollarMargin !== null ? formatCurrency(dollarMargin) : ''}</td>
                        <td>{percentMargin !== null ? `${percentMargin.toFixed(2)}%` : ''}</td>
                      </>
                    )}
                    {/* prices end */}
                    <td style={{width: '100px'}}>
                      {formatCurrency(parseFloat(item.monthlyRevenueEstimate))}
                    </td>
                    <td style={{width: '100px'}}>{item.listedSince.split('T')[0]}</td>
                    <td style={{width: '80px'}}>{item.reviewCount}</td>
                    <td style={{width: '60px'}}>{item.reviewRating}</td>
                    <td style={{width: '30px'}}>{item.buyBoxEquity}</td>
                    <td style={{width: '30px'}}>{item.productPageScore}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div>
          <button
            className=''
            onClick={() => setCurrentPage((page) => Math.max(page - 1, 1))}
            style={paginationButtons}
            onMouseOver={({currentTarget}) => {
              currentTarget.style.backgroundColor = '#36A2EB'
            }}
            onMouseOut={({currentTarget}) => {
              currentTarget.style.backgroundColor = '#0C6099'
            }}
          >
            Previous
          </button>
          <button
            className=''
            onClick={() =>
              setCurrentPage((page) => Math.min(page + 1, Math.ceil(items.length / itemsPerPage)))
            }
            style={paginationButtons}
            onMouseOver={({currentTarget}) => {
              currentTarget.style.backgroundColor = '#36A2EB'
            }}
            onMouseOut={({currentTarget}) => {
              currentTarget.style.backgroundColor = '#0C6099'
            }}
          >
            Next
          </button>
        </div>
      </div>
    )
  }

  return (
    <div style={{overflowX: 'auto'}} className={`card card-flush ${className}`}>
      <div className='card-header pt-6 pb-0'>
        <div className='row'>
          <div className='card-title'>
            <h2 className='card-label'>Search for a brand</h2>
          </div>
        </div>
        <div style={{padding: '15px 25px 0px 0px'}} className='brand-name'>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <h1 style={{fontSize: '2.5em', textAlign: 'right'}}>{searchedBrand}</h1>
              {searchedBrand && (
                <h3 style={{color: 'lightgrey', textAlign: 'right'}}>
                  <b>Brand estimated Revenue:</b> {formatCurrency(totalRevenue)}
                </h3>
              )}
              <p style={{color: 'gray', textAlign: 'right'}}>Trailing 30 Days</p>
            </>
          )}
        </div>
      </div>
      <div className='card-body pt-6 pb-4 d-flex flex-wrap align-items-center'>
        {loading ? (
          <div>Loading data, please wait...</div>
        ) : (
          <AmzProductTable items={inventoryItems} />
        )}
      </div>
    </div>
  )
}

export {CostaSearch}
