import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_designereyes/helpers'
import {PageTitle} from '../../../_designereyes/layout/core'
import {AdminUserPull, AdminAnalyticsWidget1} from '../../../_designereyes/partials/widgets'
import {useAuth} from '../../../app/modules/auth'
import {
  UnitsSoldLineAmazon,
  BestSellingBrandsAmazon,
  BestSellingBrandsOverTimeAmazon,
  TotalOrdersAmazon,
  TotalUnitsSoldAmazon,
  TotalCustomersAmazon,
  TodaySalesAmazon,
} from '../../../_designereyes/partials/widgets/'
import StoreManagerDashboard from './StoreManagerDashboard'

const AmazonPage: FC = () => {
  const {currentUser} = useAuth()

  return (
    <>
      {/* begin Luxottica Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Luxottica' && (
        <>
          <div className='row g-5 g-xl-10'>
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <TotalCustomersAmazon
                  percentageChange={32}
                  vendor={currentUser?.decoded?.vendorName}
                />
              </div>
            </div>
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <TotalUnitsSoldAmazon
                  percentageChange={20}
                  vendor={currentUser?.decoded?.vendorName}
                />
              </div>
            </div>
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <TotalOrdersAmazon
                  percentageChange={15}
                  vendor={currentUser?.decoded?.vendorName}
                />
                {/* backgroundColor='linear-gradient(30deg, #fa5f78 66%, #FF7D92 80%)' */}
              </div>
            </div>
            {/* <div className='col-lg-6 col-xl-2 col-lg-2'>
            <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
              <DailyAverageSales averageDailySales={3542/30} backgroundColor='linear-gradient(30deg, #01081F 66%, #01081F 80%)' />
            </div>
          </div> */}
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <TodaySalesAmazon todaySales={284} vendor={currentUser?.decoded?.vendorName} />
              </div>
            </div>
          </div>

          <div className='row g-5 g-xl-10 mt-5 mt-xl-5 mb-5 mb-xl-10'>
            <div className='col-md-5 col-lg-5'>
              <UnitsSoldLineAmazon
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
            <div className='col-md-7 col-lg-7'>
              <BestSellingBrandsAmazon
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-5'>
            <div className='col-md-6 col-lg-6'>
              <BestSellingBrandsOverTimeAmazon
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
            <div className='col-md-6 col-lg-6'>
              <BestSellingBrandsAmazon
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>
        </>
      )}
      {/* end Luxottica Vendor::Row */}

      {/* begin Thelios Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Thelios' && (
        <>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'></div>
            </div>
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'></div>
            </div>
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'></div>
            </div>
            <div className='col-lg-6 col-xl-3 col-lg-3'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'></div>
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-5 col-lg-5'>
              <UnitsSoldLineAmazon
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
            <div className='col-md-7 col-lg-7'>
              <BestSellingBrandsAmazon
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-6 col-lg-6'>
              <BestSellingBrandsOverTimeAmazon
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
            <div className='col-md-6 col-lg-6'>
              <BestSellingBrandsAmazon
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>
        </>
      )}
      {/* end Thelios Vendor::Row */}

      {/* begin Admin::Row */}
      {currentUser?.decoded?.role === 'admin' && (
        <div className='row gy-5 g-xl-8'>
          <div className='col-xl-6'>
            <AdminUserPull className='card-xl-stretch mb-xl-8' />
          </div>
          <div className='col-xl-6'>
            <AdminAnalyticsWidget1 className='card-xl-stretch mb-xl-8' />
          </div>
        </div>
      )}
      {/* end Admin::Row */}
    </>
  )
}

const AmazonWrapper: FC = () => {
  const intl = useIntl()
  const currentUser = useAuth()
  return (
    <>
      <PageTitle>Amazon Dashboard</PageTitle>
      <AmazonPage />
    </>
  )
}

export {AmazonWrapper}
