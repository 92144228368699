import React, { FC, useEffect, useState } from 'react';
import { PageTitle } from '../../../../_designereyes/layout/core'
import { WeekCalendar, CustomerControlCenter } from '../../../../_designereyes/partials/widgets/'
import { WeekProvider } from '../../../../_designereyes/partials/widgets/_new/cards/vendors/purchase-order-offer/WeekContext'

const POControlCenterPage = () => {
  return (
    <WeekProvider>
      <div className='col g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className=''>
          <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
            <WeekCalendar viewMode="POManager" table="controlCenter"/>
          </div>
        </div>
        <div className=''>
          <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
            <CustomerControlCenter />
          </div>
        </div>
      </div>
    </WeekProvider>
  )
}


export const POControlCenterWrapper: FC = () => {
  return (
    <>
      <PageTitle>Purchase Order Control Center</PageTitle>
      <POControlCenterPage />
    </>
  )
};
