import React, { FC, useEffect, useState } from 'react';
import { CustomerOrdersTable, WeekCalendar } from '../../../../_designereyes/partials/widgets'
import { PageTitle } from '../../../../_designereyes/layout/core'
import { WeekProvider } from '../../../../_designereyes/partials/widgets/_new/cards/vendors/purchase-order-offer/WeekContext';

const CustomerOrdersPage = () => {
  return (
    <WeekProvider>
      <div className="mb-4">
        <WeekCalendar viewMode="POManager" table="orders"/>
      </div>
      <div>
        <CustomerOrdersTable />
      </div>
    </WeekProvider>
  )
}


export const CustomerOrdersWrapper: FC = () => {
    return (
      <>
        <PageTitle>Customer Purchase Orders</PageTitle>
        <CustomerOrdersPage />
      </>
    )
};
