/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../../../../_designereyes/layout/core'
import {CostaSearch} from '../../../../../_designereyes/partials/widgets'

const CostaSearchPage: FC = () => {
  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-12 col-lg-12'>
          <CostaSearch className='h-md-50 mb-5 mb-xl-10 h-lg-100' />
        </div>
      </div>
    </>
  )
}

const CostaSearchWrapper: FC = () => {
  return (
    <>
      <PageTitle>Costa Del Mar Product Research Tool</PageTitle>
      <CostaSearchPage />
    </>
  )
}

export {CostaSearchWrapper}
