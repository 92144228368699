import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract } from '@fortawesome/free-solid-svg-icons';


const Modal = ({ isOpen, onClose, children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isOpen) return null;

  const minWidth = windowWidth < 768 ? '80%' : '30%';

  return (
    <div
      className=''
      style={{
        padding: '20px 40px',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        background: 'white',
        color: 'black',
        minHeight: '80%',
        maxHeight: '80%',
        minWidth: minWidth,
        maxWidth: minWidth,
        borderRadius: '10px',
        overflow: 'auto',
      }}
    >
      <button className="mb-4" onClick={onClose} style={{float: 'right'}}>Close</button>
      {children}
    </div>
  );
};


const ContractCard = () => {
  const [hovered, setHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCardClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const baseStyle = {
    minHeight: '18.5rem',
    background: 'linear-gradient(45deg,#FD5A49,#FDDC98 250%)',
    transition: 'background 0.3s ease',
    cursor: 'pointer',
  };
  const hoverStyle = {
    ...baseStyle,
    background: 'linear-gradient(45deg, #E74C3C, #FAD7A0 250%)',
  };

  return (
    <>
      <div
        className="card"
        style={hovered ? hoverStyle : baseStyle}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleCardClick}
      >
        <div className="h-auto rounded-top p-4 text-center">
          <header className="" style={{ fontSize: '5rem' }}>
            <FontAwesomeIcon icon={faFileContract} />
          </header>
          <div className="pt-6 pb-3 d-flex flex-column">
            <div className="fw-bolder" style={{ fontSize: '2rem', lineHeight: '1' }}>
              Contract Summary
            </div>
            <div className="pt-2 fw-bold" style={{ fontSize: '1.15rem' }}>
              Click to display
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h1 className="text-center mb-8" style={{color:'black'}}>Distribution Agreement Summary</h1>
        <h3 style={{color:'black'}}>Parties Involved:</h3>
        <ul>
          <li>Distributor: [Distributor Company Name], authorized distributor of Thelios brand sunglasses.</li>
          <li>Retailer: [Retailer Company Name], operates two physical retail locations and one online store.</li>
        </ul>

        <h3 style={{color:'black'}}>Agreement Terms:</h3>
        <p>Effective from [Start Date] to [End Date].</p>

        <h3 style={{color:'black'}}>Territory</h3>
        <p>Retailer authorized to sell in [Territory Description].</p>

        <h3 style={{color:'black'}}>Products</h3>
        <p>Includes Dior, Loewe, and other specified Thelios brands.</p>

        <h3 style={{color:'black'}}>Key Terms</h3>
        <ul>
          <li>Minimum Advertised Price (MAP): Retailer agrees not to advertise or list the Products for sale below the MAP set by the Distributor. This policy ensures price consistency across all sales channels, protecting the brand's premium positioning and dealer margins.</li>
          <li>Manufacturer's Suggested Retail Price (MSRP): Distributor will provide the MSRP for each Product. Retailer may price products above MSRP but is encouraged to adhere closely to these suggestions to maintain brand consistency and market stability.</li>
          <li>Brand Protection: Retailer must follow all brand guidelines for marketing, promotion, and sale of the Products to maintain brand integrity.</li>
          <li>Authorized to sell only in designated territories to ensure controlled distribution and avoid market saturation.</li>
          <li>Required to use approved marketing materials and adhere to branding guidelines provided by the Distributor, supporting a cohesive brand image.</li>
        </ul>

        <h3 style={{color:'black'}}>Conclusion</h3>
        <p>This agreement aims to foster a mutually beneficial relationship between the Distributor and Retailer, ensuring the successful distribution of Thelios brand sunglasses while maintaining the brand's premium image and market positioning through adherence to MAP and MSRP guidelines and strict brand protection measures.</p>
      </Modal>
    </>
  );
};

export { ContractCard };
