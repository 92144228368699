import React, { useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../helpers';
import { Dropdown1 } from '../../content/dropdown/Dropdown1';

const standardHeaders = [
  "No.",
  "UPC",
  "Model",
  "Vendor Name",
  "Brand Name",
  "Product Type",
  "Bridge Size",
  "Product Color Description",
  "Gender",
  "Item Category Code",
  "Shape Description",
  "Temple Size",
  "Product Group Code",
  "Color Code",
  "Style Name",
  "Wholesale Price",
  "Product Color Code",
  "Amazon Price",
  "ASIN",
  "Base Unit of Measure",
  "Blocked",
  "Brand",
  "Bullets",
  "Check",
  "Classification",
  "Classification Name",
  "Collection",
  "Consumer Package Weight",
  "Consumer Package Weight Uom",
  "Cost is Adjusted",
  "Costing Method",
  "Country Of Origin",
  "Description",
  "Duty Class",
  "EBay Price",
  "Final Discount Allowed",
  "Final Ticket Price",
  "First Receipt Date",
  "Flag Status",
  "Frame Color",
  "Frame Material",
  "Gen. Prod. Posting Group",
  "Gender 1",
  "Gender Code",
  "Geo Fit",
  "Indirect Cost %",
  "Inventory Posting Group",
  "Item Disc. Group",
  "Keywords",
  "Last Date Modified",
  "Last Direct Cost",
  "Last Receipt Date",
  "Lead Time Calculation",
  "Lens Base",
  "Lens Color",
  "Lens Color Code",
  "Lens Color Description",
  "Lens Curv",
  "Lens Material",
  "Lens Material Code",
  "Lens Material Description",
  "Lens Shape",
  "Lens Type",
  "Lens Width",
  "Map Price",
  "Max Discount",
  "Max Discount Override",
  "MSRP",
  "Outlet Price",
  "Overhead Rate",
  "Perm Price",
  "Polarized",
  "Price Unit Of Measure",
  "Price/Profit Calculation",
  "Product Code",
  "Product Code Description",
  "Product Group Description",
  "Profit %",
  "Purch. Unit of Measure",
  "Qty. on Purch. Order",
  "Qty. on Purch. Return",
  "Qty. on Sales Order",
  "Qty. on Sales Return",
  "Quantity on Hand",
  "Release Date",
  "Replenishment System",
  "Retail Price",
  "Rxable",
  "Sales Unit of Measure",
  "Search Description",
  "Season",
  "Selection Code Description",
  "Shelf No.",
  "Shipping Unit of Measure",
  "Size",
  "Standard Cost",
  "Status",
  "Status Description",
  "Stock Category",
  "Story/Family Name",
  "Style Number",
  "Style Type",
  "Tariff No.",
  "Unit Cost",
  "Unit Price",
  "Used For",
  "Vendor Item No.",
  "Vendor No.",
  "Vendor Part Description",
  "Vendor Part Number",
  "Web Price",
  "Year"
];

type Props = {
    className: string
}
const ListsWidget1: React.FC<Props> = ({className}) => {
  const [file, setFile] = useState(null);
    const [headers, setHeaders] = useState({ mappings: {}, unrecognized: [] });
    const [selectedMappings, setSelectedMappings] = useState({});
    
    const onFileChange = (e) => {
      setFile(e.target.files[0]);
  };

  const onUpload = async () => {
      const formData = new FormData();
    //   formData.append('file', file);

      try {
          const response = await axios.post('http://localhost:4000/upload', formData);
          
          setHeaders({
              mappings: response.data.mappings,
              unrecognized: response.data.unrecognizedHeaders
          });
      } catch (error) {
          console.error('Error uploading file:', error);
      }
  };

  const handleMappingChange = (header, event) => {
      console.log("Before update:", selectedMappings);  // Debugging line
      setSelectedMappings({
          ...selectedMappings,
          [header]: event.target.value
      });
      console.log("After update:", { ...selectedMappings, [header]: event.target.value });  // Debugging line
  };
  

  const availableHeaders = ['N/A', ...standardHeaders.filter(
      stdHeader => !Object.values(headers.mappings).includes(stdHeader) && !Object.values(selectedMappings).includes(stdHeader)
  )];

  console.log("In render:", selectedMappings);

  return (
    <>
    <div className={`card ${className} pt-6`}>
    <div className="card-header">
        <h4>Upload Excel File</h4>
    </div>
    <div className="card-body">
        <div className="row">
            <div className="col-md-8">
                <div className="mb-3">
                    <input type="file" className="form-control" onChange={onFileChange} />
                </div>
                <button className="btn btn-primary" onClick={onUpload}>Upload</button>
            </div>
            <div className="col-md-4">
                <button className="btn btn-secondary">
                    <KTIcon iconName='download' /> Download Sample Excel
                </button>
            </div>
        </div>
    </div>
</div>
<div className="card-footer">
    <h6>Unrecognized Headers:</h6>
    <table className="table">
        <thead>
            <tr>
                <th>File Header</th>
                <th>⟶</th>
                <th>Standard Header</th>
            </tr>
        </thead>
        <tbody>
            {headers.unrecognized.map((header, index) => (
                <tr key={index}>
                    <td>{header}</td>
                    <td>⟶</td>
                    <td>
                        <select className="form-select"
                            value={selectedMappings[header] || 'N/A'}
                            onChange={(event) => handleMappingChange(header, event)}
                        >
                            <option value="N/A">N/A</option>
                            {standardHeaders.map((stdHeader) => (
                                <option key={stdHeader} value={stdHeader}>
                                    {stdHeader}
                                </option>
                            ))}
                        </select>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
</div>
<div className="card mt-4">
    <div className="card-header">
        <h6>Header Mappings:</h6>
    </div>
    <div className="card-body">
        <table className="table">
            <thead>
                <tr>
                    <th>File Header</th>
                    <th>⟶</th>
                    <th>Standard Header</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(headers.mappings).map(([fileHeader, standardHeader], index) => (
                    <tr key={index}>
                        <td>{fileHeader}</td>
                        <td>⟶</td>
                        {/* <td>{standardHeader}</td> */}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>

    </>
  )
}

export {ListsWidget1}
