import {FC} from 'react'
import {toAbsoluteUrl} from '../../../_designereyes/helpers'
import {AdminUserPull, AdminAnalyticsWidget1 } from '../../../_designereyes/partials/widgets'
import {useAuth} from '../../../app/modules/auth'
import {VendorProductsSold} from '../../../_designereyes/partials/widgets'
import {
    StatisticsWidget6,
  } from '../../../_designereyes/partials/widgets'
import SalesDataComponent from '../../../_designereyes/partials/widgets/feeds/POSSalesDataComponent'



const StoreManagerDashboard: FC = () => {
    const {currentUser} = useAuth()
    return (
      <>
      <h2>{currentUser?.decoded?.vendorName} Store Stats</h2>
        <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='success'
            title='10k Goal'
            description='User 1'
            progress='50%'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='warning'
            title='10k Goal'
            description='User 2'
            progress='15%'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='primary'
            title='10k Goal'
            description='User 3'
            progress='76%'
          />
        </div>
      </div>
        {/* end Admin::Row */}
  
       <div>

       <SalesDataComponent />
       </div>
      </>
    )
  }

  export default StoreManagerDashboard;