import React, {useEffect, useRef} from 'react'

interface LowItemProps {
  className?: string
  vendor: string
}

const cardStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  height: '100%',
  margin: '0px auto',
  justifyContent: 'center'
}

const imageStyle: React.CSSProperties = {
  maxWidth: '40%',
  maxHeight: '75px',
  marginLeft: '2rem',
  objectFit: 'contain',
  overflow: 'hidden',
  
}

export const BrandFocus: React.FC<LowItemProps> = ({className}) => {
  return (
    <div
      className={`card ${className}`}
      style={{...cardStyle, minHeight: '22rem'}}
    >
        <div className='logo-chyron' style={{margin: '5% 0'}}>
          <div className='logo-chyron-slide'>
            <img src='/media/logos/Celine.png' alt='' style={imageStyle} />
            <img src='/media/logos/Dior.png' alt='' style={imageStyle} />
            <img src='/media/logos/Fendi.png' alt='' style={imageStyle} />
            <img src='/media/logos/Loewe.png' alt='' style={imageStyle} />
            <img src='/media/logos/Celine.png' alt='' style={imageStyle} />
            <img src='/media/logos/Dior.png' alt='' style={imageStyle} />
            <img src='/media/logos/Fendi.png' alt='' style={imageStyle} />
            <img src='/media/logos/Loewe.png' alt='' style={imageStyle} />
          </div>
          <div className='logo-chyron-slide'>
            <img src='/media/logos/Celine.png' alt='' style={imageStyle} />
            <img src='/media/logos/Dior.png' alt='' style={imageStyle} />
            <img src='/media/logos/Fendi.png' alt='' style={imageStyle} />
            <img src='/media/logos/Loewe.png' alt='' style={imageStyle} />
            <img src='/media/logos/Celine.png' alt='' style={imageStyle} />
            <img src='/media/logos/Dior.png' alt='' style={imageStyle} />
            <img src='/media/logos/Fendi.png' alt='' style={imageStyle} />
            <img src='/media/logos/Loewe.png' alt='' style={imageStyle} />
          </div>
        </div>

        <div className='logo-chyron' style={{margin: '5% 0'}}>
          <div className='logo-chyron-rSlide'>
            <img src='/media/logos/Celine.png' alt='' style={imageStyle} />
            <img src='/media/logos/Dior.png' alt='' style={imageStyle} />
            <img src='/media/logos/Fendi.png' alt='' style={imageStyle} />
            <img src='/media/logos/Loewe.png' alt='' style={imageStyle} />
            <img src='/media/logos/Celine.png' alt='' style={imageStyle} />
            <img src='/media/logos/Dior.png' alt='' style={imageStyle} />
            <img src='/media/logos/Fendi.png' alt='' style={imageStyle} />
            <img src='/media/logos/Loewe.png' alt='' style={imageStyle} />
          </div>
          <div className='logo-chyron-rSlide'>
            <img src='/media/logos/Celine.png' alt='' style={imageStyle} />
            <img src='/media/logos/Dior.png' alt='' style={imageStyle} />
            <img src='/media/logos/Fendi.png' alt='' style={imageStyle} />
            <img src='/media/logos/Loewe.png' alt='' style={imageStyle} />
            <img src='/media/logos/Celine.png' alt='' style={imageStyle} />
            <img src='/media/logos/Dior.png' alt='' style={imageStyle} />
            <img src='/media/logos/Fendi.png' alt='' style={imageStyle} />
            <img src='/media/logos/Loewe.png' alt='' style={imageStyle} />
          </div>
        </div>
      </div>
  )
}
