import React, { FC, CSSProperties, useEffect, useRef, useState } from 'react';
import { Chart, BarController, CategoryScale, LinearScale } from 'chart.js';

Chart.register(BarController, CategoryScale, LinearScale);

type Props = {
  className: string;
  title: string;
  vendor: string;
};

// Define the type for InventoryTable props
type InventoryTableProps = {
  filteredData: InventoryItem[];
};

// Define a type for your inventory item
type InventoryItem = {
  itemName: string;
  Brand: string;
  SKU: string;
  price: string;
  status: string;
  qty: string;
  imageUrl: string;
  thirtyDaySales: string;
};

// Sample data array based on the image you uploaded.
const inventoryDataLuxottica: InventoryItem[] = [
  { itemName: 'RAY BAN - JUSTIN 0RB4165 622/55 54', Brand: 'Ray Ban', SKU: '68911', price: '$130', status: 'In Stock', qty: '4 PCS', thirtyDaySales: '23', imageUrl: '/media/glasses/luxottica/68911.png', },
  { itemName: 'RAY BAN - HEXAGONAL 0RB3548N 001 54', Brand: 'Ray Ban', SKU: '96012', price: '$257', status: 'Low Stock', qty: '3 PCS', thirtyDaySales: '22', imageUrl: '/media/glasses/luxottica/96012.jpg' },
  { itemName: 'RAY BAN - 0RB3647N 002/R5 51', Brand: 'Ray Ban', SKU: '90937  ', price: '$460', status: 'In Stock', qty: '5 PCS', thirtyDaySales: '21', imageUrl: '/media/glasses/luxottica/90937.jpg' },
  { itemName: 'RAY BAN - CLUBMASTER METAL 0RB3716 186/R5 51', Brand: 'Ray ban', SKU: '99224', price: '$273', status: 'In Stock', qty: '5 PCS', thirtyDaySales: '20', imageUrl: '/media/glasses/luxottica/99224.jpg'  },
  { itemName: 'PRADA - 0PR 17WS 2AU8C1 49', Brand: 'Prada', SKU: '274086', price: '$566', status: 'Low Stock', qty: '1 PCS', thirtyDaySales: '20', imageUrl: '/media/glasses/luxottica/274086.jpg'  },
  { itemName: 'PRADA - 0PR 17WS 1425S0 49', Brand: 'Prada', SKU: '274084', price: '$360', status: 'In Stock', qty: '5 PCS', thirtyDaySales: '16', imageUrl: '/media/glasses/luxottica/274084.jpg'  },
  { itemName: 'PRADA - 0PR 17WSF 1AB2B0 51', Brand: 'Prada', SKU: '300795', price: '$566', status: 'Low Stock', qty: '1 PCS', thirtyDaySales: '2', imageUrl: '/media/glasses/luxottica/300795.jpg'  },
  { itemName: 'OAKLEY - 0OO9463 946313', Brand: 'Oakley', SKU: '289655', price: '$194', status: 'In Stock', qty: '2 PCS', thirtyDaySales: '11', imageUrl: '/media/glasses/luxottica/289655.jpg'  },
  { itemName: 'BURBERRY - 0BE4323 38538G 54', Brand: 'Burberry', SKU: '194747', price: '$120', status: 'In Stock', qty: '3 PCS', thirtyDaySales: '9', imageUrl: '/media/glasses/luxottica/194747.jpg'  },
  { itemName: 'BURBERRY - 0BE4216 30018G 57', Brand: 'Burberry', SKU: '90351', price: '$120', status: 'In Stock', qty: '3 PCS', thirtyDaySales: '9', imageUrl: '/media/glasses/luxottica/90351.jpg'  },
  { itemName: 'DOLCE & GABBANA - 0DG4415 502/13 56', Brand: 'Dolce & Gabbana', SKU: '313371', price: '$120', status: 'Out of Stock', qty: '0 PCS', thirtyDaySales: '0', imageUrl: '/media/glasses/luxottica/313371.jpg'  },
];

const inventoryDataThelios: InventoryItem[] = [
  { itemName: 'Loewe LW40108l Sunglasses', Brand: 'Loewe', SKU: '318751', price: '$1,230', status: 'In Stock', qty: '58 PCS', imageUrl: '/media/glasses/thelios/bestSellingGlasses.png', thirtyDaySales: '200' },
  { itemName: 'CD Diamond SI DM40072I DIOR Sunglasses', Brand: 'Dior', SKU: '304692', price: '$553', status: 'Out of Stock', qty: '0 PCS', imageUrl: '/media/glasses/304692.png', thirtyDaySales: '200' },
  { itemName: 'Bold 3 Dots CL40092I CELINE Sunglasses', Brand: 'Celine', SKU: '113467  ', price: '$460', status: 'In Stock', qty: '290 PCS', imageUrl: '/media/glasses/113467.png', thirtyDaySales: '50' },
  { itemName: 'LW40104I LOEWE Sunglasses', Brand: 'Loewe', SKU: '318730', price: '$390', status: 'Out of Stock', qty: '0 PCS', imageUrl: '/media/glasses/318730.png', thirtyDaySales: '50'  },
  { itemName: 'FENDI ROMA FENDI Sunglasses', Brand: 'Fendi', SKU: '344962', price: '$4500', status: 'In Stock', qty: '78 PCS', imageUrl: '/media/glasses/344962.png', thirtyDaySales: '50'  },
  { itemName: 'Mini CD O R2F CD50061F DIOR Eyeglasses', Brand: 'Dior', SKU: '314485', price: '$360', status: 'Low Stock', qty: '8 PCS', imageUrl: '/media/glasses/314485.png', thirtyDaySales: '50'  },
  { itemName: 'FE40098I FENDI Sunglasses'  , Brand: 'Fendi', SKU: '341249', price: '$450', status: 'In Stock', qty: '124 PCS', imageUrl: '/media/glasses/341249.png', thirtyDaySales: '50'  },
  { itemName: 'CD Diamond SI DM40072I DIOR Sunglasses', Brand: 'Dior', SKU: '304692', price: '$553', status: 'Out of Stock', qty: '0 PCS', imageUrl: '/media/glasses/304692.png', thirtyDaySales: '200' },
  { itemName: 'Bold 3 Dots CL40092I CELINE Sunglasses', Brand: 'Celine', SKU: '113467  ', price: '$460', status: 'In Stock', qty: '290 PCS', imageUrl: '/media/glasses/113467.png', thirtyDaySales: '50' },
  { itemName: 'LW40104I LOEWE Sunglasses', Brand: 'Loewe', SKU: '318730', price: '$390', status: 'Out of Stock', qty: '0 PCS', imageUrl: '/media/glasses/318730.png', thirtyDaySales: '50'  },
  { itemName: 'FENDI ROMA FENDI Sunglasses', Brand: 'Fendi', SKU: '344962', price: '$4500', status: 'In Stock', qty: '78 PCS', imageUrl: '/media/glasses/344962.png', thirtyDaySales: '50'  },
  { itemName: 'Mini CD O R2F CD50061F DIOR Eyeglasses', Brand: 'Dior', SKU: '314485', price: '$360', status: 'Low Stock', qty: '8 PCS', imageUrl: '/media/glasses/314485.png', thirtyDaySales: '50'  },
  { itemName: 'Loewe LW40108l Sunglasses', Brand: 'Loewe', SKU: '318751', price: '$1,230', status: 'In Stock', qty: '58 PCS', imageUrl: '/media/glasses/thelios/bestSellingGlasses.png', thirtyDaySales: '200' },
  { itemName: 'CD Diamond SI DM40072I DIOR Sunglasses', Brand: 'Dior', SKU: '304692', price: '$553', status: 'Out of Stock', qty: '0 PCS', imageUrl: '/media/glasses/304692.png', thirtyDaySales: '200' },
  { itemName: 'Bold 3 Dots CL40092I CELINE Sunglasses', Brand: 'Celine', SKU: '113467  ', price: '$460', status: 'In Stock', qty: '290 PCS', imageUrl: '/media/glasses/113467.png', thirtyDaySales: '50' },
  { itemName: 'LW40104I LOEWE Sunglasses', Brand: 'Loewe', SKU: '318730', price: '$390', status: 'Out of Stock', qty: '0 PCS', imageUrl: '/media/glasses/318730.png', thirtyDaySales: '50'  },
  { itemName: 'FENDI ROMA FENDI Sunglasses', Brand: 'Fendi', SKU: '344962', price: '$4500', status: 'In Stock', qty: '78 PCS', imageUrl: '/media/glasses/344962.png', thirtyDaySales: '50'  },
  { itemName: 'Mini CD O R2F CD50061F DIOR Eyeglasses', Brand: 'Dior', SKU: '314485', price: '$360', status: 'Low Stock', qty: '8 PCS', imageUrl: '/media/glasses/314485.png', thirtyDaySales: '50'  },
  { itemName: 'FE40098I FENDI Sunglasses'  , Brand: 'Fendi', SKU: '341249', price: '$450', status: 'In Stock', qty: '124 PCS', imageUrl: '/media/glasses/341249.png', thirtyDaySales: '50'  },
  // ... add more items as needed
];

// Define a mapping of vendors to their respective brands
const brandLabels = {
  Thelios: ['Dior', 'Loewe', 'Celine', 'Fendi'],
  Luxottica: ['Ray Ban', 'Prada', 'Versace', 'Oakley', 'Dolce & Gabbana', 'Burberry', 'Michael Kors', 'Coach', 'Tiffany', 'Costa Del Mar', 'Tory Burch', 'Chanel'],
  // Add more vendors and their labels here
};

const tableStyles: CSSProperties = {
  width: '100%',
  borderCollapse: 'collapse',
  fontSize: '1.2em', // Bigger font size for the whole table
};

const headerStyles: CSSProperties = {
  backgroundColor: '',
  textAlign: 'left',
  padding: '12px', // Larger padding
  borderBottom: '2px solid #ddd', // Thicker bottom border for headers
  fontSize: '20px'
};

const headerStyles2: CSSProperties = {
  backgroundColor: '',
  textAlign: 'right',
  padding: '12px', // Larger padding
  borderBottom: '2px solid #ddd', // Thicker bottom border for headers
  fontSize: '20px'
};

const cellStyles: CSSProperties = {
  textAlign: 'left',
  padding: '1.2em', // Larger padding for cells
  fontSize: '1.25rem',
  fontWeight: '500',
};

const cellStyles2: CSSProperties = {
  textAlign: 'right',
  padding: '1.2em', // Larger padding for cells
};

const cellStylesSales: CSSProperties = {
  textAlign: 'center',
  paddingLeft: '2.5em', // Larger padding for cells
  fontSize: '1.5rem',
};

const imageStyles: CSSProperties = {
  width: '80px', // Larger image width
  height: 'auto', // Height is auto to maintain aspect ratio
};

const rowStyles: CSSProperties = {
  backgroundColor: '', // default background
};

const oddRowStyles: CSSProperties = {
  backgroundColor: 'rgba(255, 255, 255, 0.025)', // slightly different background for odd rows
};

const selectWrapperStyle: React.CSSProperties = {
  position: 'relative',
  display: 'inline-block',
};

const arrowStyle: React.CSSProperties = {
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%) rotate(45deg)',
  pointerEvents: 'none',
  border: 'solid black',
  borderWidth: '0 2px 2px 0',
  display: 'inline-block',
  padding: '3px',
};

// Function to get the style based on the item's status
const getStatusStyle = (status: string): CSSProperties => {
  let style: CSSProperties = {
    display: 'flex',
    justifyContent: 'center', // Align content to the right
    height: '100%', // Take full height of the cell
    borderRadius: '10px',
    width: '80%', // Full width to ensure border alignment
    boxSizing: 'border-box', // Include padding in the width and height'
    marginLeft: '15%',
    marginTop: '1.25em',
  };
  
  switch (status) {
    case 'In Stock':
      return {...style, 
              backgroundColor: 'rgba(0, 255, 0, 0.6)',
              color: 'white', 
              textAlign: 'center',
              padding: '0.5em', // Larger padding for cells
              };
    case 'Low Stock':
      return {...style, 
              backgroundColor: 'rgba(255, 255, 0, 0.6)', 
              color: 'white', 
              textAlign: 'center',
              padding: '0.5em', // Larger padding for cells
              };
    case 'Out of Stock':
      return {...style, 
              backgroundColor: 'rgb(249, 40, 90, 0.6)', 
              color: 'white', 
              textAlign: 'center',
              padding: '0.5em', // Larger padding for cells
              };
    default:
      return {textAlign: 'center',
              padding: '0.5em', // Larger padding for cells
              }
  }
};

const InventoryTable: FC<InventoryTableProps> = ({ filteredData }) => {  
  const itemsPerPage = 7;

  // Calculate the number of empty rows needed only when there's a remainder.
  const remainder = filteredData.length % itemsPerPage;
  const emptyRowsCount = remainder > 0 ? itemsPerPage - remainder : 0;
  // Determine the starting style based on the last item's index
  const lastItemIndex = filteredData.length - 1;
  const startStyleDark = lastItemIndex % 2 === 0;

  // Function to process the quantity
  const processQuantity = (qtyString) => {
    const [numberPart, unit] = qtyString.split(' '); // Splitting by space
    const number = parseInt(numberPart, 10); // Parsing to integer

    // Perform your multiplication or any other operation
    const multipliedNumber = number * 2; // Replace 'yourMultiplier' with your actual multiplier

    return `${multipliedNumber} ${unit}`; // Concatenating back into a string
  };

  return (
    <table style={tableStyles}>
      <thead>
        <tr>
          <th style={headerStyles}></th>
          <th style={headerStyles}>Item</th>
          <th style={headerStyles2}>Brand</th>
          <th style={headerStyles2}>SKU</th>
          <th style={headerStyles2}>Price</th>
          <th style={headerStyles2}>30-Day Sales</th>
          <th style={headerStyles2}>QTY</th>
          <th style={headerStyles2}>Status</th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item, index) => (
          <tr key={index} style={index % 2 === 0 ? rowStyles : oddRowStyles}>
            <td style={cellStyles}>
              <img src={item.imageUrl} alt={item.itemName} style={imageStyles} />
            </td>
            <td style={cellStyles}>{item.itemName}</td>
            <td style={cellStyles2}>{item.Brand}</td>
            <td style={cellStyles2}>{item.SKU}</td>
            <td style={cellStyles2}>{item.price}</td>
            <td style={cellStylesSales}>
              {(parseInt(item.thirtyDaySales)*2).toString()}
            </td>
            <td style={cellStyles2}>
              {processQuantity(item.qty)}
            </td>
            <td style={getStatusStyle(item.status)}>
              {item.status}
            </td>
          </tr>
        ))}
        
        {/* Add empty rows if needed */}
        {emptyRowsCount > 0 && Array.from({ length: emptyRowsCount }, (_, index) => {
          // Calculate the style based on the last item's style and the index of the empty row
          const isDark = startStyleDark ? (index % 2 === 1) : (index % 2 === 0);
          return (
            <tr key={`empty-row-${index}`} style={isDark ? rowStyles : oddRowStyles}>
              <td style={cellStyles}>&nbsp;</td>
              <td style={cellStyles}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
              <td style={cellStylesSales}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const StoreInventoryTable1: FC<Props> = ({ className, vendor }) => {
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const inventoryData = vendor === 'Thelios' ? inventoryDataThelios : inventoryDataLuxottica;

  // Get brands for the selected vendor
  const vendorBrands = brandLabels[vendor] || [];

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };
  
  const maxPage = Math.ceil(inventoryData.length / itemsPerPage);
  const startIndex = (currentPage-1) * itemsPerPage;
  const endIndex = (startIndex + itemsPerPage);
  
  const filteredData = inventoryData.filter((item) => {
    return (selectedBrand === '' || item.Brand === selectedBrand) &&
           (selectedStatus === '' || item.status === selectedStatus);
  });

  const paginatedData = filteredData.slice(startIndex, endIndex);

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / itemsPerPage) * itemsPerPage;
    return new Array(itemsPerPage).fill(null).map((_, idx) => start + idx + 1).filter(page => page <= maxPage);
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div style={{ overflowX: 'auto' }} className={`card card-flush ${className}`}>
      <div className='card-body pt-6 pb-4 d-flex flex-wrap align-items-center'>
        <div className=' d-flex align-items-center'>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>Inventory</span>
        </div>
          <div className='d-flex align-items-center'> 
            <label htmlFor="brand-select" style={{marginLeft: '10rem', fontSize: '1.5rem'}}>Brand:</label>
          <div style={selectWrapperStyle}>
              <select className='form-select form-select-sm' id="brand-select" onChange={handleBrandChange} value={selectedBrand} style={{background: 'white', color: 'black', marginLeft: '1rem', width: 'auto'}}>
                <option value="">All Brands</option>
                
                {vendorBrands.map((brand) => (
                  <option key={brand} value={brand}>{brand}</option>
                ))}
                {/* Add options for each unique brand */}
              </select>
              <i style={arrowStyle}></i> {/* This is the arrow */}
          </div>
            
          <label htmlFor="status-select" style={{marginLeft: '4rem', fontSize: '1.5rem'}}>Status:</label>
          <div style={selectWrapperStyle}>
              <select className='form-select form-select-sm' id="status-select" onChange={handleStatusChange} value={selectedStatus} style={{background: 'white', color: 'black', marginLeft: '1rem', width: 'auto'}}>
                <option value="">All Statuses</option>
                <option value="In Stock">In Stock</option>
                <option value="Low Stock">Low Stock</option>
                <option value="Out of Stock">Out of Stock</option>
                {/* Add options for each unique status */}
              </select>
              <i style={arrowStyle}></i> {/* This is the arrow */}
          </div>
        </div>      
       
        <InventoryTable filteredData={paginatedData} />
        
        <div className="pagination">
          {/* Previous Button */}
          <button
            onClick={() => changePage(currentPage - 1)}
            disabled={currentPage === 1}
            className="page-item"
          >
            &lt;
          </button>
      
          {/* Show page numbers */}
          {getPaginationGroup().map(number => (
            <button
              key={number}
              onClick={() => changePage(number)}
              disabled={number === currentPage}
              className={`page-item ${number === currentPage ? "active" : ""}`}
            >
              {number}
            </button>
          ))}

          {/* Next Button */}
          <button
            onClick={() => changePage(currentPage + 1)} disabled={currentPage === maxPage} className="page-item">
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export { StoreInventoryTable1 };
