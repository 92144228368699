/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_designereyes/helpers'
import {Card4} from '../../../../_designereyes/partials/content/cards/Card4'
import {useAuth} from '../../../../app/modules/auth'

export function Documents() {
  const currentDate = new Date();
const dateTime = `${currentDate.getDate()}/${currentDate.getMonth()+1}/${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}`;
  const {currentUser} = useAuth()
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Documents
          {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>100+ resources</span> */}
        </h3>

        <div className='d-flex my-2'>
          <div className='d-flex align-items-center position-relative me-4'>
            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm w-150px ps-9'
              placeholder='Search'
            />
          </div>

          <a href='#' className='btn btn-primary btn-sm'>
            File Manager
          </a>
        </div>
      </div>

      <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
        <div className='col-12 col-sm-12 col-xl'>
          <Card4
            icon='/media/svg/files/folder-document.svg'
            title='Standard Operating Procedures'
            description='1 file'
          />
        </div>
        <div className='col-12 col-sm-12 col-xl'>
          <Card4
            icon='/media/svg/files/folder-document.svg'
            title='Customers'
            description='3 files'
          />
        </div>
        <div className='col-12 col-sm-12 col-xl'>
          <Card4
            icon='/media/svg/files/folder-document.svg'
            title='CRM Project'
            description='25 files'
          />
        </div>
      </div>

      <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
        <div className='col-12 col-sm-12 col-xl'>
          <Card4
            icon='/media/svg/files/pdf.svg'
            title='Daily Manual Inventory SOP'
            description='Last Updated: 12/09/2023'
            downloadLink='/media/SOPS/manual_daily_inventory.pdf'
          />
        </div>

        <div className='col-12 col-sm-12 col-xl'>
          <Card4 icon='/media/svg/files/sql.svg' title='Orders backup' description='1 week ago' />
        </div>
        <div className='col-12 col-sm-12 col-xl'>
          <Card4 icon='/media/svg/files/sql.svg' title='Orders backup' description='1 week ago' />
        </div>
        <div className='col-12 col-sm-12 col-xl'>
          <Card4 icon='/media/svg/files/sql.svg' title='Orders backup' description='1 week ago' />
        </div>
      </div>
      { (currentUser?.decoded?.role === 'admin' || currentUser?.decoded?.role === 'David') && (
          <>
          <hr className='mb-12 mt-10 ' />
          <div className='card card-flush'>
            <div className='card-header border-0'>
              <h3 className='card-title fw-bold text-dark'>
                David's Quick Reports{' '}
                <span className='fs-6 text-gray-400 fw-bold ms-1'> downloadable files</span>
              </h3>

              <div className='card-toolbar'>
                {/* begin::Menu */}
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <KTIcon iconName='category' className='fs-2' />
                </button>
                {/* end::Menu */}
              </div>
            </div>
            <div className='card-body pt-2'>
              <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
                <div className='col-12 col-sm-12 col-xl'>
                  <Card4
                    icon='/media/svg/files/excel.svg'
                    title='Davids Favorite Report'
                    downloadLink='/media/SOPS/manual_daily_inventory.pdf'
                    description={`Last Updated: ${dateTime}`}
                    backgroundColor='#EDFFED'
                  />
                </div>

                <div className='col-12 col-sm-12 col-xl'>
                  <Card4
                    icon='/media/svg/files/excel.svg'
                    title='Weekly Report'
                    description={`Last Updated: ${dateTime}`}
                    backgroundColor='#EDFFED'
                  />
                </div>
                <div className='col-12 col-sm-12 col-xl'>
                  <Card4
                    icon='/media/svg/files/excel.svg'
                    title='Sales Report'
                    description={`Last Updated: ${dateTime}`}
                    backgroundColor='#EDFFED'
                  />
                </div>
                <div className='col-12 col-sm-12 col-xl'>
                  <Card4
                    icon='/media/svg/files/excel.svg'
                    title='Purchase Order Reports'
                    description={`Last Updated: ${dateTime}`}
                    backgroundColor='#EDFFED'
                  />
                </div>
              </div>
            </div>
          </div></>
        )}
    </>
  )
}
