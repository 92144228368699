/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_designereyes/layout/core'
import {useAuth} from '../../../../modules/auth'
import {CostaSearchSeller} from '../../../../../_designereyes/partials/widgets'

const SellerCostaPage: FC = () => {
  const {currentUser} = useAuth()

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-12 col-lg-12'>
          <CostaSearchSeller className='h-md-50 mb-5 mb-xl-10 h-lg-100' />
        </div>
      </div>
    </>
  )
}

const SellerCostaWrapper: FC = () => {
  const intl = useIntl()
  const currentUser = useAuth()
  return (
    <>
      <PageTitle>Costa Del Mar Seller Research Tool</PageTitle>
      <SellerCostaPage />
    </>
  )
}

export {SellerCostaWrapper}
