import React, { FC, CSSProperties, useEffect, useState } from 'react';
import { useAccount } from '../../../../../widgets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';

type Props = {
  country: string;
  className: string;
  vendor: string;
  selectedStore: string;
  inventoryTableItems: AccountTableItems[];
};

type AccountTableItems = {
  AccountID: string;
  Name: string;
  ContactInfo: string;
  Address: string;
  Region: string;
  BusinessName: string;
};

const dummyAccountDataCaribbean: AccountTableItems[] = [
  {
    AccountID: '1',
    Name: 'Vero Aguilar',
    ContactInfo: '209-847-9592',
    Address: '1074 José E Arraras Ste 2 Terrace, 00680, Mayaguez, Puerto Rico',
    Region: 'Puerto Rico',
    BusinessName: 'SunnyShades LLC',
  },
  {
    AccountID: '2',
    Name: 'Jamie Torres',
    ContactInfo: '787-555-0147',
    Address: '1502 Ponce de León, Santurce, 00909, San Juan, Puerto Rico',
    Region: 'Puerto Rico',
    BusinessName: 'IslandEyewear Inc.',
  },
  {
    AccountID: '3',
    Name: 'Luisa Martínez',
    ContactInfo: '787-555-0234',
    Address: '18 Calle San José, San Juan, 00901, Puerto Rico',
    Region: 'Puerto Rico',
    BusinessName: 'CoastalViews Optics',
  },
  {
    AccountID: '4',
    Name: 'Roberto Vazquez',
    ContactInfo: '787-555-0333',
    Address: 'Calle Loiza 1765, San Juan, 00911, Puerto Rico',
    Region: 'Puerto Rico',
    BusinessName: 'Visionary Lenses PR',
  }
];

const dummyAccountDataIsrael: AccountTableItems[] = [
  {
    AccountID: '5',
    Name: 'Yael Cohen',
    ContactInfo: '03-555-0147',
    Address: 'Ha-Yarkon St 88, Tel Aviv',
    Region: 'Tel Aviv',
    BusinessName: 'OpticCity Ltd.',
  },
  {
    AccountID: '6',
    Name: 'Eitan Levy',
    ContactInfo: '03-555-0923',
    Address: 'Ben Yehuda St 45, Tel Aviv',
    Region: 'Tel Aviv',
    BusinessName: 'Visionary Frames Ltd.',
  },
  {
    AccountID: '7',
    Name: 'Noa Mizrahi',
    ContactInfo: '02-655-0182',
    Address: 'King George St 23, Jerusalem',
    Region: 'Jerusalem',
    BusinessName: 'Jerusalem Optics Co.',
  },
  {
    AccountID: '8',
    Name: 'Amir Rosen',
    ContactInfo: '08-643-9872',
    Address: 'Herzl St 67, Beersheba',
    Region: 'South District',
    BusinessName: 'Desert View Eyewear Ltd.',
  },
  {
    AccountID: '9',
    Name: 'Tamar Schwartz',
    ContactInfo: '04-853-2345',
    Address: 'Hahistadrut Blvd 12, Haifa',
    Region: 'Haifa',
    BusinessName: 'Haifa Sightline Optics Ltd.',
  },
  {
    AccountID: '10',
    Name: 'Omer Katz',
    ContactInfo: '09-852-4567',
    Address: 'Weizmann St 88, Netanya',
    Region: 'Central District',
    BusinessName: 'ClearVision Optics Ltd.',
  }  
];

const headerStyles: CSSProperties = {
  backgroundColor: '',
  textAlign: 'left',
  borderBottom: '2px solid #ddd',
  fontSize: '16px'
};

const headerStyles2: CSSProperties = {
  backgroundColor: '',
  textAlign: 'center',
  borderBottom: '2px solid #ddd',
  fontSize: '16px'
};

const cellStyles: CSSProperties = {
  textAlign: 'left',
  fontSize: '1.15rem',
  fontWeight: '400',
};

const cellStyles2: CSSProperties = {
  textAlign: 'center',
  fontSize: '1.25rem',
  fontWeight: '500'
};

const rowStyles: CSSProperties = {
  backgroundColor: '',
};

const oddRowStyles: CSSProperties = {
  backgroundColor: 'rgba(255, 255, 255, 0.025)',
};

const AccountTable: FC<{ filteredData: AccountTableItems[], handleSort: (column: string) => void, sortColumn: string, sortOrder: string}> = ({ filteredData, handleSort, sortColumn, sortOrder }) => {
  const [hoveredAccountId, setHoveredAccountId] = useState<string | null>(null);

  const itemsPerPage = 8;

  const remainder = filteredData.length % itemsPerPage;
  const emptyRowsCount = remainder > 0 ? itemsPerPage - remainder : 0;

  const lastItemIndex = filteredData.length - 1;
  const startStyleDark = lastItemIndex % 2 === 0;

  const { handleSelectAccount } = useAccount();

  const selectAccount = (accountID) => {
    handleSelectAccount(accountID);
  };

  return (
    <table className='table table-striped table-dark table-hover table-bordered'>
      <thead>
        <tr>
          <th style={{ ...headerStyles, width: '10px'}}></th>
          <th style={{ ...headerStyles, width: '30px', cursor: 'pointer' }} onClick={() => handleSort('AccountID')}>
            ID{sortColumn === 'AccountID' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th style={{ ...headerStyles, width: '100px', cursor: 'pointer' }} onClick={() => handleSort('Name')}>
            Name{sortColumn === 'Name' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th style={{ ...headerStyles2, width: '100px', cursor: 'pointer' }} onClick={() => handleSort('ContactInfo')}>
            Contact Info{sortColumn === 'ContactInfo' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th style={{ ...headerStyles, width: '250px', cursor: 'pointer' }} onClick={() => handleSort('Address')}>
            Address{sortColumn === 'Address' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th style={{ ...headerStyles2, width: '50px', cursor: 'pointer' }} onClick={() => handleSort('Region')}>
            Region{sortColumn === 'Region' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
          </th>
          <th style={{ ...headerStyles2, width: '50px', cursor: 'pointer' }} onClick={() => handleSort('BusinessName')}>
            Business Name{sortColumn === 'BusinessName' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item, index) => {
          return (
            <tr key={index} style={index % 2 === 0 ? rowStyles : oddRowStyles}>
              <td 
                style={{ ...cellStyles, maxWidth: '10px', maxHeight: '20px', cursor: 'pointer', textAlign: 'center', fontSize: '1.25rem'}} 
                onClick={()=>selectAccount(item.AccountID)}
                onMouseEnter={() => setHoveredAccountId(item.AccountID)}
                onMouseLeave={() => setHoveredAccountId(null)}
              >
                {hoveredAccountId === item.AccountID ? <FontAwesomeIcon icon={faMagnifyingGlassPlus} style={{color: 'red'}}/> : <FontAwesomeIcon icon={faMagnifyingGlass} />}
              </td>
              <td style={{ ...cellStyles, maxWidth: '30px' }}>{item.AccountID}</td>
              <td style={{ ...cellStyles, maxWidth: '100px' }}>{item.Name}</td>
              <td style={{ ...cellStyles2, maxWidth: '100px' }}>{item.ContactInfo}</td>
              <td style={{ ...cellStyles, maxWidth: '250px' }}>{item.Address}</td>
              <td style={{ ...cellStyles2, maxWidth: '50px' }}>{item.Region}</td>
              <td style={{ ...cellStyles2, maxWidth: '50px' }}>{item.BusinessName}</td>
            </tr>
          );
        })}

        {/* Add empty rows if needed */}
        {emptyRowsCount > 0 && Array.from({ length: emptyRowsCount }, (_, index) => {
          const isDark = startStyleDark ? (index % 2 === 1) : (index % 2 === 0);
          return (
            <tr key={`empty-row-${index}`} style={isDark ? rowStyles : oddRowStyles}>
              <td style={cellStyles}>&nbsp;</td>
              <td style={cellStyles}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
              <td style={cellStyles2}>&nbsp;</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const DistributionAccountTable: FC<Props> = ({ className, selectedStore, inventoryTableItems }) => {
  const [inventoryData, setAccountData] = useState<AccountTableItems[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAccountData(inventoryTableItems);
        console.log('Chart Data:', inventoryTableItems);
      } catch (error) {
        console.error('Error fetching inventory data:', error);
      }
    };

    if (selectedStore) {
      fetchData();
    }
  }, [inventoryTableItems, selectedStore]);

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortColumn(column);
    console.log(`Sorting by ${column}, Direction: ${isAsc ? 'desc' : 'asc'}`);
  };

  const applySort = (data) => {
    if (!sortColumn) return data;
    console.log(`Applying sort on ${sortColumn} in ${sortDirection} order`);
    return [...data].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = (startIndex + itemsPerPage);

  const sortedData = applySort(inventoryData);
  const paginatedData = sortedData.slice(startIndex, endIndex);
  const maxPage = Math.ceil(inventoryData.length / itemsPerPage);

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / itemsPerPage) * itemsPerPage;
    return new Array(itemsPerPage).fill(null).map((_, idx) => start + idx + 1).filter(page => page <= maxPage);
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div style={{ overflowX: 'auto', overflowY: 'auto', height:'auto' }} className={`card-body pt-8 px-2 card-flush ${className}`}>
      <div className='d-flex flex-wrap align-items-center'>
        <AccountTable
          filteredData={paginatedData}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortOrder={sortDirection}
        />

        <div className="pagination">
          <button
            onClick={() => changePage(currentPage - 1)}
            disabled={currentPage === 1}
            className="page-item"
          >
            &lt;
          </button>

          {getPaginationGroup().map(number => (
            <button
              key={number}
              onClick={() => changePage(number)}
              disabled={number === currentPage}
              className={`page-item ${number === currentPage ? "active" : ""}`}
            >
              {number}
            </button>
          ))}

          <button
            onClick={() => changePage(currentPage + 1)} disabled={currentPage === maxPage} className="page-item">
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export const AccountPerformanceTable: FC<{country:string}> = ({ country }) => {
  return (
    <div className="card d-flex flex-column p-4" style={{minHeight:'45.5rem'}}>
      <div className="d-flex fs-1 fw-bolder px-4">Distributor Accounts</div>
      <div>
        <DistributionAccountTable
          country={country}
          className=""
          vendor="Luxottica"
          selectedStore="Main Street Store"
          inventoryTableItems={country === 'Caribbean' ? dummyAccountDataCaribbean : dummyAccountDataIsrael}
        />
      </div>
    </div>
  )
}