import React, { FC, useEffect, useState, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { API_URL } from '../../../../../../../app/modules/auth/core/_requests'

const columns = [
  { field: 'customerID', header: 'Customer ID' },
  { field: 'customerName', header: 'Customer Name' },
  { field: 'active', header: 'Active' },
  // { field: 'Responsibility Center', header: 'Responsibility Center' },
  { field: 'locationCode', header: 'Location Code' },
  { field: 'phoneNo', header: 'Phone No' },
  { field: 'contact', header: 'Contact' },
  { field: 'searchName', header: 'Search Name' },
  { field: 'paymentTermsCode', header: 'Payment Terms Code' },
  { field: 'blocked', header: 'Blocked' },
  { field: 'creditLimit', header: 'Credit Limit' },
  { field: 'currencyCode', header: 'Currency Code' },
  // { field: 'Customer Disc Group', header: 'Customer Disc Group' },
  { field: 'customerPostingGroup', header: 'Customer Posting Group' },
  // { field: 'Customer Price Group', header: 'Customer Price Group' },
  { field: 'salespersonCode', header: 'Salesperson Code' },
  { field: 'shippingAdvice', header: 'Shipping Advice' },
  { field: 'shippingAgentCode', header: 'Shipping Agent Code' },
  { field: 'combineShipments', header: 'Combine Shipments' },
  { field: 'lastDateModified', header: 'Last Date Modified' },
  { field: 'companyCode', header: 'Company Code' },
  // { field: 'Fin Charge Terms Code', header: 'Fin Charge Terms Code' },
  // { field: 'Reminder Terms Code', header: 'Reminder Terms Code' },
  { field: 'applicationMethod', header: 'Application Method' },
  // { field: 'Base Calendar Code', header: 'Base Calendar Code' },
  { field: 'countryCode', header: 'Country Code' },
  { field: 'faxNo', header: 'Fax No' },
  { field: 'genBusPostingGroup', header: 'Gen Bus Posting Group' },
  // { field: 'IC Partner Code', header: 'IC Partner Code' },
  // { field: 'Language Code', header: 'Language Code' },
  { field: 'reserve', header: 'Reserve' },
  // { field: 'Tax Bus Posting Group', header: 'Tax Bus Posting Group' },
  { field: 'zipCode', header: 'ZIP Code' }
];

interface Customer {
  customerID: string;
  customerName: string;
  active: boolean;
  // Responsibility Center: string;
  locationCode: string;
  phoneNo: string;
  contact: string;
  searchName: string;
  paymentTermsCode: string;
  blocked: boolean;
  creditLimit: number;
  currencyCode: string;
  // Customer Disc Group: string;
  customerPostingGroup: string;
  // Customer Price Group: string;
  salespersonCode: string;
  shippingAdvice: string;
  shippingAgentCode: string;
  combineShipments: boolean;
  lastDateModified: string;
  companyCode: string;
  // Fin Charge Terms Code: string;
  // Reminder Terms Code: string;
  applicationMethod: string;
  // Base Calendar Code: string;
  countryCode: string;
  faxNo: string;
  genBusPostingGroup: string;
  // IC Partner Code: string;
  // Language Code: string;
  reserve: string;
  // Tax Bus Posting Group: string;
  zipCode: string;
}

export const CustomerMasterList = ({ active }) => {
  const [products, setProducts] = useState<Customer[]>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [selectedRows, setSelectedRows] = useState<Customer[]>([]);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  
  // Function to fetch customer data
  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${API_URL}/offers/query-customer-list?active=${active}`);
      setProducts(response.data as Customer[]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch customers when the component mounts or active prop changes
  useEffect(() => {
    fetchCustomers();
  }, [active]);

  // Function to update the active status of selected rows
  const setActiveStatus = async (newActiveStatus) => {
    try {
      // Assuming your backend is now set up to handle an array of updates
      await axios.patch(`${API_URL}/offers/set-customer-activity-bulk`, selectedRows.map(row => ({
        customerID: row.customerID,
        active: newActiveStatus
      })));
      
      setDialogContent('Customer status updated successfully.');
      fetchCustomers(); // Refresh the data after a successful update
    } catch (error) {
      console.error('Error updating customer status:', error);
      setDialogContent('Failed to update the customer status.');
    } finally {
      setIsDialogVisible(true);
    }
  };

  // Dialog's footer component
  const dialogFooter = (
    <Button label="OK" icon="pi pi-check" onClick={() => setIsDialogVisible(false)} />
  );

  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter((e.target as any).value)} placeholder="Search..." />
        </span>
        <Button label="Refresh Table" icon="pi pi-refresh" onClick={fetchCustomers} style={{ margin: '0 0.5em' }} />
        <Button label="Set as Active" className="p-button-success" onClick={() => setActiveStatus(true)} style={{ marginLeft: 'auto', marginRight: '0.5em' }} />
        <Button label="Set as Inactive" className="p-button-warning" onClick={() => setActiveStatus(false)} />
      </div>
    );
  };

  const header = useMemo(() => renderHeader(), [selectedRows]); // Recompute when selectedRows changes

  return (
    <div className="card">
      <span className='d-flex flex-row fs-2 fw-bold text-dark me-2 lh-1 ls-n2 mx-4 mt-4'>
        {active ? 'Active' : 'Inactive'} Customers
      </span>
      <div className="p-4">
        <DataTable
          value={products}
          globalFilter={globalFilter}
          header={header}
          scrollable
          scrollHeight="800px"
          style={{ minWidth: '50rem' }}
          paginator
          rows={12}
          rowsPerPageOptions={[10, 25, 1000]}
          stripedRows
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="customerID"
          selectionMode="checkbox" // Add this line for checkbox-based selection
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> // This is for the checkboxes
          {columns.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              sortable
              filter
              filterPlaceholder={`Search by ${col.header}`}
            />
          ))}
        </DataTable>
      </div>
      <Dialog header="Status Update" visible={isDialogVisible} style={{ width: '450px' }} footer={dialogFooter} onHide={() => setIsDialogVisible(false)}>
        {dialogContent}
      </Dialog>
    </div>
  );
}