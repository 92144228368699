import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlasses, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { Moment } from 'moment';
import axios from 'axios';
import {API_URL} from '../../../../../../../app/modules/auth/core/_requests'

interface totalUnitsSoldProps {
  percentageChange: number;
  vendor: string;
  isExpanded?: boolean;
  dateRange: {
    start: Moment;
    end: Moment;
  };
  rangeDisplay?: string;
}

const cardBodyStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  margin:'0 0 0 0',
  height: '100%',
  alignItems: 'center',
};

const bigNumberStyle: React.CSSProperties = {
  paddingTop: '1rem',
  paddingLeft: '0rem',
  fontSize: '4rem',
  lineHeight: '1',
  fontWeight: '500',
  color: '#fff',
};

const footerInnerContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const footerTitleStyle: React.CSSProperties = {
  color: '#fff',
  fontSize: '1.15rem',
  fontWeight: '700',
};

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: 'white',
  paddingTop: '1rem',
  paddingRight: '1rem',
};

const iconStyle: React.CSSProperties = {
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  fontSize: '2rem',
  color: 'white',
};


const innerContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

const growthContainerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return Math.round(num).toString();
  }
}

export const TotalUnitsSold: React.FC<totalUnitsSoldProps> = ({percentageChange, vendor, isExpanded = false, dateRange, rangeDisplay}) => {
  const [totalUnitsFromApi, setTotalUnitsFromApi] = useState(0);
  
  const formattedStartDate = dateRange.start.format('YYYY-MM-DD');
  const formattedEndDate = dateRange.end.format('YYYY-MM-DD');  

  const cardStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    color: '#fff',
    borderRadius: isExpanded ? '8px 8px 0 0' : '8px',
    border: isExpanded ? '0px' : '',

    background: 'linear-gradient(45deg,#6155D4,#5B97F2)',
  };

  const getTotalUnitsSold = (vendor: string): number => {
    if (vendor === 'Thelios') {
      return totalUnitsFromApi;
    }
  
    const vendorSales = {
      'Luxottica': 31881,
    }
  
    return vendorSales[vendor] || 0;
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/data/query-units-sold`, { 
          params: { startDate: formattedStartDate, endDate: formattedEndDate }
        });
        setTotalUnitsFromApi(response.data.totalQuantity);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [formattedStartDate, formattedEndDate]);

  const totalUnitsSold = getTotalUnitsSold(vendor);
  const formattedNumber = formatNumber(totalUnitsSold);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="card card-flush" 
        style={cardStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
      <FontAwesomeIcon icon={faGlasses} style={iconStyle} />
      <div style={{ position: 'absolute', top: '1.5rem', right: '1.5rem', width: '12rem', height: '6rem' }}>
      </div>
      <div className="card-body" style={cardBodyStyle}>
        <div style={innerContainerStyle}>
          <div style={growthContainerStyle}>
            <span className="text-dark" style={bigNumberStyle}>
              {isHovered ? totalUnitsSold.toLocaleString() : formattedNumber}
            </span>            
            {/* {percentageChange > 0 && (
              <>
                <FontAwesomeIcon icon={faArrowUp} style={arrowStyle} />
                <span style={percentageStyle}>{`${percentageChange}%`}</span>
              </>
            )}  */}
          </div>
        </div>
      </div>
      <div className="card-footer" style={footerStyle}>
        <div style={footerInnerContainerStyle}>
          <div style={footerTitleStyle}>Total Units Sold</div>
          {rangeDisplay}    
        </div>  
      </div>
    </div>
  );
};
