import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useWeek } from './WeekContext'; // Adjust the import path accordingly
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { API_URL } from '../../../../../../../app/modules/auth/core/_requests';

const columns = [
  { field: 'customerID', header: 'Customer ID' },
  { field: 'customerName', header: 'Customer Name' },
  { field: 'weekNumber', header: 'Week Number' },
  { field: 'vendorName', header: 'Vendor Name' },
  { field: 'wholesaleMargin', header: 'Margin' },
  { field: 'wholesaleDiscount', header: 'Discount' },
  { field: 'active', header: 'Allow Offers' },
  { field: 'orderStatus', header: 'Order Status' }
];

type Customer = {
  customerID: number;
  customerName: string;
  weekNumber: number;
  vendorName: string;
  wholesaleMargin: number;
  wholesaleDiscount: number;
  active: boolean;
  orderStatus: string;
};


export const CustomerControlCenter = () => {
  const { currentWeek } = useWeek();
  const currentYear = new Date().getFullYear();

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [editedCustomers, setEditedCustomers] = useState<Set<number>>(new Set()); // Track IDs of edited customers

  const orderStatusOptions = [
    { label: 'New', value: 'new' },
    { label: 'Open', value: 'open' },
    { label: 'Submitted', value: 'submitted' },
    { label: 'Ordered', value: 'ordered' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Archived', value: 'archived' }
  ];

  
  useEffect(() => {
    axios.get(`${API_URL}/offers/query-control-center?weekNumber=${currentWeek}&currentYear=${currentYear}`)
      .then(res => {
        setCustomers(res.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [currentWeek]);

  const onEditorValueChange = (props, value) => {
    const updatedCustomers = [...customers];
    const originalValue = updatedCustomers[props.rowIndex][props.field];
  
    if (originalValue !== value) {
      updatedCustomers[props.rowIndex][props.field] = value;
      setCustomers(updatedCustomers);
      setEditedCustomers(new Set(editedCustomers).add(updatedCustomers[props.rowIndex].customerID));
    }
  };
  
  const inputNumberEditor = (options) => {
    return (
      <InputNumber value={options.value} onValueChange={(e) => onEditorValueChange(options, e.value)}
        mode="decimal" min={0} max={100} showButtons buttonLayout="horizontal"
        decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" step={1} />
    );
  };

  const booleanEditor = (options) => {
    const booleanOptions = [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ];
    return (
      <Dropdown value={options.value} options={booleanOptions}
        onChange={(e) => onEditorValueChange(options, e.value)}
        optionLabel="label" placeholder="Select" />
    );
  };

  const orderStatusEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={orderStatusOptions}
        onChange={(e) => onEditorValueChange(options, e.value)}
        optionLabel="label"
        placeholder="Select Order Status"
        style={{ width: '100%' }}
      />
    );
  };

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const saveCustomerUpdates = async () => {
    try {
      const customersToUpdate = customers.filter(customer => editedCustomers.has(customer.customerID));
      
      for (const customer of customersToUpdate) {
        const response = await axios.patch(`${API_URL}/offers/update-customer`, customer);
        console.log(response.data);
      }
  
      setDialogMessage('Customer(s) updated successfully');
      setEditedCustomers(new Set()); // Clear the edited set post-update
    } catch (error) {
      console.error('Error updating customer:', error);
      let message = 'Internal Server Error';
      if (axios.isAxiosError(error)) {
        message = error.response?.data || 'Internal Server Error';
      }
      setDialogMessage(message);
    } finally {
      setIsDialogVisible(true);
    }
  };
  

  const renderFooter = () => {
    return (
      <div>
        <Button label="OK" onClick={() => setIsDialogVisible(false)} autoFocus />
      </div>
    );
  };

  return (
    <div
      className="card d-flex flex-column"
      style={{ overflow: 'auto' }}
    >
      <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2 mx-4 mt-4">Control Center</span>
      <div className="d-flex mx-4">Week {currentWeek}</div>
      {customers.length > 0 ? (
        <div className="m-4">
          <DataTable
            value={customers}
            scrollable scrollHeight="800px" style={{ minWidth: '50rem' }} stripedRows
            paginator rows={12} rowsPerPageOptions={[10, 25, 1000]}
            editMode='cell' resizableColumns columnResizeMode='expand'
          >
            {columns.map((col, index) => (
              (col.field === 'wholesaleMargin')
                ? <Column field="wholesaleMargin" header="Margin" editor={inputNumberEditor} sortable />
                : (col.field === 'wholesaleDiscount')
                  ? <Column field="wholesaleDiscount" header="Discount" editor={inputNumberEditor} sortable />
                  : (col.field === 'active')
                    ? <Column field="active" header="Allow Offers" body={rowData => rowData.active ? 'Yes' : 'No'} editor={booleanEditor} sortable />
                    : (col.field === 'orderStatus')
                      ? <Column field="orderStatus" header="Order Status" editor={orderStatusEditor} sortable />
                      : <Column key={index} field={col.field} header={col.header} sortable />
            ))}
          </DataTable>
        </div>
      ) : (
        <div className="m-4">No customers found for week {currentWeek}.</div>
      )}
      <div className="mx-4">
        <Button label="Save" icon="pi pi-check" onClick={saveCustomerUpdates} />
      </div>
      <Dialog header="Response" visible={isDialogVisible} style={{ width: '50vw' }} footer={renderFooter} onHide={() => setIsDialogVisible(false)}>
        {dialogMessage}
      </Dialog>
    </div>
  );
};
