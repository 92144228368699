import React, {useState, useEffect, useRef} from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Toast} from 'primereact/toast'
import 'primereact/resources/themes/arya-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import {API_URL} from '../../../../../../../../app/modules/auth/core/_requests'

interface DetailItem {
  label: string
  sales?: number
  refunds?: number
  amount?: number
  is_total?: boolean
  align?: string
  type?: string
}

interface Details {
  revenue: DetailItem[]
  fees: DetailItem[]
  costs: DetailItem[]
  company_fees: DetailItem[]
  expenses: DetailItem[]
  incomes: DetailItem[]
  ppc: DetailItem[]
  adjustments: DetailItem[]
  promotions: DetailItem[]
  profit: number
}

interface Product {
  asin: string
  product: {
    image: string
    title: string
  }
  quantity_sold: number
  quantity_refunded: number
  organic: number
  ppc_units: number
  promos: number
  replacements: number
  reimbursements: number
  revenue: number
  profit: number
  margin: number
  roi: number
  acos: number
}

interface DashboardTableProps {
  minDate: string
  maxDate: string
}

export const CostaDashboardTable: React.FC<DashboardTableProps> = ({minDate, maxDate}) => {
  const [data, setData] = useState<Product[]>([])
  const [expandedRows, setExpandedRows] = useState<Product[]>([])
  const [detailedData, setDetailedData] = useState<{[key: string]: Details}>({})
  const toast = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://de-dashboard-be.azurewebsites.net/asin-dashboard/search-asins',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({minDate, maxDate}),
          }
        )
        const result = await response.json()
        setData(result)
        setDetailedData({}) // Clear detailed data when date range changes
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    if (minDate && maxDate) {
      fetchData()
    }
  }, [minDate, maxDate])

  const fetchDetailedData = async (asin: string, minDate: string, maxDate: string) => {
    try {
      let url = ''
      if (asin === '__total__') {
        url = `${API_URL}/asin-dashboard/total-breakdown?dateMin=${minDate}&dateMax=${maxDate}`
      } else {
        url = `${API_URL}/asin-dashboard/asin-breakdown?asin=${asin}&dateMin=${minDate}&dateMax=${maxDate}`
      }
      const response = await fetch(url, {
        headers: {
          Accept: '*/*',
          'Accept-Language': 'en-US,en;q=0.9',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          'Sec-Fetch-Dest': 'empty',
          'Sec-Fetch-Mode': 'cors',
          'Sec-Fetch-Site': 'same-origin',
          'User-Agent':
            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36',
        },
      })
      const result = await response.json()
      setDetailedData((prevData) => ({...prevData, [asin]: result.data}))
    } catch (error) {
      console.error('Error fetching detailed data:', error)
    }
  }

  const onRowExpand = (e) => {
    const asin = e.data.asin
    setExpandedRows([...expandedRows, e.data])
    fetchDetailedData(asin, minDate, maxDate) // Always fetch detailed data when expanding a row
  }

  const onRowCollapse = (e) => {
    setExpandedRows(expandedRows.filter((row) => row.asin !== e.data.asin))
  }

  const formatNumber = (value: number) => {
    return isNaN(value) ? value : value.toFixed(2)
  }

  const formatDetails = (value: number) => {
    if (isNaN(value)) return value
    if (value === 0) return '-'
    const formattedValue = value.toLocaleString('en-US', {style: 'currency', currency: 'USD'})
    return value < 0 ? (
      <span style={{color: '#FF6961'}}>{formattedValue}</span>
    ) : (
      <span style={{color: '#77DD77'}}>{formattedValue}</span>
    )
  }

  const renderDetails = (details: Details) => {
    const tableStyle: React.CSSProperties = {
      width: '100%',
      borderCollapse: 'collapse' as 'collapse',
      marginBottom: '16px',
      backgroundColor: '#2b2b40', // Dark background for the table
      color: '#fff',
    }

    const thStyle: React.CSSProperties = {
      textAlign: 'left' as 'left',
      padding: '6px',
      backgroundColor: '#2b2b40',
      color: '#fff',
      fontSize: '14px', // Bigger text
      fontWeight: 'bold', // Bold text
    }

    const tdStyle: React.CSSProperties = {
      padding: '6px 4px',
      borderBottom: '1px solid #666',
      color: '#fff',
      fontSize: '13px', // Bigger text
    }

    const salesStyle: React.CSSProperties = {
      color: 'lightgreen',
    }

    const refundsStyle: React.CSSProperties = {
      color: 'lightcoral',
    }

    const fixLabel = (label: string) => {
      switch (label) {
        case 'labels.principal':
          return 'Principal'
        case 'labels.giftwrap_fba':
          return 'Giftwrap FBA'
        case 'labels.giftwrap_tax':
          return 'Giftwrap Tax'
        case 'labels.promo':
          return 'Promo'
        case 'labels.tax_payable':
          return 'Tax Payable'
        case 'labels.promo_tax':
          return 'Promo Tax'
        case 'labels.tax':
          return 'VAT/Sales tax'
        case 'labels.shipping_fba':
          return 'Shipping FBA'
        case 'labels.facilitator_tax':
          return 'Marketplace facilitator tax'
        case 'cost_manufacturing':
          return 'Manufacturing'
        default:
          return label
      }
    }

    const renderSection = (section: string, items: DetailItem[]) => {
      const filteredItems = items.filter(
        (item) =>
          item.label !== 'labels.shipping_tax' &&
          item.label !== 'Shipping Chargeback' &&
          item.label !== 'cost_inspection' &&
          item.label !== 'cost_shipping' &&
          item.label !== 'cost_local_shipping' &&
          item.label !== 'cost_import_taxes' &&
          item.label !== 'cost_other' &&
          item.label !== 'labels.promo_tax' &&
          item.label !== 'labels.promo' &&
          item.label !== 'cost_other_fbm'
        // add here to filter out value
      )

      return (
        <React.Fragment key={section}>
          <thead>
            <tr>
              <th colSpan={4} style={{...thStyle, backgroundColor: '#555'}}>
                {section}
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((item, index) => (
              <tr key={index}>
                <td style={{...tdStyle, fontWeight: 'bold', width: '140px'}}>
                  {fixLabel(item.label)}
                </td>
                <td style={{...tdStyle, ...salesStyle, whiteSpace: 'nowrap'}}>
                  {formatDetails(item.sales ?? item.amount ?? 0)}
                </td>
                <td style={{...tdStyle, ...refundsStyle, whiteSpace: 'nowrap'}}>
                  {formatDetails(item.refunds ?? 0)}
                </td>
                <td style={{...tdStyle, whiteSpace: 'nowrap'}}>
                  {formatDetails((item.sales ?? 0) + (item.refunds ?? 0))}
                </td>
              </tr>
            ))}
          </tbody>
        </React.Fragment>
      )
    }

    return (
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}></th>
            <th style={thStyle}>Orders</th>
            <th style={thStyle}>Refunds</th>
            <th style={thStyle}>Total</th>
          </tr>
        </thead>
        {renderSection('Revenue', details.revenue)}
        {renderSection('Commissions and fees', details.fees)}
        {renderSection('Adjustments', details.adjustments)}
      </table>
    )
  }

  const rowExpansionTemplate = (data) => {
    const details = detailedData[data.asin]
    if (!details) {
      return <div>Loading...</div>
    }
    return (
      <div
        className='p-3'
        style={{
          maxWidth: '30%',
          margin: '0 25% 0 0',
          backgroundColor: '#2b2b40',
          background: '#2b2b40',
          color: '#fff',
        }}
      >
        <h5 style={{color: 'white'}}>Details for ASIN: {data.asin}</h5>
        {renderDetails(details)}
      </div>
    )
  }

  const imageBodyTemplate = (rowData: Product) => {
    return rowData.product && rowData.product.image ? (
      <img
        src={rowData.product.image}
        alt={rowData.product.title}
        width='100'
        style={{borderRadius: '12px'}}
      />
    ) : null
  }

  const formatCurrency = (value: number) => {
    return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'})
  }

  const formatPercentage = (value: number | boolean) => {
    if (value === false) {
      return '-'
    }
    return isNaN(Number(value)) ? value : `${value}%`
  }

  const organicPpcTemplate = (rowData: Product) => {
    const pastelColors = {
      organic: '#77DD77', // Light green
      ppc: '#FFB347', // Light orange
      refunds: '#FF6961', // Light red
      replacements: '#CBAACB', // Light purple
      reimbs: '#FFF', // Light pink
    }

    return (
      <div>
        {rowData.organic > 0 && (
          <div style={{color: pastelColors.organic}}>{rowData.organic} organic</div>
        )}
        {rowData.ppc_units > 0 && (
          <div style={{color: pastelColors.ppc}}>{rowData.ppc_units} ppc</div>
        )}
        {rowData.quantity_refunded > 0 && (
          <div style={{color: pastelColors.refunds, whiteSpace: 'nowrap'}}>
            {rowData.quantity_refunded} refunds
          </div>
        )}
        {rowData.replacements > 0 && (
          <div style={{color: pastelColors.replacements, whiteSpace: 'nowrap'}}>
            {rowData.replacements} replacements
          </div>
        )}
        {rowData.reimbursements > 0 && (
          <div style={{color: pastelColors.reimbs, whiteSpace: 'nowrap'}}>
            {rowData.reimbursements} reimbs
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      style={{
        height: '800px',
        overflow: 'auto',
        margin: '0 auto',
        backgroundColor: '#2b2b40',
        background: '#2b2b40',
        color: '#fff',
      }}
    >
      <Toast ref={toast} />
      <DataTable
        value={data}
        scrollable
        scrollHeight='800px'
        expandedRows={expandedRows}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        sortField='revenue'
        sortOrder={-1}
        style={{backgroundColor: '#2b2b40', color: '#fff'}} // Table dark mode
      >
        <Column expander style={{width: '2em', backgroundColor: '#2b2b40', color: '#fff'}} />
        <Column
          field='product.image'
          header=''
          body={imageBodyTemplate}
          style={{backgroundColor: '#2b2b40', color: '#fff'}}
        ></Column>
        <Column
          field='asin'
          header='ASIN'
          sortable
          body={(rowData) => (rowData.asin === '__total__' ? 'TOTAL' : rowData.asin)}
          style={{backgroundColor: '#2b2b40', color: '#fff', fontSize: '14px', fontWeight: 'bold'}}
        />
        <Column
          field='quantity_sold'
          header='Units Sold'
          sortable
          style={{backgroundColor: '#2b2b40', color: '#fff', fontSize: '16px', fontWeight: 'bold'}}
        ></Column>
        <Column
          field='organicPpcTemplate'
          header='Stats'
          body={organicPpcTemplate}
          style={{backgroundColor: '#2b2b40', color: '#fff', fontSize: '12px', fontWeight: 'bold'}}
        ></Column>
        <Column
          field='revenue'
          header='Revenue'
          sortable
          body={(rowData) => formatCurrency(rowData.revenue)}
          style={{backgroundColor: '#2b2b40', color: '#fff', fontSize: '16px', fontWeight: 'bold'}}
        ></Column>
        <Column
          field='acos'
          header='ACOS (%)'
          sortable
          body={(rowData) => formatPercentage(rowData.acos)}
          style={{backgroundColor: '#2b2b40', color: '#fff', fontSize: '16px', fontWeight: 'bold'}}
        ></Column>
      </DataTable>
    </div>
  )
}
