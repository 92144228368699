import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlasses, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Moment } from 'moment';
import axios from 'axios';
import {API_URL} from '../../../../../../../../app/modules/auth/core/_requests'

interface totalUnitsSoldProps {
  percentageChange: number;
  vendor: string;
  isExpanded?: boolean;
  dateRange: {
    start: Moment;
    end: Moment;
  };
  rangeDisplay?: string;
}

const cardBodyStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  margin:'0 0 0 0',
  height: '100%',
  alignItems: 'center',
};

const bigNumberStyle: React.CSSProperties = {
  paddingTop: '1rem',
  paddingLeft: '0rem',
  fontSize: '4rem',
  lineHeight: '1',
  fontWeight: '500',
  color: '#fff',
};

const footerInnerContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const footerTitleStyle: React.CSSProperties = {
  color: '#fff',
  fontSize: '1.15rem',
  fontWeight: '700',
};

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: 'white',
  paddingTop: '1rem',
  paddingRight: '1rem',
};

const iconStyle: React.CSSProperties = {
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  fontSize: '2rem',
  color: 'white',
};

const arrowStyle: React.CSSProperties = {
  color: '#00FF00',
  marginLeft: '0.5rem',
  fontSize: '1.25rem',
};  

const percentageStyle: React.CSSProperties = {
  fontSize: '1.25rem',
  marginLeft: '0.5rem',
  color: '#00FF00',
};

const innerContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

const growthContainerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return Math.round(num).toString();
  }
}

export const TotalUnitsSold1: React.FC<totalUnitsSoldProps> = ({percentageChange, vendor, isExpanded = false, dateRange, rangeDisplay}) => {
  const [totalUnitsFromApi, setTotalUnitsFromApi] = useState(0);
  
  const formattedStartDate = dateRange.start.format('YYYY-MM-DD');
  const formattedEndDate = dateRange.end.format('YYYY-MM-DD');  

  const cardStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    color: '#fff',
    borderRadius: isExpanded ? '8px 8px 0 0' : '8px',
    border: isExpanded ? '0px' : '',

    background: 'linear-gradient(45deg,#6155D4,#5B97F2)',
  };

  const getTotalUnitsSold = (vendor: string): number => {
    if (vendor === 'Thelios') {
      return totalUnitsFromApi;
    }
  
    const vendorSales = {
      'Luxottica': 31881,
    }
  
    return vendorSales[vendor] || 0;
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/data/query-units-sold`, { 
          params: { startDate: formattedStartDate, endDate: formattedEndDate }
        });
        setTotalUnitsFromApi(response.data.totalQuantity);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [formattedStartDate, formattedEndDate]);

  const totalUnitsSold = getTotalUnitsSold(vendor);

  const formattedNumber = formatNumber(totalUnitsSold);
  
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const getChartData = (vendor) => {
    const dataForVendors = {
      'Thelios': [16, 28, 21, 37, 124, 199, 73, 58, 17, 38, 64, 62, 24, 337, 556, 30, 35, 57, 313, 251, 128, 63, 70, 63, 302, 324, 109, 406, 65, 28],
      'Luxottica': [211, 238, 166, 302, 454, 490, 376, 337, 210, 184, 585, 260, 176, 384, 505, 203, 202, 170, 944, 1173, 954, 920, 304, 188, 1937, 1141, 1025, 1512, 211, 135],
    };

    return {
      labels: [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 
        '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', 
        '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
      ],
      datasets: [
        {
          label: 'Total Units Sold',
          data: dataForVendors[vendor] || [], 
          fill: false,
          backgroundColor: 'white',
          borderColor: 'white',
          pointRadius: 0,
          tension: 0.4,
        },
      ],
    };
  };

  const chartData = getChartData(vendor);

  const chartOptions: any = {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        beginAtZero: false,
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        axis: 'x',
        intersect: false
      }
    },
    maintainAspectRatio: false
  };

  return (
    <div className="card card-flush" 
        style={cardStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
      <FontAwesomeIcon icon={faGlasses} style={iconStyle} />
      <div style={{ position: 'absolute', top: '1.5rem', right: '1.5rem', width: '12rem', height: '6rem' }}>
        <Line data={chartData} options={chartOptions} />
      </div>
      <div className="card-body" style={cardBodyStyle}>
        <div style={innerContainerStyle}>
          <div style={growthContainerStyle}>
            <span className="text-dark" style={bigNumberStyle}>
              {isHovered ? totalUnitsSold.toLocaleString() : formattedNumber}
            </span>            
            {percentageChange > 0 && (
              <>
                <FontAwesomeIcon icon={faArrowUp} style={arrowStyle} />
                <span style={percentageStyle}>{`${percentageChange}%`}</span>
              </>
            )} 
          </div>
        </div>
      </div>
      <div className="card-footer" style={footerStyle}>
        <div style={footerInnerContainerStyle}>
          <div style={footerTitleStyle}>Total Units Sold</div>
          {rangeDisplay}    
        </div>  
      </div>
    </div>
  );
};
