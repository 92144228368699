import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';

interface totalCustomersProps {
  percentageChange: number;
  vendor: string;
  isExpanded?: boolean;
}

function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
}

export const TotalCustomersEcom: React.FC<totalCustomersProps> = ({ percentageChange, vendor , isExpanded = false}) => {
  
  const getTotalCustomers = (vendor: string): number => {
    const vendorSales = {
      'Thelios': 1536,
      'Luxottica': 5531,
    }

    return vendorSales[vendor] || 0;
  }
  
  const totalCustomers = getTotalCustomers(vendor);

  const formattedNumber = formatNumber(totalCustomers);
  
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const cardStyle: React.CSSProperties = {
    height: '100%',  // Fixed height
    width: '100%',   // Fixed width
    overflow: 'hidden', // Hides any overflowing content
    position: 'relative',
    // background: 'linear-gradient(to top right, #111, #222)', // Adjust the colors to match your desired gradient
    color: '#fff',

    borderRadius: isExpanded ? '8px 8px 0 0' : '8px', // Top corners rounded when expanded
    border: isExpanded ? '0px' : '', // No border when expanded

    background: 'rgb(255, 99, 132)',
    // border: '1px solid white',
    // boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', // Optional: if you want to add shadow for elegance

    // Gradient style definition
    // background: 'linear-gradient(45deg, #01081F, #021530, #032242, #021530, #01081F)',
    // backgroundSize: '500% 500%',
    // animation: 'gradient 15s ease infinite',
  };
  
  const cardBodyStyle: React.CSSProperties = {
    display: 'flex', // Use Flexbox
    flexDirection: 'column', // Arrange items vertically
    justifyContent: 'flex-end', // Align items to the end (bottom) of the container
    margin:'0 0 0 0%',
    height: '100%', // Ensure the card body takes the full height of its container
    alignItems: 'center', // Center items horizontally
  };
  
  const bigNumberStyle: React.CSSProperties = {
    paddingTop: '1rem',
    paddingLeft: '0rem',
    fontSize: '4rem',
    lineHeight: '1',
    fontWeight: '500', // Bold for emphasis
    color: '#fff', // Ensure the number is white
  };

  const footerStyle: React.CSSProperties = {
    textAlign: 'center', // Center the text
    color: 'white', // Keep the original color for the rest of the footer
    paddingTop: '1rem', // Padding top
    paddingRight: '1rem', // Padding right
  };

  const footerTitleStyle: React.CSSProperties = {
    color: '#fff', // White color for the title
    fontSize: '1.15rem', // Larger font size for the title
    fontWeight: '700',
  };

  const footerInnerContainerStyle: React.CSSProperties = {
    display: 'flex', // Use flexbox
    justifyContent: 'space-between', // Spread out the contents
    alignItems: 'center', // Align items vertically
  };

  const iconStyle: React.CSSProperties = {
    position: 'absolute',
    top: '1rem', // Adjust as needed
    left: '1rem', // Position to the far left with some padding
    fontSize: '2rem', // Adjust as needed
    color: 'white', // Icon color
  };

  const arrowStyle: React.CSSProperties = {
    color: '#00FF00', // Bright green
    marginLeft: '0.5rem',
    fontSize: '1.25rem',
  };  

  const percentageStyle: React.CSSProperties = {
    fontSize: '1.25rem', // Adjust the font size to match your design
    marginLeft: '0.5rem', // Space between the arrow and the text
    color: '#00FF00', // Green color, matching the arrow
  };

  const innerContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between', // Spread out the content
    alignItems: 'center',
    width: '100%', // Take up the full width of the card body
  };
  
  const growthContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
  };

  const getChartData = (vendor) => {
    const dataForVendors = {
      'Thelios': [2085, 2116, 2239, 2248, 2267, 2319, 2324, 2410, 2514, 2537, 2542, 2574, 2579, 2508, 2518, 2440, 2476, 2323, 2205, 2346, 2574, 2678, 2533, 2949, 2865, 2969, 2975, 3020, 3023, 3065], // Sample data
      'Luxottica': [211, 238, 166, 302, 454, 490, 376, 337, 210, 184, 585, 260, 176, 384, 505, 203, 202, 170, 944, 873, 954, 920, 304, 188, 837, 941, 1025, 912, 211, 135],
    };    
    
    return {
      labels: [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 
        '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', 
        '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', 
        '31'
      ], // Dummy labels
      datasets: [
        {
          label: 'Total Customers',
          data: dataForVendors[vendor] || [],
          fill: false,
          backgroundColor: 'white',
          borderColor: 'white',
          pointRadius: 0,
          tension: 0.4,
        },
      ],
    }
  };
  
  // Use the getChartData function to set the chart data
  const chartData = getChartData(vendor);

  const chartOptions = {
    scales: {
      x: {
        display: false, // Hides X axis labels
      },
      y: {
        display: false, // Hides Y axis labels
        beginAtZero: false,
      }
    },
    plugins: {
      legend: {
        display: false, // Hides the legend
      }
    },
    maintainAspectRatio: false
  };  

  return (
    <div className="card card-flush" 
          style={cardStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
      {/* Inline style tag for keyframes */}
      <FontAwesomeIcon icon={faUser} style={iconStyle} />
      <div style={{ position: 'absolute', top: '1.5rem', right: '1.5rem', width: '12rem', height: '6rem' }}>
        <Line data={chartData} options={chartOptions} />
      </div> 
      <div className="card-body" style={cardBodyStyle}>
        <div style={innerContainerStyle}>
          <div style={growthContainerStyle}>
            <span className="text-dark" style={bigNumberStyle}>
              {isHovered ? totalCustomers : formattedNumber}
            </span>
            {percentageChange > 0 && (
              <>
                <FontAwesomeIcon icon={faArrowUp} style={arrowStyle} />
                <span style={percentageStyle}>{`${percentageChange}%`}</span>
              </>
            )} 
          </div>
        </div>
      </div>
      <div className="card-footer" style={footerStyle}>
        <div style={footerInnerContainerStyle}>
          <div style={footerTitleStyle}>Total Customers</div>
          Dec 2023
        </div>  
      </div>
    </div>
  );
};
