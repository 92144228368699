interface LowItemProps {
  className?: string;
  vendor: string;
  lowItem: InventoryTableItems;
  productName?: string;
  SKU?: string;
}

type InventoryTableItems = {
  UPC: string;
  "Brand Name": string;
  Description: string;
  Store_Inventory: number;
  SalesQuantity: number;
  productName: string;
  sku: string;
};

export const LowPerformingItem: React.FC<LowItemProps> = ({ className, vendor, lowItem }) => {
  const vendorData = {
    Thelios: {
      imgSrc: '/media/glasses/thelios/192337113994.jpg', 
      brand: lowItem['Brand Name'],
      itemName: lowItem.Description, 
      sales: lowItem.SalesQuantity,
      upc: lowItem.UPC,
    },
    Luxottica: {
      imgSrc: '/media/glasses/luxottica/313371.jpg',
      itemName: 'DOLCE & GABBANA - 0DG4415 502/13 56',
      sales: 'Recent 30-Day Sales: 0',
    },
    Costa: {
      imgSrc: '/media/glasses/costa/lowSellingGlasses.png',
      itemName: lowItem.productName, 
      sales: lowItem.SalesQuantity || 0, 
      upc: lowItem.sku,
    }
  };
  
  const currentVendorData = vendorData[vendor];

  const cardStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
  };

  const contentStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%', // Subtract the height of the title
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const titleStyle: React.CSSProperties = {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.75rem',
    width: '100%',
    textAlign: 'center',
    margin: '0.6rem 0 2.5rem 0',
  };

  const imageContainerStyle: React.CSSProperties = {
    width: '55%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imageStyle: React.CSSProperties = {
    maxWidth: '100%',
    maxHeight: '14rem',
    marginBottom: '3rem',
    objectFit: 'contain',
    borderRadius: '0.5rem',
    boxShadow: '0 4em 3em hsla(0,0%,0%,.4)',
  };

  const infoStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between', 
    height: '100%', 
    width: '100%',
    padding: '0 1rem 1rem 1rem',
  };

  const itemDetailStyle: React.CSSProperties = {
    fontSize: '1.15rem',
    alignSelf: 'flex-end',
  };

  const salesStyle: React.CSSProperties = {
    fontSize: '1.25rem',
    alignSelf: 'flex-end',
  };

  const bigNumberStyle: React.CSSProperties = {
    marginLeft: '50%',
    paddingTop: '1rem',
    fontSize: '3rem',
    lineHeight: '1',
    fontWeight: '500', // Bold for emphasis
    color: '#fff', // Ensure the number is white
  };
  

  return (
    <div className={`card ${className}`} style={{...cardStyle, minHeight: '18rem'}}>
      <div style={contentStyle}>
        <div style={titleStyle}>Low Performing Style</div>
        <div style={imageContainerStyle}>
          <img
            src={currentVendorData.imgSrc}
            alt='Low Selling Styles'
            style={imageStyle}
          />
        </div>
        
        <div style={infoStyle}>
          <div className={'item-info'} style={itemDetailStyle}>
            <div>{currentVendorData.brand}</div>
            <div>{currentVendorData.itemName}</div>
            <div>{currentVendorData.upc}</div>
          </div>
          <div style={salesStyle}>
            <div style={bigNumberStyle}>{currentVendorData.sales}</div>
            <div style={{  
              color: '#fff',
              fontSize: '1.15rem',
              fontWeight: '700',}}>
              60-Day Sales
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

