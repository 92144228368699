import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {useAuth} from '../../../../../../../app/modules/auth'

interface GeoJsonObject {
  type: 'FeatureCollection';
  features: Array<{
    type: 'Feature';
    properties: {
      name: string;
      'Units sold': number;
    };
    geometry: {
      type: 'Polygon' | 'MultiPolygon'; // Adjust based on your data, could be other geometry types
      coordinates: number[][][] | number[][][][]; // For Polygon or MultiPolygon
    };
  }>;
}

// Custom component to update map view
const UpdateMapView = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.flyTo(center, zoom);
  }, [center, zoom, map]);
  return null;
};

const onEachFeature = (feature, layer) => {
  console.log("Feature", feature); // Add this line to log the feature
  if (feature.properties && feature.properties.Country) {
    layer.bindTooltip(feature.properties.Country + ': ' + feature.properties.UnitsSold + ' units');
  }
};

export const ConsumerMetricsMap = ({ selectedCountry, vendor }) => {
  const [geoJsonData, setGeoJsonData] = useState<GeoJsonObject | null>(null);
  const [mapCenter, setMapCenter] = useState<[number, number]>([20, 0]); // Default center
  const [mapZoom, setMapZoom] = useState<number>(2); // Default zoom
  const {currentUser} = useAuth()


  // Define a function to fetch GeoJSON data based on the selected country
  const fetchGeoJsonData = async (jsonFile: string) => {
    const response = await fetch(jsonFile);
    const data = await response.json();
    setGeoJsonData(data);
  };

  // Inline styles for the legend
  const legendStyle = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '25%',
    overflow: 'auto',
  };

  const legendItemStyle = {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  };

  const legendColorStyle = (backgroundColor) => ({
    width: '20px',
    height: '20px',
    display: 'inline-block',
    marginRight: '5px',
    backgroundColor,
  });

  const legendValueStyle = {
    display: 'inline-block',
  };

  // Legend items array
  const legendItems = [
    { color: '#00441b', value: '> 1000' },
    { color: '#006d2c', value: '> 500' },
    { color: '#238b45', value: '> 200' },
    { color: '#41ae76', value: '> 100' },
    { color: '#66c2a4', value: '> 10' },
    { color: '#99d8c9', value: '> 1' },
  ];

  
  // Effect to handle fetching of GeoJSON data and updating the map view
  useEffect(() => {
    let jsonFile = '/worldwideconsumers.json'; // Default to worldwide
    
    if (vendor === 'Costa') {
      jsonFile = '/costaworldwideconsumers.json';
    } 

    let newMapCenter: [number, number] = [20, 0]; // Default center
    let newMapZoom = 2; // Default zoom

    if (selectedCountry === 'USA' && (vendor === 'Luxottica' || vendor === 'Thelios')) {
      jsonFile = '/usaconsumers.json';
      newMapCenter = [37.0902, -95.7129]; // Coordinates for the USA
      newMapZoom = 4; // Zoom level for the USA
    } 
    else if (selectedCountry === 'USA' && vendor === 'Costa') {
      jsonFile = '/costausaconsumers.json';
      newMapCenter = [37.0902, -95.7129]; // Coordinates for the USA
      newMapZoom = 4; // Zoom level for the USA
    }
    else if (selectedCountry === 'Europe' && (vendor === 'Luxottica' || vendor === 'Thelios')) {
      jsonFile = '/europeconsumers.json';
      newMapCenter = [54.5260, 15.2551]; // Coordinates for Europe
      newMapZoom = 4; // Zoom level for Europe
    }
    else if (selectedCountry === 'Europe' && vendor === 'Costa') {
      jsonFile = '/costaeuropeconsumers.json';
      newMapCenter = [54.5260, 15.2551]; // Coordinates for Europe
      newMapZoom = 4; // Zoom level for Europe
    }
    
    fetchGeoJsonData(jsonFile);
    setMapCenter(newMapCenter);
    setMapZoom(newMapZoom);
  }, [selectedCountry]);

  // Function to determine the fill color based on units sold
  const getColor = (unitsSold) => {
    return unitsSold > 1000 ? '#00441b' : // Dark Green
          unitsSold > 500  ? '#006d2c' : // Medium Dark Green
          unitsSold > 200  ? '#238b45' : // Medium Green
          unitsSold > 100  ? '#41ae76' : // Light Green
          unitsSold > 10   ? '#66c2a4' : // Lighter Green
          unitsSold > 1   ? '#99d8c9' : // Even Lighter Green
          // unitsSold > 10   ? '#ccece6' : // Very Light Green
                              '#99d8c9';   // Almost White Green
  };

  // Define the style for the GeoJSON layer based on units sold
  const geoJsonStyle = (feature) => ({
    fillColor: getColor(feature.properties['UnitsSold']),
    weight: 1,
    opacity: 1,
    color: 'white', // Border color of the features
    dashArray: '3',
    fillOpacity: 0.7
  });

  return (
    <div className="card card-flush">
      <div className="card-header">
        {/* Legend with inline styles */}
        <div style={legendStyle}>
          {legendItems.map((item, index) => (
            <div key={index} style={legendItemStyle}>
              <div style={legendColorStyle(item.color)}></div>
              <span style={legendValueStyle}>{item.value}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="card-body">
        <MapContainer 
          center={mapCenter} 
          zoom={mapZoom} 
          style={{ height: '500px', width: '100%' }}
        >
          <UpdateMapView center={mapCenter} zoom={mapZoom} />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {geoJsonData && (
            <GeoJSON 
              data={geoJsonData} 
              style={geoJsonStyle} 
              onEachFeature={onEachFeature} // Add this line
            />          
          )}
        </MapContainer>
      </div>
      <div className="card-footer">
      </div>
    </div>
  );
};