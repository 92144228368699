import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlasses, faSortUp, faSortDown, faArrowUp, faArrowDown, faMoneyBillTransfer, faS } from '@fortawesome/free-solid-svg-icons';
import React, { CSSProperties, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';

interface CostaConsumerMetricsTableProps {
  vendor: string;
  isExpanded?: boolean;
  selectedCountry: string;
}

const worldwideData = [
  { Country: "United States", UnitsSold: 3863, GrossRevenue: 255108.47 },
  { Country: "Mexico", UnitsSold: 35, GrossRevenue: 35555.76 },
  { Country: "Colombia", UnitsSold: 50, GrossRevenue: 3144.56 },
  { Country: "Canada", UnitsSold: 23, GrossRevenue: 1238.97 },
  { Country: "Peru", UnitsSold: 12, GrossRevenue: 900.12 },
  { Country: "Philippines", UnitsSold: 14, GrossRevenue: 783.73 },
  { Country: "Taiwan", UnitsSold: 4, GrossRevenue: 367.90 },
  { Country: "South Korea", UnitsSold: 4, GrossRevenue: 367.67 },
  { Country: "New Zealand", UnitsSold: 2, GrossRevenue: 309.57 },
  { Country: "Hong Kong", UnitsSold: 3, GrossRevenue: 182.43 },
  { Country: "United Kingdom", UnitsSold: 3, GrossRevenue: 145.97 },
  { Country: "Estonia", UnitsSold: 1, GrossRevenue: 139.85 },
  { Country: "Nigera", UnitsSold: 1, GrossRevenue: 135.12 },
  { Country: "Ireland", UnitsSold: 2, GrossRevenue: 129.19 },
  { Country: "Guam", UnitsSold: 1, GrossRevenue: 116.98},
  { Country: "Saudi Arabia", UnitsSold: 1, GrossRevenue: 102.99 },
  { Country: "Australia", UnitsSold: 1, GrossRevenue: 94.54 },
  { Country: "United Arab Emirates", UnitsSold: 2, GrossRevenue: 93.47 },
  { Country: "South Africa", UnitsSold: 2, GrossRevenue: 89.80 },
  { Country: "Luxembourg", UnitsSold: 1, GrossRevenue: 77.37 },
  { Country: "Czech Republic", UnitsSold: 1, GrossRevenue: 71.08 },
  { Country: "Israel", UnitsSold: 2, GrossRevenue: 70.80 },
  { Country: "Spain", UnitsSold: 1, GrossRevenue: 66.56 },
  { Country: "Indonesia", UnitsSold: 1, GrossRevenue: 64.21 },
  { Country: "Puerto Rico", UnitsSold: 1, GrossRevenue: 52.35 },
  { Country: "Jordan", UnitsSold: 1, GrossRevenue: 48.28 },
  { Country: "Kuwait", UnitsSold: 1, GrossRevenue: 26.08 },
  { Country: "Chile", UnitsSold: 1, GrossRevenue: 24.75 },
];

const unitedStatesData = [
  { Country: "California", UnitsSold: 537, GrossRevenue: 37286.15 },
  { Country: "Florida", UnitsSold: 527, GrossRevenue: 35927.86 },
  { Country: "Texas", UnitsSold: 321, GrossRevenue: 20926.38 },
  { Country: "New York", UnitsSold: 289, GrossRevenue: 20229.67 },
  { Country: "New Jersey", UnitsSold: 164, GrossRevenue: 11527.56 },
  { Country: "Illinois", UnitsSold: 127, GrossRevenue: 8592.75 },
  { Country: "Georgia", UnitsSold: 116, GrossRevenue: 7655.56 },
  { Country: "Pennsylvania", UnitsSold: 120, GrossRevenue: 7585.27 },
  { Country: "North Carolina", UnitsSold: 108, GrossRevenue: 7232.20 },
  { Country: "Virginia", UnitsSold: 99, GrossRevenue: 7052.67 },
  { Country: "Arizona", UnitsSold: 96, GrossRevenue: 6706.66 },
  { Country: "Michigan", UnitsSold: 85, GrossRevenue: 6228.65 },
  { Country: "Maryland", UnitsSold: 97, GrossRevenue: 6211.49 },
  { Country: "Ohio", UnitsSold: 101, GrossRevenue: 6159.23 },
  { Country: "Massachusetts", UnitsSold: 93, GrossRevenue: 5900.14 },
  { Country: "Washington", UnitsSold: 83, GrossRevenue: 5399.05 },
  { Country: "Colorado", UnitsSold: 66, GrossRevenue: 4399.09 },
  { Country: "Indiana", UnitsSold: 56, GrossRevenue: 3278.74 },
  { Country: "Connecticut", UnitsSold: 48, GrossRevenue: 3162.68 },
  { Country: "Nevada", UnitsSold: 49, GrossRevenue: 3145.02 },
  { Country: "South Carolina", UnitsSold: 49, GrossRevenue: 3144.15 },
  { Country: "Wisconsin", UnitsSold: 55, GrossRevenue: 3117.20 },
  { Country: "Alabama", UnitsSold: 45, GrossRevenue: 2865.66 },
  { Country: "Louisiana", UnitsSold: 41, GrossRevenue: 2541.28 },
  { Country: "Tennessee", UnitsSold: 42, GrossRevenue: 2401.65 },
  { Country: "Missouri", UnitsSold: 39, GrossRevenue: 2318.21 },
  { Country: "Kentucky", UnitsSold: 36, GrossRevenue: 2150.54 },
  { Country: "Oklahoma", UnitsSold: 37, GrossRevenue: 2105.62 },
  { Country: "Minnesota", UnitsSold: 37, GrossRevenue: 1942.45 },
  { Country: "Oregon", UnitsSold: 32, GrossRevenue: 1681.28 },
  { Country: "Utah", UnitsSold: 25, GrossRevenue: 1533.58 },
  { Country: "Arkansas", UnitsSold: 27, GrossRevenue: 1519.91 },
  { Country: "New Mexico", UnitsSold: 21, GrossRevenue: 1465.48 },
  { Country: "Kansas", UnitsSold: 19, GrossRevenue: 1339.30 },
  { Country: "Delaware", UnitsSold: 22, GrossRevenue: 1183.88 },
  { Country: "Mississippi", UnitsSold: 21, GrossRevenue: 1156.70 },
  { Country: "Rhode Island", UnitsSold: 13, GrossRevenue: 1131.28 },
  { Country: "West Virginia", UnitsSold: 12, GrossRevenue: 749.62 },
  { Country: "New Hampshire", UnitsSold: 14, GrossRevenue: 740.43 },
  { Country: "Hawaii", UnitsSold: 12, GrossRevenue: 701.59 },
  { Country: "Iowa", UnitsSold: 11, GrossRevenue: 679.07 },
  { Country: "Idaho", UnitsSold: 8, GrossRevenue: 647.76 },
  { Country: "Maine", UnitsSold: 12, GrossRevenue: 542.17 },
  { Country: "Nebraska", UnitsSold: 11, GrossRevenue: 485.13 },
  { Country: "Puerto Rico", UnitsSold: 7, GrossRevenue: 358.57 },
  { Country: "Montana", UnitsSold: 4, GrossRevenue: 304.02 },
  { Country: "Puerto Rico", UnitsSold: 6, GrossRevenue: 281.85 },
  { Country: "Vermont", UnitsSold: 4, GrossRevenue: 246.80 },
  { Country: "District of Columbia", UnitsSold: 3, GrossRevenue: 216.12 },
  { Country: "Wyoming", UnitsSold: 4, GrossRevenue: 210.03 },
  { Country: "Armed Forces Pacific", UnitsSold: 3, GrossRevenue: 195.40 },
  { Country: "Armed Forces Europe", UnitsSold: 3, GrossRevenue: 191.06 },
  { Country: "Armed Forces Africa", UnitsSold: 1, GrossRevenue: 123.54 },
  { Country: "Alaska", UnitsSold: 2, GrossRevenue: 77.71 },
  { Country: "North Dakota", UnitsSold: 1, GrossRevenue: 65.07 },
  { Country: "South Dakota", UnitsSold: 1, GrossRevenue: 47.96 },
  { Country: "Virgin Islands", UnitsSold: 1, GrossRevenue: 39.58 },
];

const europeData = [
  { Country: "United Kingdom", UnitsSold: 3, GrossRevenue: 145.97 },
  { Country: "Estonia", UnitsSold: 1, GrossRevenue: 139.85 },
  { Country: "Ireland", UnitsSold: 2, GrossRevenue: 129.19 },
  { Country: "Luxembourg", UnitsSold: 1, GrossRevenue: 77.37 },
  { Country: "Czech Republic", UnitsSold: 1, GrossRevenue: 71.08 },
  { Country: "Spain", UnitsSold: 1, GrossRevenue: 66.56 },
];

const headerStyles: CSSProperties = {
  textAlign: 'left',
  padding: '12px 15px',
  borderBottom: '2px solid #e1e1e1', // Solid bottom border
  color: 'white', // Black text color
  fontWeight: 'bold', // Bold font weight
  fontSize: '1.25rem',
  cursor: 'pointer', // Change cursor to indicate clickable headers
};

// Define the table styles
const tableStyles: CSSProperties = {
  width: '100%',
  borderCollapse: 'collapse',
};

// Function to generate row style based on row index
const getRowStyle = (index: number): CSSProperties => {
  return {
    backgroundColor: index % 2 === 0 ? '#1A233F' : '#1F2844', // alternating colors for rows
    color: 'white', // text color
    fontSize: '1.1rem',
  };
};

// New style for aligning icons
const iconStyle: CSSProperties = {
  marginLeft: '5px', // Space between header text and icon
  // any other styling you might need for proper alignment
};

export const CostaConsumerMetricsTable: React.FC<CostaConsumerMetricsTableProps> = ({ vendor, isExpanded = false, selectedCountry }) => {
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const handleSort = (field: string) => {
    if (sortField === field) {
      // Toggle sort direction or remove sorting
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else if (sortDirection === 'desc') {
        setSortField(null); // Remove sorting
        setSortDirection('asc'); // Reset to default for next sort
      }
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Function to get sorted dataset
  const getSortedDataset = () => {
    const dataset = getDataset();
    if (!sortField) return dataset;

    return [...dataset].sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const renderSortIcon = (field: string) => {
    if (sortField !== field) return null;
    return (
      <FontAwesomeIcon 
        icon={sortDirection === 'asc' ? faSortDown : faSortUp} 
        style={iconStyle} 
      />
    );
  };
  
  // Function to select the correct dataset based on selectedCountry
  const getDataset = () => {
    switch (selectedCountry) {
      case 'Worldwide':
        return worldwideData;
      case 'USA':
        return unitedStatesData;
      case 'Europe':
        return europeData;
      default:
        return [];
    }
  };
  
  // Rest of your component
  return (
    <div className="card card-flush" style={{ maxHeight: '44.9rem', minHeight: '44.9rem' }}>
      <div style={{overflow: 'auto', padding:'0.5rem'}}>
      <table style={tableStyles}>
        <thead style={headerStyles}>
          <tr>
            <th onClick={() => handleSort('Country')}>Country/State {renderSortIcon('Country')}</th>
            <th onClick={() => handleSort('UnitsSold')}>Units Sold {renderSortIcon('UnitsSold')}</th>
            <th onClick={() => handleSort('GrossRevenue')}>Gross Revenue {renderSortIcon('GrossRevenue')}</th>
          </tr>
        </thead>
        <tbody>
          {getSortedDataset().map((data, index) => (
            <tr key={index} style={getRowStyle(index)}>
              <td>{data.Country}</td>
              <td>{data.UnitsSold}</td>
              <td>${data.GrossRevenue.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className="card-footer">
        {/* Footer content here */}
      </div>
    </div>
  );
};