// Import necessary libraries
import { Chart } from 'chart.js';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Moment } from 'moment';
import moment from 'moment';
import axios from 'axios';
import {API_URL} from '../../../../../../../app/modules/auth/core/_requests'

type Props = {
  className: string;
  vendor: string;
  dateRange: {
    start: Moment;
    end: Moment;
  };
  rangeDisplay?: string;
};

type SalesData = {
  date: string;
  quantity: number;
};

const cardStyle: React.CSSProperties = {
  minHeight: '30rem', 
  height: '100%', 
  width: '100%', 
  overflow: 'hidden', 
  position: 'relative', 
  color: '#fff', 
  borderRadius: '8px', 
  // background: 'linear-gradient(45deg, #01081F, #021530, #032242, #021530, #01081F)',
};

const buttonGroupStyle = {
  boxShadow: '0 2px 1px 2px rgba(0,0,0,0.3)',
  borderRadius: '5rem',
  background: '#1A233F',
};

const smallButtonStyle: React.CSSProperties = {
  padding: '0.25rem 0.5rem',
  fontSize: '1em',
  lineHeight: '1.5',
};

const aggregateSalesData = (data, aggregation) => {
  const groupedData = {};

  data.forEach(item => {
    let key;
    switch (aggregation) {
      case 'weekly':
        key = moment(item.date).startOf('week').format('YYYY-MM-DD');
        break;
      case 'monthly':
        key = moment(item.date).startOf('month').format('YYYY-MM');
        break;
      case 'yearly':
        key = moment(item.date).startOf('year').format('YYYY');
        break;
      default:
        key = item.date;
        break;
    }

    if (!groupedData[key]) {
      groupedData[key] = 0;
    }
    groupedData[key] += item.quantity;
  });

  return Object.keys(groupedData).map(key => {
    return { date: key, quantity: groupedData[key] };
  });
};


const TotalSalesLine: FC<Props> = ({ className, vendor, dateRange, rangeDisplay }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const [salesData, setSalesData] = useState<SalesData[]>([]);
  const delayed = useRef(false);
  const [aggregation, setAggregation] = useState('daily');

  useEffect(() => {
    const rangeInDays = dateRange.end.diff(dateRange.start, 'days');

    if (rangeInDays > 1300) {
      setAggregation('monthly');
    }
    else if (rangeInDays > 60) {
      setAggregation('weekly');
    } else {
      setAggregation('daily');
    }
  }, [dateRange]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/data/query-range-sales`, {
          params: {
            startDate: dateRange.start.format('YYYY-MM-DD'),
            endDate: dateRange.end.format('YYYY-MM-DD'),
            aggregation 
          }
        });
        setSalesData(response.data.salesData);
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };
  
    fetchData();
  }, [dateRange, aggregation]); 

  useEffect(() => {
    const aggregatedData = aggregateSalesData(salesData, aggregation);

    const allDates = aggregatedData.map(data => data.date);
    const uniqueDates = Array.from(new Set(allDates)).sort();

    const labels = uniqueDates.map(date => {
      if (aggregation === 'weekly') {
        return `Week of ${date}`;
      } else if (aggregation === 'monthly') {
        return `${moment(date).format('YYYY-MM')}`;
      } else if (aggregation === 'yearly') {
        return `${moment(date).format('YYYY')}`;
      }
      return date;
    });
  
    const quantities = aggregatedData.map(data => data.quantity);

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        const gradient = ctx.createLinearGradient(0, 0, 0, chartRef.current.height);
        gradient.addColorStop(0, 'rgba(54, 162, 235, 0.7)');
        gradient.addColorStop(1, 'rgba(54, 162, 235, 0.1)');

        if (chartInstance.current) {
          chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(chartRef.current, {
          type: 'line',
          data: {
            labels,
            datasets: [{
              label: 'Units Sold',
              data: quantities,
              backgroundColor: gradient,
              pointBackgroundColor: '#00c7d6',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              tension: 0,
              fill: 'start',
            }]
          },
          options: {
            animation: {
              onComplete: () => {
                delayed.current = true;
              },
              delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed.current) {
                  delay = context.dataIndex * 100 + context.datasetIndex * 50;
                }
                return delay;
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white'
                },
                grid: {
                  color: 'rgba(255, 255, 255, 0)'
                },
              },
              y: {
                ticks: {
                  color: 'white'
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.08)',
                },
                beginAtZero: true
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                mode: 'index',
                axis: 'x',
                intersect: false,
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                titleFont: {
                  size: 16,
                },
                bodyFont: {
                  size: 14, 
                },
                padding: {
                  top: 10,
                  right: 15,
                  bottom: 10,
                  left: 10
                }
              }
            } 
          }
        });
      }
    }
  }, [salesData]);

  return (
    <div className={`card card-flush ${className}`} style={cardStyle}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>Total Sales Over Time</span>
          </div>
          <span className='pt-1 fw-semibold fs-6' style={{color: 'rgba(255, 255, 255, 0.7)'}}>{rangeDisplay}</span>  
        </div>
        <div>
          <div className="btn-group mb-5" role="group" aria-label="Aggregation selector" style={buttonGroupStyle}>
            {['Daily', 'Weekly', 'Monthly', 'Yearly'].map((agg) => (
              <button
                key={agg}
                type="button"
                className={`btn ${aggregation === agg.toLowerCase() ? 'btn-primary' : 'btn-outline-primary'}`}
                style={smallButtonStyle}
                onClick={() => setAggregation(agg.toLowerCase())}
              >
                {agg}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <canvas ref={chartRef} style={{ minWidth: '100%', maxHeight: '22rem' }} />
      </div>
    </div>
  );
};

export { TotalSalesLine };
