import {FC, useState, useMemo} from 'react'
import {PageTitle} from '../../../_designereyes/layout/core'
import {useAuth} from '../../../app/modules/auth'
import {
  InventoryMap,
  StoreInventoryTable,
  BrandFocus,
  BestPerformingItem,
  LowPerformingItem,
  StoreInventoryBar,
  StoreImage,
} from '../../../_designereyes/partials/widgets/'
import StoreManagerDashboard from './StoreManagerDashboard'
import {
  BestPerformingItem1,
  WorstPerformingItem,
  StoreInventoryBar1,
  StoreInventoryTable1,
} from '../../../_designereyes/partials/widgets/'
import {StoreCapacities} from '../../../_designereyes/partials/widgets/_new/cards/vendors/inventory/StoreCapacities'
import axios from 'axios'
import {API_URL} from '../../modules/auth/core/_requests'
import moment from 'moment'
import {useQuery} from 'react-query'
import {CostaInventoryInsights} from '../../../_designereyes/partials/widgets/_new/cards/vendors/inventory/costa/CostaInventoryInsights'

const storeDataThelios = {
  'All Stores': [900, 1600, 1150, 350],
  'R001 - POV Sawgrass': [88, 30, 50, 38],
  'R002 - Just One Look': [38, 94, 70, 24],
  'R003 - Eyes on Lincoln': [72, 22, 42, 90],
  'R004 - PV2 Dolphin': [100, 34, 42, 30],
  'R005 - DE Mall of San Juan': [68, 78, 24, 30],
  'R006 - DE Brickell': [62, 41, 100, 34],
  'R008 - DE Florida Mall': [33, 42, 31, 68],
  'R009 - Aventura': [72, 36, 42, 86],
  'R010 - PV3 iDrive': [62, 66, 42, 86],
  'R011 - PV4 Vineland': [62, 66, 42, 86],
  'R014 - DE Plaza Las Americas': [62, 66, 42, 86],
}

const storeDataLuxottica = {
  'All Stores': [584, 361, 270, 298, 183, 302, 151, 140, 164, 169, 140, 88],
  'R001 - POV Sawgrass': [88, 30, 50, 38, 83, 102, 61, 38, 94, 70, 24, 38],
  'R002 - Just One Look': [38, 94, 70, 24, 83, 102, 61, 38, 94, 70, 24, 38],
  'R003 - Eyes on Lincoln': [138, 60, 70, 24, 83, 102, 61, 38, 94, 70, 24, 38],
  'R004 - PV2 Dolphin': [100, 34, 42, 30, 83, 102, 61, 38, 94, 70, 24, 38],
  'R005 - DE Mall of San Juan': [48, 78, 24, 21, 33, 42, 31, 68, 78, 24, 30, 68],
  'R006 - DE Brickell': [172, 74, 42, 90, 88, 82, 41, 72, 22, 42, 90, 72],
  'R008 - DE Florida Mall': [110, 34, 82, 30, 55, 62, 41, 100, 34, 42, 30, 100],
  'R009 - Aventura': [124, 69, 50, 38, 83, 102, 51, 30, 55, 62, 41, 88],
  'R010 - PV3 iDrive': [62, 66, 42, 86, 33, 12, 25, 62, 66, 42, 86, 62],
  'R011 - PV4 Vineland': [62, 66, 42, 86, 33, 12, 25, 62, 66, 42, 86, 62],
  'R014 - DE Plaza Las Americas': [72, 36, 42, 86, 33, 12, 25, 62, 66, 42, 86, 62],
}

const selectContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  height: 'auto',
}

const selectStyle: React.CSSProperties = {
  padding: '10px 20px',
  borderRadius: '5px',
  border: '1px solid #ccc',
  fontSize: '16px',
  color: '#333',
  backgroundColor: '#fff',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  position: 'sticky',
  zIndex: '101',
  top: '1.5%',
  marginBottom: '10px',
}

const InventoryInsightsPage: FC = () => {
  const {currentUser} = useAuth()

  const storeData =
    currentUser?.decoded?.vendorName === 'Thelios' ? storeDataThelios : storeDataLuxottica

  const [selectedStore, setSelectedStore] = useState(Object.keys(storeData)[0])

  const fetchInventoryData = async (selectedStore: string) => {
    if (currentUser?.decoded?.vendorName === 'Luxottica') {
      return []
    }
    const response = await axios.get(
      `${API_URL}/inventory/brand-inventory-table?storeSelection=${selectedStore}`
    )
    return response.data.inventoryData.map((item) => ({
      ...item,
      status: item.Store_Inventory > 0 ? 'In Stock' : 'Out of Stock',
    }))
  }

  const {
    data: inventoryData,
    isLoading,
    error,
  } = useQuery(['inventoryData', selectedStore], () => fetchInventoryData(selectedStore), {
    enabled: !!selectedStore,
  })

  const bestItem = useMemo(
    () =>
      inventoryData?.reduce(
        (acc, item) => (!acc || item.SalesQuantity > acc.SalesQuantity ? item : acc),
        null
      ),
    [inventoryData]
  )
  const lowItem = useMemo(() => {
    return inventoryData?.reduce((acc, item) => {
      if (item.SalesQuantity >= 0) {
        return !acc || (item.SalesQuantity < acc.SalesQuantity && item.SalesQuantity >= 0)
          ? item
          : acc
      }
      return acc
    }, null)
  }, [inventoryData])

  const handleStoreSelect = (event) => {
    event.preventDefault()
    setSelectedStore(event.target.value)
  }

  if (isLoading) return <div style={{height: '2000px'}}>Loading...</div>
  if (error instanceof Error) return <div>An error occurred: {error.message}</div>

  const handleDownloadCSV = () => {
    const todayDate = moment().format('YYYY-MM-DD')

    const filename = `retail_sales_report_${todayDate}.csv`

    axios
      .get(`${API_URL}/data/download-inventory-data`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => console.error('Error downloading file:', error))
  }

  if (currentUser?.decoded?.role === 'Store Manager') {
    return <StoreManagerDashboard />
  }

  return (
    <>
      {/* begin Thelios Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Thelios' && (
        <>
          <select style={selectStyle} onChange={handleStoreSelect} value={selectedStore}>
            {Object.keys(storeData).map((store) => (
              <option key={store} value={store}>
                {store}
              </option>
            ))}
          </select>

          <button
            onClick={handleDownloadCSV}
            className={'btn btn-primary btn-hover'}
            style={{marginLeft: '1%', fontSize: '1rem', padding: '4px 6px'}}
          >
            Download CSV Report
          </button>

          <div className='row g-5 g-xl-10'>
            <div className='col-md-12 col-lg-12 mb-5 mb-xl-10'>
              <StoreImage className='h-md-50 h-lg-100' title={selectedStore} />
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-6 col-lg-6'>
              <StoreInventoryBar
                className='h-md-50 h-lg-100'
                selectedStore={selectedStore}
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
            <div className='col-md-6 col-lg-6'>
              <StoreCapacities
                className='h-md-50 h-lg-100'
                data={storeData[selectedStore]}
                selectedStore={selectedStore}
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-6 col-lg-6'>
              <BrandFocus className=' h-lg-100' vendor={currentUser?.decoded?.vendorName} />
            </div>
            <div className='col-md-3 col-lg-3'>
              {bestItem ? (
                <BestPerformingItem
                  className=' h-lg-100'
                  vendor={currentUser?.decoded?.vendorName}
                  bestItem={bestItem}
                />
              ) : (
                <div>No best selling item data available</div>
              )}
            </div>
            <div className='col-md-3 col-lg-3'>
              {lowItem ? (
                <LowPerformingItem
                  className=' h-lg-100'
                  vendor={currentUser?.decoded?.vendorName}
                  lowItem={lowItem}
                />
              ) : (
                <div>No low selling item data available</div>
              )}
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-12 col-lg-12 mb-md-5 mb-xl-10'>
              <StoreInventoryTable
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                selectedStore={selectedStore}
                vendor={currentUser?.decoded?.vendorName}
                inventoryTableItems={inventoryData}
              />
            </div>
          </div>
        </>
      )}
      {/* end Thelios Vendor::Row */}

      {/* begin Luxottica Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Luxottica' && (
        <>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div style={selectContainerStyle}>
              <select style={selectStyle} onChange={handleStoreSelect}>
                {Object.keys(storeData).map((store) => (
                  <option key={store} value={store}>
                    {store}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 col-lg-12 mb-5 mb-xl-5'>
              <div className='well'>
                <StoreImage title={selectedStore} />
              </div>
            </div>
          </div>

          <div className='row g-5 g-xl-10'>
            <div className='col-md-8 col-lg-8 mb-md-5 mb-xl-10'>
              <StoreInventoryBar1
                className='h-md-50 mb-5 mb-xl-5 h-lg-100'
                data={storeData[selectedStore]}
                title={selectedStore}
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
            <div className='col-md-4 col-lg-4 mb-md-5 mb-xl-10'>
              <div className='row'>
                <div className='col-md-12 col-lg-12 mb-20'>
                  <div className='well'>
                    <BestPerformingItem1
                      className='h-md-50 h-lg-100'
                      vendor={currentUser?.decoded?.vendorName}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 col-lg-12 mt-5'>
                  <div className='well'>
                    <WorstPerformingItem
                      className='h-md-50 h-lg-100'
                      vendor={currentUser?.decoded?.vendorName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-12 col-lg-12 mb-md-5 mb-xl-10'>
              <StoreInventoryTable1
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                title={selectedStore}
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-12 col-lg-12 mb-md-5 mb-xl-10'>
              <InventoryMap
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>
        </>
      )}
      {/* end Luxottica Vendor::Row */}
    </>
  )
}

const CostaInventoryInsightsPage: FC = () => {
  return <CostaInventoryInsights />
}

const InventoryInsightsWrapper: FC = () => {
  const {currentUser} = useAuth()

  return (
    <>
      <>
        {(currentUser?.decoded?.vendorName === 'Thelios' ||
          currentUser?.decoded?.vendorName === 'Luxottica') && (
          <>
            <PageTitle>Retail Inventory Insights</PageTitle>
            <InventoryInsightsPage />
          </>
        )}
        {currentUser?.decoded?.vendorName === 'Costa' && (
          <>
            <PageTitle>Costa Inventory Insights</PageTitle>
            <CostaInventoryInsightsPage />
          </>
        )}
      </>
    </>
  )
}

export {InventoryInsightsWrapper}
