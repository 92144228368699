// Import necessary libraries
import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';
import React, { FC, useEffect, useRef, useState } from 'react';

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale);

type Props = {
  className: string;
  vendor: string;
  // Additional props can be defined here
};

const cardStyle: React.CSSProperties = {
  height: '100%',  // Fixed height
  width: '100%',   // Fixed width
  overflow: 'hidden', // Hides any overflowing content
  position: 'relative',
  color: '#fff',
  borderRadius: '8px', // Optional: if you want rounded corners
  // border: '0px solid white',

  // Gradient style definition
  // background: 'linear-gradient(315deg, #01081F 0%, #021530 40%, #032242 90%, #021530 100%, #01081F 130%)',
};

export const UnitsSoldLineEcom: FC<Props> = ({ className, vendor }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const [timeFilter, setTimeFilter] = useState('months'); // 'days' or 'months'
  const delayed = useRef(false); // Using useRef to persist the value without causing re-renders

  const vendorData = {
    'Thelios': {
      months: [187, 437, 330, 398, 361, 666, 245, 454, 374, 589, 438, 547],
      days: [0, 302, 324, 109, 406, 65, 28]
    },
    'Luxottica': {
      months: [24826 / 2.5, 15399 / 2.5, 12959 / 2.5, 12493 / 2.5, 14348 / 2.5, 17198 / 2.5, 12974 / 2.5, 14258 / 2.5, 10643 / 2.5, 11369 / 2.5, 18310 / 2.5, 15897 / 2.5],
      days: [0, 1937 / 2.5, 1141 / 2.5, 1025 / 2.5, 1512 / 2.5, 211 / 2.5, 135 / 2.5]
    },
  }

  useEffect(() => {
    // Sample data, should be replaced with dynamic data
    const labels = timeFilter === 'months' ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] : ['12/25', '12/26', '12/27', '12/28', '12/29', '12/30', '12/31'];
    const currentVendorData = vendorData[vendor] ? vendorData[vendor][timeFilter] : [];

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        // Create gradient
        const gradient = ctx.createLinearGradient(0, 0, 0, chartRef.current.height);
        gradient.addColorStop(0, 'rgba(54, 162, 235, 0.7)'); // Top color
        gradient.addColorStop(1, 'rgba(54, 162, 235, 0.1)'); // Bottom color, fully transparent
  
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(chartRef.current, {
          type: 'line',
          data: {
            labels: labels,
            datasets: [{
              label: 'Units Sold',
              data: currentVendorData,
              backgroundColor: gradient,
              pointBackgroundColor: '#00c7d6',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              tension: 0, // Smooths the line
              fill: 'start',
            }]
          },
          options: {
            animation: {
              onComplete: () => {
                delayed.current = true; // Update the useRef value
              },
              delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed.current) {
                  delay = context.dataIndex * 100 + context.datasetIndex * 50;
                }
                return delay;
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white'
                },
                grid: {
                  color: 'rgba(255, 255, 255, 0)'
                },
              },
              y: {
                ticks: {
                  color: 'white'
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.08)',
                },
                beginAtZero: true
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                mode: 'index',
                axis: 'x',
                intersect: false,
                backgroundColor: 'rgba(0, 0, 0, 0.9)', // Optional: Tooltip background color
                titleFont: {
                  size: 16, // Increase title font size
                },
                bodyFont: {
                  size: 14, // Increase body font size
                },
                padding: {
                  top: 10,
                  right: 15,
                  bottom: 10,
                  left: 10
                }
              }
            } 
          }
        });
      }
    }
  }, [timeFilter]);

  return (
    <div className={`card card-flush ${className}`} style={cardStyle}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center justify-content-between'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2' style={{whiteSpace: 'nowrap'}}>Units Sold</span>
            <select className='form-select form-select-sm' style={{color: 'white', marginLeft:'2rem'}} onChange={(e) => setTimeFilter(e.target.value)}>
              <option value="months">Past 12 Months</option>
              <option value="days">Past 7 Days</option>
            </select>
          </div>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <canvas ref={chartRef} style={{ width: '100%', maxHeight: '200px' }} />
      </div>
    </div>
  )
}

