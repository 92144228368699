import React, { useState, useEffect } from 'react';

interface Order {
  id: number;
  total_price: string;
  // Include other relevant properties of an order
}

interface FetchError {
  message: string;
}


const POSSalesDataComponent = () => {
  const [salesData, setSalesData] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    const fetchSalesData = async () => {

      setIsLoading(true);
      try {
        const response = await fetch('http://localhost:4000/shopify/fetch-sales');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setSalesData(data.orders);
      } catch (error) {
        if (error instanceof Error) {
          console.error('There was a problem fetching sales data:', error);
          setError(error.message);
        }
      }
      setIsLoading(false);
    };

    fetchSalesData();
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Sales Data</h1>
      {salesData.map((order: Order, index: number) => (
        <div key={index}>
          Order ID: {order.id}, Total Price: {order.total_price}
        </div>
      ))}
    </div>
  );
};

export default POSSalesDataComponent;
