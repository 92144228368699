import React, { FC, useEffect, useRef, useState } from 'react';
import { Moment } from 'moment';
import axios from 'axios';
import moment from 'moment';
import {API_URL} from '../../../../../../../app/modules/auth/core/_requests'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';

type Props = {
  className: string;
  vendor: string;
  dateRange: {
    start: Moment;
    end: Moment;
  };
  rangeDisplay?: string;
};

type BrandSale = {
  brandName: string;
  totalQuantity: number;
};

const cardStyle: React.CSSProperties = {
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '8px',
};

const roundUpToNearest = (num, nearest) => {
  return Math.ceil(num / nearest) * nearest;
};

export const BrandSalesBar: FC<Props> = ({ className, vendor, dateRange, rangeDisplay }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const [brandSalesData, setBrandSalesData] = useState<BrandSale[]>([]);
  const delayed = useRef(false);

  const brandColorMapping: Record<string, string> = {
    'CELINE': 'rgba(255, 99, 132, 1)',
    'DIOR': 'rgba(54, 162, 235, 1)',
    'DIOR HOMME': 'rgba(75, 192, 192, 1)',
    'FENDI': 'rgba(255, 206, 86, 1)',
    'FRED': 'rgba(153, 102, 255, 1)',
    'GIVENCHY': 'rgba(255, 159, 64, 1)',
    'LOEWE': 'rgba(155, 225, 132, 1)',
  };

  useEffect(() => {
    const fetchBrandSalesData = async () => {
      try {
        const startDate = moment(dateRange.start).format('YYYY-MM-DD');
        const endDate = moment(dateRange.end).format('YYYY-MM-DD');
        const response = await axios.get(`${API_URL}/data/query-brand-sales?startDate=${startDate}&endDate=${endDate}`);
        
        const positiveBrandSalesData = response.data.filter((sale: BrandSale) => sale.totalQuantity >= 0);

        setBrandSalesData(positiveBrandSalesData);
      } catch (error) {
        console.error('Error fetching brand sales data:', error);
      }
    };

    fetchBrandSalesData();
  }, [dateRange]);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const chartLabels = brandSalesData.map(item => item.brandName);
      const chartData = brandSalesData.map(item => item.totalQuantity);
      const maxDataValue = Math.max(...chartData);
      const maxAxisValue =  roundUpToNearest(maxDataValue + (maxDataValue * 0.075), 5);
      const backgroundColors = chartLabels.map(label => brandColorMapping[label] || 'rgba(0, 0, 0, 1)');

      chartInstance.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: [{
            label: 'Unit Sales per brand',
            data: chartData,
            backgroundColor: backgroundColors,
            borderWidth: 0.2,
            barPercentage: 0.5, 
            categoryPercentage: 1
          }]
        },
        options: {
          indexAxis: 'y',
          animation: {
            onComplete: () => {
              delayed.current = true;
            },
            delay: (context) => {
              let delay = 0;
              if (context.type === 'data' && context.mode === 'default' && !delayed.current) {
                delay = context.dataIndex * 100 + context.datasetIndex * 150;
              }
              return delay;
            },
          },
          scales: {
            x: {
              beginAtZero: true,
              grid: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              ticks: {
                color: 'white'
              },
              max: maxAxisValue,
            },
            y: {
              beginAtZero: true,
              grid: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              ticks: {
                color: 'white'
              }
            },
          },
          plugins: {
            datalabels: {
              color: 'white',
              anchor: 'end',
              align: 'right',
              formatter: (value, context) => {
                return value.toLocaleString();
              },
            },
            legend: {
              labels: {
                color: 'white'
              },
              onClick: () => {}
            },
            tooltip: {
              mode: 'index',
              axis: 'y',
              intersect: false,
              backgroundColor: '0, 0, 0, 0.9',
              titleFont: {
                size: 16,
              },
              bodyFont: {
                size: 14,
              },
              padding: {
                top: 10,
                right: 15,
                bottom: 10,
                left: 10
              }
            }
          },
        },
        plugins: [ChartDataLabels],
      });
    }
  }, [brandSalesData]);

  return (
    <div className={`card card-flush ${className}`} style={cardStyle}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fw-bold text-dark me-2 lh-1 ls-n2' style={{ whiteSpace: 'nowrap', fontSize: '2rem' }}>Total Units Sold per Brand</span>
          </div>
          <span className='pt-1 fw-semibold fs-6' style={{color: 'rgba(255, 255, 255, 0.7)'}}>{rangeDisplay}</span>  
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <canvas ref={chartRef} style={{ maxWidth: '100%', maxHeight: '300px' }} />
      </div>
    </div>
  );
};
