/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_designereyes/helpers'
import {PageTitle} from '../../../_designereyes/layout/core'
import {AdminUserPull, AdminAnalyticsWidget1} from '../../../_designereyes/partials/widgets'
import {useAuth} from '../../modules/auth'
import {VendorProductsSold, TimeSeriesLine} from '../../../_designereyes/partials/widgets'
import StoreManagerDashboard from './StoreManagerDashboard'

import axios from 'axios'
import React, {useEffect, useState} from 'react'

const AIForecastingPage: FC = () => {
  const {currentUser} = useAuth()

  if (currentUser?.decoded?.role === 'Store Manager') {
    return <StoreManagerDashboard />
  }

  return (
    <>
      {/* begin Luxottica Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Luxottica' && (
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='col-md-12 col-lg-12'>
            <TimeSeriesLine
              className='h-md-50 mb-5 mb-xl-10 h-lg-100'
              vendor={currentUser?.decoded?.vendorName}
            />
          </div>
          {/* end::Col */}
        </div>
      )}
      {/* end Luxottica Vendor::Row */}

      {/* begin Thelios Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Thelios' && (
        <>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-12 col-lg-12'>
              <TimeSeriesLine
                className='h-md-50 mb-5 mb-xl-10 h-lg-100'
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>
        </>
      )}
      {/* end Thelios Vendor::Row */}

      {/* begin Admin::Row */}
      {currentUser?.decoded?.role === 'admin' && (
        <div className='row gy-5 g-xl-8'>
          <div className='col-xl-6'>
            <AdminUserPull className='card-xl-stretch mb-xl-8' />
          </div>
          <div className='col-xl-6'>
            <AdminAnalyticsWidget1 className='card-xl-stretch mb-xl-8' />
          </div>
        </div>
      )}
      {/* end Admin::Row */}
    </>
  )
}

const AIForecastingWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'MENU.AIFORECASTING'})}</PageTitle>
      <AIForecastingPage />
    </>
  )
}

export {AIForecastingWrapper}
