import React from 'react';
import { Bar } from 'react-chartjs-2';

interface TotalCustomersBarProps {
  data: { [key: string]: number };
  isExpanded?: boolean;
}

export const TotalCustomersBar = ({ data, isExpanded = false }: TotalCustomersBarProps) => {
  const chartData = {
    labels: Object.keys(data),
    datasets: [{
      label: 'Sales', // You can set the label for your dataset
      data: Object.values(data),
      backgroundColor: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#4BC0C0',
      ],
      borderColor: [
        // You can also define border colors if needed
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#4BC0C0',
      ],
      borderWidth: 1,
      barThickness: 20,
    }]
  };

  const chartOptions = {
    indexAxis: 'y' as const, // This will make the chart horizontal
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.05)',
        },
        ticks: {
          color: 'white' // X-axis labels in white
        },
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.05)',  
        },
        ticks: {
          color: 'white' // Y-axis labels in white
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  // Styles remain the same
  const cardStyle: React.CSSProperties = {
    background: 'rgb(26,35,63)',
    border: '5px solid rgb(253,119,90)',
    borderRadius: isExpanded ? '0 0 8px 8px' : '8px',
    color: 'white',
    margin: '0',
    padding: '1rem 1rem 0 1rem',
    height: '100%',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
  };

  const barContainerStyle: React.CSSProperties = {
    position: 'relative',
    height: '200px',
    width: '100%'
  };

  return (
    <div style={cardStyle}>
      <div style={barContainerStyle}>
        <Bar data={chartData} options={chartOptions}/>
      </div>
    </div>
  );
};