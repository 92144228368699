import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBox } from '@fortawesome/free-solid-svg-icons';

interface AOVProps {
  totalOrders: number;
  averageOrderValue: number;
}

export const AverageOrderValueCard: FC<AOVProps> = ({totalOrders, averageOrderValue}) => {
  return (
    <div className="card d-flex flex-column h-auto" style={{background: 'linear-gradient(45deg,#27A47C,#6FE594)'}}>
      <div className="h-auto rounded-top p-4">
        <header className="" style={{fontSize: '2.5rem'}}>
          <FontAwesomeIcon icon={faBox} />        
        </header>
        <div className="pt-6 pb-3 d-flex flex-column">
          <div className="fw-bolder" style={{fontSize: '4rem', lineHeight: '1'}}>
            {totalOrders.toLocaleString()}
          </div>
          <div className="pt-2 fw-bold" style={{fontSize: '1.15rem'}}>
            Total Orders
          </div>
        </div>
      </div>
      <div style={{ height: '1px', width: 'auto', margin:'0 50% 0 5%', background: 'rgba(255,255,255,0.5)' }}></div>
      <footer className="h-auto rounded-bottom pt-4 pb-8 px-4">
        <div className="d-flex flex-column">
          <div className="fs-1 fw-bold">${averageOrderValue.toLocaleString()}</div>
          <div className="text-body-secondary fw-bold">Average Order Value</div>
        </div>
      </footer>
    </div>
  )
}