import React, {FC, useState, useEffect} from 'react'
import axios from 'axios'
import {API_URL} from '../../../../../../../../app/modules/auth/core/_requests'
import {Pie} from 'react-chartjs-2'
import {ChartData, ChartDataset} from 'chart.js'

type Props = {
  className: string
}

type InventoryItem = {
  amazonSellerId: string
  sellerName: string
  numberOffers: number
  brandName: string
  monthlyRevenue: string
  estimateBrandPercentage: number
  moMCoverageChange: any
}

interface Dataset extends ChartDataset<'pie', number[]> {
  backgroundColor?: string[]
  hoverBackgroundColor?: string[]
}

// Define the type for the chart data
interface PieChartData extends ChartData<'pie', number[], unknown> {
  datasets: Dataset[]
}

const paginationButtons: React.CSSProperties = {
  padding: '4px 12px',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
  backgroundColor: '#0C6099',
  border: '1px solid #2B2B40',
  borderRadius: '5px',
  cursor: 'pointer',
  outline: 'none',
  boxShadow: '0 1px 4px rgba(255, 255, 255, 0.3)',
  transition: 'background-color 0.3s',
  marginBottom: '10px',
  marginRight: '10px',
}

const BrandSearchSeller: FC<Props> = ({className}) => {
  const [brandName, setBrandName] = useState('')
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([])
  const [searchedBrand, setSearchedBrand] = useState(null)
  const [chartData, setChartData] = useState<PieChartData>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
      },
    ],
  })

  const formatCurrency = (amount: number, currency: string = 'USD'): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amount)
  }

  const handleSearch = async () => {
    try {
      const response = await axios.post(`${API_URL}/amazon-data/searchseller`, {
        brand: brandName,
      })
      setSearchedBrand(response.data[0].brandName)
      setInventoryItems(response.data)

      const topItems = response.data.slice(0, 6)
      const otherItems = response.data.slice(6)

      const othersRevenue = otherItems.reduce(
        (total, item) => total + parseFloat(item.monthlyRevenue),
        0
      )
      setChartData({
        labels: [...topItems.map((item) => item.sellerName), 'Others'],
        datasets: [
          {
            data: [...topItems.map((item) => parseFloat(item.monthlyRevenue)), othersRevenue],
            backgroundColor: [
              '#FF6384',
              '#36A2EB',
              '#FFCE56',
              '#A3A0FB',
              '#5ACA82',
              '#FD6B78',
              '#D3D3D3',
            ], // Add more colors if needed
            hoverBackgroundColor: [
              '#FF6384',
              '#36A2EB',
              '#FFCE56',
              '#A3A0FB',
              '#5ACA82',
              '#FD6B78',
              '#D3D3D3',
            ], // Add more colors if needed
          },
        ],
      })
    } catch (error) {
      console.error('Error fetching data:', error)
      // Handle the error appropriately
    }
  }

  const AmzProductTable: FC<{items: InventoryItem[]}> = ({items}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 20
    const [paginatedItems, setPaginatedItems] = useState<InventoryItem[]>([])

    useEffect(() => {
      const startIndex = (currentPage - 1) * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      setPaginatedItems(items.slice(startIndex, endIndex))
    }, [items, currentPage])

    return (
      <div>
        <div style={{overflowX: 'auto'}}>
          <table
            className='table table-striped table-dark table-hover table-bordered'
            style={{minWidth: '1300px'}}
          >
            <thead>
              <tr style={{borderBottom: '2px solid #ddd'}}>
                <th>Seller Id</th>
                <th>Seller Name</th>
                <th>Number of Offers</th>
                <th>Brand Revenue</th>
                <th>Estimated Brand Share</th>
                <th>Monthly Coverage Change</th>
              </tr>
            </thead>
            <tbody>
              {paginatedItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.amazonSellerId}</td>
                  <td className='bold'>{item.sellerName}</td>
                  <td>{item.numberOffers}</td>
                  <td>{formatCurrency(parseFloat(item.monthlyRevenue))}</td>
                  <td>{item.estimateBrandPercentage.toFixed(0)}%</td>
                  <td>{item.moMCoverageChange}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <button
            className='mr-5'
            onClick={() => setCurrentPage((page) => Math.max(page - 1, 1))}
            style={paginationButtons}
            onMouseOver={({currentTarget}) => {
              currentTarget.style.backgroundColor = '#36A2EB'
            }}
            onMouseOut={({currentTarget}) => {
              currentTarget.style.backgroundColor = '#0C6099'
            }}
          >
            Previous
          </button>
          <button
            className=''
            onClick={() =>
              setCurrentPage((page) => Math.min(page + 1, Math.ceil(items.length / itemsPerPage)))
            }
            style={paginationButtons}
            onMouseOver={({currentTarget}) => {
              currentTarget.style.backgroundColor = '#36A2EB'
            }}
            onMouseOut={({currentTarget}) => {
              currentTarget.style.backgroundColor = '#0C6099'
            }}
          >
            Next
          </button>
        </div>
      </div>
    )
  }

  return (
    <div style={{overflowX: 'auto'}} className={`card card-flush ${className}`}>
      <div
        className='card-header pt-6 pb-0 row g-0'
        style={{display: 'flex', flexDirection: 'row'}}
      >
        <div className='col-md-6' style={{flex: 3}}>
          <div className='card-title'>
            <h2 className='card-label'>Costa Del Mar Amazon Products</h2>
          </div>
          <div className='float-right d-flex' style={{color: 'white'}}>
            <input
              type='text'
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              placeholder='Enter brand name'
              className='form-control'
              style={{color: 'black', backgroundColor: 'white'}}
            />
            <button onClick={handleSearch} className='btn btn-primary ml-2'>
              {' '}
              <i className='fas fa-search'></i>
            </button>
          </div>
        </div>
        <div
          className='col-md-6'
          style={{display: 'flex', justifyContent: 'flex-end', padding: '0px'}}
        >
          {searchedBrand && (
            <div style={{width: '500px', height: '300px', padding: '0px', margin: '0px'}}>
              <h2 style={{}}>{searchedBrand} Brand Share</h2>
              <Pie
                data={chartData}
                options={{
                  plugins: {legend: {position: 'right'}},
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className='card-body pt-6 pb-4 d-flex flex-wrap align-items-center'>
        <AmzProductTable items={inventoryItems} />
      </div>
    </div>
  )
}

export {BrandSearchSeller}
