import React from 'react';

interface BestItemProps {
  className?: string;
  vendor: string;
  bestItem: InventoryTableItems;
  productName?: string;
  SKU?: string;
}

type InventoryTableItems = {
  UPC: string;
  "Brand Name": string;
  Description: string;
  Store_Inventory: number;
  SalesQuantity: number;
  productName: string;
  sku: string;
};

export const BestPerformingItem: React.FC<BestItemProps> = ({ className, vendor, bestItem }) => {
  const vendorData = {
    Thelios: {
      imgSrc: '/media/glasses/thelios/bestSellingGlasses.jpg', 
      brand: bestItem['Brand Name'],
      itemName: bestItem.Description, 
      sales: bestItem.SalesQuantity, 
      upc: bestItem.UPC,
    },
    Luxottica: {
      imgSrc: '/media/glasses/luxottica/bestSellingGlasses.png', 
      itemName: 'RAY BAN - JUSTIN 0RB4165 622/55 54', 
      sales: '4500', 
      upc: '',
    },
    Costa: {
      imgSrc: '/media/glasses/costa/bestSellingGlasses.png',
      itemName: bestItem.productName, 
      sales: bestItem.SalesQuantity || 5, 
      upc: bestItem.sku,
    }
  };

  
  const currentVendorData = vendorData[vendor];

  const cardStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
  };

  const contentStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%', 
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const titleStyle: React.CSSProperties = {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.75rem',
    width: '100%',
    textAlign: 'center',
    margin: '0.6rem 0 2.5rem 0',
  };

  const imageContainerStyle: React.CSSProperties = {
    width: '55%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imageStyle: React.CSSProperties = {
    maxWidth: '100%',
    maxHeight: '14rem',
    marginBottom: '3rem',
    objectFit: 'contain',
    borderRadius: '0.5rem',
    boxShadow: '0 4em 3em hsla(0,0%,0%,.4)',
  };

  const infoStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between', 
    height: '100%', 
    width: '100%',
    padding: '0 1rem 1rem 1rem',
  };

  const itemDetailStyle: React.CSSProperties = {
    fontSize: '1.15rem',
    alignSelf: 'flex-end',
  };

  const salesStyle: React.CSSProperties = {
    fontSize: '1.25rem',
    alignSelf: 'flex-end',
  };

  const bigNumberStyle: React.CSSProperties = {
    marginLeft: '50%',
    paddingTop: '1rem',
    fontSize: '3rem',
    lineHeight: '1',
    fontWeight: '500', 
    color: '#fff', 
  };
  

  return (
    <div className={`card ${className}`} style={{...cardStyle, minHeight: '18rem'}}>
      <div style={contentStyle}>
        <div style={titleStyle}>Best Performing Style</div>
        <div style={imageContainerStyle}>
          <img
            src={currentVendorData.imgSrc}
            alt='Best Selling Styles'
            style={imageStyle}
          />
        </div>
        
        <div style={infoStyle}>
          <div className={'item-info'} style={itemDetailStyle}>
            <div>{currentVendorData.brand}</div>
            <div>{currentVendorData.itemName}</div>
            <div>{currentVendorData.upc}</div>
          </div>
          <div style={salesStyle}>
            <div style={bigNumberStyle}>{currentVendorData.sales}</div>
            <div style={{  
              color: '#fff',
              fontSize: '1.15rem',
              fontWeight: '700',}}>
              60-Day Sales
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};