import React, { FC } from 'react';
import { PageTitle } from '../../../../_designereyes/layout/core'
import { CustomerMasterList } from '../../../../_designereyes/partials/widgets';

const CustomerMasterListPage = () => {
  return (
    <div className="d-flex flex-column">
      <div className="mb-10">
        <CustomerMasterList active={true} />
      </div>
      <div>
        <CustomerMasterList active={false} />
      </div>
    </div>
  )
}

export const CustomerMasterListWrapper: FC = () => {
  return (
    <>
      <PageTitle>Customer Master List</PageTitle>
      <CustomerMasterListPage />
    </>
  )
};
