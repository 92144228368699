import React, { FC, useEffect, useRef, useState } from 'react';
import { Chart, BarController, CategoryScale, LinearScale } from 'chart.js';

Chart.register(BarController, CategoryScale, LinearScale);

type Props = {
  className: string;
  vendor: string;
};

const cardStyle: React.CSSProperties = {
  height: '100%',  // Fixed height
  width: '100%',   // Fixed width
  overflow: 'hidden', // Hides any overflowing content
  position: 'relative',
  borderRadius: '8px', // Optional: if you want rounded corners
  // border: '0px solid white',

  // Gradient style definition
  // background: 'linear-gradient(45deg, #01081F 0%, #021530 40%, #032242 90%, #021530 100%, #01081F 130%)',
};

export const BestSellingBrandsAmazon: FC<Props> = ({ className, vendor }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const [timeRange, setTimeRange] = useState('Year');
  const [selectedYear, setSelectedYear] = useState('2023'); // for 'Year' view
  const [selectedMonth, setSelectedMonth] = useState('January');
  const [selectedYearForMonth, setSelectedYearForMonth] = useState('2023');
  const delayed = useRef(false); // Using useRef to persist the value without causing re-renders

  const years = ['2023', '2022'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // Define a mapping for each vendor's labels
  const vendorLabels = {
    'Luxottica': {
      '2022': ['Oakley', 'Ray Ban', 'Costa Del Mar', 'Versace', 'Prada', 'Prada Sport', 'Michael Kors', 'Dolce & Gabanna'],
      '2023': ['Oakley', 'Ray Ban', 'Costa Del Mar', 'Prada', 'Versace', 'Dolce & Gabbana', 'Michael Kors', 'Armani Exchange', 'Burberry'],
    },
    'Thelios': {
      '2022': ['Dior', 'Loewe', 'Celine', 'Fendi'],
      '2023': ['Dior', 'Celine', 'Loewe', 'Fendi'],
    },
    // Add more vendors as needed
  };

  const multi = 1.3;

  // Define a mapping for each vendor's data
  const vendorYearlyData = {
    'Thelios': {
      '2022': [35, 25, 10, 15],
      '2023': [30, 20, 15, 10],
    },
    'Luxottica': {
      '2022': [74213 / 1.5, 56201 / 1.3, 29175 / multi, 27588 / multi, 18900 / multi, 15068 / multi, 15032 / multi, 14517 / multi],
      '2023': [83215 / 1.5, 48889 / 1.3, 23398 / multi, 19082 / multi, 11661 / multi, 9501 / multi, 7999 / multi, 7431 / multi,  7369 / multi],
    },
  };

  const vendorMonthlyData = {
    'Thelios': {
      '2022': {
        'January': [10, 20, 25, 15],
        'February': [15, 20, 20, 20],
        'March': [20, 15, 20, 20],
        'April': [10, 30, 15, 25],
        'May': [20, 25, 20, 25],
        'June': [25, 20, 30, 10],
        'July': [15, 25, 20, 20],
        'August': [20, 15, 20, 20],
        'September': [10, 20, 15, 25],
        'October': [20, 25, 20, 25],
        'November': [25, 20, 30, 10],
        'December': [15, 25, 20, 20],
        // ... data for other months in 2022
      },
      '2023': {
        'January': [20, 15, 20, 20],
        'February': [10, 30, 15, 25],
        'March': [25, 20, 30, 10],
        'April': [15, 25, 20, 20],
        'May': [20, 25, 20, 25],
        'June': [10, 20, 25, 15],
        'July': [20, 30, 20, 10],
        'August': [30, 20, 15, 10],
        'September': [25, 20, 30, 10],
        'October': [20, 15, 20, 20],
        'November': [10, 30, 15, 25],
        'December': [15, 25, 20, 20],
        // ... data for other months in 2023
      },
    },
    'Luxottica': {
      '2022': {
        'January': [1000, 20, 25, 15],
        'February': [15, 20, 20, 20],
        'March': [20, 15, 20, 20],
        'April': [10, 30, 15, 25],
        'May': [20, 25, 20, 25],
        'June': [25, 20, 30, 10],
        'July': [15, 25, 20, 20],
        'August': [20, 15, 20, 20],
        'September': [10, 20, 15, 25],
        'October': [20, 25, 20, 25],
        'November': [25, 20, 30, 10],
        'December': [15, 25, 20, 20],
        // ... data for other months in 2022
      },
      '2023': {
        'January': [20, 15, 20, 20],
        'February': [10, 30, 15, 25],
        'March': [25, 20, 30, 10],
        'April': [15, 25, 20, 20],
        'May': [20, 25, 20, 25],
        'June': [10, 20, 25, 15],
        'July': [20, 30, 20, 10],
        'August': [30, 20, 15, 10],
        'September': [25, 20, 30, 10],
        'October': [20, 15, 20, 20],
        'November': [10, 30, 15, 25],
        'December': [15, 25, 20, 20],
        // ... data for other months in 2023
      },
    },
    // Add more vendors as needed
  };


  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
  
      let chartData;

      const chartLabels = vendorLabels[vendor]?.[selectedYear] || [];

      if (timeRange === 'Year') {
        chartData = vendorYearlyData[vendor]?.[selectedYear] || [];
      } else {
        chartData = vendorMonthlyData[vendor]?.[selectedYearForMonth]?.[selectedMonth] || [];
      }
  
      chartInstance.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: [{
            label: 'Unit Sales per brand',
            data: chartData,
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(255, 204, 0, 1)',
              'rgba(144, 108, 207, 1)',
              'rgba(255, 155, 156, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(144, 108, 207, 1)',
              'rgba(255, 204, 0, 1)',
              'rgba(255, 155, 156, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 0.2,
            barPercentage: 0.5, 
            categoryPercentage: 1
          }]
        },
        options: {
          indexAxis: 'y',
          animation: {
            onComplete: () => {
              delayed.current = true; // Update the useRef value
            },
            delay: (context) => {
              let delay = 0;
              if (context.type === 'data' && context.mode === 'default' && !delayed.current) {
                delay = context.dataIndex * 100 + context.datasetIndex * 150;
              }
              return delay;
            },
          },
          scales: {
            x: {
              beginAtZero: true,
              grid: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              ticks: {
                color: 'white'
              }
            },
            y: {
              beginAtZero: true,
              grid: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              ticks: {
                color: 'white'
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                color: 'white' // Set legend text to white
              }
            },
            tooltip: {
              mode: 'index',
              axis: 'y',
              intersect: false,
              backgroundColor: '0, 0, 0, 0.9',
              titleFont: {
                size: 16, // Increase title font size
              },
              bodyFont: {
                size: 14, // Increase body font size
              },
              padding: {
                top: 10,
                right: 15,
                bottom: 10,
                left: 10
              }
            }
          },
        },
        // plugins: [dataValuePlugin]
      });
    }
  }, [vendor, selectedYear, selectedMonth, selectedYearForMonth, timeRange]);

  const handleTimeRangeChange = (e) => {
    setTimeRange(e.target.value);
    if (e.target.value === 'Year') {
      setSelectedYear('2023');
    } else {
      setSelectedMonth('January');
    }
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearForMonthChange = (e) => {
    setSelectedYearForMonth(e.target.value);
  };

  return (
    <div className={`card card-flush ${className}`} style={cardStyle}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>Best Selling Brands</span>
            <select className='form-select form-select-sm' value={timeRange} onChange={handleTimeRangeChange} style={{color: 'white', marginLeft:'4rem', width: 'auto'}}>
              <option value="Year">Year</option>
              <option value="Month">Month</option>
            </select>
            {timeRange === 'Year' ? (
              <select className='form-select form-select-sm' value={selectedYear} onChange={handleYearChange} style={{color: 'white', width: 'auto'}}>
                {years.map(year => <option key={year} value={year}>{year}</option>)}
              </select>
            ) : (
              <>
                <select className='form-select form-select-sm' value={selectedMonth} onChange={handleMonthChange} style={{color: 'white', width: 'auto'}}>
                  {months.map(month => <option key={month} value={month}>{month}</option>)}
                </select>
                <select className='form-select form-select-sm' value={selectedYearForMonth} onChange={handleYearForMonthChange} style={{color: 'white', width: 'auto'}}>
                  {years.map(year => <option key={year} value={year}>{year}</option>)}
                </select>
              </>
            )}
          </div>
          <span className='pt-1 fw-semibold fs-6' style={{color: 'rgba(255, 255, 255, 0.7)'}}>By Units Sold per Brand</span>  
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <canvas ref={chartRef} style={{ width: '100%', maxHeight: '300px' }} />
      </div>
    </div>
  );
};
