import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';

function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return Math.round(num).toString();
  }
}

const TotalBalanceCard = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="card" 
      style={{background: 'linear-gradient(45deg,#27A47C,#6FE594 120%)'}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="h-auto rounded-top p-4">
        <header className="" style={{fontSize: '2rem'}}>
          <FontAwesomeIcon icon={faMoneyCheckDollar} />        
        </header>
        <div className="pt-6 pb-3 d-flex flex-column">
          <div className="fw-bolder" style={{fontSize: '3rem', lineHeight: '1'}}>
            $ {isHovered ? (179349).toLocaleString() : formatNumber(179349)}
          </div>
          <div className="pt-2 fw-bold" style={{fontSize: '1.15rem'}}>
            Total Outstanding Balance
          </div>
        </div>
      </div>
      <div style={{ height: '1px', width: 'auto', margin:'0 50% 0 5%', background: 'rgba(255,255,255,0.5)' }}></div>
      <footer className="h-auto rounded-bottom pt-4 pb-8 px-4">
        <div className="d-flex flex-row align-items-center">
          <div className="fs-1 fw-bold">$ {isHovered ? (3502309).toLocaleString() : formatNumber(3502309)}</div>
          <div className="text-body-secondary fw-bold ms-2">YTD Sales</div>
        </div>
      </footer>
    </div>
  )
}

export { TotalBalanceCard }