import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlasses, faArrowUp, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';

interface todaySalesProps {
  todaySales: number;
  vendor: string;
  isExpanded?: boolean;
}

function formatNumber(num) {
  let roundedNum = Math.round(num);
  
  if (roundedNum >= 1000000) {
    return (roundedNum / 1000000).toFixed(1) + 'M';
  } else if (roundedNum >= 1000) {
    return (roundedNum / 1000).toFixed(1) + 'K';
  } else {
    return roundedNum.toString();
  }
}

export const TodaySales1: React.FC<todaySalesProps> = ({ todaySales, vendor, isExpanded = false }) => {
  const formattedNumber = formatNumber(todaySales);
  
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const cardStyle: React.CSSProperties = {
    height: '100%',  // Fixed height
    width: '100%',   // Fixed width
    overflow: 'hidden', // Hides any overflowing content
    position: 'relative',
    color: '#fff',

    borderRadius: isExpanded ? '8px 8px 0 0' : '8px', // Top corners rounded when expanded
    border: isExpanded ? '0px' : '', // No border when expanded
    
    background: 'linear-gradient(45deg,#F1467A,#FB949E)',
  };
  
  const cardBodyStyle: React.CSSProperties = {
    display: 'flex', // Use Flexbox
    flexDirection: 'column', // Arrange items vertically
    justifyContent: 'flex-end', // Align items to the end (bottom) of the container
    margin:'0 0 0 0',
    height: '100%', // Ensure the card body takes the full height of its container
    alignItems: 'center', // Center items horizontally
  };
  
  const bigNumberStyle: React.CSSProperties = {
    paddingTop: '1rem',
    fontSize: '4rem',
    lineHeight: '1',
    fontWeight: '500', // Bold for emphasis
    color: '#fff', // Ensure the number is white
  };

  const footerInnerContainerStyle: React.CSSProperties = {
    display: 'flex', // Use flexbox
    justifyContent: 'space-between', // Spread out the contents
    alignItems: 'center', // Align items vertically
  };

  const footerTitleStyle: React.CSSProperties = {
    color: '#fff', // White color for the title
    fontSize: '1.15rem', // Larger font size for the title
    fontWeight: '700',
  };

  const footerStyle: React.CSSProperties = {
    textAlign: 'center', // Center the text
    color: 'black', // Keep the original color for the rest of the footer
    paddingTop: '1rem', // Padding top
    paddingRight: '1rem', // Padding right
  };

  const iconStyle: React.CSSProperties = {
    position: 'absolute',
    top: '1rem', // Adjust as needed
    left: '1rem', // Position to the far left with some padding
    fontSize: '2rem', // Adjust as needed
    color: 'white', // Icon color
  };

  const innerContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between', // Spread out the content
    alignItems: 'center',
    width: '100%', // Take up the full width of the card body
  };
  
  const growthContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
  };

  const getChartData = (vendor, todaySales) => {
    // Placeholder data for the 7-day bar chart
    const sevenDayData = {
      'Thelios': [135, 50, 80, 120, 143, todaySales - 150, 156],
      'Luxottica': [188, 1937, 1141, 1025, 1512, 211, todaySales],
    }; // Replace with actual data
    
    return sevenDayData[vendor] || [];
  }
  const sevenDayLabels = ['12/25', '12/26', '12/27', '12/28', '12/29', '12/30', '12/31']; // Adjust according to your needs

  // Get today's day name
  // const today = new Date().toLocaleString('en-us', { weekday: 'short' });

  //Hardcoded today to stay sunday December 31st 2024
  const today = '12/31';

  // Highlight today's bar
  const barChartBackgroundColor = sevenDayLabels.map(day => day === today ? '#ffcc00' : 'white'); // Highlight color for today

  const barChartData = {
    labels: sevenDayLabels,
    datasets: [
      {
        label: 'Sales',
        data: getChartData(vendor, todaySales),
        backgroundColor: barChartBackgroundColor,
      },
    ],
  };

  

  const barChartOptions = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index' as 'index',
        axis: 'x' as 'x',
        intersect: false,
      }
    },
  };

  // Small chart style
  const smallChartStyle: React.CSSProperties = {
    position: 'absolute',
    top: '1.5rem',
    right: '1.5rem',
    width: '12rem',
    height: '8rem',
  };

return (
  <div className="card card-flush" 
      style={cardStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
    <FontAwesomeIcon icon={faMoneyBillTransfer} style={iconStyle} />
    <div style={smallChartStyle}>
      <Bar data={barChartData} options={barChartOptions} />
    </div>
      {/* <div style={{ position: 'absolute', top: '1.5rem', right: '1.5rem', width: '10rem', height: '6rem' }}>
        <Line data={chartData} options={chartOptions} />
      </div> */}
      <div className="card-body" style={cardBodyStyle}>
        <div style={innerContainerStyle}>
          <div style={growthContainerStyle}>
            <span className="text-dark" style={bigNumberStyle}>
              {isHovered ? Math.round(todaySales) : formattedNumber}
            </span>            
            {/* {percentageChange > 0 && (
              <>
                <FontAwesomeIcon icon={faArrowUp} style={arrowStyle} />
                <span style={percentageStyle}>{`${percentageChange}%`}</span>
              </>
            )} */}
          </div>
        </div>
      </div>
      <div className="card-footer" style={footerStyle}>
        <div style={footerInnerContainerStyle}>
          <div style={footerTitleStyle}>Today's Sales</div>
        </div>  
      </div>
    </div>
  );
};
