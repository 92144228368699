import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_designereyes/layout/core'
import { useAuth } from '../../../app/modules/auth'
import {
  CostaLineChart,
  CostaUnitsSold
} from '../../../_designereyes/partials/widgets/'

const OverviewPage: FC = () => {
  const { currentUser } = useAuth()

  return (
    <>
      {/* begin Costa Vendor::Row */}
      {currentUser?.decoded?.vendorName === 'Costa' && (
        <>
          <div className='row g-5 g-xl-10 mb-5'>
            <div className='col-lg-4 col-xl-4'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <CostaUnitsSold date="today" title="Top Sellers Today" className="bg-primary" />
              </div>
            </div>
            <div className='col-lg-4 col-xl-4'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <CostaUnitsSold date="yesterday" title="Top Sellers Yesterday" className="bg-primary" />
              </div>
            </div>
            <div className='col-lg-4 col-xl-4'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <CostaUnitsSold date="last7Days" title="Top Sellers Last 7 Days" className="bg-primary" />
              </div>
            </div>
          </div>

          <div className='row g-5 g-xl-10'>
            <div className='col-lg-12 col-xl-12'>
              <div className='h-md-50 mb-5 mb-xl-10 h-lg-100'>
                <CostaLineChart />
              </div>
            </div>
          </div>
        </>
      )}
      {/* end Thelios Vendor::Row */}
    </>
  )
}

const OverviewWrapper: FC = () => {
  return (
    <>
      <PageTitle>Overview</PageTitle>
      <OverviewPage />
    </>
  )
}

export { OverviewWrapper }
