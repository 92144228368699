import { MapContainer, TileLayer, Marker, Popup, Tooltip, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Chart, BarController, CategoryScale, LinearScale } from 'chart.js';
import React, { FC, useEffect, useState } from 'react';
import L from 'leaflet';

Chart.register(BarController, CategoryScale, LinearScale);

type Props = {
  className: string;
  vendor: string;
};

type Location = {
  id: number;
  lat: number;
  lng: number;
  name: string;
  inventory: number[];
};


const iconDE = new L.Icon({
  iconUrl: '/media/logos/de-map-icon.png', 
  iconSize: [50, 50], 
  iconAnchor: [12, 41], 
  popupAnchor: [1, -34], 
});

const iconPOV = new L.Icon({
  iconUrl: '/media/logos/pov-map-icon.png', 
  iconSize: [50, 50], 
  iconAnchor: [12, 41], 
  popupAnchor: [1, -34], 
});

const selectIcon = (id) => {
  if ((id >= 1 && id <= 9)) {
    return iconDE;
  } else if (id === 10) {
    return iconPOV;
  } else {
    return iconDE; 
  }
};

const AttributionControl = () => {
  const map = useMap();

  useEffect(() => {
    
    map.attributionControl.setPrefix(false);
  }, [map]);

  return null;
};

const buttonStyle: React.CSSProperties = {
  position: 'absolute',
  zIndex: 101,
  width: '80px', 
  height: '30px', 
  fontSize: '12px', 
  padding: '5px',
  borderRadius: '5px',
  backgroundColor: '#000',
  color: '#fff',
  border: 'none',
  cursor: 'pointer',
  fontWeight: 'bold',
  margin: '5px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)'
};

const CenterOnSunriseButton = () => {
  const map = useMap();

  const centerMapOnSunrise = () => {
    
    const lat = 26.151480;
    const lng = -80.321050;
    map.flyTo([lat, lng], 15, { duration: 0.5 }); 
  };

  return (
    <button 
      onClick={centerMapOnSunrise} 
      style={{ 
        ...buttonStyle,
        top: '60%', 
        left: '10px', 
      }}
    >
      Sunrise
    </button>
  );
};

const CenterOnOrlandoButton = () => {
  const map = useMap();

  const centerMapOnOrlando = () => {
    
    const lat = 28.4507;
    const lng = -81.3976;
    map.flyTo([lat, lng], 11.2, { duration: 0.5 }); 
  };

  return (
    <button 
      onClick={centerMapOnOrlando} 
      style={{ 
        ...buttonStyle,
        top: '70%', 
        left: '10px', 
      }}
    >
      Orlando
    </button>
  );
};

const CenterOnMiamiButton = () => {
  const map = useMap();

  const centerMapOnMiami = () => {
    
    const lat = (25.957 + 25.7675 + 25.7901) / 3 + 0.03;
    const lng = (-80.1423 + -80.1937 + -80.1396) / 3 + 0.02;
    map.flyTo([lat, lng], 11.2, { duration: 0.5 }); 
  };

  return (
    <button 
      onClick={centerMapOnMiami} 
      style={{ 
        ...buttonStyle,
        top: '80%', 
        left: '10px', 
      }}
    >
      Miami Area
    </button>
  );
};

const CenterOnSanJuanButton = () => {
  const map = useMap();

  const centerMapOnSanJuan = () => {
    
    const lat = (18.4126 + 18.4226) / 2 + 0.00135;
    const lng = (-66.0711 + -66.0715) / 2;
    map.flyTo([lat, lng], 15.5, {duration: 1}); 
  };

  return (
    <button 
      onClick={centerMapOnSanJuan} 
      style={{ 
        ...buttonStyle,
        top: '90%', 
        left: '10px', 
      }}
    >
      Puerto Rico
    </button>
  );
};

const ResetViewButton = () => {
  const map = useMap();

  const resetView = () => {
    map.flyTo([25, -70], 5.35, {duration: 1}); 
  };

  return (
    <button 
      onClick={resetView} 
      style={{ 
        ...buttonStyle,
        position: 'absolute',
        top: '90%', 
        right: '10px',
      }}
    >
      Reset View
    </button>
  );
};

const InventoryMap: FC<Props> = ({ className, vendor }) => {
  const [currentZoom, setCurrentZoom] = useState(4);

  const MapZoomHandler = () => {
    const map = useMap();

    useMapEvents({
      zoomend: () => {
        setCurrentZoom(map.getZoom());
      },
    });
    return null;
  };

  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [chartInstance, setChartInstance] = useState<Chart | null>(null); 

   
   const vendorData = {
    Thelios: {
      labels: ['Dior', 'Loewe', 'Celine', 'Fendi'],
      inventory: [
        {
          id: 1,
          lat: 25.957,
          lng: -80.1423,
          name: 'Aventura Mall',
          inventory: [88, 30, 50, 38],
        },
        {
          id: 2,
          lat: 25.7901,
          lng: -80.1396,
          name: 'Eyes on Lincoln',
          inventory: [38, 94, 70, 24],
        },
        {
          id: 3,
          lat: 25.7675,
          lng: -80.1937,
          name: 'Brickell City Centre',
          inventory: [72, 22, 42, 90],
        },
        {
          id: 4,
          lat: 28.4507,
          lng: -81.3976,
          name: 'The Florida Mall, Orlando',
          inventory: [100, 34, 42, 30],
        },
        {
          id: 5,
          lat: 18.4126,
          lng: -66.0711,
          name: 'The Mall of San Juan',
          inventory: [68, 78, 24, 30],
        },
        {
          id: 6,
          lat: 18.4226,
          lng: -66.0715,
          name: 'Plaza Las Américas, San Juan',
          inventory: [62, 66, 42, 86],
        },
        {
          id: 7,
          lat: 25.8881025,
          lng: -80.1252848,
          name: 'Bal Harbour',
          inventory: [78, 46, 90, 44],
        },
        {
          id: 8,
          lat: 25.888378,
          lng: -80.125237,
          name: 'Miami Beach',
          inventory: [78, 46, 90, 44],
        },
        {
          id: 9,
          lat: 25.787480,
          lng: -80.380010,
          name: 'Miami',
          inventory: [78, 46, 90, 44],
        },
        {
          id: 10,
          lat: 26.151480,
          lng: -80.321050,
          name: 'Sunrise',
          inventory: [78, 46, 90, 44],
        },
      ],
    },
    Luxottica: {
      labels: ['Ray Ban', 'Prada', 'Versace', 'Oakley', 'Dolce & Gabbana', 'Burberry'],
      inventory: [
        {
          id: 1,
          lat: 25.957,
          lng: -80.1423,
          name: 'Aventura Mall',
          inventory: [68, 30, 34, 45, 50, 38],
        },
        {
          id: 2,
          lat: 25.7901,
          lng: -80.1396,
          name: 'Eyes on Lincoln',
          inventory: [38, 94, 34, 45, 70, 24],
        },
        {
          id: 3,
          lat: 25.7675,
          lng: -80.1937,
          name: 'Brickell City Centre',
          inventory: [52, 22, 34, 45, 42, 90],
        },
        {
          id: 4,
          lat: 28.4507,
          lng: -81.3976,
          name: 'The Florida Mall, Orlando',
          inventory: [70, 34, 34, 45, 42, 30],
        },
        {
          id: 5,
          lat: 18.4126,
          lng: -66.0711,
          name: 'The Mall of San Juan',
          inventory: [68, 78, 34, 45, 24, 30],
        },
        {
          id: 6,
          lat: 18.4226,
          lng: -66.0715,
          name: 'Plaza Las Américas, San Juan',
          inventory: [62, 66, 34, 45, 42, 86],
        },
        {
          id: 7,
          lat: 25.8881025,
          lng: -80.1252848,
          name: 'Bal Harbour',
          inventory: [78, 46, 34, 45, 90, 44],
        },
        {
          id: 8,
          lat: 25.888378,
          lng: -80.125237,
          name: 'Miami Beach',
          inventory: [78, 46, 34, 45, 90, 44],
        },
        {
          id: 9,
          lat: 25.787480,
          lng: -80.380010,
          name: 'Miami',
          inventory: [78, 46, 34, 45, 90, 44],
        },
        {
          id: 10,
          lat: 26.151480,
          lng: -80.321050,
          name: 'Sunrise',
          inventory: [78, 46, 34, 45, 90, 44],
        },
      ],
    },
  };

  
  const currentVendorData = vendorData[vendor];

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
    
  
  

  useEffect(() => {
    if (selectedLocation) {
      const chartId = `chart-${selectedLocation.id}`;
      const chartElement = document.getElementById(chartId) as HTMLCanvasElement | null;

      
      if (chartInstance) {
        chartInstance.destroy();
      }

      if (chartElement) {
        
        chartElement.width = 400; 
        chartElement.height = 400; 

        const ctx = chartElement.getContext('2d');
        if (ctx) {
            const newChartInstance = new Chart(ctx, {
                type: 'bar',
                data: {
                labels: vendorData[vendor].labels,
                datasets: [{
                    label: 'Inventory',
                    data: selectedLocation.inventory,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)'
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)'
                    ],
                    borderWidth: 1,
                }],
                },
                options: {
                scales: {
                    y: {
                    beginAtZero: true,
                    },
                },
                },
          });
          setChartInstance(newChartInstance); 
        }
      }
    }
  }, [selectedLocation]);

  useEffect(() => {
    
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [selectedLocation]);

  return (
    <MapContainer
      center={[25, -70]} 
      zoom={5.45} 
      style={{ height: '500px', width: '100%' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution=''
      />
      <AttributionControl />
      <MapZoomHandler />
        {currentVendorData.inventory.map(location => (
          <Marker
            key={location.id}
            position={[location.lat, location.lng]}
            icon={selectIcon(location.id)}
            eventHandlers={{
              click: () => setSelectedLocation(location),
            }}
          >
          {currentZoom >= 6 && (
            <Tooltip 
            direction="top" 
            offset={[15, -40]} 
            opacity={1} 
            permanent
            >
              {location.name}
            </Tooltip>
            )}
            <Popup>
              <div>
                <h2>{location.name}</h2>
                <canvas id={`chart-${location.id}`} width="400" height="400"></canvas>
              </div>
            </Popup>
          </Marker>
        ))}
      <CenterOnSunriseButton />
      <CenterOnOrlandoButton />
      <CenterOnMiamiButton />
      <CenterOnSanJuanButton />
      <ResetViewButton />
    </MapContainer>
  );
};

export { InventoryMap };
