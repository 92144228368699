import React, { FC, useState, useEffect } from 'react';
import { useAccount } from '../../../../../../widgets';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

type EyewearItem = {
  POID: number;
  ImageURL: string;
  UPC: string;
  Brand: string;
  Model: string;
  Color: string;
  Quantity: number;
};

// https://images2.designereyes.com/ftp/images3/CELINE/CL40254U/30N/192337133282_P01.jpg	192337133282    	CELINE	CL40254U	30N
// https://images2.designereyes.com/ftp/images3/DIOR/CD40149I/52V/192337151002_P01.jpg	192337151002    	DIOR WOMAN	CD40149I	20B0
// https://images2.designereyes.com/ftp/images3/DIOR/CD40157I/01B/192337167362_P01.jpg	192337133282    	DIOR WOMAN	CD40157I	10A1
// https://images2.designereyes.com/ftp/images3/CELINE/CL40239F/01F/192337121371_P01.jpg	192337121371    	CELINE	CL40239F	01F
// https://images2.designereyes.com/ftp/images3/DIOR/CD40157I/96B/192337167409_P01.jpg	192337167409    	DIOR WOMAN	CD40157I	55A1
// https://images2.designereyes.com/ftp/images3/DIOR/CD50087I/086/192337164439_P01.jpg	192337164439    	DIOR WOMAN	CD50087I	8000
// https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/001/192337157936_P01.jpg	192337157936    	FENDI	FE50085I	001
// https://images2.designereyes.com/ftp/images3/LOEWE/LW40108I/96C/192337136429_P01.jpg	192337136429    	LOEWE	LW40108I	96C
// https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/021/192337157943_P01.jpg	192337157943    	FENDI	FE50085I	021
// https://images2.designereyes.com/ftp/images3/DIOR/CD50102I/093/192337162268_P01.jpg	192337162268    	DIOR WOMAN	CD50102I	5500
// https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg	192337150708    	DIOR WOMAN	CD40144F	01B
// https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg	192337134227    	FENDI	FE40098I	01W
// https://images2.designereyes.com/ftp/images3/CELINE/CL40216U/01F/192337105531_P01.jpg	192337105531    	CELINE	CL40216U	01F
// https://images2.designereyes.com/ftp/images3/CELINE/CL40238U/01A/192337121326_P01.jpg	192337121326    	CELINE	CL40238U	01A
// https://images2.designereyes.com/ftp/images3/LOEWE/LW40064U/01Y/192337077180_P01.jpg	192337077180    	LOEWE	LW40064U	01Y


const dummyEyewearData: EyewearItem[] = [
  { POID: 1, ImageURL: "", UPC: "1234567890", Brand: "Ray-Ban", Model: "RB2140", Color: "Black", Quantity: 100 },
  { POID: 1, ImageURL: "", UPC: "1234567891", Brand: "Ray-Ban", Model: "RB2132", Color: "Tortoise", Quantity: 150 },
  { POID: 1, ImageURL: "", UPC: "1234567892", Brand: "Oakley", Model: "Holbrook", Color: "Black", Quantity: 200 },
  { POID: 2, ImageURL: "", UPC: "2234567890", Brand: "Gucci", Model: "GG0022S", Color: "Black", Quantity: 50 },
  { POID: 3, ImageURL: "", UPC: "3234567890", Brand: "Prada", Model: "PR01OS", Color: "Navy", Quantity: 75 },

  { POID: 20, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40254U/30N/192337133282_P01.jpg", UPC: "192337133282", Brand: "CELINE", Model: "CL40254U", Color: "30N", Quantity: 300 },
  { POID: 20, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40149I/52V/192337151002_P01.jpg", UPC: "192337151002", Brand: "DIOR WOMAN", Model: "CD40149I ", Color: "20B0", Quantity: 500 },
  { POID: 20, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40157I/01B/192337167362_P01.jpg", UPC: "192337133282", Brand: "DIOR WOMAN", Model: "CD40157I", Color: "10A1", Quantity: 400 },
  { POID: 20, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40239F/01F/192337121371_P01.jpg", UPC: "192337121371", Brand: "CELINE", Model: "CL40239F", Color: "01F", Quantity: 300 },

  { POID: 21, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40157I/96B/192337167409_P01.jpg", UPC: "192337167409", Brand: "DIOR WOMAN", Model: "CD40157I", Color: "55A1", Quantity: 100 },
  { POID: 21, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50087I/086/192337164439_P01.jpg", UPC: "192337164439", Brand: "DIOR WOMAN", Model: "CD50087I", Color: "8000", Quantity: 100 },
  { POID: 21, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40149I/52V/192337151002_P01.jpg", UPC: "192337151002", Brand: "DIOR WOMAN", Model: "CD40149I ", Color: "20B0", Quantity: 100 },
  { POID: 21, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40254U/30N/192337133282_P01.jpg", UPC: "192337133282", Brand: "CELINE", Model: "CL40254U", Color: "30N", Quantity: 150 },
  { POID: 21, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40216U/01F/192337105531_P01.jpg", UPC: "192337105531", Brand: "CELINE", Model: "CL40216U", Color: "01F", Quantity: 50 },

  { POID: 22, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/001/192337157936_P01.jpg", UPC: "192337157936", Brand: "FENDI", Model: "FE50085I", Color: "001", Quantity: 100 },
  { POID: 22, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40108I/96C/192337136429_P01.jpg", UPC: "192337136429", Brand: "LOEWE", Model: "LW40108I", Color: "96C", Quantity: 150 },
  { POID: 22, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/021/192337157943_P01.jpg", UPC: "192337157943", Brand: "FENDI", Model: "FE50085I", Color: "021", Quantity: 50 },
  { POID: 22, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50102I/093/192337162268_P01.jpg", UPC: "192337162268", Brand: "DIOR WOMAN", Model: "CD50102I", Color: "5500", Quantity: 50 },
  { POID: 22, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg", UPC: "192337150708", Brand: "DIOR WOMAN", Model: "CD40144F", Color: "01B", Quantity: 100 },
  { POID: 22, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg", UPC: "192337134227", Brand: "FENDI", Model: "FE40098I", Color: "01W", Quantity: 150 },
  { POID: 22, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40239F/01F/192337121371_P01.jpg", UPC: "192337121371", Brand: "CELINE", Model: "CL40239F", Color: "01F", Quantity: 100 },

  //POID23 has total of 6 items adding up to 873
  { POID: 23, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40254U/30N/192337133282_P01.jpg", UPC: "192337133282", Brand: "CELINE", Model: "CL40254U", Color: "30N", Quantity: 300 },
  { POID: 23, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40149I/52V/192337151002_P01.jpg", UPC: "192337151002", Brand: "DIOR WOMAN", Model: "CD40149I ", Color: "20B0", Quantity: 500 },
  { POID: 23, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40157I/01B/192337167362_P01.jpg", UPC: "192337133282", Brand: "DIOR WOMAN", Model: "CD40157I", Color: "10A1", Quantity: 400 },
  { POID: 23, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40239F/01F/192337121371_P01.jpg", UPC: "192337121371", Brand: "CELINE", Model: "CL40239F", Color: "01F", Quantity: 300 },
  { POID: 23, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40157I/96B/192337167409_P01.jpg", UPC: "192337167409", Brand: "DIOR WOMAN", Model: "CD40157I", Color: "55A1", Quantity: 100 },
  { POID: 23, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50087I/086/192337164439_P01.jpg", UPC: "192337164439", Brand: "DIOR WOMAN", Model: "CD50087I", Color: "8000", Quantity: 173 },

  //POID24 has total of 4 random items adding up to 453
  { POID: 24, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/001/192337157936_P01.jpg", UPC: "192337157936", Brand: "FENDI", Model: "FE50085I", Color: "001", Quantity: 100 },
  { POID: 24, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40108I/96C/192337136429_P01.jpg", UPC: "192337136429", Brand: "LOEWE", Model: "LW40108I", Color: "96C", Quantity: 150 },
  { POID: 24, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/021/192337157943_P01.jpg", UPC: "192337157943", Brand: "FENDI", Model: "FE50085I", Color: "021", Quantity: 50 },
  { POID: 24, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50102I/093/192337162268_P01.jpg", UPC: "192337162268", Brand: "DIOR WOMAN", Model: "CD50102I", Color: "5500", Quantity: 153 },

  //POID25 has total of 6 random items adding up to 1516
  { POID: 25, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg", UPC: "192337150708", Brand: "DIOR WOMAN", Model: "CD40144F", Color: "01B", Quantity: 300 },
  { POID: 25, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg", UPC: "192337134227", Brand: "FENDI", Model: "FE40098I", Color: "01W", Quantity: 150 },
  { POID: 25, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40216U/01F/192337105531_P01.jpg", UPC: "192337105531", Brand: "CELINE", Model: "CL40216U", Color: "01F", Quantity: 250 },
  { POID: 25, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40238U/01A/192337121326_P01.jpg", UPC: "192337121326", Brand: "CELINE", Model: "CL40238U", Color: "01A", Quantity: 200 },
  { POID: 25, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40064U/01Y/192337077180_P01.jpg", UPC: "192337077180", Brand: "LOEWE", Model: "LW40064U", Color: "01Y", Quantity: 350 },
  { POID: 25, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/001/192337157936_P01.jpg", UPC: "192337157936", Brand: "FENDI", Model: "FE50085I", Color: "001", Quantity: 316 },
  
  //POID26 has total of 5 random items adding up to 352 
  { POID: 26, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40108I/96C/192337136429_P01.jpg", UPC: "192337136429", Brand: "LOEWE", Model: "LW40108I", Color: "96C", Quantity: 79 },
  { POID: 26, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/021/192337157943_P01.jpg", UPC: "192337157943", Brand: "FENDI", Model: "FE50085I", Color: "021", Quantity: 223 },
  { POID: 26, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50102I/093/192337162268_P01.jpg", UPC: "192337162268", Brand: "DIOR WOMAN", Model: "CD50102I", Color: "5500", Quantity: 49 },
  { POID: 26, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg", UPC: "192337150708", Brand: "DIOR WOMAN", Model: "CD40144F", Color: "01B", Quantity: 83 },
  { POID: 26, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg", UPC: "192337134227", Brand: "FENDI", Model: "FE40098I", Color: "01W", Quantity: 26 },
  { POID: 26, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40216U/01F/192337105531_P01.jpg", UPC: "192337105531", Brand: "CELINE", Model: "CL40216U", Color: "01F", Quantity: 57 },

  //POID27 has total of 5 random items adding up to 1788
  { POID: 27, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40238U/01A/192337121326_P01.jpg", UPC: "192337121326", Brand: "CELINE", Model: "CL40238U", Color: "01A", Quantity: 350 },
  { POID: 27, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40064U/01Y/192337077180_P01.jpg", UPC: "192337077180", Brand: "LOEWE", Model: "LW40064U", Color: "01Y", Quantity: 250 },
  { POID: 27, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/001/192337157936_P01.jpg", UPC: "192337157936", Brand: "FENDI", Model: "FE50085I", Color: "001", Quantity: 250 },
  { POID: 27, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg", UPC: "192337150708", Brand: "DIOR WOMAN", Model: "CD40144F", Color: "01B", Quantity: 188 },
  { POID: 27, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg", UPC: "192337134227", Brand: "FENDI", Model: "FE40098I", Color: "01W", Quantity: 350 },

  //POID28 has total of 4 random items adding up to 674
  { POID: 28, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40254U/30N/192337133282_P01.jpg", UPC: "192337133282", Brand: "CELINE", Model: "CL40254U", Color: "30N", Quantity: 180 },
  { POID: 28, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40149I/52V/192337151002_P01.jpg", UPC: "192337151002", Brand: "DIOR WOMAN", Model: "CD40149I ", Color: "20B0", Quantity: 125 },
  { POID: 28, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40157I/01B/192337167362_P01.jpg", UPC: "192337133282", Brand: "DIOR WOMAN", Model: "CD40157I", Color: "10A1", Quantity: 150 },
  { POID: 28, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40239F/01F/192337121371_P01.jpg", UPC: "192337121371", Brand: "CELINE", Model: "CL40239F", Color: "01F", Quantity: 219 },

  //POID29 has total of 3 random items adding up to 350
  { POID: 29, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50087I/086/192337164439_P01.jpg", UPC: "192337164439", Brand: "DIOR WOMAN", Model: "CD50087I", Color: "8000", Quantity: 100 },
  { POID: 29, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/001/192337157936_P01.jpg", UPC: "192337157936", Brand: "FENDI", Model: "FE50085I", Color: "001", Quantity: 150 },
  { POID: 29, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40108I/96C/192337136429_P01.jpg", UPC: "192337136429", Brand: "LOEWE", Model: "LW40108I", Color: "96C", Quantity: 100 },

  //POID30 has total of 5 random items adding up to 250
  { POID: 30, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/021/192337157943_P01.jpg", UPC: "192337157943", Brand: "FENDI", Model: "FE50085I", Color: "021", Quantity: 50 },
  { POID: 30, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50102I/093/192337162268_P01.jpg", UPC: "192337162268", Brand: "DIOR WOMAN", Model: "CD50102I", Color: "5500", Quantity: 50 },
  { POID: 30, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg", UPC: "192337150708", Brand: "DIOR WOMAN", Model: "CD40144F", Color: "01B", Quantity: 50 },
  { POID: 30, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg", UPC: "192337134227", Brand: "FENDI", Model: "FE40098I", Color: "01W", Quantity: 50 },
  { POID: 30, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40216U/01F/192337105531_P01.jpg", UPC: "192337105531", Brand: "CELINE", Model: "CL40216U", Color: "01F", Quantity: 50 },

  //POID31 has total of 2 random items adding up to 791
  { POID: 31, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40238U/01A/192337121326_P01.jpg", UPC: "192337121326", Brand: "CELINE", Model: "CL40238U", Color: "01A", Quantity: 350 },
  { POID: 31, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40064U/01Y/192337077180_P01.jpg", UPC: "192337077180", Brand: "LOEWE", Model: "LW40064U", Color: "01Y", Quantity: 441 },

  //POID32 has total of 4 random items adding up to 1500
  { POID: 32, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/001/192337157936_P01.jpg", UPC: "192337157936", Brand: "FENDI", Model: "FE50085I", Color: "001", Quantity: 300 },
  { POID: 32, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40108I/96C/192337136429_P01.jpg", UPC: "192337136429", Brand: "LOEWE", Model: "LW40108I", Color: "96C", Quantity: 150 },
  { POID: 32, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/021/192337157943_P01.jpg", UPC: "192337157943", Brand: "FENDI", Model: "FE50085I", Color: "021", Quantity: 50 },
  { POID: 32, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50102I/093/192337162268_P01.jpg", UPC: "192337162268", Brand: "DIOR WOMAN", Model: "CD50102I", Color: "5500", Quantity: 100 },
  { POID: 32, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg", UPC: "192337150708", Brand: "DIOR WOMAN", Model: "CD40144F", Color: "01B", Quantity: 100 },

  //POID33 has total of 3 random items adding up to 500
  { POID: 33, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg", UPC: "192337134227", Brand: "FENDI", Model: "FE40098I", Color: "01W", Quantity: 150 },
  { POID: 33, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40216U/01F/192337105531_P01.jpg", UPC: "192337105531", Brand: "CELINE", Model: "CL40216U", Color: "01F", Quantity: 50 },
  { POID: 33, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40238U/01A/192337121326_P01.jpg", UPC: "192337121326", Brand: "CELINE", Model: "CL40238U", Color: "01A", Quantity: 300 },

  //POID34 has total of 4 random items adding up to 700
  { POID: 34, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40064U/01Y/192337077180_P01.jpg", UPC: "192337077180", Brand: "LOEWE", Model: "LW40064U", Color: "01Y", Quantity: 250 },
  { POID: 34, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/001/192337157936_P01.jpg", UPC: "192337157936", Brand: "FENDI", Model: "FE50085I", Color: "001", Quantity: 150 },
  { POID: 34, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg", UPC: "192337150708", Brand: "DIOR WOMAN", Model: "CD40144F", Color: "01B", Quantity: 100 },
  { POID: 34, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg", UPC: "192337134227", Brand: "FENDI", Model: "FE40098I", Color: "01W", Quantity: 200 },

  //POID35 has total of 5 random items adding up to 873
  { POID: 35, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg", UPC: "192337150708", Brand: "DIOR WOMAN", Model: "CD40144F", Color: "01B", Quantity: 300 },
  { POID: 35, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg", UPC: "192337134227", Brand: "FENDI", Model: "FE40098I", Color: "01W", Quantity: 150 },
  { POID: 35, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40216U/01F/192337105531_P01.jpg", UPC: "192337105531", Brand: "CELINE", Model: "CL40216U", Color: "01F", Quantity: 250 },
  { POID: 35, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40238U/01A/192337121326_P01.jpg", UPC: "192337121326", Brand: "CELINE", Model: "CL40238U", Color: "01A", Quantity: 200 },
  { POID: 35, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40064U/01Y/192337077180_P01.jpg", UPC: "192337077180", Brand: "LOEWE", Model: "LW40064U", Color: "01Y", Quantity: 173 },

  //POID36 has total of 6 random items adding up to 453
  { POID: 36, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40254U/30N/192337133282_P01.jpg", UPC: "192337133282", Brand: "CELINE", Model: "CL40254U", Color: "30N", Quantity: 300 },
  { POID: 36, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40149I/52V/192337151002_P01.jpg", UPC: "192337151002", Brand: "DIOR WOMAN", Model: "CD40149I ", Color: "20B0", Quantity: 500 },
  { POID: 36, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40157I/01B/192337167362_P01.jpg", UPC: "192337133282", Brand: "DIOR WOMAN", Model: "CD40157I", Color: "10A1", Quantity: 400 },
  { POID: 36, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40239F/01F/192337121371_P01.jpg", UPC: "192337121371", Brand: "CELINE", Model: "CL40239F", Color: "01F", Quantity: 300 },
  { POID: 36, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40157I/96B/192337167409_P01.jpg", UPC: "192337167409", Brand: "DIOR WOMAN", Model: "CD40157I", Color: "55A1", Quantity: 100 },
  { POID: 36, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50087I/086/192337164439_P01.jpg", UPC: "192337164439", Brand: "DIOR WOMAN", Model: "CD50087I", Color: "8000", Quantity: 316 },

  //POID37 has total of 6 random items adding up to 820
  { POID: 37, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/001/192337157936_P01.jpg", UPC: "192337157936", Brand: "FENDI", Model: "FE50085I", Color: "001", Quantity: 100 },
  { POID: 37, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40108I/96C/192337136429_P01.jpg", UPC: "192337136429", Brand: "LOEWE", Model: "LW40108I", Color: "96C", Quantity: 150 },
  { POID: 37, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/021/192337157943_P01.jpg", UPC: "192337157943", Brand: "FENDI", Model: "FE50085I", Color: "021", Quantity: 50 },
  { POID: 37, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50102I/093/192337162268_P01.jpg", UPC: "192337162268", Brand: "DIOR WOMAN", Model: "CD50102I", Color: "5500", Quantity: 50 },
  { POID: 37, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg", UPC: "192337150708", Brand: "DIOR WOMAN", Model: "CD40144F", Color: "01B", Quantity: 100 },
  { POID: 37, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg", UPC: "192337134227", Brand: "FENDI", Model: "FE40098I", Color: "01W", Quantity: 370 },

  //POID38 has total of 5 random items adding up to 750
  { POID: 38, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg", UPC: "192337150708", Brand: "DIOR WOMAN", Model: "CD40144F", Color: "01B", Quantity: 300 },
  { POID: 38, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg", UPC: "192337134227", Brand: "FENDI", Model: "FE40098I", Color: "01W", Quantity: 150 },
  { POID: 38, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40216U/01F/192337105531_P01.jpg", UPC: "192337105531", Brand: "CELINE", Model: "CL40216U", Color: "01F", Quantity: 250 },
  { POID: 38, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40238U/01A/192337121326_P01.jpg", UPC: "192337121326", Brand: "CELINE", Model: "CL40238U", Color: "01A", Quantity: 50 },
  { POID: 38, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40064U/01Y/192337077180_P01.jpg", UPC: "192337077180", Brand: "LOEWE", Model: "LW40064U", Color: "01Y", Quantity: 100 },

  //POID39 has total of 5 random items adding up to 560
  { POID: 39, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40254U/30N/192337133282_P01.jpg", UPC: "192337133282", Brand: "CELINE", Model: "CL40254U", Color: "30N", Quantity: 300 },
  { POID: 39, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40149I/52V/192337151002_P01.jpg", UPC: "192337151002", Brand: "DIOR WOMAN", Model: "CD40149I ", Color: "20B0", Quantity: 100 },
  { POID: 39, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40157I/01B/192337167362_P01.jpg", UPC: "192337133282", Brand: "DIOR WOMAN", Model: "CD40157I", Color: "10A1", Quantity: 50 },
  { POID: 39, ImageURL: "https://images2.designereyes.com/ftp/images3/CELINE/CL40239F/01F/192337121371_P01.jpg", UPC: "192337121371", Brand: "CELINE", Model: "CL40239F", Color: "01F", Quantity: 50 },
  { POID: 39, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40157I/96B/192337167409_P01.jpg", UPC: "192337167409", Brand: "DIOR WOMAN", Model: "CD40157I", Color: "55A1", Quantity: 60 },

  //POID40 has total of 4 random items adding up to 800
  { POID: 40, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50087I/086/192337164439_P01.jpg", UPC: "192337164439", Brand: "DIOR WOMAN", Model: "CD50087I", Color: "8000", Quantity: 100 },
  { POID: 40, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/001/192337157936_P01.jpg", UPC: "192337157936", Brand: "FENDI", Model: "FE50085I", Color: "001", Quantity: 150 },
  { POID: 40, ImageURL: "https://images2.designereyes.com/ftp/images3/LOEWE/LW40108I/96C/192337136429_P01.jpg", UPC: "192337136429", Brand: "LOEWE", Model: "LW40108I", Color: "96C", Quantity: 150 },
  { POID: 40, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE50085I/021/192337157943_P01.jpg", UPC: "192337157943", Brand: "FENDI", Model: "FE50085I", Color: "021", Quantity: 400 },

  //POID41 has total of 3 random items adding up to 799
  { POID: 41, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD50102I/093/192337162268_P01.jpg", UPC: "192337162268", Brand: "DIOR WOMAN", Model: "CD50102I", Color: "5500", Quantity: 300 },
  { POID: 41, ImageURL: "https://images2.designereyes.com/ftp/images3/DIOR/CD40144F/01B/192337150708_P01.jpg", UPC: "192337150708", Brand: "DIOR WOMAN", Model: "CD40144F", Color: "01B", Quantity: 150 },
  { POID: 41, ImageURL: "https://images2.designereyes.com/ftp/images3/FENDI/FE40098I/01W/192337134227_P01.jpg", UPC: "192337134227", Brand: "FENDI", Model: "FE40098I", Color: "01W", Quantity: 349 },

];


type PurchaseOrderTableItems = {
  POID: number;
  AccountID: number;
  Products: number;
  Quantity: number;
  Date: string;
  Status: string;
};

const dummyAccountPO: PurchaseOrderTableItems[] = [
  {
    POID: 1,
    AccountID: 1,
    Products: 3,
    Quantity: 450,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 2,
    AccountID: 1,
    Products: 5,
    Quantity: 1200,
    Date: '2024-02-28',
    Status: 'unfulfilled'
  },
  {
    POID: 3,
    AccountID: 1,
    Products: 4,
    Quantity: 875,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 4,
    AccountID: 2,
    Products: 3,
    Quantity: 650,
    Date: '2024-02-28',
    Status: 'unfulfilled'
  },
  {
    POID: 5,
    AccountID: 2,
    Products: 5,
    Quantity: 300,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 6,
    AccountID: 1,
    Products: 4,
    Quantity: 1500,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 7,
    AccountID: 2,
    Products: 3,
    Quantity: 1100,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 8,
    AccountID: 1,
    Products: 4,
    Quantity: 950,
    Date: '2024-02-28',
    Status: 'unfulfilled'
  },
  {
    POID: 9,
    AccountID: 1,
    Products: 6,
    Quantity: 500,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 10,
    AccountID: 1,
    Products: 3,
    Quantity: 700,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 11,
    AccountID: 2,
    Products: 4,
    Quantity: 873,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 12,
    AccountID: 3,
    Products: 4,
    Quantity: 453,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 13,
    AccountID: 3,
    Products: 6,
    Quantity: 1516,
    Date: '2024-02-28',
    Status: 'unfulfilled'
  },
  {
    POID: 14,
    AccountID: 4,
    Products: 5,
    Quantity: 1507,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 15,
    AccountID: 3,
    Products: 5,
    Quantity: 1788,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 16,
    AccountID: 4,
    Products: 4,
    Quantity: 1505,
    Date: '2024-02-28',
    Status: 'unfulfilled'
  },
  {
    POID: 17,
    AccountID: 3,
    Products: 3,
    Quantity: 799,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 18,
    AccountID: 2,
    Products: 5,
    Quantity: 1751,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 19,
    AccountID: 4,
    Products: 2,
    Quantity: 791,
    Date: '2024-02-28',
    Status: 'unfulfilled'
  },
  {
    POID: 20,
    AccountID: 5,
    Products: 4,
    Quantity: 1500,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 21,
    AccountID: 5,
    Products: 6,
    Quantity: 500,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 22,
    AccountID: 5,
    Products: 7,
    Quantity: 700,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 23,
    AccountID: 5,
    Products: 6,
    Quantity: 873,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 24,
    AccountID: 6,
    Products: 4,
    Quantity: 453,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 25,
    AccountID: 6,
    Products: 6,
    Quantity: 891,
    Date: '2024-02-28',
    Status: 'unfulfilled'
  },
  {
    POID: 26,
    AccountID: 7,
    Products: 5,
    Quantity: 352,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 27,
    AccountID: 6,
    Products: 5,
    Quantity: 245,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 28,
    AccountID: 7,
    Products: 4,
    Quantity: 674,
    Date: '2024-02-28',
    Status: 'unfulfilled'
  },
  {
    POID: 29,
    AccountID: 6,
    Products: 3,
    Quantity: 350,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 30,
    AccountID: 7,
    Products: 5,
    Quantity: 250,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 31,
    AccountID: 8,
    Products: 2,
    Quantity: 791,
    Date: '2024-02-28',
    Status: 'unfulfilled'
  },  
  {
    POID: 32,
    AccountID: 8,
    Products: 4,
    Quantity: 800,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 33,
    AccountID: 8,
    Products: 3,
    Quantity: 500,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 34,
    AccountID: 8,
    Products: 2,
    Quantity: 700,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 35,
    AccountID: 8,
    Products: 3,
    Quantity: 400,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 36,
    AccountID: 9,
    Products: 4,
    Quantity: 453,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 37,
    AccountID: 9,
    Products: 6,
    Quantity: 820,
    Date: '2024-02-28',
    Status: 'unfulfilled'
  },
  {
    POID: 38,
    AccountID: 9,
    Products: 5,
    Quantity: 750,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 39,
    AccountID: 9,
    Products: 5,
    Quantity: 560,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
  {
    POID: 40,
    AccountID: 9,
    Products: 4,
    Quantity: 800,
    Date: '2024-02-28',
    Status: 'unfulfilled'
  },
  {
    POID: 41,
    AccountID: 10,
    Products: 3,
    Quantity: 799,
    Date: '2024-02-28',
    Status: 'fulfilled'
  },
];

const PurchaseOrderTable: FC = () => {
  const { selectedAccountID } = useAccount();
  const [filteredData, setFilteredData] = useState<PurchaseOrderTableItems[]>([]);
  const [selectedPO, setSelectedPO] = useState<PurchaseOrderTableItems | null>(null);
  const [displayModal, setDisplayModal] = useState(false);

  useEffect(() => {
    // Filter the dummyAccountPO data based on the selectedAccountID
    const filtered = dummyAccountPO.filter(item => item.AccountID === parseInt(selectedAccountID));
    setFilteredData(filtered);
  }, [selectedAccountID]);

  const eyewearTemplate = (rowData: PurchaseOrderTableItems) => {
    return (
      <Button
        icon="pi pi-search"
        onClick={() => {
          setSelectedPO(rowData);
          setDisplayModal(true);
        }}
        className="p-button-rounded p-button-info"
        aria-label="View Details"
      />
    );
  };

  const imageTemplate = (rowData: EyewearItem) => {
    return <img src={rowData.ImageURL} alt="Product" style={{ width: '100px', height: 'auto' }} />;
  };

  const detailTemplate = (purchaseOrder: PurchaseOrderTableItems | null) => {
    if (!purchaseOrder) return <div>No Details</div>;

    const eyewearDetails = dummyEyewearData.filter(item => item.POID === purchaseOrder.POID);
    return (
      <div>
        <h5>Eyewear Details for POID: {purchaseOrder.POID}</h5>
        <DataTable value={eyewearDetails} className="p-datatable-sm">
          <Column field="UPC" header="UPC"></Column>
          <Column body={imageTemplate} header="Image"></Column>
          <Column field="Brand" header="Brand"></Column>
          <Column field="Model" header="Model"></Column>
          <Column field="Color" header="Color"></Column>
          <Column field="Quantity" header="Quantity"></Column>
        </DataTable>
      </div>
    );
  };

  return (
    <div>
      <DataTable value={filteredData} paginator rows={10} className="p-datatable-striped">
        <Column body={eyewearTemplate} headerStyle={{ width: '4em', textAlign: 'center' }}></Column>
        <Column field="POID" header="PO ID"></Column>
        <Column field="Products" header="Products"></Column>
        <Column field="Quantity" header="Quantity"></Column>
        <Column field="Date" header="Date"></Column>
        <Column field="Status" header="Status"></Column>
      </DataTable>
      
      <Dialog header="Purchase Order Details" visible={displayModal} style={{ width: '50vw' }} modal onHide={() => setDisplayModal(false)}>
        {detailTemplate(selectedPO)}
      </Dialog>
    </div>
  );
};

export { PurchaseOrderTable };