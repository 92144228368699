import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_designereyes/layout/core'
import {useAuth} from '../../../app/modules/auth'
import {
  ConsumerMetricsMap,
  ConsumerMetricsMapUSA,
  ConsumerMetricsTable,
  CostaConsumerMetricsTable,
} from '../../../_designereyes/partials/widgets/'
import React, {useState} from 'react'

const ConsumerMetricsPage = () => {
  const {currentUser} = useAuth()

  const [selectedCountry, setSelectedCountry] = useState('Worldwide')

  const buttonGroupStyle = {
    boxShadow: '0 2px 1px 2px rgba(0,0,0,0.3)',
    borderRadius: '0.25rem',
    background: '#1A233F',
  }

  const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem 0',
  }

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value)
  }

  const renderMap = () => {
    if (selectedCountry === 'USA') {
      return (
        <ConsumerMetricsMapUSA
          selectedCountry={selectedCountry}
          vendor={currentUser?.decoded?.vendorName}
        />
      )
    } else {
      return (
        <ConsumerMetricsMap
          selectedCountry={selectedCountry}
          vendor={currentUser?.decoded?.vendorName}
        />
      )
    }
  }

  return (
    <>
      {currentUser?.decoded?.vendorName === 'Luxottica' && (
        <div>
          <div style={centerStyle}>
            <div
              className='btn-group mb-5'
              role='group'
              aria-label='Country selector'
              style={buttonGroupStyle}
            >
              {['Worldwide', 'USA', 'Europe'].map((country) => (
                <button
                  key={country}
                  type='button'
                  className={`btn ${
                    selectedCountry === country ? 'btn-primary' : 'btn-outline-primary'
                  }`}
                  onClick={() => setSelectedCountry(country)}
                >
                  {country}
                </button>
              ))}
            </div>
          </div>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-7 col-lg-7'>{renderMap()}</div>
            <div className='col-md-5 col-lg-5'>
              <ConsumerMetricsTable
                selectedCountry={selectedCountry}
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>
        </div>
      )}
      {currentUser?.decoded?.vendorName === 'Thelios' && (
        <div>
          <div style={centerStyle}>
            <div
              className='btn-group mb-5'
              role='group'
              aria-label='Country selector'
              style={buttonGroupStyle}
            >
              {['Worldwide', 'USA', 'Europe'].map((country) => (
                <button
                  key={country}
                  type='button'
                  className={`btn ${
                    selectedCountry === country ? 'btn-primary' : 'btn-outline-primary'
                  }`}
                  onClick={() => setSelectedCountry(country)}
                >
                  {country}
                </button>
              ))}
            </div>
          </div>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-7 col-lg-7'>{renderMap()}</div>
            <div className='col-md-5 col-lg-5'>
              <ConsumerMetricsTable
                selectedCountry={selectedCountry}
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>
        </div>
      )}
            {currentUser?.decoded?.vendorName === 'Costa' && (
        <div>
          <div style={centerStyle}>
            <div
              className='btn-group mb-5'
              role='group'
              aria-label='Country selector'
              style={buttonGroupStyle}
            >
              {['Worldwide', 'USA', 'Europe'].map((country) => (
                <button
                  key={country}
                  type='button'
                  className={`btn ${
                    selectedCountry === country ? 'btn-primary' : 'btn-outline-primary'
                  }`}
                  onClick={() => setSelectedCountry(country)}
                >
                  {country}
                </button>
              ))}
            </div>
          </div>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-7 col-lg-7'>{renderMap()}</div>
            <div className='col-md-5 col-lg-5'>
              <CostaConsumerMetricsTable
                selectedCountry={selectedCountry}
                vendor={currentUser?.decoded?.vendorName}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const ConsumerMetricsWrapper: FC = () => {
  const intl = useIntl()
  const currentUser = useAuth()
  return (
    <>
      <PageTitle>Consumer Metrics</PageTitle>
      <ConsumerMetricsPage />
    </>
  )
}

export {ConsumerMetricsWrapper}
