/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { PageTitle } from '../../../../_designereyes/layout/core'
import { DistributionUnitsSoldCard, AverageOrderValueCard, AccountPerformanceTable } from '../../../../_designereyes/partials/widgets'
import { useAccount } from '../../../../_designereyes/partials/widgets'
import { AccountInfoCard, ContractCard, OpenPOCard, TotalBalanceCard } from '../../../../_designereyes/partials/widgets'
import { StoreDetailsTable, PurchaseOrderTable } from '../../../../_designereyes/partials/widgets'


const IsraelPage = () => {
  return (
    <>
      <div className='row g-12 g-xl-12'>
        <div className='col-xl-3 col-lg-3'>
          <div className='mb-5 mb-xl-10'>
            <AverageOrderValueCard totalOrders={952} averageOrderValue={189402}/>
          </div>
          <div className='mb-5 mb-xl-10'>
          <DistributionUnitsSoldCard totalUnitsSold={47883} averageMonthlySales={149}/>
          </div>
        </div>
        <div className='col-xl-9 col-lg-9'>
          <div className='mb-5 mb-xl-10'>
            <AccountPerformanceTable country="Israel"/>
          </div>
        </div>
      </div>
    </>
  )
}

const AccountPage: FC = () => {
  const { selectedAccountID } = useAccount();
  const { handleSelectAccount } = useAccount();

  const selectAccount = (accountID) => {
    handleSelectAccount(accountID);
  };

  useEffect(() => {
    return () => {
      handleSelectAccount("");
    };
  }, [handleSelectAccount]);

  return (
    <>
      <button className={'btn btn-sm btn-primary'} onClick={() => selectAccount("")}>Back</button>
      <div className='row g-5 g-xl-10 mt-1'>
        <div className='col-xl-4 col-lg-4'>
          <div className='mb-5 mb-xl-10'>
            <AccountInfoCard />
          </div>
        </div>
        <div className='col-xl-3 col-lg-3'>
          <div className='mb-5 mb-xl-10'>
            <OpenPOCard />
          </div>
        </div>

        <div className='col-xl-3 col-lg-3'>
          <div className='mb-5 mb-xl-10'>
            <TotalBalanceCard />
          </div>
        </div>
        <div className='col-xl-2 col-lg-2'>
          <div className='mb-5 mb-xl-10'>
            <ContractCard />
          </div>
        </div>
      </div>

      <div className='row g-12 g-xl-12'>
        <div className='col-xl-6 col-lg-6'>
          <div className='mb-5 mb-xl-10'>
            <StoreDetailsTable />
          </div>
        </div>
        <div className='col-xl-6 col-lg-6'>
          <div className='mb-5 mb-xl-10'>
            <PurchaseOrderTable />
          </div>
        </div>
      </div>
    </>
  )
}

export const IsraelWrapper: FC = () => {
  const { selectedAccountID } = useAccount();

  return (
    <>
      <PageTitle>Israel Distribution</PageTitle>
      {
        (selectedAccountID === "") 
        ? <IsraelPage />
        : <AccountPage />
      }
    </>
  )
}
