import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {useAuth} from '../../../../../app/modules/auth'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  roleNeeded?: string[]
  projectNameNeeded?: string[]
  vendorNameNeeded?: string[],
  customIconHtml?: JSX.Element
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  roleNeeded,
  projectNameNeeded,
  vendorNameNeeded,
  customIconHtml,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const {currentUser} = useAuth()
  const userRole = currentUser?.decoded?.role
  const userProjectName = currentUser?.decoded?.projectName
  const userVendorName = currentUser?.decoded?.vendorName
  if (
    (roleNeeded && userRole && !roleNeeded.includes(userRole)) ||
    (projectNameNeeded && (!userProjectName || !projectNameNeeded.includes(userProjectName))) ||
    (vendorNameNeeded && (!userVendorName || !vendorNameNeeded.includes(userVendorName)))
  ) {
    return null;
  }

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
      {customIconHtml ? customIconHtml : null}
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon text-white'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title' style={{userSelect: "none"}}>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
